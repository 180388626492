import React from 'react';
import { Route, Routes, Outlet } from 'react-router-dom';
import settings from 'configs/constants/settings';

import Sidebar from './components/sidebar';
import ErrorMessages from 'components/errorMessages';

import PageHeader from '../components/PageHeader';
import SelectPage from '../components/SelectPage';

import ProjectAdd from 'helpdesk/settings/projects/projectAdd';
import ProjectEdit from 'helpdesk/components/projects/projectEdit';

import AccessDenied from 'components/accessDenied';

import TaskList from './task/tasklist';

import NotificationList from 'components/notifications';

import RepeatsList from 'helpdesk/components/repeat/repeatsListLoader';
import CompanyList from 'helpdesk/settings/companies';
import CompanyEdit from 'helpdesk/settings/companies/companyEdit';
import UserList from 'helpdesk/settings/users';
import WebChat from './webchat';

import AISort from 'helpdesk/settings/ai/ai-sort';
import AISolutions from 'helpdesk/settings/ai/ai-solutions';
import AINotifications from 'helpdesk/settings/ai/ai-notifications';
import AIStatistics from 'helpdesk/settings/ai/ai-statistics';
import Statistics from 'helpdesk/statistics';

import { getMyData } from 'helperFunctions';

import { webchat } from 'configs/restAPI';

export default function Navigation(props) {
    const currentUser = getMyData();
    const accessRights =
        currentUser && currentUser.role
            ? currentUser.role.accessRights
            : {};

    return (
        <Routes>
            <Route
                path="helpdesk/*"
                element={
                    <div>
                        <div className="page-header">
                            <div className="center-ver row center flex">
                                <SelectPage />
                                <PageHeader />
                            </div>
                        </div>

                        <div className="row center center-ver">
                            <Sidebar {...props} />
                            <div className="main">
                                <Outlet />
                            </div>
                        </div>
                    </div>
                }
            >
                <Route
                    path="errorMessages"
                    element={
                        accessRights.viewErrors ? (
                            <ErrorMessages />
                        ) : (
                            <AccessDenied />
                        )
                    }
                />

                <Route
                    path="notifications"
                    element={<NotificationList />}
                />

                <Route
                    path="notifications/:notificationID"
                    element={<NotificationList />}
                />

                <Route
                    path="taskList/i/:filterID"
                    element={<TaskList />}
                />
                <Route
                    path="taskList/i/:filterID/p/:page/:taskID"
                    element={<TaskList />}
                />
                <Route
                    path="taskList/i/:filterID/p/:page"
                    element={<TaskList />}
                />
                <Route
                    path="taskList/i/:filterID/:taskID"
                    element={<TaskList />}
                />

                {accessRights.addProjects ? (
                    <Route
                        path="project/add"
                        element={<ProjectAdd />}
                    />
                ) : (
                    <Route
                        path="project/add"
                        element={<AccessDenied />}
                    />
                )}

                <Route
                    path="project/:projectID/:tabId"
                    element={<ProjectEdit />}
                />

                <Route
                    path="repeats"
                    element={<RepeatsList />}
                />

                <Route
                    path="companies"
                    element={
                        accessRights.companies ? (
                            <CompanyList />
                        ) : (
                            <AccessDenied />
                        )
                    }
                />
                <Route
                    path="companies/:id"
                    element={
                        accessRights.companies ? (
                            <CompanyList />
                        ) : (
                            <AccessDenied />
                        )
                    }
                />

                <Route
                    path="users"
                    element={
                        accessRights.companies ? (
                            <UserList />
                        ) : (
                            <AccessDenied />
                        )
                    }
                />
                <Route
                    path="users/:id"
                    element={
                        accessRights.companies ? (
                            <UserList />
                        ) : (
                            <AccessDenied />
                        )
                    }
                />

                {webchat && (
                    <Route path="webchat">
                        <Route
                            path=""
                            element={<WebChat />}
                        />
                        <Route
                            path=":id"
                            element={<WebChat />}
                        />
                    </Route>
                )}

                <Route
                    path="statistics"
                    element={
                        accessRights.statisticsProject ||
                        accessRights.statisticsCustomer ||
                        accessRights.statisticsTasks ? (
                            <Statistics />
                        ) : (
                            <AccessDenied />
                        )
                    }
                />

                {/* SETTINGS */}
                <Route path="settings">
                    {accessRights.companies && (
                        <Route
                            path="company/:id"
                            element={<CompanyEdit />}
                        />
                    )}
                    {settings
                        .filter(
                            (item) => item.link !== 'ai'
                        )
                        .map((item) => (
                            <Route
                                key={item.link}
                                path={`${item.link}`}
                                element={
                                    accessRights[
                                        item.value
                                    ] ||
                                    item.value ===
                                        'webchat' ||
                                    item.value ===
                                        'taskTypes' ? (
                                        item.element
                                    ) : (
                                        <AccessDenied />
                                    )
                                }
                            />
                        ))}
                    {settings
                        .filter(
                            (item) => item.link !== 'ai'
                        )
                        .map((item) => (
                            <Route
                                key={item.link}
                                path={`${item.link}/:id`}
                                element={
                                    accessRights[
                                        item.value
                                    ] ? (
                                        item.element
                                    ) : (
                                        <AccessDenied />
                                    )
                                }
                            />
                        ))}
                    <Route path="ai">
                        <Route
                            path=""
                            element={
                                accessRights.aiSettings ? (
                                    <AISort />
                                ) : (
                                    <AccessDenied />
                                )
                            }
                        />
                        <Route
                            path="sort"
                            element={
                                accessRights.aiSettings ? (
                                    <AISort />
                                ) : (
                                    <AccessDenied />
                                )
                            }
                        />
                        <Route
                            path="solutions"
                            element={
                                accessRights.aiSettings ? (
                                    <AISolutions />
                                ) : (
                                    <AccessDenied />
                                )
                            }
                        />
                        <Route
                            path="notifications"
                            element={
                                accessRights.aiSettings ? (
                                    <AINotifications />
                                ) : (
                                    <AccessDenied />
                                )
                            }
                        />
                        <Route
                            path="statistics"
                            element={
                                accessRights.aiSettings ? (
                                    <AIStatistics />
                                ) : (
                                    <AccessDenied />
                                )
                            }
                        />
                    </Route>
                </Route>
            </Route>

            <Route path="*" element={null} />
        </Routes>
    );
}
