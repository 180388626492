import React from 'react';

import { NavLink, useNavigate } from 'react-router-dom';
import GeneralPopover from 'components/generalPopover';

import { useTranslation } from 'react-i18next';
import { getMyData } from 'helperFunctions';
import { defaultFilter } from 'configs/constants/filter';

export default function PageHeader() {
    const navigate = useNavigate();
    const currentUser = getMyData();
    const accessRights = currentUser && currentUser.role ? currentUser.role.accessRights : {};
    const { t } = useTranslation();

    const [open, setOpen] = React.useState(false);
    let subpage = 0;

    if (window.location.pathname.includes('/helpdesk')) {
        subpage = 1;
    }
    if (window.location.pathname.includes('/lanwiki')) {
        subpage = 2;
    }
    if (window.location.pathname.includes('/cmdb')) {
        subpage = 3;
    }
    if (window.location.pathname.includes('/lanpass')) {
        subpage = 4;
    }

    const selectSubpageTitle = () => {
        let subpageTitle = t('lanhelpdesk');
        if (subpage === 2) {
            subpageTitle = t('lanwiki');
        } else if (subpage === 3) {
            subpageTitle = t('cmdb');
        } else if (subpage === 4) {
            subpageTitle = t('lanpass');
        }
        return subpageTitle;
    };
    const selectSubpageLink = () => {
        let subpageLink = `/helpdesk/taskList/i/${defaultFilter}`;
        if (subpage === 2) {
            subpageLink = '/lanwiki/i/all';
        } else if (subpage === 3) {
            subpageLink = '/cmdb/i/all';
        } else if (subpage === 4) {
            subpageLink = '/lanpass/i/all';
        }
        return subpageLink;
    };

    return (
        <div className="width-270 page-header row">
            {false && (accessRights.lanwiki || accessRights.pass || accessRights.cmdb) && (
                <GeneralPopover
                    placement="bottom-start"
                    className="overflow-auto max-height-200 min-width-0"
                    target="page-select-popover"
                    open={open}
                    close={() => setOpen(false)}
                    hideButtons="hideButtons"
                >
                    <NavLink
                        className={({ isActive }) => `link-item ${isActive ? 'active' : ''}`}
                        to={`/helpdesk/taskList/i/all`}
                        onClick={() => {
                            setOpen(false);
                        }}
                    >
                        {t('lanhelpdesk')}
                    </NavLink>
                    {accessRights.lanwiki && (
                        <NavLink
                            className={({ isActive }) => `link-item ${isActive ? 'active' : ''}`}
                            to={`/lanwiki/i/all`}
                            onClick={() => {
                                setOpen(false);
                            }}
                        >
                            {t('lanwiki')}
                        </NavLink>
                    )}
                    {accessRights.cmdb && (
                        <NavLink
                            className={({ isActive }) => `link-item ${isActive ? 'active' : ''}`}
                            to={`/cmdb/i/all`}
                            onClick={() => {
                                setOpen(false);
                            }}
                        >
                            {t('cmdb')}
                        </NavLink>
                    )}
                    {accessRights.pass && (
                        <NavLink
                            className={({ isActive }) => `link-item ${isActive ? 'active' : ''}`}
                            to={`/lanpass/i/all`}
                            onClick={() => {
                                setOpen(false);
                            }}
                        >
                            {t('lanpass')}
                        </NavLink>
                    )}
                </GeneralPopover>
            )}
            <div className="lansystems-title">
                {false && (accessRights.lanwiki || accessRights.lanpass || accessRights.cmdb) && (
                    <button
                        className="btn-link color-white center-hor m-l-15"
                        id="page-select-popover"
                        onClick={() => setOpen(true)}
                    >
                        <span className="material-symbols-outlined">widgets</span>
                    </button>
                )}
                <h1
                    className="center-hor clickable noselect m-l-15"
                    onClick={() => navigate(selectSubpageLink())}
                >
                    {selectSubpageTitle()}
                </h1>
            </div>
        </div>
    );
}
