import React from 'react';

import {
    useQuery,
    useSubscription,
    useMutation,
} from '@apollo/client';

import SettingLoading from '../components/settingLoading';
import SettingListContainer from '../components/settingListContainer';
import { itemAttributesFullfillsString } from '../components/helpers';
import DeleteReplacement from 'components/deleteReplacement';

import CompanyAdd from './companyAdd';
import CompanyEdit from './companyEdit';
import {
    GET_COMPANIES,
    COMPANIES_SUBSCRIPTION,
    DELETE_COMPANY,
} from 'helpdesk/queries';
import { toSelArr } from 'helperFunctions';
import { useTranslation } from 'react-i18next';
import { addLocalError } from 'apollo/localSchema/actions';

export default function CompaniesList() {
    const { t } = useTranslation();

    const {
        data: companiesData,
        loading: companiesLoading,
        refetch: companiesRefetch,
    } = useQuery(GET_COMPANIES, {
        fetchPolicy: 'network-only',
    });

    useSubscription(COMPANIES_SUBSCRIPTION, {
        onData: () => {
            companiesRefetch();
        },
    });

    const [deleteCompany] = useMutation(DELETE_COMPANY);

    // state
    const [companyFilter, setCompanyFilter] =
        React.useState('');
    const [editedItemId, setEditedItemId] =
        React.useState(null);
    const [itemToDeleteId, setItemToDeleteId] =
        React.useState(null);

    const deleteCompanyFunc = (replacement) => {
        if (window.confirm(t('generalConfirmation'))) {
            deleteCompany({
                variables: {
                    id: itemToDeleteId,
                    newId: parseInt(replacement.id),
                },
            })
                .then(() => {
                    setItemToDeleteId(null);
                })
                .catch((err) => {
                    addLocalError(err);
                });
        }
    };

    if (companiesLoading) {
        return <SettingLoading />;
    }

    const companies = companiesData.companies.filter(
        (company) => !company.def
    );

    return (
        <SettingListContainer
            header={t('companies')}
            addLabel={t('company')}
            filter={companyFilter}
            setFilter={setCompanyFilter}
            AddComponent={CompanyAdd}
            EditComponent={CompanyEdit}
            editedItemId={editedItemId}
            setEditedItemId={setEditedItemId}
        >
            <table className="table">
                <thead>
                    <tr>
                        <th>{t('title')}</th>
                        <th width="100px">
                            {t('actions')}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {companies
                        .filter((item) =>
                            itemAttributesFullfillsString(
                                item,
                                companyFilter,
                                ['title']
                            )
                        )
                        .map((company) => (
                            <tr key={company.id}>
                                <td>{company.title}</td>
                                <td className="row">
                                    <button
                                        className="btn-link-red m-r-0 m-l-0 p-0"
                                        onClick={() => {
                                            setItemToDeleteId(
                                                company.id
                                            );
                                        }}
                                    >
                                        <span className="material-symbols-outlined">
                                            delete
                                        </span>
                                    </button>
                                    <button
                                        className="btn-link m-r-0 m-l-10 p-0"
                                        onClick={() => {
                                            setEditedItemId(
                                                company.id
                                            );
                                        }}
                                    >
                                        <span className="material-symbols-outlined">
                                            edit
                                        </span>
                                    </button>
                                </td>
                            </tr>
                        ))}
                </tbody>
            </table>

            <DeleteReplacement
                isOpen={itemToDeleteId ? true : false}
                label={t('company')}
                options={toSelArr(
                    companies.filter(
                        (company) =>
                            company.id !== itemToDeleteId
                    )
                )}
                close={() => setItemToDeleteId(null)}
                finishDelete={deleteCompanyFunc}
            />
        </SettingListContainer>
    );
}
