import React from 'react';
import Checkbox from 'components/checkbox';

export default function SubtasksPrint( props ) {

  const {
    task,
    t
  } = props;

  const {
    subtasks
  } = task;

  const columns = [
    {
      label: null,
      key: 'done',
    },{
      label: t('title'),
      key: 'title',
    },{
      label: t('assignedTo'),
      key: 'assignedTo',
      key2: 'fullName',
    },{
      label: t('quantityShort'),
      key: 'quantity',
    } ];

    if (subtasks.length === 0){
      return null;
    }

  //const sortedSubtasks = subtasks.sort((s1,s2) => s1.order > s2.order ? 1 : -1);

  return (
    <div>
      <label>{t('works')}</label>
    <table>
      <thead>
        <tr>
        {
          columns.map((column) => (
            <th key={column.key}>{column.label}</th>
          ))
        }
      </tr>
      </thead>
      <tbody>
        {
          subtasks.map((subtask) => {
            return (
              <tr key={subtask.id}>
                {
                  columns.map((column) => {
                    if (column.key === "done"){
                      return (
                        <td key={column.key}>
                          <Checkbox
                            readOnly={true}
                            className="m-t-5 segoe-blue-text"
                            value={ subtask.done }
                            />
                        </td>
                      )
                    }
                    if (column.key === "assignedTo"){
                      return (
                        <td key={column.key}>{subtask[column.key][column.key2]}</td>
                      )
                    }
                    return (<td key={column.key}>{subtask[column.key]}</td>)
                  })
                }
              </tr>
            )
          })
        }
      </tbody>
    </table>
  </div>
  )

}
