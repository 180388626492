import React from 'react';
import { useMutation, useQuery } from '@apollo/client';
import Loading from 'components/loading';
import SubfolderEdit from './edit';
import { getMyData } from 'helperFunctions';
import { GET_SUBFOLDER, UPDATE_SUBFOLDER, DELETE_SUBFOLDER } from 'lanwiki/queries';

export default function EditSubfolderLoader(props) {
    const { id } = props;
    const currentUser = getMyData();

    const { data: subfolderData, loading: subfolderLoading } = useQuery(GET_SUBFOLDER, {
        variables: {
            id,
        },
        fetchPolicy: 'network-only',
    });

    const [updateSubfolder] = useMutation(UPDATE_SUBFOLDER);
    const [deleteSubfolder] = useMutation(DELETE_SUBFOLDER);

    if (subfolderLoading) {
        return <Loading />;
    }

    return (
        <SubfolderEdit
            {...props}
            updateSubfolder={updateSubfolder}
            deleteSubfolder={deleteSubfolder}
            subfolder={subfolderData.lanwikiSubfolder}
            currentUser={currentUser}
        />
    );
}
