//import moment from 'moment';
import * as fns from 'date-fns';
import {
  makeVar
} from "@apollo/client";

//const currentDate = moment();
const currentDate = new Date();
//const weekStart = currentDate.clone().startOf( 'isoWeek' );
const weekStart = fns.startOfISOWeek(new Date(currentDate.valueOf()));
//const weekEnd = currentDate.clone().endOf( 'isoWeek' );
const weekEnd = fns.endOfISOWeek(new Date(currentDate.valueOf()));

//export const fromVar = makeVar( weekStart.valueOf() );
export const fromVar = makeVar( fns.getUnixTime(weekStart) * 1000 );
//export const toVar = makeVar( weekEnd.valueOf() );
export const toVar = makeVar( fns.getUnixTime(weekEnd) * 1000 );

export const calendarUserIdVar = makeVar( null );
