import React from 'react';
import {
    useQuery,
    useMutation,
    useSubscription,
} from '@apollo/client';
import {
    toSelArr,
    extractImages,
    replacePlaceholdersWithLinks,
    childrenSubfoldersFlat,
} from 'helperFunctions';
import axios from 'axios';
import Empty from 'components/Empty';
import { Modal, ModalBody } from 'reactstrap';
import LanwikiPageForm from 'lanwiki/pages/form';
import { useTranslation } from 'react-i18next';

import { REST_URL } from 'configs/restAPI';

import {
    GET_TAGS,
    TAGS_SUBSCRIPTION,
    GET_FOLDERS,
    FOLDERS_SUBSCRIPTION,
    ADD_PAGE,
    UPDATE_PAGE,
} from 'lanwiki/queries';

import { GET_PROJECT } from 'apollo/queries';

export default function PageAddContainer(props) {
    const { folderId, tagId, sidebarFolder } = props;

    const { t } = useTranslation();

    const { data: projectData } = useQuery(GET_PROJECT);
    const localProject = projectData.localProject;

    const {
        data: tagsData,
        loading: tagsLoading,
        refetch: tagsRefetch,
    } = useQuery(GET_TAGS, {
        variables: {
            projectId: localProject.project.id,
        },
        fetchPolicy: 'network-only',
    });

    useSubscription(TAGS_SUBSCRIPTION, {
        onData: () => {
            tagsRefetch({
                projectId: localProject.project.id,
            });
        },
    });

    const {
        data: foldersData,
        loading: foldersLoading,
        refetch: foldersRefetch,
    } = useQuery(GET_FOLDERS, {
        fetchPolicy: 'network-only',
        variables: {
            projectId: localProject.project.id,
            archived: false,
        },
    });

    useSubscription(FOLDERS_SUBSCRIPTION, {
        onData: () => {
            foldersRefetch({
                projectId: localProject.project.id,
                archived: false,
            });
        },
    });

    //mutations
    const [addPage] = useMutation(ADD_PAGE);
    const [updatePage] = useMutation(UPDATE_PAGE);

    //state
    const [open, setOpen] = React.useState(false);

    const tags = tagsLoading ? [] : tagsData.lanwikiTags;
    const folders = foldersLoading
        ? []
        : foldersData.lanwikiFolders;

    return (
        <Empty>
            <button
                className="btn sidebar-btn"
                onClick={() => {
                    setOpen(true);
                }}
            >
                <span className="material-symbols-outlined bigger">
                    add
                </span>
                {`${t('page')}`}
            </button>
            <Modal
                isOpen={open}
                className="task-add-container"
                modalClassName="overflow-x-auto"
            >
                <ModalBody>
                    {open && (
                        <LanwikiPageForm
                            edit={false}
                            addPage={(
                                data,
                                setSaving,
                                afterAdd
                            ) => {
                                setSaving(true);
                                const separatedData =
                                    extractImages(
                                        data.body
                                    );
                                data.body =
                                    separatedData.value;
                                addPage({ variables: data })
                                    .then((response1) => {
                                        const id =
                                            response1.data
                                                .addLanwikiPage
                                                .id;
                                        if (
                                            separatedData
                                                .files
                                                .length > 0
                                        ) {
                                            const formData =
                                                new FormData();
                                            separatedData.files.forEach(
                                                (file) =>
                                                    formData.append(
                                                        `file`,
                                                        file
                                                    )
                                            );
                                            formData.append(
                                                'token',
                                                `${sessionStorage.getItem(
                                                    'acctok'
                                                )}`
                                            );
                                            formData.append(
                                                'lanwikiId',
                                                id
                                            );
                                            axios
                                                .post(
                                                    `${REST_URL}/api/lw-upload-text-images`,
                                                    formData,
                                                    {
                                                        headers:
                                                            {
                                                                'Content-Type':
                                                                    'multipart/form-data',
                                                            },
                                                    }
                                                )
                                                .then(
                                                    (
                                                        response2
                                                    ) => {
                                                        if (
                                                            !response2
                                                                .data
                                                                .ok
                                                        ) {
                                                            setSaving(
                                                                false
                                                            );
                                                            return;
                                                        }
                                                        const newBody =
                                                            replacePlaceholdersWithLinks(
                                                                separatedData.value,
                                                                response2
                                                                    .data
                                                                    .attachments,
                                                                'get-lw-file'
                                                            );
                                                        updatePage(
                                                            {
                                                                variables:
                                                                    {
                                                                        id,
                                                                        body: newBody,
                                                                        title: data.title,
                                                                    },
                                                            }
                                                        )
                                                            .then(
                                                                () => {
                                                                    setSaving(
                                                                        false
                                                                    );
                                                                    afterAdd();
                                                                }
                                                            )
                                                            .catch(
                                                                (
                                                                    e
                                                                ) => {
                                                                    console.log(
                                                                        e
                                                                    );
                                                                    setSaving(
                                                                        false
                                                                    );
                                                                }
                                                            );
                                                    }
                                                );
                                        } else {
                                            setSaving(
                                                false
                                            );
                                            afterAdd();
                                        }
                                    })
                                    .catch((e) => {
                                        console.log(e);
                                        setSaving(false);
                                    });
                            }}
                            close={() => setOpen(false)}
                            allTags={toSelArr(tags)}
                            allFolders={toSelArr(folders)}
                            allSubfolders={toSelArr(folders)
                                .map((folder) =>
                                    folder.subfolders
                                        .map(
                                            (
                                                childSubfolder
                                            ) =>
                                                childrenSubfoldersFlat(
                                                    childSubfolder
                                                ).flat()
                                        )
                                        .flat()
                                )
                                .flat()}
                            folderId={folderId}
                            tagId={tagId}
                            sidebarFolder={sidebarFolder}
                        />
                    )}
                </ModalBody>
            </Modal>
        </Empty>
    );
}
