export const removeStylesFromString = (html) => {
    var divElement = document.createElement('div');
    divElement.innerHTML = html;

    var elements = divElement.getElementsByTagName('*');
    for (var i = 0; i < elements.length; i++) {
        if (
            ((elements[i].getAttribute('style') || '').includes('margin') ||
                (elements[i].getAttribute('style') || '').includes('padding')) &&
            ((elements[i].getAttribute('style') || '').includes(':-') ||
                (elements[i].getAttribute('style') || '').includes(' -'))
        ) {
            elements[i].removeAttribute('style');
        }
    }

    return divElement.innerHTML;
};
