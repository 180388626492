import React from 'react';

import { useParams } from 'react-router-dom';

import { useMutation, useQuery } from '@apollo/client';
import Form from './form';
import Loading from 'components/loading';

import { getMyData } from 'helperFunctions';
import { useTranslation } from 'react-i18next';
import { addLocalError } from 'apollo/localSchema/actions';

import {
    GET_COMPANY,
    UPDATE_COMPANY,
    UPDATE_COMPANY_DEFAULTS,
} from 'helpdesk/queries';

export default function CompanyEdit(props) {
    const { closeModal } = props;

    const params = useParams();

    const id = props.id ? props.id : parseInt(params.id);

    const { t } = useTranslation();

    const {
        data: companyData,
        loading: companyLoading,
        refetch: companyRefetch,
    } = useQuery(GET_COMPANY, {
        variables: {
            id,
        },
        fetchPolicy: 'network-only',
        notifyOnNetworkStatusChange: true,
    });

    const [updateCompany] = useMutation(UPDATE_COMPANY);
    const [updateCompanyDefaults] = useMutation(
        UPDATE_COMPANY_DEFAULTS
    );

    //state
    const [saving, setSaving] = React.useState(false);
    const [newData, setNewData] = React.useState(false);

    const myRights = getMyData().role.accessRights;

    const dataLoading = companyLoading;
    const updateCompanyFunc = (
        data,
        defChanged,
        defDph
    ) => {
        setSaving(true);

        let newRents = data.rents.map((r) => {
            let newRent = {
                id: r.id,
                title: r.title,
                quantity: isNaN(parseInt(r.quantity))
                    ? 0
                    : parseInt(r.quantity),
                cost: isNaN(parseInt(r.unitCost))
                    ? 0
                    : parseInt(r.unitCost),
                price: isNaN(parseInt(r.unitPrice))
                    ? 0
                    : parseInt(r.unitPrice),
            };
            return newRent;
        });

        if (defChanged) {
            updateCompanyDefaults({
                variables: {
                    dph: isNaN(parseInt(defDph))
                        ? 0
                        : parseInt(defDph),
                },
            });
        }

        updateCompany({
            variables: {
                id,
                ...data,
                rents: newRents,
            },
        })
            .then(() => {
                if (newRents.some((rent) => rent.id < 1)) {
                    companyRefetch({
                        id,
                    });
                }
                closeModal();
            })
            .catch((err) => {
                addLocalError(err);
            });

        setSaving(false);
        setNewData(false);
    };

    if (dataLoading) {
        return <Loading />;
    }

    return (
        <Form
            label={
                companyData.company.def
                    ? `${t('edit')} Helpdesk ${t(
                          'company2'
                      )}`
                    : `${t('edit')} ${t(
                          'company2'
                      ).toLowerCase()}`
            }
            onSave={updateCompanyFunc}
            onClose={closeModal}
            saving={saving}
            data={companyData.company}
            newData={newData}
            setNewData={setNewData}
            showContract={myRights.pausals}
        />
    );
}
