import React from 'react';
import FolderAddLoader from './index';
import Empty from 'components/Empty';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { useTranslation } from 'react-i18next';

export default function FolderAddModal(props) {
    const { className, label, headerLabel } = props;

    const { t } = useTranslation();

    const [open, setOpen] = React.useState(false);

    return (
        <Empty>
            <button
                className={'btn-link ' + className}
                onClick={() => setOpen(true)}
            >
                <span className="material-symbols-outlined bigger">
                    add
                </span>
                {label}
            </button>
            {open && (
                <Modal
                    isOpen={open}
                    className="small-modal"
                >
                    <ModalHeader className="p-l-20">{`${t(
                        'add'
                    )} ${headerLabel.toLowerCase()}`}</ModalHeader>
                    <ModalBody>
                        <FolderAddLoader
                            {...props}
                            close={() => setOpen(false)}
                        />
                    </ModalBody>
                </Modal>
            )}
        </Empty>
    );
}
