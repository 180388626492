import React from 'react';

import { useQuery } from '@apollo/client';
import classnames from 'classnames';

import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import SettingsInput from '../components/settingsInput';
import { isEmail } from 'helperFunctions';
import { useTranslation } from 'react-i18next';
import { GET_COMPANY_DEFAULTS } from 'helpdesk/queries';
let fakeID = -1;
const getFakeID = () => {
    return fakeID--;
};
export default function CompanyForm(props) {
    const { label, onSave, onClose, saving, data, newData, setNewData, showContract } = props;

    const { t } = useTranslation();

    const { data: companyDefaultsData, loading: companyDefaultsLoading } = useQuery(
        GET_COMPANY_DEFAULTS,
        {
            fetchPolicy: 'network-only',
        }
    );

    //state
    const [title, setTitle] = React.useState('');
    const [dph, setDph] = React.useState(0);
    const [ico, setIco] = React.useState('');
    const [dic, setDic] = React.useState('');
    const [ic_dph, setIcDph] = React.useState('');
    const [country, setCountry] = React.useState('');
    const [city, setCity] = React.useState('');
    const [street, setStreet] = React.useState('');
    const [zip, setZip] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [phone, setPhone] = React.useState('');
    const [description, setDescription] = React.useState('');

    const [rents, setRents] = React.useState([]);
    const [clearCompanyRents, setClearCompanyRents] = React.useState(false);

    const [defDph, setDefDph] = React.useState(0);
    const [defChanged, setDefChanged] = React.useState(false);

    const [openedTab, setOpenedTab] = React.useState('company');

    //sync
    React.useEffect(() => {
        if (data) {
            setTitle(data.title);
            setDph(data.dph);
            setIco(data.ico);
            setDic(data.dic);
            setIcDph(data.ic_dph);
            setCountry(data.country);
            setCity(data.city);
            setStreet(data.street);
            setZip(data.zip);
            setEmail(data.email ? data.email : '');
            setPhone(data.phone);
            setDescription(data.description);
            let r = data.companyRents.map((rent) => {
                return {
                    id: rent.id,
                    title: rent.title,
                    quantity: rent.quantity,
                    unitPrice: rent.price,
                    unitCost: rent.cost,
                    totalPrice: parseInt(rent.quantity) * parseFloat(rent.price),
                };
            });
            setRents(r);
        }
    }, [data]);

    React.useEffect(() => {
        if (!companyDefaultsLoading) {
            if (data) {
                setDefDph(companyDefaultsData.companyDefaults.dph);
            } else {
                setDefDph(companyDefaultsData.companyDefaults.dph);
                setDph(companyDefaultsData.companyDefaults.dph);
            }
        }
    }, [companyDefaultsLoading, data]);

    const cancelChanges = () => {
        if (data) {
            setTitle(data.title);
            setDph(data.dph);
            setIco(data.ico);
            setDic(data.dic);
            setIcDph(data.ic_dph);
            setCountry(data.country);
            setCity(data.city);
            setStreet(data.street);
            setZip(data.zip);
            setEmail(data.email ? data.email : '');
            setPhone(data.phone);
            setDescription(data.description);
            let r = data.companyRents.map((rent) => {
                return {
                    id: rent.id,
                    title: rent.title,
                    quantity: rent.quantity,
                    unitPrice: rent.price,
                    unitCost: rent.cost,
                    totalPrice: parseInt(rent.quantity) * parseFloat(rent.price),
                };
            });
            setRents(r);
        }
    };

    const attributes = [title, ico];
    const cannotSave =
        saving || attributes.some((attr) => attr === '') || (!isEmail(email) && email.length !== 0);

    return (
        <div
            className={classnames(
                {
                    'p-r-20 p-l-20 scroll-visible fit-with-header': data && data.def,
                },
                'p-t-20 p-b-20'
            )}
        >
            <h2 className="m-b-20">{label}</h2>

            <Nav tabs className="no-border m-b-25">
                <NavItem className="noselect">
                    <NavLink
                        className={classnames(
                            {
                                active: openedTab === 'company',
                            },
                            'clickable',
                            ''
                        )}
                        onClick={() => setOpenedTab('company')}
                    >
                        {t('billingInformation')}
                    </NavLink>
                </NavItem>
                {showContract && (
                    <Empty>
                        <NavItem className="noselect">
                            <NavLink>|</NavLink>
                        </NavItem>
                        <NavItem className="noselect">
                            <NavLink
                                className={classnames(
                                    {
                                        active: openedTab === 'contract',
                                    },
                                    'clickable',
                                    ''
                                )}
                                onClick={() => setOpenedTab('contract')}
                            >
                                {t('contract')}
                            </NavLink>
                        </NavItem>
                    </Empty>
                )}
            </Nav>

            <TabContent activeTab={openedTab}>
                <TabPane tabId={'company'}>
                    <SettingsInput
                        required
                        id="name"
                        label={t('companyTitle')}
                        value={title}
                        onChange={(e) => {
                            setTitle(e.target.value);
                            setNewData(true);
                        }}
                    />

                    <SettingsInput
                        id="dph"
                        label={t('tax')}
                        value={dph}
                        onChange={(e) => {
                            setDph(e.target.value);
                            setNewData(true);
                        }}
                    />

                    <SettingsInput
                        required
                        id="ico"
                        label={t('ico')}
                        value={ico}
                        onChange={(e) => {
                            setIco(e.target.value);
                            setNewData(true);
                        }}
                    />

                    <SettingsInput
                        id="dic"
                        label={t('dic')}
                        value={dic}
                        onChange={(e) => {
                            setDic(e.target.value);
                            setNewData(true);
                        }}
                    />

                    <SettingsInput
                        id="ic_dph"
                        label={t('icDph')}
                        value={ic_dph}
                        onChange={(e) => {
                            setIcDph(e.target.value);
                            setNewData(true);
                        }}
                    />

                    <SettingsInput
                        id="country"
                        label={t('country')}
                        placeholder={`${t('enter')} ${t('country2').toLowerCase()}`}
                        value={country}
                        onChange={(e) => {
                            setCountry(e.target.value);
                            setNewData(true);
                        }}
                    />

                    <SettingsInput
                        id="city"
                        label={t('city')}
                        value={city}
                        onChange={(e) => {
                            setCity(e.target.value);
                            setNewData(true);
                        }}
                    />

                    <SettingsInput
                        id="street"
                        label={t('street')}
                        placeholder={`${t('enter')} ${t('street2').toLowerCase()}`}
                        value={street}
                        onChange={(e) => {
                            setStreet(e.target.value);
                            setNewData(true);
                        }}
                    />

                    <SettingsInput
                        id="psc"
                        label={t('postCode')}
                        value={zip}
                        onChange={(e) => {
                            setZip(e.target.value);
                            setNewData(true);
                        }}
                    />

                    <SettingsInput
                        id="mail"
                        label={t('email')}
                        placeholder={t('emailPlaceholderChecked')}
                        value={email}
                        onChange={(e) => {
                            setEmail(e.target.value);
                            setNewData(true);
                        }}
                        inputClassName={
                            email.length > 0 && !isEmail(email) ? 'form-control-warning' : ''
                        }
                    />

                    <SettingsInput
                        id="phone"
                        label={t('phone')}
                        value={phone}
                        onChange={(e) => {
                            setPhone(e.target.value);
                            setNewData(true);
                        }}
                    />

                    <SettingsInput
                        id="description"
                        label={t('description')}
                        type="textarea"
                        value={description}
                        onChange={(e) => {
                            setDescription(e.target.value);
                            setNewData(true);
                        }}
                    />
                </TabPane>

                {showContract && (
                    <TabPane tabId={'contract'}>
                        <CompanyRents
                            clearForm={clearCompanyRents}
                            setClearForm={() => setClearCompanyRents(false)}
                            data={rents}
                            updateRent={(rent) => {
                                let newRents = [...rents];
                                newRents[newRents.findIndex((item) => item.id === rent.id)] = {
                                    ...newRents.find((item) => item.id === rent.id),
                                    ...rent,
                                };
                                setRents(newRents);
                                setNewData(true);
                            }}
                            addRent={(rent) => {
                                let newRents = [...rents];
                                newRents.push({
                                    ...rent,
                                    id: getFakeID(),
                                });
                                setRents(newRents);
                                setNewData(true);
                            }}
                            removeRent={(rent) => {
                                let newRents = [...rents];
                                newRents.splice(
                                    newRents.findIndex((item) => item.id === rent.id),
                                    1
                                );
                                setRents(newRents);
                                setNewData(true);
                            }}
                        />
                    </TabPane>
                )}
            </TabContent>

            {data && data.def && (
                <SettingsInput
                    id="description"
                    className="m-t-20"
                    label={t('defaultTax')}
                    labelClassName="color-danger"
                    type="number"
                    value={defDph}
                    onChange={(e) => {
                        setDefDph(e.target.value);
                        setDefChanged(true);
                        setNewData(true);
                    }}
                />
            )}

            <div className="form-buttons-row">
                {onClose && (
                    <button className="btn-link" disabled={saving} onClick={onClose}>
                        {t('close')}
                    </button>
                )}

                {newData && data && (
                    <button
                        className="btn-link-cancel btn-distance"
                        disabled={saving}
                        onClick={cancelChanges}
                    >
                        {t('cancelChanges')}
                    </button>
                )}

                <div className="ml-auto message row p-t-0 m-r-10">
                    {cannotSave && (
                        <div className="message error-message m-r-10">
                            {t('fillAllRequiredInformation')}
                        </div>
                    )}

                    {newData && (
                        <div className="message error-message">{t('saveBeforeLeaving')}</div>
                    )}
                    {!newData && data && (
                        <div className="message success-message">{t('saved')}</div>
                    )}
                </div>

                <button
                    className={classnames('btn')}
                    disabled={cannotSave || !newData}
                    onClick={() => {
                        const data = {
                            title,
                            dph: dph === '' ? 0 : parseInt(dph),
                            ico,
                            dic,
                            ic_dph,
                            country,
                            city,
                            street,
                            zip,
                            email: email.length > 0 ? email : null,
                            phone,
                            description,
                            rents: [],
                        };
                        onSave(data, defChanged, defDph);
                    }}
                >
                    {saving ? `${t('saving')}...` : t('saveChanges')}
                </button>
            </div>
        </div>
    );
}
