import React from 'react';

import {
    useNavigate,
    useParams,
    NavLink,
} from 'react-router-dom';

import { useQuery, useSubscription } from '@apollo/client';

import {
    Label,
    Nav,
    NavItem,
    Modal,
    ModalBody,
} from 'reactstrap';

import Loading from 'components/loading';
import Empty from 'components/Empty';

import classnames from 'classnames';

import Filter from '../filter';
import TaskAdd from '../../task/add';
import MilestoneAdd from '../milestones/milestoneAdd';
import UserAdd from 'helpdesk/settings/users/userAdd';
import CompanyAdd from 'helpdesk/settings/companies/companyAdd';
import ProjectAdd from 'helpdesk/settings/projects/projectAdd';
import ProjectEdit from 'helpdesk/settings/projects/projectEdit';
import WorkspaceAdd from 'helpdesk/settings/workspaces/workspaceAdd';
import WorkspaceEdit from 'helpdesk/settings/workspaces/workspaceEdit';

import {
    toSelItem,
    sortBy,
    filterUnique,
    getMyData,
    splitArrayByFilter,
} from 'helperFunctions';

import {
    getEmptyGeneralFilter,
    assignedTasksFilter,
    requestedTasksFilter,
    importantTasksFilter,
} from 'configs/constants/filter';
import { allWorkspaces } from 'configs/constants/sidebar';
import settings from 'configs/constants/settings';

import { useTranslation } from 'react-i18next';

import {
    GET_MY_PROJECTS,
    PROJECTS_SUBSCRIPTION,
    GET_WORKSPACES,
    WORKSPACES_SUBSCRIPTION,
    MILESTONES_SUBSCRIPTION,
    GET_MY_FILTERS,
    FILTERS_SUBSCRIPTION,
} from 'helpdesk/queries';

import {
    GET_PROJECT,
    GET_LOCAL_WORKSPACE,
    GET_LOCAL_CALENDAR_USER_ID,
    GET_FILTER_OPEN,
} from 'apollo/queries';

import {
    setFilter,
    setProject,
    setWorkspace,
    setMilestone,
    setCalendarUserId,
    setFilterOpen,
} from 'apollo/localSchema/actions';

export default function TasksSidebar() {
    //data & queries
    const navigate = useNavigate();
    const params = useParams();

    const { t } = useTranslation();

    //ziskaj len z networku data
    //prichystaj refresg ak sa nieco zmeni
    //listener zmien updatne lokalny projekt/filter/milestone
    //network
    const {
        data: myProjectsData,
        loading: myProjectsLoading,
        refetch: myProjectsRefetch,
    } = useQuery(GET_MY_PROJECTS, {
        fetchPolicy: 'network-only',
    });

    const {
        data: workspacesData,
        loading: workspacesLoading,
        refetch: workspacesRefetch,
    } = useQuery(GET_WORKSPACES, {
        fetchPolicy: 'network-only',
    });

    const {
        data: myFiltersData,
        loading: myFiltersLoading,
        refetch: myFiltersRefetch,
    } = useQuery(GET_MY_FILTERS, {
        fetchPolicy: 'network-only',
    });

    //local
    /*
  const {
  data: filterData,
  loading: filterLoading
  } = useQuery(GET_FILTER);
  */
    const { data: projectData, loading: projectLoading } =
        useQuery(GET_PROJECT);

    const { data: localWorkspaceData } = useQuery(
        GET_LOCAL_WORKSPACE
    );

    const { data: localCalendarUserId } = useQuery(
        GET_LOCAL_CALENDAR_USER_ID
    );

    const { data: filterOpenData } =
        useQuery(GET_FILTER_OPEN);

    useSubscription(PROJECTS_SUBSCRIPTION, {
        onData: () => {
            myProjectsRefetch();
        },
    });

    useSubscription(WORKSPACES_SUBSCRIPTION, {
        onData: () => {
            workspacesRefetch();
        },
    });

    useSubscription(MILESTONES_SUBSCRIPTION, {
        onData: () => {
            myProjectsRefetch();
        },
    });

    useSubscription(FILTERS_SUBSCRIPTION, {
        onData: () => {
            myFiltersRefetch();
        },
    });

    //state
    const [showFilters, setShowFilters] =
        React.useState(true);
    const [showCalendarUsers, setShowCalendarUsers] =
        React.useState(true);
    const [openMilestoneAdd, setOpenMilestoneAdd] =
        React.useState(false);
    const [openCompanyAdd, setOpenCompanyAdd] =
        React.useState(false);
    const [openUserAdd, setOpenUserAdd] =
        React.useState(false);

    const [openProjectAdd, setOpenProjectAdd] =
        React.useState(false);
    const [openProjectEditId, setOpenProjectEditId] =
        React.useState(null);

    const [openWorkspaceAdd, setOpenWorkspaceAdd] =
        React.useState(false);
    const [openWorkspaceEditId, setOpenWorkspaceEditId] =
        React.useState(null);

    // sync
    React.useEffect(() => {
        if (!myFiltersLoading) {
            setLocalFilter();
        }
    }, [myFiltersData, myFiltersLoading, params.filterID]);

    React.useEffect(() => {
        if (!myProjectsLoading) {
            if (myProjectsData.myProjects) {
                const currentProject =
                    myProjectsData.myProjects.find(
                        (project) =>
                            project.project.id ===
                            projectData.localProject.id
                    );
                if (currentProject) {
                    setProject({
                        ...currentProject,
                        id: currentProject.project.id,
                        value: currentProject.project.id,
                        title: currentProject.project.title,
                        label: currentProject.project.title,
                    });
                }
            }
        }
    }, [myProjectsData, myProjectsLoading]);

    React.useEffect(() => {
        if (!workspacesLoading) {
            if (workspacesData.workspaces) {
                const currentWorkspace =
                    workspacesData.workspaces.find(
                        (workspace) => {
                            workspace.id ===
                                localWorkspaceData
                                    .localWorkspace.id;
                        }
                    );
                if (currentWorkspace) {
                    setWorkspace({
                        ...currentWorkspace,
                        id: currentWorkspace.id,
                        value: currentWorkspace.id,
                        title: currentWorkspace.title,
                        label: currentWorkspace.title,
                    });
                }
            }
        }
    }, [workspacesData, workspacesLoading]);

    const currentUser = getMyData();
    const localProject = projectData.localProject;
    const localWorkspace =
        localWorkspaceData.localWorkspace;
    const filterOpen = filterOpenData.filterOpen;

    const getApplicableFilters = () => {
        if (myFiltersLoading || projectLoading) {
            return [];
        }
        let [publicFilters, customFilters] =
            splitArrayByFilter(
                myFiltersData.myFilters,
                (filter) => filter.pub
            );
        publicFilters = sortBy(publicFilters, [
            {
                key: 'order',
                asc: true,
            },
        ]);
        customFilters = sortBy(customFilters, [
            {
                key: 'title',
                asc: true,
            },
        ]);

        if (localProject.id === null) {
            return {
                publicFilters,
                customFilters,
            };
        }
        if (localProject.id) {
            return {
                publicFilters: publicFilters.filter(
                    (myFilter) =>
                        myFilter.global ||
                        (myFilter.project &&
                            myFilter.project.id ===
                                localProject.id)
                ),
                customFilters: customFilters.filter(
                    (myFilter) =>
                        myFilter.global ||
                        (myFilter.project &&
                            myFilter.project.id ===
                                localProject.id)
                ),
            };
        }
        return {
            publicFilters: [],
            customFilters: [],
        };
    };

    const getProjectFilters = () => {
        if (localProject.id === null) {
            return [];
        }
        const sortedFilters = sortBy(
            localProject.project.projectFilters,
            [
                {
                    key: 'order',
                    asc: true,
                },
            ]
        );

        return sortedFilters;
    };

    const setLocalFilter = () => {
        if (window.location.pathname.length > 12) {
            if (params.filterID === 'assigned') {
                setFilter(assignedTasksFilter);
            } else if (params.filterID === 'requested') {
                setFilter(requestedTasksFilter);
            } else if (params.filterID === 'important') {
                setFilter(importantTasksFilter);
            } else {
                let applicableFilters =
                    getApplicableFilters();
                const allAvailableFilters = [
                    ...applicableFilters.publicFilters,
                    ...getProjectFilters(),
                    ...applicableFilters.customFilters,
                ];
                const newFilter = allAvailableFilters.find(
                    (item) =>
                        item.id ===
                        parseInt(params.filterID)
                );
                if (newFilter) {
                    setFilter(newFilter);
                } else {
                    setFilter(getEmptyGeneralFilter());
                }
            }
        }
    };

    const dataLoading =
        !currentUser ||
        myProjectsLoading ||
        workspacesLoading ||
        myFiltersLoading ||
        projectLoading;

    if (dataLoading) {
        return <Loading />;
    }

    //Constants
    const myRights = currentUser.role.accessRights;
    const myProjects = (
        myProjectsData ? [...myProjectsData.myProjects] : []
    )
        .filter(
            (project) =>
                localWorkspace.id === null ||
                localWorkspace.id ===
                    project.project.workspace.id
        )
        .sort((project1, project2) =>
            project1.project.title > project2.project.title
                ? 1
                : -1
        );

    const canEditProject =
        localProject.id !== null &&
        (myRights.projects ||
            (localProject.right !== undefined &&
                localProject.right.projectPrimaryRead));

    const canAddTask =
        localProject.id === null ||
        (localProject.right !== undefined &&
            localProject.right.addTasks);

    const renderWorkspaces = () => {
        return (
            <div>
                <div
                    className="sidebar-label row"
                    style={{ alignItems: 'center' }}
                >
                    <span className="material-symbols-outlined p-r-5">
                        folder_open
                    </span>
                    <Label>
                        {localWorkspace.id !== null
                            ? localWorkspace.title
                            : t('allWorkspaces')}
                    </Label>
                    {localWorkspace.myRights
                        .editWorkspace && (
                        <button
                            className="btn-link m-l-auto"
                            onClick={() => {
                                setOpenWorkspaceEditId(
                                    localWorkspace.id
                                );
                            }}
                        >
                            <span className="material-symbols-outlined p-r-0">
                                settings
                            </span>
                        </button>
                    )}
                    {localWorkspace.id === null &&
                        myRights.addProjects && (
                            <button
                                className="btn-link m-l-auto"
                                onClick={() => {
                                    setOpenWorkspaceAdd(
                                        true
                                    );
                                }}
                            >
                                <span className="material-symbols-outlined bigger">
                                    add
                                </span>
                            </button>
                        )}
                </div>
            </div>
        );
    };

    const renderProjects = () => {
        return (
            <div>
                <div
                    className="sidebar-label row"
                    style={{ alignItems: 'center' }}
                >
                    <span className="material-symbols-outlined p-r-5">
                        folder_open
                    </span>
                    <Label>
                        {localProject.id !== null
                            ? localProject.project.title
                            : t('allProjects')}
                    </Label>
                    {localProject.id !== null &&
                        localProject.right.projectRead && (
                            <button
                                className="btn-link m-l-auto"
                                onClick={() => {
                                    setOpenProjectEditId(
                                        localProject.id
                                    );
                                }}
                            >
                                <span className="material-symbols-outlined p-r-3">
                                    settings
                                </span>
                            </button>
                        )}
                    {localProject.id === null &&
                        (localWorkspace.id === null ||
                            localWorkspace.myRights
                                .addProjects) &&
                        myRights.addProjects && (
                            <button
                                className="btn-link m-l-auto"
                                onClick={() => {
                                    setOpenProjectAdd(true);
                                }}
                            >
                                <span className="material-symbols-outlined bigger">
                                    add
                                </span>
                            </button>
                        )}
                </div>
            </div>
        );
    };

    const renderCalendarUsers = (alwaysShow) => {
        return (
            <div
                className={classnames({
                    clickable: !alwaysShow,
                })}
            >
                <div
                    className="sidebar-label row clickable"
                    onClick={() =>
                        setShowCalendarUsers(
                            alwaysShow
                                ? showCalendarUsers
                                : !showCalendarUsers
                        )
                    }
                >
                    <div>
                        <span className="material-symbols-outlined">
                            person
                        </span>
                        <Label
                            className={classnames({
                                clickable: !alwaysShow,
                            })}
                        >
                            {t('calendarUsers')}
                        </Label>
                    </div>
                    {!alwaysShow && (
                        <div className="ml-auto">
                            {showCalendarUsers && (
                                <span className="material-symbols-outlined">
                                    expand_less
                                </span>
                            )}
                            {!showCalendarUsers && (
                                <span className="material-symbols-outlined">
                                    expand_more
                                </span>
                            )}
                        </div>
                    )}
                </div>
                {(showCalendarUsers || alwaysShow) &&
                    renderCalendarUsersList()}
            </div>
        );
    };
    const renderCalendarUsersList = () => {
        if (!myRights.tasklistCalendar) {
            return null;
        }
        const myID = currentUser.id;
        const userID =
            localCalendarUserId.localCalendarUserId;
        let usersToRender = [];
        if (localProject.id !== null) {
            usersToRender =
                localProject.usersWithRights.filter(
                    (userWithRights) =>
                        userWithRights.assignable
                );
        } else {
            usersToRender = myProjects
                .filter(
                    (myProject) =>
                        myProject.attributeRights.assigned
                            .view
                )
                .reduce((acc, cur) => {
                    const usersToAdd =
                        cur.usersWithRights.filter(
                            (userWithRights) =>
                                userWithRights.assignable
                        );
                    return [...acc, ...usersToAdd];
                }, []);
        }
        usersToRender = sortBy(
            filterUnique(
                usersToRender.map(
                    (userToRender) => userToRender.user
                ),
                'id'
            ),
            [
                {
                    key: 'fullName',
                    asc: true,
                },
            ]
        );

        return (
            <Nav vertical>
                {usersToRender.map((user) => (
                    <NavItem
                        key={user.id}
                        className={classnames(
                            'row full-width sidebar-item noselect',
                            {
                                'active':
                                    userID === user.id ||
                                    (userID === null &&
                                        myID === user.id),
                            }
                        )}
                    >
                        <span
                            className={classnames(
                                'clickable sidebar-menu-item link',
                                {
                                    'active':
                                        userID ===
                                            user.id ||
                                        (userID === null &&
                                            myID ===
                                                user.id),
                                }
                            )}
                            onClick={() => {
                                setCalendarUserId(user.id);
                            }}
                        >
                            {user.fullName}
                        </span>
                    </NavItem>
                ))}
            </Nav>
        );
    };

    const renderFiltersList = () => {
        const applicableFilters = getApplicableFilters();
        return (
            <Empty>
                <NavLink
                    key="assigned"
                    className={({ isActive }) =>
                        `link-item p-l-15 ${
                            isActive ||
                            'assigned' === params.filterID
                                ? 'active-highlight'
                                : ''
                        }`
                    }
                    to={`/helpdesk/taskList/i/assigned`}
                >
                    {t('myTasks')}
                </NavLink>

                <NavLink
                    key="requested"
                    className={({ isActive }) =>
                        `link-item p-l-15 ${
                            isActive ||
                            'requested' === params.filterID
                                ? 'active-highlight'
                                : ''
                        }`
                    }
                    to={`/helpdesk/taskList/i/requested`}
                >
                    {t('requestedTasks')}
                </NavLink>

                <NavLink
                    key="repeats"
                    className={({ isActive }) =>
                        `link-item p-l-15 ${
                            isActive
                                ? 'active-highlight'
                                : ''
                        }`
                    }
                    to={`/helpdesk/repeats`}
                >
                    {t('repetitiveTasks')}
                </NavLink>

                <NavLink
                    key="important"
                    className={({ isActive }) =>
                        `link-item p-l-15 ${
                            isActive ||
                            'important' === params.filterID
                                ? 'active-highlight'
                                : ''
                        }`
                    }
                    to={`/helpdesk/taskList/i/important`}
                >
                    {t('importantTasks')}
                </NavLink>

                <NavLink
                    key="all"
                    className={({ isActive }) =>
                        `link-item p-l-15 ${
                            isActive ||
                            'all' === params.filterID
                                ? 'active-highlight'
                                : ''
                        }`
                    }
                    to={`/helpdesk/taskList/i/all`}
                >
                    {t('allTasks')}
                </NavLink>

                {applicableFilters.publicFilters.map(
                    (filter) => (
                        <NavLink
                            key={filter.id}
                            className={({ isActive }) =>
                                `link-item p-l-15 ${
                                    isActive ||
                                    filter.id ===
                                        parseInt(
                                            params.filterID
                                        )
                                        ? 'active-highlight'
                                        : ''
                                }`
                            }
                            to={`/helpdesk/taskList/i/${filter.id}`}
                        >
                            {filter.title}
                            {myRights.publicFilters &&
                                filter.id ===
                                    parseInt(
                                        params.filterID
                                    ) && (
                                    <div
                                        className="m-l-auto active"
                                        onClick={() => {
                                            if (
                                                filter.id ===
                                                parseInt(
                                                    params.filterID
                                                )
                                            ) {
                                                setFilterOpen(
                                                    true
                                                );
                                            }
                                        }}
                                    >
                                        <span className="material-symbols-outlined p-r-0">
                                            settings
                                        </span>
                                    </div>
                                )}
                        </NavLink>
                    )
                )}
                {getProjectFilters().map((filter) => (
                    <NavItem
                        key={filter.id}
                        className={classnames(
                            'row full-width sidebar-item noselect',
                            {
                                'active':
                                    filter.id ===
                                    parseInt(
                                        params.filterID
                                    ),
                            }
                        )}
                    >
                        <span
                            className={classnames(
                                'clickable sidebar-menu-item link',
                                {
                                    'active':
                                        filter.id ===
                                        parseInt(
                                            params.filterID
                                        ),
                                }
                            )}
                            onClick={() =>
                                navigate(
                                    `/helpdesk/taskList/i/${filter.id}`
                                )
                            }
                        >
                            {filter.title}
                        </span>
                    </NavItem>
                ))}
                {applicableFilters.customFilters.map(
                    (filter) => (
                        <NavLink
                            key={filter.id}
                            className={({ isActive }) =>
                                `link-item p-l-15 ${
                                    isActive ||
                                    filter.id ===
                                        parseInt(
                                            params.filterID
                                        )
                                        ? 'active-highlight'
                                        : ''
                                }`
                            }
                            to={`/helpdesk/taskList/i/${filter.id}`}
                        >
                            {filter.title}
                            {myRights.customFilters &&
                                filter.id ===
                                    parseInt(
                                        params.filterID
                                    ) && (
                                    <div
                                        className="m-l-auto active"
                                        onClick={() => {
                                            if (
                                                filter.id ===
                                                parseInt(
                                                    params.filterID
                                                )
                                            ) {
                                                setFilterOpen(
                                                    true
                                                );
                                            }
                                        }}
                                    >
                                        <span className="material-symbols-outlined p-r-0">
                                            settings
                                        </span>
                                    </div>
                                )}
                        </NavLink>
                    )
                )}
            </Empty>
        );
    };
    const renderFilters = () => {
        return (
            <div>
                <div
                    className="sidebar-label row p-l-12 m-b-10"
                    style={{ alignItems: 'center' }}
                >
                    <span className="material-symbols-outlined p-r-5">
                        filter_alt
                    </span>
                    <Label>{t('filters')}</Label>
                </div>
                {showFilters && renderFiltersList()}
                {showFilters &&
                    (myRights.customFilters ||
                        myRights.publicFilters) &&
                    renderFilterAddBtn()}
            </div>
        );
    };
    const renderFilterAddBtn = () => {
        if (
            !myRights.customFilters &&
            !myRights.publicFilters
        ) {
            return null;
        }
        return (
            <div className="row full-width">
                <button
                    className="btn-link p-l-10"
                    onClick={() => {
                        navigate(
                            '/helpdesk/taskList/i/all'
                        );
                        setFilterOpen(true);
                    }}
                >
                    <span className="material-symbols-outlined bigger">
                        add
                    </span>
                    {t('filter')}
                </button>
            </div>
        );
    };
    const renderFilterAdd = () => {
        if (
            !myRights.customFilters &&
            !myRights.publicFilters
        ) {
            return null;
        }
        return (
            <div>
                <div
                    className="sidebar-label row"
                    onClick={() =>
                        setShowFilters(!showFilters)
                    }
                >
                    <div className="row align-items-center">
                        <span
                            className="material-symbols-outlined m-r-5"
                            style={{
                                color: '#212121',
                            }}
                        >
                            filter_alt
                        </span>
                        <Label>
                            {parseInt(params.filterID)
                                ? `${t('edit')} ${t(
                                      'filter'
                                  ).toLowerCase()}`
                                : `${t('add')} ${t(
                                      'filter'
                                  ).toLowerCase()}`}
                        </Label>
                    </div>
                </div>
                <Filter
                    close={() => {
                        setFilterOpen(false);
                    }}
                />
            </div>
        );
    };

    const renderTaskAddBtn = () => {
        return (
            <TaskAdd
                disabled={!canAddTask}
                projectID={
                    localProject.id !== null &&
                    localProject.right.addTask
                        ? localProject.id
                        : null
                }
            />
        );
    };

    const renderSettings = (canSeeSettings) => {
        return (
            <div>
                {canSeeSettings && (
                    <NavLink
                        key="settings"
                        className={({ isActive }) =>
                            `link-item p-l-12 align-items-center ${
                                isActive
                                    ? 'active-highlight'
                                    : ''
                            }`
                        }
                        to={`/helpdesk/settings`}
                    >
                        <span className="material-symbols-outlined p-r-5">
                            settings
                        </span>
                        {t('settings')}
                    </NavLink>
                )}
                {myRights.users && (
                    <NavLink
                        key="users"
                        className={({ isActive }) =>
                            `link-item p-l-12 align-items-center ${
                                isActive
                                    ? 'active-highlight'
                                    : ''
                            }`
                        }
                        to={`/helpdesk/users`}
                    >
                        <span className="material-symbols-outlined p-r-5">
                            group
                        </span>
                        {t('users')}
                    </NavLink>
                )}
                {myRights.companies && (
                    <NavLink
                        key="companies"
                        className={({ isActive }) =>
                            `link-item p-l-12 align-items-center ${
                                isActive
                                    ? 'active-highlight'
                                    : ''
                            }`
                        }
                        to={`/helpdesk/companies`}
                    >
                        <span className="material-symbols-outlined p-r-5">
                            apartment
                        </span>
                        {t('companies')}
                    </NavLink>
                )}

                <NavLink
                    key="webchat"
                    className={({ isActive }) =>
                        `link-item p-l-12 align-items-center ${
                            isActive
                                ? 'active-highlight'
                                : ''
                        }`
                    }
                    to={`/helpdesk/webchat`}
                >
                    <span className="material-symbols-outlined p-r-5">
                        comment
                    </span>
                    {t('webchat')}
                </NavLink>
                <NavLink
                    key="statistics"
                    className={({ isActive }) =>
                        `link-item p-l-12 align-items-center ${
                            isActive
                                ? 'active-highlight'
                                : ''
                        }`
                    }
                    to={`/helpdesk/statistics`}
                >
                    <span className="material-symbols-outlined p-r-5">
                        show_chart
                    </span>
                    {t('statistics')}
                </NavLink>
            </div>
        );
    };

    const renderSidebar = () => {
        const canSeeSettings = settings.some(
            (setting) => myRights[setting.value]
        );
        return (
            <div>
                {renderTaskAddBtn()}

                <hr className="m-l-15 m-r-15 m-t-11" />
                {renderWorkspaces()}

                {renderProjects()}

                <hr className="m-l-15 m-r-15 m-t-15" />
                {!filterOpen && (
                    <Empty>
                        {renderFilters()}

                        {(localProject.id === null ||
                            localProject.attributeRights
                                .assigned.view) &&
                            currentUser.tasklistLayout ===
                                3 &&
                            window.location.href.includes(
                                'taskList'
                            ) && (
                                <Empty>
                                    <hr className="m-l-15 m-r-15 m-t-11" />
                                    {renderCalendarUsers()}
                                </Empty>
                            )}
                    </Empty>
                )}

                {filterOpen &&
                    (myRights.customFilters ||
                        myRights.publicFilters) &&
                    renderFilterAdd()}

                {!filterOpen &&
                    (canSeeSettings || myRights.vykazy) && (
                        <hr className="m-l-15 m-r-15 m-t-11 m-b-11" />
                    )}

                {!filterOpen &&
                    (canSeeSettings || myRights.vykazy) &&
                    renderSettings(canSeeSettings)}

                {!filterOpen &&
                    (canSeeSettings || myRights.vykazy) && (
                        <hr className="m-l-15 m-r-15 m-t-11 m-b-11" />
                    )}

                {!filterOpen &&
                    (canSeeSettings || myRights.vykazy) && (
                        <NavLink
                            key="invoices"
                            className={({ isActive }) =>
                                `link-item p-l-12 align-items-center m-b-20 ${
                                    isActive
                                        ? 'active-highlight'
                                        : ''
                                }`
                            }
                            to={`/invoices`}
                        >
                            <span className="material-symbols-outlined p-r-5">
                                description
                            </span>
                            {t('invoices')}
                        </NavLink>
                    )}
            </div>
        );
    };

    return (
        <div>
            {renderSidebar()}

            {canEditProject && openMilestoneAdd && (
                <MilestoneAdd
                    open={openMilestoneAdd}
                    closeModal={(newMilestone) => {
                        if (newMilestone) {
                            setMilestone(
                                toSelItem(newMilestone)
                            );
                        }
                        setOpenMilestoneAdd(false);
                    }}
                />
            )}

            {openProjectAdd && (
                <Modal
                    isOpen={openProjectAdd}
                    className="modal-without-borders"
                >
                    <ModalBody>
                        <ProjectAdd
                            closeModal={() =>
                                setOpenProjectAdd(false)
                            }
                        />
                    </ModalBody>
                </Modal>
            )}

            {openProjectEditId && (
                <Modal
                    isOpen={
                        openProjectEditId ? true : false
                    }
                    className="modal-without-borders"
                >
                    <ModalBody>
                        <ProjectEdit
                            closeModal={() =>
                                setOpenProjectEditId(null)
                            }
                            projectID={openProjectEditId}
                        />
                    </ModalBody>
                </Modal>
            )}

            {openWorkspaceAdd && (
                <Modal
                    isOpen={openWorkspaceAdd}
                    className="modal-without-borders"
                >
                    <ModalBody>
                        <WorkspaceAdd
                            closeModal={() => {
                                setOpenWorkspaceAdd(false);
                            }}
                            onSuccess={(response) => {
                                workspacesRefetch();
                                setWorkspace(
                                    toSelItem(
                                        response.data
                                            .addWorkspace
                                    )
                                );
                                setOpenWorkspaceAdd(false);
                            }}
                        />
                    </ModalBody>
                </Modal>
            )}

            {openWorkspaceEditId && (
                <Modal
                    isOpen={
                        openWorkspaceEditId ? true : false
                    }
                    className="modal-without-borders"
                >
                    <ModalBody>
                        <WorkspaceEdit
                            id={openWorkspaceEditId}
                            closeModal={() =>
                                setOpenWorkspaceEditId(null)
                            }
                            onSuccessUpdate={(response) => {
                                workspacesRefetch();
                                setWorkspace(
                                    toSelItem(
                                        response.data
                                            .updateWorkspace
                                    )
                                );
                                setOpenWorkspaceEditId(
                                    null
                                );
                            }}
                            onSuccessDelete={() => {
                                workspacesRefetch();
                                setWorkspace(allWorkspaces);
                                setOpenWorkspaceEditId(
                                    null
                                );
                            }}
                        />
                    </ModalBody>
                </Modal>
            )}

            {openUserAdd && (
                <Modal
                    isOpen={openUserAdd}
                    className="modal-without-borders"
                >
                    <ModalBody>
                        <UserAdd
                            closeModal={() =>
                                setOpenUserAdd(false)
                            }
                            addUserToList={() => {}}
                        />
                    </ModalBody>
                </Modal>
            )}

            {openCompanyAdd && (
                <Modal
                    isOpen={openCompanyAdd}
                    className="modal-without-borders"
                >
                    <ModalBody>
                        <CompanyAdd
                            closeModal={() =>
                                setOpenCompanyAdd(false)
                            }
                            addCompanyToList={() => {}}
                        />
                    </ModalBody>
                </Modal>
            )}
        </div>
    );
}
