import React from 'react';

import { useMutation, useQuery } from '@apollo/client';
import ErrorMessage from 'components/errorMessage';
import SettingsInput from '../components/settingsInput';
import Loading from 'components/loading';
import RightRow from './rightRow';

import { getMyData } from 'helperFunctions';
import { useTranslation } from 'react-i18next';
import { addLocalError } from 'apollo/localSchema/actions';

import { GET_ROLE, UPDATE_ROLE } from 'helpdesk/queries';

export default function RoleEdit(props) {
    const { id, closeModal, refetch } = props;

    const { t } = useTranslation();

    const currentUser = getMyData();

    const {
        data: roleData,
        loading: roleLoading,
        refetch: roleRefetch,
    } = useQuery(GET_ROLE, {
        variables: {
            id,
        },
        fetchPolicy: 'network-only',
        notifyOnNetworkStatusChange: true,
    });

    const [updateRole] = useMutation(UPDATE_ROLE);

    //state
    const [title, setTitle] = React.useState('');
    const [order, setOrder] = React.useState(0);
    const [level, setLevel] = React.useState(0);
    const [dataChanged, setDataChanged] =
        React.useState(false);
    const [saving, setSaving] = React.useState(false);

    const generalRights = [
        {
            state: React.useState(false),
            key: 'login',
            label: t('loginToSystem'),
        },
        {
            state: React.useState(false),
            key: 'vykazy',
            label: t('invoices'),
        },
        {
            state: React.useState(false),
            key: 'publicFilters',
            label: t('publicFilters'),
        },
        {
            state: React.useState(false),
            key: 'addProjects',
            label: t('addProjects'),
        },
        {
            state: React.useState(false),
            key: 'viewErrors',
            label: t('viewErrors'),
        },
        {
            state: React.useState(false),
            key: 'lanwiki',
            label: t('lanwiki'),
        },
        {
            state: React.useState(false),
            key: 'cmdb',
            label: t('cmdb'),
        },
        {
            state: React.useState(false),
            key: 'pass',
            label: t('pass'),
        },
    ];
    const settings = [
        {
            state: React.useState(false),
            key: 'users',
            label: t('users'),
        },
        {
            state: React.useState(false),
            key: 'companies',
            label: t('companies'),
        },
        /*
        {
          state: React.useState( false ),
          key: 'pausals',
          label: t( 'pausals' )
        },*/
        {
            state: React.useState(false),
            key: 'projects',
            label: t('projects'),
        },
        {
            state: React.useState(false),
            key: 'statuses',
            label: t('statuses'),
        },
        /*
        {
          state: React.useState( false ),
          key: 'prices',
          label: t( 'pricelists' )
        },*/
        {
            state: React.useState(false),
            key: 'roles',
            label: t('roles'),
        },

        {
            state: React.useState(false),
            key: 'taskTypes',
            label: t('taskTypes'),
        },
        {
            state: React.useState(false),
            key: 'tripTypes',
            label: t('tripTypes'),
        },
        {
            state: React.useState(false),
            key: 'imaps',
            label: t('imaps'),
        },
        {
            state: React.useState(false),
            key: 'smtps',
            label: t('smtps'),
        },
        {
            state: React.useState(false),
            key: 'aiSettings',
            label: t('aiSettings'),
        },
    ];
    const helpdesk = [
        {
            state: React.useState(false),
            key: 'tasklistLayout',
            label: t('tasklistLayoutRight'),
        },
        {
            state: React.useState(false),
            key: 'tasklistCalendar',
            label: t('tasklistCalendarRight'),
        },
        {
            state: React.useState(false),
            key: 'tasklistPreferences',
            label: t('tasklistPreferencesRight'),
        },
        {
            state: React.useState(false),
            key: 'customFilters',
            label: t('customFiltersRight'),
        },
        {
            state: React.useState(false),
            key: 'statisticsProject',
            label: t('statisticsAboutProject'),
        },
        {
            state: React.useState(false),
            key: 'statisticsCustomer',
            label: t('statisticsAboutCustomer'),
        },
        {
            state: React.useState(false),
            key: 'statisticsTasks',
            label: t('statisticsAboutTasks'),
        },
    ];

    // sync
    React.useEffect(() => {
        if (!roleLoading) {
            setData();
        }
    }, [roleLoading]);

    React.useEffect(() => {
        roleRefetch({
            id,
        });
    }, [id]);

    // functions
    const setData = () => {
        if (roleLoading) {
            return;
        }
        const role = roleData.role;
        setTitle(role.title);
        setOrder(role.order);
        setLevel(role.level);
        [
            ...generalRights,
            ...settings,
            ...helpdesk,
        ].forEach((right) =>
            right.state[1](role.accessRights[right.key])
        );
        setDataChanged(false);
    };

    const updateRoleFunc = () => {
        setSaving(true);
        let accessRights = {};
        [
            ...generalRights,
            ...settings,
            ...helpdesk,
        ].forEach(
            (right) =>
                (accessRights[right.key] = right.state[0])
        );
        updateRole({
            variables: {
                id,
                title,
                level: level !== '' ? parseInt(level) : 0,
                order: order !== '' ? parseInt(order) : 0,
                accessRights,
            },
        })
            .then(() => {
                if (closeModal) {
                    closeModal();
                }
                if (refetch) {
                    refetch();
                }
            })
            .catch((err) => {
                addLocalError(err);
            });

        setSaving(false);
        setDataChanged(false);
    };

    if (roleLoading) {
        return <Loading />;
    }

    const currentUserLevel = currentUser
        ? currentUser.role.level
        : null;
    const disabled =
        currentUserLevel === null ||
        currentUserLevel >= roleData.role.level;

    return (
        <div className="p-20">
            <h2 className="m-b-20">{`${t('edit')} ${t(
                'role'
            ).toLowerCase()}`}</h2>

            <SettingsInput
                required
                id="title"
                label={t('roleTitle')}
                disabled={disabled}
                value={title}
                onChange={(e) => {
                    setTitle(e.target.value);
                    setDataChanged(true);
                }}
            />

            <SettingsInput
                id="order"
                label={t('order')}
                disabled={disabled}
                value={order}
                onChange={(e) => {
                    setOrder(e.target.value);
                    setDataChanged(true);
                }}
            />

            <SettingsInput
                required
                id="level"
                label={t('level')}
                type="number"
                disabled={disabled}
                value={level}
                onChange={(e) => {
                    setLevel(e.target.value);
                    setDataChanged(true);
                }}
            />

            <div className="">
                <h2>{t('generalRights')}</h2>
                <table className="table">
                    <thead>
                        <tr>
                            <th width={'90%'} key={1}>
                                {t('title')}
                            </th>
                            <th
                                className="text-center"
                                key={2}
                            >
                                {t('granted')}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {generalRights.map((right) => (
                            <RightRow
                                key={[
                                    right.key,
                                    right.state[0],
                                ].toString()}
                                onChange={(e) => {
                                    right.state[1](e);
                                    setDataChanged(true);
                                }}
                                label={right.label}
                                disabled={disabled}
                                value={right.state[0]}
                            />
                        ))}
                    </tbody>
                </table>
            </div>

            <div className="">
                <h2>{t('settings')}</h2>
                <table className="table">
                    <thead>
                        <tr>
                            <th width={'90%'} key={1}>
                                {t('access')}
                            </th>
                            <th
                                className="text-center"
                                key={2}
                            >
                                {t('viewAndEdit')}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {settings.map((right) => (
                            <RightRow
                                key={[
                                    right.key,
                                    right.state[0],
                                ].toString()}
                                onChange={(e) => {
                                    right.state[1](e);
                                    setDataChanged(true);
                                }}
                                label={right.label}
                                disabled={disabled}
                                value={right.state[0]}
                            />
                        ))}
                    </tbody>
                </table>
            </div>

            <div className="">
                <h2>{t('helpdeskRights')}</h2>
                <table className="table">
                    <thead>
                        <tr>
                            <th width={'90%'} key={1}>
                                {t('title')}
                            </th>
                            <th
                                className="text-center"
                                key={2}
                            >
                                {t('granted')}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {helpdesk.map((right) => (
                            <RightRow
                                key={[
                                    right.key,
                                    right.state[0],
                                ].toString()}
                                onChange={(e) => {
                                    right.state[1](e);
                                    setDataChanged(true);
                                }}
                                label={right.label}
                                disabled={disabled}
                                value={right.state[0]}
                            />
                        ))}
                    </tbody>
                </table>
            </div>

            <div className="form-buttons-row">
                {closeModal && (
                    <button
                        className="btn-link btn-distance"
                        onClick={closeModal}
                    >
                        {t('cancel')}
                    </button>
                )}
                {props.close && (
                    <button
                        className="btn-link btn-distance"
                        onClick={() => {
                            props.close();
                        }}
                    >
                        {t('cancel')}
                    </button>
                )}

                <div className="ml-auto message m-r-10">
                    {!disabled && dataChanged && (
                        <div className="message error-message">
                            {t('saveBeforeLeaving')}
                        </div>
                    )}
                    {!disabled && !dataChanged && (
                        <div className="message success-message">
                            {t('saved')}
                        </div>
                    )}
                    <ErrorMessage
                        show={
                            currentUserLevel === null ||
                            level <= currentUserLevel
                        }
                        message={`${t(
                            'cantEditRole'
                        )}(${currentUserLevel})!`}
                    />
                </div>

                <button
                    className="btn m-t-5"
                    disabled={
                        saving ||
                        currentUserLevel === null ||
                        currentUserLevel >= level
                    }
                    onClick={updateRoleFunc}
                >
                    {saving
                        ? `${t('Saving')}...`
                        : t('save')}
                </button>
            </div>
        </div>
    );
}
