import React from 'react';
import {
    FormGroup,
    Popover,
    PopoverBody,
    Label,
} from 'reactstrap';
import { timestampToStringFNS } from 'helperFunctions';
import DatePicker from 'components/DatePicker';
import classnames from 'classnames';
import * as fns from 'date-fns';
import { useTranslation } from 'react-i18next';

export default function FilterDatePickerPopover(props) {
    const {
        id,
        label,
        dateFrom,
        dateTo,
        setDateFrom,
        setDateTo,
        minimal,
    } = props;
    const { t } = useTranslation();

    const [popoverOpen, setPopoverOpen] =
        React.useState(false);
    const [newDateFrom, setNewDateFrom] =
        React.useState(dateFrom);
    const [newDateTo, setNewDateTo] =
        React.useState(dateTo);

    return (
        <FormGroup
            className={classnames({
                'sidebar-filter-row': minimal,
            })}
        >
            <Label
                id={`date-popover-${id}`}
                style={{ display: 'block' }}
            >
                {label}
            </Label>
            <div className="bkg-white">
                <button
                    type="button"
                    className="btn-link p-l-0 p-r-0 m-r-0"
                    onClick={() => setPopoverOpen(true)}
                >
                    {`${
                        dateFrom
                            ? timestampToStringFNS(
                                  dateFrom,
                                  'dd.MM.yyyy'
                              )
                            : t('all')
                    } - ${
                        dateTo
                            ? timestampToStringFNS(
                                  dateTo,
                                  'dd.MM.yyyy'
                              )
                            : t('all')
                    }`}
                </button>
                <Popover
                    placement="bottom-end"
                    className="custom-popover"
                    isOpen={popoverOpen}
                    target={`date-popover-${id}`}
                    toggle={() => setPopoverOpen(false)}
                >
                    <PopoverBody>
                        <label
                            style={{ display: 'block' }}
                        >{`Set ${label}`}</label>
                        <button
                            type="button"
                            className="btn-link"
                            style={{ display: 'block' }}
                            onClick={() => {
                                //setNewDateFrom(moment().startOf('week').add(1, 'days'));
                                setNewDateFrom(
                                    fns.addDays(
                                        fns.startOfWeek(
                                            new Date()
                                        ),
                                        1
                                    )
                                );
                                //setNewDateTo(moment().endOf('week').add(1, 'days'));
                                setNewDateTo(
                                    fns.addDays(
                                        fns.endOfWeek(
                                            new Date()
                                        ),
                                        1
                                    )
                                );
                            }}
                        >
                            {t('thisWeek')}
                        </button>
                        <button
                            type="button"
                            className="btn-link"
                            style={{ display: 'block' }}
                            onClick={() => {
                                //setNewDateFrom(moment().subtract(1, 'weeks').startOf('week').add(1, 'days'));
                                setNewDateFrom(
                                    fns.addDays(
                                        fns.startOfWeek(
                                            fns.subWeeks(
                                                new Date(),
                                                1
                                            )
                                        ),
                                        1
                                    )
                                );
                                //setNewDateTo(moment().subtract(1, 'weeks').endOf('week').add(1, 'days'));
                                setNewDateTo(
                                    fns.addDays(
                                        fns.endOfWeek(
                                            fns.subWeeks(
                                                new Date(),
                                                1
                                            )
                                        ),
                                        1
                                    )
                                );
                            }}
                        >
                            {t('lastWeek')}
                        </button>
                        <button
                            type="button"
                            className="btn-link"
                            style={{ display: 'block' }}
                            onClick={() => {
                                //setNewDateFrom(moment().startOf('month'));
                                setNewDateFrom(
                                    fns.startOfMonth(
                                        new Date()
                                    )
                                );
                                //setNewDateTo(moment().endOf('month'));
                                setNewDateTo(
                                    fns.endOfMonth(
                                        new Date()
                                    )
                                );
                            }}
                        >
                            {t('thisMonth')}
                        </button>
                        <button
                            type="button"
                            className="btn-link"
                            style={{ display: 'block' }}
                            onClick={() => {
                                //setNewDateFrom(moment().subtract(1, 'months').startOf('month'));
                                setNewDateFrom(
                                    fns.startOfMonth(
                                        fns.subMonths(
                                            new Date(),
                                            1
                                        )
                                    )
                                );
                                //setNewDateTo(moment().subtract(1, 'months').endOf('month'));
                                setNewDateFrom(
                                    fns.endOfMonth(
                                        fns.subMonths(
                                            new Date(),
                                            1
                                        )
                                    )
                                );
                            }}
                        >
                            {t('lastMonth')}
                        </button>
                        <div className="row">
                            <div className="width-50-p">
                                <div className="row">
                                    <label className="center-hor">
                                        {t('from')}
                                    </label>
                                    <button
                                        type="button"
                                        className="btn-link ml-auto m-r-0"
                                        onClick={() =>
                                            setNewDateFrom(
                                                new Date()
                                            )
                                        }
                                    >
                                        {t('now')}
                                    </button>
                                </div>
                                <DatePicker
                                    className="form-control"
                                    hideTime={true}
                                    selected={newDateFrom}
                                    onChange={(e) => {
                                        setNewDateFrom(e);
                                    }}
                                    placeholderText={t(
                                        'noDate'
                                    )}
                                />
                            </div>

                            <div className="width-50-p">
                                <div className="row">
                                    <label className="center-hor">
                                        {t('to')}
                                    </label>
                                    <button
                                        type="button"
                                        className="btn-link ml-auto m-r-0"
                                        onClick={() =>
                                            setNewDateTo(
                                                new Date()
                                            )
                                        }
                                    >
                                        {t('now')}
                                    </button>
                                </div>
                                <DatePicker
                                    className="form-control"
                                    hideTime={true}
                                    selected={newDateTo}
                                    onChange={(e) => {
                                        setNewDateTo(e);
                                    }}
                                    placeholderText={t(
                                        'noDate'
                                    )}
                                />
                            </div>
                        </div>
                        <div className="row m-t-15">
                            <button
                                type="button"
                                className="btn-link-reversed"
                                onClick={() => {
                                    setNewDateFrom(
                                        dateFrom
                                    );
                                    setNewDateTo(dateTo);
                                    setPopoverOpen(false);
                                }}
                            >
                                {t('cancel')}
                            </button>
                            <button
                                type="button"
                                className="btn ml-auto"
                                onClick={() => {
                                    setDateFrom(
                                        newDateFrom
                                    );
                                    setDateTo(newDateTo);
                                    setPopoverOpen(false);
                                }}
                            >
                                {t('save')}
                            </button>
                        </div>
                    </PopoverBody>
                </Popover>
            </div>
        </FormGroup>
    );
}
