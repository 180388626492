export default {
  statistics: {
    en: 'Statistics',
    sk: 'Štatistiky',
    cz: 'Statistika',
    de: 'Statistiken',
  },
  customers: {
    en: 'Customers',
    sk: 'Zákazníci',
    cz: 'Zákazníci',
    de: 'Kunden',
  },
  totalClosedTasks: {
    en: 'Total number of closed tasks',
    sk: 'Celkový počet uzavretých úloh',
    cz: 'Celkový počet uzavřených úkolů',
    de: 'Gesamtzahl der abgeschlossenen Aufgaben',
  },
  totalHoursOfClosedTasks: {
    en: 'Total of hours in closed tasks',
    sk: 'Celkovo hodiny za uzavreté úlohy',
    cz: 'Celkový počet hodin v uzavřených úkolech',
    de: 'Gesamtstunden in geschlossenen Aufgaben',
  },
  totalCreatedTasks: {
    en: 'Total number of created tasks',
    sk: 'Celkový počet vytvorených úloh',
    cz: 'Celkový počet vytvořených úkolů',
    de: 'Gesamtzahl der erstellten Aufgaben',
  },
  totalHoursOfCreatedTasks: {
    en: 'Total of hours in created tasks',
    sk: 'Celkovo hodiny za vytvorené úlohy',
    cz: 'Celkový počet hodin ve vytvořených úkolech',
    de: 'Gesamtzahl der Stunden in erstellten Aufgaben',
  },
  totalUpdatedTasks: {
    en: 'Total number of updated tasks',
    sk: 'Celkový počet upravených úloh',
    cz: 'Celkový počet aktualizovaných úkolů',
    de: 'Gesamtzahl der aktualisierten Aufgaben',
  },
  totalHoursOfUpdatedTasks: {
    en: 'Total of hours in updated tasks',
    sk: 'Celkovo hodiny za upravené úlohy',
    cz: 'Celkový počet hodin v aktualizovaných úkolech',
    de: 'Gesamtstunden in aktualisierten Aufgaben',
  },
  hoursShort: {
    en: 'Hours',
    sk: 'Hod.',
    cz: 'Hod.',
    de: 'Std.',
  },
  provider: {
    en: 'Provider',
    sk: 'Poskytovateľ',
    cz: 'Poskytovatel',
    de: 'Anbieter',
  },
  taskParameter: {
    en: 'Task parameter',
    sk: 'Parameter úlohy',
    cz: 'Parametr úkolu',
    de: 'Aufgabenparameter',
  },
}
