import React from 'react';

import { useParams } from 'react-router-dom';

import { useQuery } from '@apollo/client';
import { NavItem, Nav } from 'reactstrap';
import Empty from 'components/Empty';
import Loading from 'components/loading';

import SubfolderAdd from 'lanwiki/subfolders/add/modalButton';
import FolderAdd from 'lanwiki/folders/add/modalButton';
import FolderEdit from 'lanwiki/folders/edit/modalButton';
import classnames from 'classnames';

import { useTranslation } from 'react-i18next';

import {
    L_SIDEBAR_FOLDER,
    GET_PROJECT,
} from 'apollo/queries';

import {
    FOLDERS,
    COMBINED,
} from 'configs/constants/subfolders';

import { getMyData } from 'helperFunctions';

export default function FoldersSidebar(props) {
    const {
        folders,
        navHighlight,
        foldersLoading,
        handleNavItemClick,
        showArchived,
        setShowArchived,
    } = props;

    const params = useParams();

    const { t } = useTranslation();
    const currentUser = getMyData();
    const userIsAdmin =
        currentUser && currentUser.role
            ? currentUser.role.level === 0
            : false;

    const { data: projectData } = useQuery(GET_PROJECT);
    const localProject = projectData.localProject;

    const { data: sidebarFolderData } = useQuery(
        L_SIDEBAR_FOLDER
    );

    const folderId =
        sidebarFolderData.lSidebarFolder === null
            ? null
            : sidebarFolderData.lSidebarFolder.id;

    if (foldersLoading) {
        return <Loading />;
    }

    return (
        <Empty>
            <Nav vertical className="m-t-15">
                <Empty>
                    <NavItem
                        className={classnames(
                            'row full-width sidebar-item noselect',
                            {
                                'active':
                                    [
                                        FOLDERS,
                                        COMBINED,
                                    ].includes(
                                        navHighlight
                                    ) &&
                                    window.location.pathname.includes(
                                        '/lanwiki/i/all'
                                    ),
                            }
                        )}
                    >
                        <span
                            className={classnames(
                                'clickable sidebar-menu-item link',
                                {
                                    'active':
                                        [
                                            FOLDERS,
                                            COMBINED,
                                        ].includes(
                                            navHighlight
                                        ) &&
                                        window.location.pathname.includes(
                                            '/lanwiki/i/all'
                                        ),
                                }
                            )}
                            onClick={() => {
                                handleNavItemClick(
                                    null,
                                    null,
                                    null
                                );
                                setShowArchived(false);
                            }}
                        >
                            {!showArchived && (
                                <span className="material-symbols-outlined p-r-5">
                                    folder_open
                                </span>
                            )}
                            {showArchived && (
                                <span className="material-symbols-outlined bigger p-r-5">
                                    chevron_left
                                </span>
                            )}
                            {t('allFolders')}
                        </span>
                    </NavItem>
                    {folders.map((folder) => (
                        <NavItem
                            key={folder.id}
                            className={classnames(
                                'row full-width sidebar-item align-items-center noselect',
                                {
                                    'active':
                                        [
                                            FOLDERS,
                                            COMBINED,
                                        ].includes(
                                            navHighlight
                                        ) &&
                                        folderId ===
                                            folder.id,
                                }
                            )}
                        >
                            <span
                                className={classnames(
                                    'clickable sidebar-menu-item link',
                                    {
                                        'active':
                                            [
                                                FOLDERS,
                                                COMBINED,
                                            ].includes(
                                                navHighlight
                                            ) &&
                                            folderId ===
                                                folder.id,
                                    }
                                )}
                                onClick={() => {
                                    handleNavItemClick(
                                        folder,
                                        null,
                                        null
                                    );
                                }}
                            >
                                <span className="material-symbols-outlined p-r-5">
                                    folder_open
                                </span>
                                {folder.title}
                            </span>
                            {folder.myRights.manage &&
                                folder.subfolders.length ===
                                    0 &&
                                parseInt(
                                    params.folderID
                                ) === folder.id && (
                                    <FolderEdit
                                        id={folder.id}
                                        folders={folders}
                                        className={
                                            folder
                                                .subfolders
                                                .length > 0
                                                ? ' m-r-0'
                                                : ''
                                        }
                                    />
                                )}
                            {folder.subfolders.length >
                                0 && (
                                <button
                                    className="btn-link ml-auto m-r-15"
                                    style={{
                                        color: '#3c3c3c',
                                    }}
                                    onClick={() => {
                                        handleNavItemClick(
                                            folder,
                                            null,
                                            null
                                        );
                                    }}
                                >
                                    <span className="material-symbols-outlined bigger">
                                        chevron_right
                                    </span>
                                </button>
                            )}
                            {!showArchived &&
                                folder.subfolders.length ===
                                    0 &&
                                parseInt(
                                    params.folderID
                                ) === folder.id &&
                                (localProject.right
                                    .lanwikiCreateFolder ||
                                    userIsAdmin) && (
                                    <SubfolderAdd
                                        parentVariables={{
                                            folderId:
                                                folder.id,
                                        }}
                                        className="m-r-15"
                                    />
                                )}
                        </NavItem>
                    ))}
                </Empty>
            </Nav>
            {!showArchived &&
                (localProject.right.lanwikiCreateFolder ||
                    userIsAdmin) && <FolderAdd />}
        </Empty>
    );
}
