import React from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import { useQuery, useSubscription } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { Label } from 'reactstrap';
import classnames from 'classnames';
import { timestampToStringFNS } from 'helperFunctions';

import SettingLoading from '../settings/components/settingLoading';
import Conversation from './conversationLoader';
import { GET_CONVERSATIONS, CONVERSATIONS_SUBSCRIPTION } from 'helpdesk/queries';

export default function WebChatList() {
    const navigate = useNavigate();
    const params = useParams();

    const { t } = useTranslation();

    // state
    const [webchatFilter, setWebchatFilter] = React.useState('');

    const {
        data: conversationsData,
        loading: conversationsLoading,
        refetch: conversationsRefetch,
    } = useQuery(GET_CONVERSATIONS, {
        fetchPolicy: 'network-only',
    });

    useSubscription(CONVERSATIONS_SUBSCRIPTION, {
        onData: () => {
            conversationsRefetch();
        },
    });

    if (conversationsLoading) {
        return <SettingLoading />;
    }

    const conversations = conversationsData.conversations.filter(
        (conversation) =>
            conversation.fullName.toLowerCase().includes(webchatFilter.toLowerCase()) ||
            conversation.email.toLowerCase().includes(webchatFilter.toLowerCase()) ||
            conversation.initialComment.toLowerCase().includes(webchatFilter.toLowerCase())
    );

    return (
        <div className="content">
            <div className="row m-0 p-0 taskList-container">
                <div className="col-lg-4">
                    <div className="p-t-20 p-r-10 p-l-10 scroll-visible fit-with-header">
                        <div className="row m-b-20">
                            <h2>{t('webchat')}</h2>
                        </div>
                        <div className="search-row p-b-10">
                            <div className="search">
                                <button className="search-btn" type="button">
                                    <i className="fa fa-search" />
                                </button>
                                <input
                                    type="text"
                                    className="form-control search-text"
                                    value={webchatFilter}
                                    onChange={(e) => setWebchatFilter(e.target.value)}
                                    placeholder={t('search')}
                                />
                            </div>
                        </div>
                        {conversations.length === 0 && (
                            <span
                                className="color-muted"
                                style={{
                                    marginLeft: 'auto',
                                    display: 'block',
                                    width: 'fit-content',
                                    marginRight: 'auto',
                                }}
                            >
                                {t('noConversations')}
                            </span>
                        )}
                        <table className="table table-hover">
                            <tbody>
                                {conversations.map((conversation) => (
                                    <tr
                                        key={conversation.id}
                                        className={classnames('clickable', {
                                            'active': parseInt(params.id) === conversation.id,
                                        })}
                                        onClick={() => {
                                            navigate(`/helpdesk/webchat/${conversation.id}`);
                                        }}
                                    >
                                        <td>
                                            <div className="row">
                                                <Label>{conversation.fullName}</Label>
                                                <div className="ml-auto width-200 row">
                                                    <span
                                                        className="label label-info height-20"
                                                        style={{
                                                            backgroundColor: conversation.active
                                                                ? '#7ed321'
                                                                : '#9b9b9b',
                                                        }}
                                                    >
                                                        {conversation.active
                                                            ? t('open')
                                                            : t('closed')}
                                                    </span>
                                                    <div
                                                        style={{
                                                            color: conversation.read
                                                                ? 'transparent'
                                                                : 'red',
                                                        }}
                                                        className="m-l-5"
                                                    >
                                                        {conversation.unreadMessages}
                                                        <span className="material-symbols-outlined">
                                                            notifications
                                                        </span>
                                                    </div>
                                                    <div className="ml-auto">
                                                        {timestampToStringFNS(
                                                            conversation.createdAt
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            <Label>{conversation.email}</Label>
                                            <p>{conversation.initialComment}</p>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="col-lg-8">
                    {params.id && conversations.some((item) => item.id === parseInt(params.id)) && (
                        <Conversation
                            conversation={conversations.find(
                                (item) => item.id === parseInt(params.id)
                            )}
                        />
                    )}
                </div>
            </div>
        </div>
    );
}
