import React from 'react';
import { Route, Routes, Outlet } from 'react-router-dom';

import PageHeader from 'components/PageHeader';
import SelectPage from 'components/SelectPage';
import Sidebar from 'lanpass/components/sidebar';
import AccessDenied from 'components/accessDenied';
import ErrorMessages from 'components/errorMessages';
import NotificationList from 'components/notifications';

import PasswordsList from './passwords/list';
import PasswordView from './passwords/edit';

import { getMyData } from 'helperFunctions';

export default function LanpassNavigation() {
    const currentUser = getMyData();
    const accessRights = currentUser && currentUser.role ? currentUser.role.accessRights : {};

    return (
        <Routes>
            <Route
                path="lanpass/*"
                element={
                    <div>
                        <div className="page-header">
                            <div className="center-ver row center flex">
                                <SelectPage />
                                <PageHeader />
                            </div>
                        </div>

                        <div className="row center center-ver">
                            <Sidebar />
                            <div className="main">
                                <Outlet />
                            </div>
                        </div>
                    </div>
                }
            >
                <Route
                    path="errorMessages"
                    element={accessRights.viewErrors ? <ErrorMessages /> : <AccessDenied />}
                />
                <Route path="notifications" element={<NotificationList />} />
                <Route path="notifications/:notificationID" element={<NotificationList />} />

                <Route path="i/:folderID">
                    <Route path="" element={<PasswordsList />} />

                    <Route path="p/:password" element={<PasswordsList />} />
                    <Route
                        path="p/:password/:passwordID"
                        element={
                            <div className="row">
                                <PasswordsList />
                                <div style={{ width: '20%' }}>
                                    <PasswordView />
                                </div>
                            </div>
                        }
                    />

                    <Route path="s/:subfolderID" element={<PasswordsList />} />

                    <Route
                        path="s/:subfolderID/p/:password"
                        element={
                            <div className="row">
                                <PasswordsList />
                                <div style={{ width: '20%' }}>
                                    <PasswordView />
                                </div>
                            </div>
                        }
                    />
                    <Route
                        path="s/:subfolderID/p/:password/:passwordID"
                        element={
                            <div className="row">
                                <PasswordsList />
                                <div style={{ width: '20%' }}>
                                    <PasswordView />
                                </div>
                            </div>
                        }
                    />
                </Route>
            </Route>

            <Route path="*" element={null} />
        </Routes>
    );
}
