import React from 'react';

import { useNavigate } from 'react-router-dom';
import {
  useTranslation
} from "react-i18next";

export default function AccessDenied( props ) {

  const navigate = useNavigate();

  const {
    t
  } = useTranslation();

  return (
    <div className="content">
      <div className="centerHor centerVer m-t-20 m-l-20">
          {t('notPermittedToView')}
      </div>
        <div className="centerHor centerVer m-t-20">
          { props.children }
        </div>
        <div className="centerHor centerVer m-t-20 m-l-20">
          <button
            className="btn-link m-r-150"
            onClick={(e) => {
              e.preventDefault();
              navigate(-1);
            }}
            >
            {t('back')}
          </button>
            <button
              className="btn"
              onClick={(e) => {
                e.preventDefault();
                navigate("/helpdesk");
              }}
              >
              {t('mainPage')}
            </button>
        </div>
    </div>
  );
}
