import React from 'react';

import { useQuery, useMutation, useApolloClient } from '@apollo/client';

import Empty from 'components/Empty';
import SettingLoading from '../components/settingLoading';
import SettingListContainer from '../components/settingListContainer';
import ImapAdd from './imapAdd';
import ImapEdit from './imapEdit';

import { itemAttributesFullfillsString } from '../components/helpers';
import { useTranslation } from 'react-i18next';

import { GET_IMAPS, TEST_IMAPS, DELETE_IMAP } from 'helpdesk/queries';
import { addLocalError } from 'apollo/localSchema/actions';

export default function IMAPsList() {
    const client = useApolloClient();

    const { t } = useTranslation();

    const [deleteImap] = useMutation(DELETE_IMAP);

    const {
        data: imapsData,
        loading: imapsLoading,
        refetch: imapsRefetch,
    } = useQuery(GET_IMAPS, {
        fetchPolicy: 'network-only',
    });

    const [testImaps] = useMutation(TEST_IMAPS);

    // state
    const [editedItemId, setEditedItemId] = React.useState(null);
    const [imapFilter, setImapFilter] = React.useState('');
    const [imapTesting, setImapTesting] = React.useState(false);
    const [wasRefetched, setWasRefetched] = React.useState(false);

    const imaps = imapsLoading ? [] : imapsData.imaps;

    const deleteImapFunc = (id) => {
        if (window.confirm(t('generalConfirmation'))) {
            deleteImap({
                variables: {
                    id,
                },
            })
                .then(() => {
                    const filteredIMAPs = imaps.filter((IMAP) => IMAP.id !== id);

                    client.writeQuery({
                        query: GET_IMAPS,
                        data: {
                            imaps: filteredIMAPs,
                        },
                    });
                })
                .catch((err) => {
                    addLocalError(err);
                });
        }
    };

    if (imapsLoading) {
        return <SettingLoading />;
    }

    const testIMAPs = () => {
        setImapTesting(true);
        testImaps();
    };

    const getStatusIcon = (imap) => {
        let color = 'red';
        let iconName = 'cancel';
        if (imap.active) {
            if ((imapTesting && !wasRefetched) || imap.currentlyTested) {
                color = 'orange';
                iconName = 'sync';
            } else if (imap.working) {
                color = 'green';
                iconName = 'check_circle';
            }
        }
        return (
            <span className="material-symbols-outlined" style={{ color }}>
                {iconName}
            </span>
        );
    };

    const TestImapComponent = (
        <Empty>
            {!imapTesting && (
                <button
                    disabled={imapTesting}
                    className="btn center-hor ml-auto"
                    onClick={testIMAPs}
                >
                    {t('testImaps')}
                </button>
            )}
            {imapTesting && <div className="center-hor ml-auto">{t('testingImaps')}...</div>}
            {imapTesting && (
                <button
                    className="btn btn-primary center-hor ml-auto"
                    onClick={() => {
                        imapsRefetch().then(() => {
                            setWasRefetched(true);
                        });
                    }}
                >
                    {t('refetch')}
                </button>
            )}
        </Empty>
    );

    return (
        <SettingListContainer
            header={t('imaps')}
            addLabel={t('imap')}
            filter={imapFilter}
            setFilter={setImapFilter}
            RightFilterComponent={TestImapComponent}
            AddComponent={ImapAdd}
            EditComponent={ImapEdit}
            editedItemId={editedItemId}
            setEditedItemId={setEditedItemId}
        >
            <table className="table">
                <thead>
                    <tr className="clickable">
                        <th>{t('title')}</th>
                        <th>{t('host')}</th>
                        <th>{t('username')}</th>
                        <th>{t('order')}</th>
                        <th>{t('status')}</th>
                        <th width="100px">{t('actions')}</th>
                    </tr>
                </thead>
                <tbody>
                    {imaps
                        .filter((item) =>
                            itemAttributesFullfillsString(item, imapFilter, [
                                'title',
                                'host',
                                'username',
                                'order',
                            ])
                        )
                        .map((imap) => (
                            <tr key={imap.id}>
                                <td>{imap.title}</td>
                                <td>{imap.host}</td>
                                <td>{imap.username}</td>
                                <td>{imap.order}</td>
                                <td>
                                    <span className="material-symbols-outlined p-r-0">
                                        {getStatusIcon(imap)}
                                    </span>
                                </td>
                                <td className="row">
                                    {imaps.length > 1 && (
                                        <button
                                            className="btn-link-red m-r-0 m-l-0 p-0"
                                            onClick={() => {
                                                deleteImapFunc(imap.id);
                                            }}
                                        >
                                            <span className="material-symbols-outlined">
                                                delete
                                            </span>
                                        </button>
                                    )}
                                    <button
                                        className="btn-link m-r-0 m-l-10 p-0"
                                        onClick={() => {
                                            setEditedItemId(imap.id);
                                        }}
                                    >
                                        <span className="material-symbols-outlined">edit</span>
                                    </button>
                                </td>
                            </tr>
                        ))}
                </tbody>
            </table>
        </SettingListContainer>
    );
}
