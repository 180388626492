import React from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import { useMutation, useQuery } from '@apollo/client';

import PasswordForm from 'cmdb/passwords/form';

import { ADD_PASSWORD } from 'cmdb/queries';

import { GET_PROJECT } from 'apollo/queries';

export default function PasswordAddContainer() {
    const navigate = useNavigate();
    const params = useParams();

    const { data: projectData } = useQuery(GET_PROJECT);
    const localProject = projectData.localProject;

    const companyId =
        params.companyID === 'all'
            ? null
            : parseInt(params.companyID);
    const projectId = localProject.project.id;

    //mutations
    const [addPassword] = useMutation(ADD_PASSWORD);

    return (
        <PasswordForm
            edit={false}
            addPassword={(data, setSaving) => {
                setSaving(true);
                addPassword({
                    variables: {
                        ...data,
                        companyId,
                        projectId,
                    },
                })
                    .then((response1) => {
                        const id =
                            response1.data.addCmdbPassword
                                .id;
                        setSaving(false);
                        navigate(
                            `/cmdb/passwords/${
                                companyId === null
                                    ? 'all'
                                    : companyId
                            }/p/1/${id}`
                        );
                    })
                    .catch((e) => {
                        console.log(e);
                        setSaving(false);
                    });
            }}
            close={() => navigate(-1)}
        />
    );
}
