import { gql } from '@apollo/client';

export const AI_SETTING = gql`
    query {
        aiSetting {
            sortType
        }
    }
`;

export const AI_AUTOFILL_STATISTIC = gql`
    query aiAutofillStatistic(
        $dateFrom: String!
        $dateTo: String!
        $aiAutofillId: Int!
    ) {
        aiAutofillStatistic(
            dateFrom: $dateFrom
            dateTo: $dateTo
            aiAutofillId: $aiAutofillId
        ) {
            taskId
            subtaskValues
            closeDate
            createdAt
        }
    }
`;

export const AI_AUTOFILL = gql`
    query aiAutofill($id: Int!) {
        aiAutofill(id: $id) {
            id
            createdAt
            updatedAt
            active
            title
            maxSinceCreation
            maxSubtasksValues
            subtasks {
                id
                createdAt
                updatedAt
                title
                quantity
            }
        }
    }
`;

export const AI_AUTOFILLS = gql`
    query {
        aiAutofills {
            id
            createdAt
            active
            title
            maxSinceCreation
            maxSubtasksValues
        }
    }
`;

export const UPDATE_AI_SETTING = gql`
    mutation updateAISetting($sortType: AISortTypeEnum!) {
        updateAISetting(sortType: $sortType) {
            sortType
        }
    }
`;

export const ADD_AI_AUTOFILL = gql`
    mutation addAIAutofill(
        $active: Boolean!
        $title: String!
        $maxSinceCreation: Int
        $maxSubtasksValues: Float
        $maxSinceCreationActive: Boolean!
        $maxSubtasksValuesActive: Boolean!
        $subtasks: [AIAutofillSubtaskInput]
    ) {
        addAIAutofill(
            active: $active
            title: $title
            maxSinceCreation: $maxSinceCreation
            maxSubtasksValues: $maxSubtasksValues
            maxSinceCreationActive: $maxSinceCreationActive
            maxSubtasksValuesActive: $maxSubtasksValuesActive
            subtasks: $subtasks
        ) {
            active
            title
            maxSinceCreation
            maxSubtasksValues
            subtasks {
                id
                title
                quantity
            }
        }
    }
`;

export const UPDATE_AI_AUTOFILL = gql`
    mutation updateAIAutofill(
        $id: Int!
        $active: Boolean
        $title: String
        $maxSinceCreation: Int
        $maxSubtasksValues: Float
    ) {
        updateAIAutofill(
            id: $id
            active: $active
            title: $title
            maxSinceCreation: $maxSinceCreation
            maxSubtasksValues: $maxSubtasksValues
        ) {
            id
            active
            title
            maxSinceCreation
            maxSubtasksValues
        }
    }
`;

export const DELETE_AI_AUTOFILL = gql`
    mutation deleteAIAutofill($id: Int!) {
        deleteAIAutofill(id: $id) {
            id
        }
    }
`;

export const ADD_AI_AUTOFILL_SUBTASK = gql`
    mutation addAIAutofillSubtask(
        $autofillId: Int!
        $title: String!
        $quantity: Float
    ) {
        addAIAutofillSubtask(
            autofillId: $autofillId
            title: $title
            quantity: $quantity
        ) {
            id
            title
            quantity
        }
    }
`;

export const UPDATE_AI_AUTOFILL_SUBTASK = gql`
    mutation updateAIAutofillSubtask(
        $id: Int!
        $title: String!
        $quantity: Float
    ) {
        updateAIAutofillSubtask(
            id: $id
            title: $title
            quantity: $quantity
        ) {
            id
            title
            quantity
        }
    }
`;

export const DELETE_AI_AUTOFILL_SUBTASK = gql`
    mutation deleteAIAutofillSubtask($id: Int!) {
        deleteAIAutofillSubtask(id: $id) {
            id
        }
    }
`;

export const GET_AUTOFILL_FOR_TASK = gql`
    query getAutofillForTask($taskId: Int!) {
        getAutofillForTask(taskId: $taskId) {
            id
            title
            maxSinceCreation
            maxSubtasksValues
            subtasks {
                id
                title
                quantity
            }
        }
    }
`;
