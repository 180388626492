import React from 'react';
import Select from 'react-select';
import { pickSelectStyle } from 'configs/components/select';
import { FormGroup, Label } from 'reactstrap';
import Empty from 'components/Empty';
import { toSelArr } from 'helperFunctions';

import Checkbox from 'components/checkbox';

import { useTranslation } from 'react-i18next';

export default function GeneralFields(props) {
    const {
        groups,
        order,
        setOrder,
        title,
        setTitle,
        required,
        setRequired,
        read,
        setRead,
        add,
        setAdd,
        write,
        setWrite,
    } = props;

    const { t } = useTranslation();

    return (
        <Empty>
            <FormGroup>
                <Label>{t('title')}</Label>
                <input
                    className="form-control"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                />
            </FormGroup>
            <FormGroup>
                <Label>{t('order')}</Label>
                <input
                    className="form-control"
                    type="number"
                    value={order}
                    onChange={(e) => setOrder(e.target.value)}
                />
            </FormGroup>
            <Checkbox
                className="m-t-5"
                label={t('required')}
                value={required}
                onChange={() => {
                    if (!required) {
                        setAdd(toSelArr(groups));
                    }
                    setRequired(!required);
                }}
            />
            <FormGroup>
                <Label>{t('rightToAdd')}</Label>
                <Select
                    styles={pickSelectStyle()}
                    options={toSelArr(groups)}
                    value={toSelArr(add)}
                    isMulti
                    onChange={(addRights) => {
                        if (!required) {
                            setAdd(addRights);
                        }
                    }}
                />
            </FormGroup>
            <FormGroup>
                <Label>{t('rightToRead')}</Label>
                <Select
                    styles={pickSelectStyle()}
                    options={toSelArr(groups)}
                    value={toSelArr(read)}
                    isMulti
                    onChange={(readRights) => {
                        setRead(readRights);
                        setWrite(
                            write.filter((writeRight) =>
                                readRights.some((readRight) => readRight.id === writeRight.id)
                            )
                        );
                    }}
                />
            </FormGroup>
            <FormGroup>
                <Label>{t('rightToWrite')}</Label>
                <Select
                    styles={pickSelectStyle()}
                    options={toSelArr(groups)}
                    value={toSelArr(write)}
                    isMulti
                    onChange={(writeRights) => {
                        setWrite(writeRights);
                        for (var i = 0; i < writeRights.length; i++) {
                            if (!read.find((right) => right.id === writeRights[i].id)) {
                                setRead([...toSelArr(read), { ...writeRights[i] }]);
                            }
                        }
                    }}
                />
            </FormGroup>
        </Empty>
    );
}
