import {
  makeVar
} from "@apollo/client";
//import moment from 'moment';
import * as fns from 'date-fns';

import {
  actions
} from 'configs/constants/statuses';

export const reportsStatusActionsVar = makeVar( [ actions.find( ( action ) => action.value === 'CloseDate' ) ] );
//export const reportsFromDateVar = makeVar( moment().startOf( 'month' ) );
export const reportsFromDateVar = makeVar( fns.startOfMonth(new Date) );
//export const reportsToDateVar = makeVar( moment().endOf( 'month' ) );
export const reportsToDateVar = makeVar( fns.endOfMonth(new Date) );
export const reportsAgentStatusesVar = makeVar( [] );
