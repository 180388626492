import React from 'react';
import { useMutation, useQuery } from '@apollo/client';
import Loading from 'components/loading';
import FolderEdit from './edit';
import { getMyData } from 'helperFunctions';
import {
    GET_LANWIKI_USERS,
    GET_FOLDER,
    UPDATE_FOLDER,
    DELETE_FOLDER,
} from 'lanwiki/queries';

import { GET_PROJECT } from 'apollo/queries';

export default function EditFolderLoader(props) {
    const { id, folders } = props;
    const currentUser = getMyData();

    const { data: projectData } = useQuery(GET_PROJECT);
    const localProject = projectData.localProject;

    const { data: usersData, loading: usersLoading } =
        useQuery(GET_LANWIKI_USERS, {
            variables: {
                projectId: localProject.project.id,
            },
            fetchPolicy: 'network-only',
        });

    const { data: folderData, loading: folderLoading } =
        useQuery(GET_FOLDER, {
            variables: {
                id,
            },
            fetchPolicy: 'network-only',
        });

    const [updateFolder] = useMutation(UPDATE_FOLDER);
    const [deleteFolder] = useMutation(DELETE_FOLDER);

    if (usersLoading || folderLoading) {
        return <Loading />;
    }

    return (
        <FolderEdit
            {...props}
            updateFolder={updateFolder}
            deleteFolder={deleteFolder}
            users={usersData.lanwikiUsers}
            folder={folderData.lanwikiFolder}
            folders={folders}
            currentUser={currentUser}
        />
    );
}
