import React from 'react';
import { Modal, ModalBody, ModalHeader, FormGroup, Label } from 'reactstrap';
import GeneralFields from './generalFields';
import TextNumberSpecificFields from './textNumberSpecificFields';
import SelectsSpecificFields from './selectsSpecificFields';

import {
    ORDER_IS_NOT_NUMBER,
    TITLE_REQUIRED,
    OPTION_REQUIRED,
    READ_REQUIRED,
} from 'configs/constants/projects';

import { useTranslation } from 'react-i18next';

export default function CustomAttributes(props) {
    const { open, groups, updateCustomAttribute, attribute, closeModal } = props;

    const { t } = useTranslation();

    const [order, setOrder] = React.useState(0);
    const [title, setTitle] = React.useState('');
    const [selectValues, setSelectValues] = React.useState([]);
    const [, setAllSelectValues] = React.useState([]);
    const [add, setAdd] = React.useState([]);
    const [read, setRead] = React.useState([]);
    const [write, setWrite] = React.useState([]);
    const [defaultValue, setDefaultValue] = React.useState({
        text: null,
        number: null,
    });
    const [required, setRequired] = React.useState(false);
    const [errors, setErrors] = React.useState([]);

    // sync
    React.useEffect(() => {
        if (attribute !== null) {
            setOrder(attribute.order);
            setTitle(attribute.title);
            setSelectValues(
                attribute.selectValues
                    ? attribute.selectValues.sort((o1, o2) => (o1.order < o2.order ? -1 : 1))
                    : []
            );
            setAllSelectValues(
                attribute.selectValues
                    ? attribute.selectValues.sort((o1, o2) => (o1.order < o2.order ? -1 : 1))
                    : []
            );
            setAdd(translateGroup(attribute.addGroups));
            setRead(translateGroup(attribute.viewGroups));
            setWrite(translateGroup(attribute.editGroups));
            setDefaultValue(attribute.defaultValue);
            setRequired(attribute.required);
        }
    }, [attribute]);

    const translateGroup = (group) => {
        return group.map((level) => ({
            ...level,
            title: t(level.title),
        }));
    };

    let type = null;
    let typeLabel = '';
    if (attribute && attribute.type) {
        if (typeof attribute.type === 'string') {
            type = attribute.type;
            typeLabel =
                attribute.type === 'select' ? t(attribute.type + 'Noun') : t(attribute.type);
        } else {
            type = attribute.type.value;
            typeLabel =
                attribute.type.value === 'select'
                    ? t(attribute.type.value + 'Noun')
                    : t(attribute.type.value);
        }
    }

    const getErrors = () => {
        let result = [];
        if (isNaN(parseInt(order))) {
            result.push(ORDER_IS_NOT_NUMBER);
        }
        if (title.length === 0) {
            result.push(TITLE_REQUIRED);
        }
        if (
            required &&
            ['select', 'multiselect'].includes(type.value) &&
            selectValues.length === 0
        ) {
            result.push(OPTION_REQUIRED);
        }
        if (read.length === 0) {
            result.push(READ_REQUIRED);
        }
        return result;
    };

    return (
        <Modal isOpen={open}>
            <ModalHeader>{t('editCustomAttribute')}</ModalHeader>
            <ModalBody>
                <FormGroup>
                    <Label>{t('type')}: </Label>
                    <span className="m-l-5">{typeLabel ? typeLabel : ''}</span>
                </FormGroup>

                {attribute && type !== null && (
                    <GeneralFields
                        type={type}
                        groups={groups}
                        order={order}
                        setOrder={setOrder}
                        title={title}
                        setTitle={setTitle}
                        required={required}
                        setRequired={setRequired}
                        selectValues={selectValues}
                        read={read}
                        setRead={setRead}
                        add={add}
                        setAdd={setAdd}
                        write={write}
                        setWrite={setWrite}
                    />
                )}

                {attribute && ['text', 'textarea', 'number'].includes(type) && (
                    <TextNumberSpecificFields
                        type={type}
                        defaultValue={defaultValue.text ? defaultValue.text : defaultValue.number}
                        setDefaultValue={setDefaultValue}
                    />
                )}
                {attribute && ['select', 'multiselect'].includes(type) && (
                    <SelectsSpecificFields
                        type={type}
                        selectValues={selectValues}
                        setSelectValues={setSelectValues}
                        defaultValue={defaultValue}
                        setDefaultValue={setDefaultValue}
                    />
                )}

                {errors.length > 0 && (
                    <div>
                        {errors.map((error) => (
                            <span className="error-message">{t(error)}</span>
                        ))}
                    </div>
                )}

                <div className="row">
                    <button
                        className="btn-link-cancel"
                        onClick={() => {
                            setOrder(0);
                            setTitle('');
                            setSelectValues([]);
                            setAllSelectValues([]);
                            setAdd([]);
                            setRead([]);
                            setWrite([]);
                            setDefaultValue({
                                text: null,
                                number: null,
                            });
                            setRequired(false);
                            closeModal();
                        }}
                    >
                        {' '}
                        {t('cancel')}{' '}
                    </button>

                    <button
                        className="btn ml-auto"
                        onClick={() => {
                            let newErrors = getErrors();
                            if (newErrors.length > 0) {
                                setErrors(newErrors);
                            } else {
                                updateCustomAttribute({
                                    id: attribute.id,
                                    order,
                                    title,
                                    selectValues,
                                    addGroups: add,
                                    viewGroups: read,
                                    editGroups: write,
                                    defaultValue,
                                    required,
                                });
                                setOrder(0);
                                setTitle('');
                                setSelectValues([]);
                                setAllSelectValues([]);
                                setAdd([]);
                                setRead([]);
                                setWrite([]);
                                setDefaultValue({
                                    text: null,
                                    number: null,
                                });
                                setRequired(false);
                                closeModal();
                            }
                        }}
                    >
                        {t('saveCustomAttribute')}
                    </button>
                </div>
            </ModalBody>
        </Modal>
    );
}
