export default {
  actionNone: {
    en: 'None (nothing happens)',
    sk: 'Žiadna (nič sa nestane)',
    cz: 'Žádný (nic se neděje)',
    de: 'Keine (es passiert nichts)',
  },
  actionIsOpen: {
    en: 'Open (none is open)',
    sk: 'Otvorená (žiadna, je otvorená)',
    cz: 'Otevřeno (žádný není otevřený)',
    de: 'Offen (keine ist offen)',
  },
  actionIsNew: {
    en: 'New (none is new)',
    sk: 'Nová (žiadna, je nová)',
    cz: 'Nový (žádný není nový)',
    de: 'Neu (keine ist neu)',
  },
  actionCloseDate: {
    en: 'Close (set close date as current, can be invoiced)',
    sk: 'Zavrieť (nastaví dátum zavretia úlohy, može byť vykázaná)',
    cz: 'Zavřít (nastavit datum uzavření jako aktuální, lze fakturovat)',
    de: 'Schließen (Schließdatum auf aktuell setzen, kann fakturiert werden)',
  },
  actionCloseInvalid: {
    en: `Close Invalid (set close date as current, can't be invoiced)`,
    sk: `Zavrieť neplatný (nastaví dátum zavretia úlohy, nemože byť vykázaná)`,
    cz: 'Zavřít Neplatné (nastavit datum uzavření jako aktuální, nelze fakturovat)',
    de: 'Abschluss ungültig (Abschlussdatum auf aktuell setzen, kann nicht in Rechnung gestellt werden)',
  },
  actionPendingDate: {
    en: 'Pending (set pending date to 24 hour)',
    sk: 'Čaká (nastaví dátum čakania na 24 hodín)',
    cz: 'Nevyřízeno (nastavit datum čekání na 24 hodin)',
    de: 'Ausstehend (ausstehendes Datum auf 24 Stunden setzen)',
  },
}
