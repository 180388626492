import React from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import Empty from 'components/Empty';
import ProjectFilterForm from './projectFilterForm';

export default function ProjectFilterEdit(props) {
    //props
    const {
        allGroups,
        allStatuses,
        allTags,
        customAttributes,
        allUsers,
        allCompanies,
        filter,
        updateFilter,
        /*allTaskTypes,*/
    } = props;

    const { t } = useTranslation();

    const [open, setOpen] = React.useState(false);

    return (
        <Empty>
            <button
                className="btn-link btn-distance"
                style={{ height: 'auto' }}
                onClick={() => setOpen(true)}
            >
                {t('edit')}
            </button>
            <Modal isOpen={open}>
                <ModalHeader>{`${t('edit')} ${t('projectFilter').toLowerCase()}`}</ModalHeader>
                <ModalBody>
                    <ProjectFilterForm
                        allGroups={allGroups}
                        allStatuses={allStatuses}
                        allTags={allTags}
                        allCustomAttributes={customAttributes}
                        allUsers={allUsers}
                        allCompanies={allCompanies}
                        edit
                        isOpened={open}
                        submit={(projectFilter) => updateFilter(projectFilter)}
                        filter={filter}
                        closeModal={() => setOpen(false)}
                    />
                </ModalBody>
            </Modal>
        </Empty>
    );
}
