import React, { useState, useMemo, useEffect } from 'react';
import { useQuery } from '@apollo/client';

import {
    FormGroup,
    Label,
    Modal,
    ModalBody,
    ModalHeader,
} from 'reactstrap';
import Loading from 'components/loading';
import Switch from 'components/switch';
import Select from 'react-select';
import SettingsInput from 'helpdesk/settings/components/settingsInput';

import { pickSelectStyle } from 'configs/components/select';

import { useTranslation } from 'react-i18next';

import { AI_AUTOFILL } from 'helpdesk/queries';

let fakeID = -1;

export default function AISolutionsForm(props) {
    const { autofillId, onSave, onClose } = props;

    const { t } = useTranslation();

    const [openAddSubtask, setOpenAddSubtask] =
        useState(false);

    const [active, setActive] = useState(true);
    const [title, setTitle] = useState('');
    const [
        maxSinceCreationValue,
        setMaxSinceCreationValue,
    ] = useState(0);
    const [maxSinceCreationUnit, setMaxSinceCreationUnit] =
        useState({
            label: t('days'),
            value: 'd',
        });
    const [maxSubtasksValues, setMaxSubtasksValues] =
        useState(0);

    const [subtasks, setSubtasks] = useState([]);
    const [addedSubtasks, setAddedSubtasks] = useState([]);
    const [updatedSubtasks, setUpdatedSubtasks] = useState(
        []
    );
    const [deletedSubtasks, setDeletedSubtasks] = useState(
        []
    );

    const [newSubtaskTitle, setNewSubtaskTitle] =
        useState('');
    const [newSubtaskQuantity, setNewSubtaskQuantity] =
        useState(0);

    const [errors, setErrors] = useState([]);

    const {
        data: autofillData,
        loading: autofillLoading,
        refetch: autofillRefetch,
    } = useQuery(AI_AUTOFILL, {
        variables: {
            id: autofillId ? autofillId : -1,
        },
        fetchPolicy: 'network-only',
    });

    useEffect(() => {
        if (autofillId) {
            autofillRefetch({
                id: autofillId,
            });
        }
    }, [autofillId]);

    useEffect(() => {
        if (autofillData && autofillData.aiAutofill) {
            setActive(autofillData.aiAutofill.active);
            setTitle(autofillData.aiAutofill.title);
            if (
                autofillData.aiAutofill.maxSinceCreation %
                    24 ===
                0
            ) {
                if (
                    !autofillData.aiAutofill
                        .maxSinceCreation
                ) {
                    setMaxSinceCreationValue(0);
                } else {
                    setMaxSinceCreationValue(
                        autofillData.aiAutofill
                            .maxSinceCreation / 24
                    );
                }
                setMaxSinceCreationUnit({
                    label: t('days'),
                    value: 'd',
                });
            } else {
                if (
                    !autofillData.aiAutofill
                        .maxSinceCreation
                ) {
                    setMaxSinceCreationValue(0);
                } else {
                    setMaxSinceCreationValue(
                        autofillData.aiAutofill
                            .maxSinceCreation
                    );
                }
                setMaxSinceCreationUnit({
                    label: t('hours'),
                    value: 'h',
                });
            }
            setMaxSubtasksValues(
                autofillData.aiAutofill.maxSubtasksValues
            );
            setSubtasks(autofillData.aiAutofill.subtasks);
            setAddedSubtasks([]);
            setUpdatedSubtasks([]);
            setDeletedSubtasks([]);
        }
    }, [autofillData]);

    const filteredSubtasks = useMemo(() => {
        return [
            ...subtasks.map((subtask) => {
                const updatedSubtask = updatedSubtasks.find(
                    (updatedSubtask) =>
                        updatedSubtask.id === subtask.id
                );
                if (updatedSubtask) {
                    return updatedSubtask;
                }
                return subtask;
            }),
            ...addedSubtasks,
        ].filter(
            (subtask) =>
                !deletedSubtasks.includes(subtask.id)
        );
    }, [
        subtasks,
        addedSubtasks,
        updatedSubtasks,
        deletedSubtasks,
    ]);

    const getErrors = () => {
        let result = [];

        if (title.length < 2) {
            result.push('titleTooShort');
        }

        return result;
    };

    if (autofillLoading && autofillId) {
        return <Loading />;
    }

    const convertToHours = (value, unit) => {
        if (parseInt(value) === 0) {
            return null;
        }
        if (unit.value === 'd') {
            return value * 24;
        }
        return value;
    };

    const returnSubtasksToSave = () => {
        if (autofillId) {
            return {
                addedSubtasks: addedSubtasks.map(
                    (subtask) => ({
                        autofillId,
                        title: subtask.title,
                        quantity: parseFloat(
                            subtask.quantity
                        ),
                    })
                ),
                updatedSubtasks: updatedSubtasks.map(
                    (subtask) => ({
                        id: subtask.id,
                        title: subtask.title,
                        quantity: parseFloat(
                            subtask.quantity
                        ),
                    })
                ),
                deletedSubtasks: deletedSubtasks,
            };
        }
        return {
            subtasks: subtasks.map((subtask) => ({
                title: subtask.title,
                quantity: parseFloat(subtask.quantity),
            })),
        };
    };

    const subtaskTimeInsufficinet = () => {
        let totalSubtaskTime = 0;
        filteredSubtasks.forEach((subtask) => {
            totalSubtaskTime += parseFloat(
                subtask.quantity
            );
        });
        return (
            parseFloat(maxSubtasksValues) < totalSubtaskTime
        );
    };

    return (
        <Modal isOpen={true}>
            <ModalHeader>{`${
                !autofillId ? t('add') : t('edit')
            } ${t('autofill')}`}</ModalHeader>
            <ModalBody>
                <h1></h1>
                <Switch
                    value={active}
                    onChange={() => {
                        setActive(!active);
                    }}
                    label={t('active')}
                    labelClassName="text-normal font-normal"
                    simpleSwitch
                />
                <SettingsInput
                    required
                    id="title"
                    label={t('autofillName')}
                    value={title}
                    onChange={(e) => {
                        setTitle(e.target.value);
                    }}
                />

                <div
                    className="row m-b-10"
                    style={{ alignItems: 'flex-end' }}
                >
                    <SettingsInput
                        id="maxInterval"
                        type="number"
                        label={t(
                            'maxIntervalFromCreateToClose'
                        )}
                        className="w-50 m-b-0"
                        value={maxSinceCreationValue}
                        onChange={(e) => {
                            setMaxSinceCreationValue(
                                e.target.value
                            );
                        }}
                    />
                    <div
                        className="flex m-l-10"
                        style={{ width: '50%' }}
                    >
                        <Select
                            options={[
                                {
                                    label: t('hours'),
                                    value: 'h',
                                },
                                {
                                    label: t('days'),
                                    value: 'd',
                                },
                            ]}
                            onChange={(newValue) => {
                                setMaxSinceCreationUnit(
                                    newValue
                                );
                            }}
                            value={maxSinceCreationUnit}
                            styles={pickSelectStyle([
                                'colored',
                            ])}
                        />
                    </div>
                </div>

                <SettingsInput
                    id="maxInterval"
                    type="number"
                    label={t('maxWorkTime')}
                    value={maxSubtasksValues}
                    error={subtaskTimeInsufficinet()}
                    errorMessage={t(
                        'subtaskTimeInsufficinet'
                    )}
                    onChange={(e) => {
                        setMaxSubtasksValues(
                            e.target.value
                        );
                    }}
                />

                <FormGroup>
                    <Label>{t('works')}</Label>
                    <table className="table">
                        <thead>
                            <tr>
                                <th className="p-l-0">
                                    {t('title')}
                                </th>
                                <th width="100px">
                                    {t('quantity')}
                                </th>
                                <th
                                    width="100px"
                                    className="text-end"
                                >
                                    {t('actions')}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredSubtasks.map(
                                (subtask) => (
                                    <tr key={subtask.id}>
                                        <td className="p-l-0">
                                            <input
                                                className="form-control hidden-input segoe-blue-text"
                                                value={
                                                    subtask.title
                                                }
                                                onChange={(
                                                    e
                                                ) => {
                                                    if (
                                                        autofillId
                                                    ) {
                                                        if (
                                                            subtask.id >=
                                                            0
                                                        ) {
                                                            const wasUpdatedBefore =
                                                                updatedSubtasks.find(
                                                                    (
                                                                        updatedSubtask
                                                                    ) =>
                                                                        updatedSubtask.id ===
                                                                        subtask.id
                                                                );
                                                            if (
                                                                wasUpdatedBefore
                                                            ) {
                                                                setUpdatedSubtasks(
                                                                    updatedSubtasks.map(
                                                                        (
                                                                            updatedSubtask
                                                                        ) => {
                                                                            if (
                                                                                updatedSubtask.id ===
                                                                                subtask.id
                                                                            ) {
                                                                                return {
                                                                                    ...updatedSubtask,
                                                                                    title: e
                                                                                        .target
                                                                                        .value,
                                                                                };
                                                                            }
                                                                            return updatedSubtask;
                                                                        }
                                                                    )
                                                                );
                                                            } else {
                                                                setUpdatedSubtasks(
                                                                    [
                                                                        ...updatedSubtasks,
                                                                        {
                                                                            ...subtask,
                                                                            title: e
                                                                                .target
                                                                                .value,
                                                                        },
                                                                    ]
                                                                );
                                                            }
                                                        } else {
                                                            setAddedSubtasks(
                                                                addedSubtasks.map(
                                                                    (
                                                                        addedSubtask
                                                                    ) => {
                                                                        if (
                                                                            addedSubtask.id ===
                                                                            subtask.id
                                                                        ) {
                                                                            return {
                                                                                ...addedSubtask,
                                                                                title: e
                                                                                    .target
                                                                                    .value,
                                                                            };
                                                                        }
                                                                        return addedSubtask;
                                                                    }
                                                                )
                                                            );
                                                        }
                                                    } else {
                                                        setSubtasks(
                                                            subtasks.map(
                                                                (
                                                                    updatedSubtask
                                                                ) => {
                                                                    if (
                                                                        updatedSubtask.id ===
                                                                        subtask.id
                                                                    ) {
                                                                        return {
                                                                            ...updatedSubtask,
                                                                            title: e
                                                                                .target
                                                                                .value,
                                                                        };
                                                                    }
                                                                    return updatedSubtask;
                                                                }
                                                            )
                                                        );
                                                    }
                                                }}
                                            />
                                        </td>
                                        <td className="p-l-0">
                                            <input
                                                type="number"
                                                className="form-control hidden-input segoe-blue-text"
                                                value={
                                                    subtask.quantity
                                                }
                                                onChange={(
                                                    e
                                                ) => {
                                                    if (
                                                        autofillId
                                                    ) {
                                                        if (
                                                            subtask.id >=
                                                            0
                                                        ) {
                                                            const wasUpdatedBefore =
                                                                updatedSubtasks.find(
                                                                    (
                                                                        updatedSubtask
                                                                    ) =>
                                                                        updatedSubtask.id ===
                                                                        subtask.id
                                                                );
                                                            if (
                                                                wasUpdatedBefore
                                                            ) {
                                                                setUpdatedSubtasks(
                                                                    updatedSubtasks.map(
                                                                        (
                                                                            updatedSubtask
                                                                        ) => {
                                                                            if (
                                                                                updatedSubtask.id ===
                                                                                subtask.id
                                                                            ) {
                                                                                return {
                                                                                    ...updatedSubtask,
                                                                                    quantity:
                                                                                        e
                                                                                            .target
                                                                                            .value,
                                                                                };
                                                                            }
                                                                            return updatedSubtask;
                                                                        }
                                                                    )
                                                                );
                                                            } else {
                                                                setUpdatedSubtasks(
                                                                    [
                                                                        ...updatedSubtasks,
                                                                        {
                                                                            ...subtask,
                                                                            quantity:
                                                                                e
                                                                                    .target
                                                                                    .value,
                                                                        },
                                                                    ]
                                                                );
                                                            }
                                                        } else {
                                                            setAddedSubtasks(
                                                                addedSubtasks.map(
                                                                    (
                                                                        addedSubtask
                                                                    ) => {
                                                                        if (
                                                                            addedSubtask.id ===
                                                                            subtask.id
                                                                        ) {
                                                                            return {
                                                                                ...addedSubtask,
                                                                                quantity:
                                                                                    e
                                                                                        .target
                                                                                        .value,
                                                                            };
                                                                        }
                                                                        return addedSubtask;
                                                                    }
                                                                )
                                                            );
                                                        }
                                                    } else {
                                                        setSubtasks(
                                                            subtasks.map(
                                                                (
                                                                    updatedSubtask
                                                                ) => {
                                                                    if (
                                                                        updatedSubtask.id ===
                                                                        subtask.id
                                                                    ) {
                                                                        return {
                                                                            ...updatedSubtask,
                                                                            quantity:
                                                                                e
                                                                                    .target
                                                                                    .value,
                                                                        };
                                                                    }
                                                                    return updatedSubtask;
                                                                }
                                                            )
                                                        );
                                                    }
                                                }}
                                            />
                                        </td>
                                        <td className="row justify-content-fe">
                                            <button
                                                className="btn-link btn-distance"
                                                onClick={() => {}}
                                            >
                                                <span className="material-symbols-outlined">
                                                    arrow_upward
                                                </span>
                                            </button>
                                            <button
                                                className="btn-link btn-distance"
                                                onClick={() => {}}
                                            >
                                                <span className="material-symbols-outlined">
                                                    arrow_downward
                                                </span>
                                            </button>
                                            <button
                                                className="btn-link"
                                                onClick={() => {
                                                    setDeletedSubtasks(
                                                        [
                                                            ...deletedSubtasks,
                                                            subtask.id,
                                                        ]
                                                    );
                                                }}
                                            >
                                                <span className="material-symbols-outlined p-r-0">
                                                    close
                                                </span>
                                            </button>
                                        </td>
                                    </tr>
                                )
                            )}
                            {openAddSubtask && (
                                <tr key={'addOption'}>
                                    <td className="p-l-0">
                                        <input
                                            className="form-control segoe-blue-text"
                                            value={
                                                newSubtaskTitle
                                            }
                                            onChange={(
                                                e
                                            ) => {
                                                setNewSubtaskTitle(
                                                    e.target
                                                        .value
                                                );
                                            }}
                                        />
                                    </td>
                                    <td className="p-l-0">
                                        <input
                                            type="number"
                                            className="form-control segoe-blue-text"
                                            value={
                                                newSubtaskQuantity
                                            }
                                            onChange={(
                                                e
                                            ) => {
                                                setNewSubtaskQuantity(
                                                    e.target
                                                        .value
                                                );
                                            }}
                                        />
                                    </td>
                                    <td className="text-end">
                                        <button
                                            className="btn-link"
                                            onClick={() => {
                                                if (
                                                    autofillId
                                                ) {
                                                    setAddedSubtasks(
                                                        [
                                                            ...addedSubtasks,
                                                            {
                                                                id: fakeID--,
                                                                title: newSubtaskTitle,
                                                                quantity:
                                                                    newSubtaskQuantity,
                                                            },
                                                        ]
                                                    );
                                                } else {
                                                    setSubtasks(
                                                        [
                                                            ...subtasks,
                                                            {
                                                                id: fakeID--,
                                                                title: newSubtaskTitle,
                                                                quantity:
                                                                    newSubtaskQuantity,
                                                            },
                                                        ]
                                                    );
                                                }
                                                setNewSubtaskTitle(
                                                    ''
                                                );
                                                setNewSubtaskQuantity(
                                                    0
                                                );
                                                setOpenAddSubtask(
                                                    false
                                                );
                                            }}
                                        >
                                            <span className="material-symbols-outlined bigger">
                                                add
                                            </span>
                                        </button>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                    {!openAddSubtask && (
                        <button
                            className="btn-link"
                            onClick={() =>
                                setOpenAddSubtask(true)
                            }
                        >
                            <span className="material-symbols-outlined bigger">
                                add
                            </span>
                            {t('option')}
                        </button>
                    )}
                </FormGroup>

                {errors.length > 0 && (
                    <div>
                        {errors.map((error) => (
                            <span className="error-message">
                                {t(error)}
                            </span>
                        ))}
                    </div>
                )}

                <div className="form-buttons-row">
                    <button
                        className="btn-link"
                        onClick={() => {
                            onClose();

                            setActive(true);
                            setTitle('');
                            setMaxSinceCreationValue(0);
                            setMaxSinceCreationUnit({
                                label: 'Days',
                                value: 'd',
                            });
                            setMaxSubtasksValues(0);
                            setSubtasks([]);
                            setAddedSubtasks([]);
                            setUpdatedSubtasks([]);
                            setDeletedSubtasks([]);
                        }}
                    >
                        {t('close')}
                    </button>
                    <button
                        className="btn ml-auto"
                        onClick={() => {
                            const newErrors = getErrors();
                            if (newErrors.length > 0) {
                                setErrors(newErrors);
                            } else {
                                onSave({
                                    ...(autofillId
                                        ? { id: autofillId }
                                        : {}),
                                    title,
                                    active,
                                    maxSinceCreation:
                                        convertToHours(
                                            parseInt(
                                                maxSinceCreationValue
                                            ),
                                            maxSinceCreationUnit
                                        ),
                                    maxSubtasksValues:
                                        parseFloat(
                                            maxSubtasksValues
                                        ) === 0
                                            ? null
                                            : parseFloat(
                                                  maxSubtasksValues
                                              ),
                                    maxSinceCreationActive: true,
                                    maxSubtasksValuesActive: true,
                                    ...returnSubtasksToSave(),
                                });

                                setActive(true);
                                setTitle('');
                                setMaxSinceCreationValue(0);
                                setMaxSinceCreationUnit({
                                    label: 'Days',
                                    value: 'd',
                                });
                                setMaxSubtasksValues(0);
                                setSubtasks([]);
                                setAddedSubtasks([]);
                                setUpdatedSubtasks([]);
                                setDeletedSubtasks([]);
                            }
                        }}
                    >
                        {t('save')}
                    </button>
                </div>
            </ModalBody>
        </Modal>
    );
}
