import React from 'react';

import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { defaultFilter } from 'configs/constants/filter';

export default function NotificationInfo(props) {
    const { notification, currentUser, setEmailNotifications } = props;

    const navigate = useNavigate();

    const { t } = useTranslation();

    let subject = notification.subject;
    if (
        notification.task &&
        notification.subject.includes('v úlohe') &&
        notification.subject.substring(notification.subject.indexOf('v úlohe') + 7).includes(':')
    ) {
        subject = notification.subject.substring(0, notification.subject.indexOf('v úlohe') + 7);
    }
    if (
        notification.task &&
        notification.subject.includes('task') &&
        notification.subject.substring(notification.subject.indexOf('task') + 4).includes(':')
    ) {
        subject = notification.subject.substring(0, notification.subject.indexOf('task') + 4);
    }

    return (
        <div className="notification-info">
            <div className="p-20 scroll-visible fit-with-header">
                <h2>{subject}</h2>
                <h3>
                    <span
                        className="clickable"
                        onClick={() => {
                            if (notification.task) {
                                navigate(
                                    `/helpdesk/taskList/i/${defaultFilter}/${notification.task.id}`
                                );
                            }
                        }}
                    >
                        {notification.task
                            ? `${notification.task.id}: ${notification.task.title}`
                            : t('noTask')}
                    </span>
                </h3>
                <hr />
                <div>
                    <div
                        className="m-b-15 font-13 notification-body"
                        dangerouslySetInnerHTML={{
                            __html: notification.message.replace(/(?:\r\n|\r|\n)/g, '<br>'),
                        }}
                    />
                </div>
                <div>
                    {currentUser.receiveNotifications && (
                        <button
                            className="btn-link"
                            style={{ display: 'block' }}
                            onClick={() => {
                                if (window.confirm(t('confirmMessagesCancelMailNotifications'))) {
                                    setEmailNotifications();
                                }
                            }}
                        >
                            {t('cancelMailNotifications')}
                        </button>
                    )}
                    {!currentUser.receiveNotifications && (
                        <button
                            className="btn-link"
                            style={{ display: 'block' }}
                            onClick={() => {
                                if (window.confirm(t('confirmMessagesAllowMailNotifications'))) {
                                    setEmailNotifications();
                                }
                            }}
                        >
                            {t('allowMailNotifications')}
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
}
