import React from 'react';

import {
    useQuery,
    useSubscription,
    useMutation,
} from '@apollo/client';

import SettingLoading from '../components/settingLoading';
import SettingListContainer from '../components/settingListContainer';
import StatusAdd from './statusAdd';
import StatusEdit from './statusEdit';

import { orderArr } from 'helperFunctions';
import { itemAttributesFullfillsString } from '../components/helpers';
import { useTranslation } from 'react-i18next';
import { addLocalError } from 'apollo/localSchema/actions';

import {
    GET_STATUS_TEMPLATES,
    STATUS_TEMPLATE_SUBSCRIPTION,
    DELETE_STATUS_TEMPLATE,
} from 'helpdesk/queries';

export default function StatusesList() {
    const { t } = useTranslation();

    const [deleteStatus] = useMutation(
        DELETE_STATUS_TEMPLATE
    );

    const {
        data: statusTemplatesData,
        loading: statusTemplatesLoading,
        refetch: statusTemplatesRefetch,
    } = useQuery(GET_STATUS_TEMPLATES, {
        fetchPolicy: 'network-only',
    });

    useSubscription(STATUS_TEMPLATE_SUBSCRIPTION, {
        onData: () => {
            statusTemplatesRefetch();
        },
    });

    // state
    const [statusFilter, setStatusFilter] =
        React.useState('');
    const [editedItemId, setEditedItemId] =
        React.useState(null);

    if (statusTemplatesLoading) {
        return <SettingLoading />;
    }

    const statuses = orderArr(
        statusTemplatesData.statusTemplates
    );

    const deleteStatusFunc = (id) => {
        if (window.confirm(t('generalConfirmation'))) {
            deleteStatus({
                variables: {
                    id,
                },
            }).catch((err) => {
                addLocalError(err);
            });
        }
    };

    return (
        <SettingListContainer
            header={t('statusTemplates')}
            addLabel={t('statusTemplate')}
            filter={statusFilter}
            setFilter={setStatusFilter}
            AddComponent={StatusAdd}
            EditComponent={StatusEdit}
            editedItemId={editedItemId}
            setEditedItemId={setEditedItemId}
        >
            <table className="table">
                <thead>
                    <tr>
                        <th>{t('title')}</th>
                        <th>{t('order')}</th>
                        <th width="100px">
                            {t('actions')}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {statuses
                        .filter((item) =>
                            itemAttributesFullfillsString(
                                item,
                                statusFilter,
                                ['title']
                            )
                        )
                        .map((status) => (
                            <tr key={status.id}>
                                <td>{status.title}</td>
                                <td>
                                    {status.order
                                        ? status.order
                                        : 0}
                                </td>
                                <td className="row">
                                    {statuses.length >
                                        1 && (
                                        <button
                                            className="btn-link-red m-r-0 m-l-0 p-0"
                                            onClick={() => {
                                                deleteStatusFunc(
                                                    status.id
                                                );
                                            }}
                                        >
                                            <span className="material-symbols-outlined">
                                                delete
                                            </span>
                                        </button>
                                    )}
                                    <button
                                        className="btn-link m-r-0 m-l-10 p-0"
                                        onClick={() => {
                                            setEditedItemId(
                                                status.id
                                            );
                                        }}
                                    >
                                        <span className="material-symbols-outlined">
                                            edit
                                        </span>
                                    </button>
                                </td>
                            </tr>
                        ))}
                </tbody>
            </table>
        </SettingListContainer>
    );
}
