import React from 'react';
import { useSubscription, useQuery } from '@apollo/client';
import { Input, Label, FormGroup, Modal, ModalBody, ModalHeader, Spinner } from 'reactstrap';
import Checkbox from 'components/checkbox';
import Pagination from './pagination';
import { getMyData } from 'helperFunctions';
import Creatable from 'react-select/creatable';
import CKEditor from 'components/CKEditor';
import { pickSelectStyle } from 'configs/components/select';
import axios from 'axios';
import classnames from 'classnames';

import { REST_URL } from 'configs/restAPI';
import { GET_COMMENTS, COMMENTS_SUBSCRIPTION, WORKING_SMTP } from 'helpdesk/queries';
import Loading from 'components/loading';

import Email from './email';

import downloadjs from 'downloadjs';
import { useTranslation } from 'react-i18next';
import { addLocalError } from 'apollo/localSchema/actions';
import Empty from 'components/Empty';

const limit = 5;
const maximumSize = 20 * 1024 * 1024; //20MB in B

export default function Comments(props) {
    const {
        id,
        isMulti,
        users,
        userRights,
        disabled,
        fromInvoice,
        taskRequesterId,
        taskAssignedIds,
        taskTitle,
    } = props;

    const currentUser = getMyData();

    const { t } = useTranslation();
    const [page, setPage] = React.useState(1);

    const { data: workingSmtpData } = useQuery(WORKING_SMTP);

    const {
        data: commentsData,
        loading: commentsLoading,
        refetch: commentsRefetch,
        error: commentsError,
    } = useQuery(GET_COMMENTS, {
        variables: {
            task: id,
            page,
            limit,
            fromInvoice,
        },
        fetchPolicy: 'network-only',
    });

    useSubscription(COMMENTS_SUBSCRIPTION, {
        variables: {
            taskId: id,
        },
        onData: () => {
            commentsRefetch({
                task: id,
                page,
                limit,
                fromInvoice,
            });
        },
    });

    const [attachments, setAttachments] = React.useState([]);
    const [attachmentError, setAttachmentError] = React.useState(null);

    const [emailBody, setEmailBody] = React.useState(
        `<br>${currentUser.signature.replace(/(?:\r\n|\r|\n)/g, '<br>')}`
    );
    const [hasError] = React.useState(false);
    const [isInternal, setIsInternal] = React.useState(false);
    const [saving, setSaving] = React.useState(false);
    const [subject, setSubject] = React.useState('');
    const [tos, setTos] = React.useState([]);
    const [openAddRecipient, setOpenAddRecipient] = React.useState(false);
    const [addCommentOpen, setAddCommentOpen] = React.useState(false);

    const [showInModal, setShowInModal] = React.useState(false);

    React.useEffect(() => {
        setPage(1);
        commentsRefetch({
            task: id,
            page: 1,
            limit,
            fromInvoice,
        });
    }, [id]);

    React.useEffect(() => {
        commentsRefetch({
            task: id,
            page,
            limit,
            fromInvoice,
        });
    }, [page]);

    const getAttachment = (attachment) => {
        axios
            .get(`${REST_URL}/api/get-attachments`, {
                params: {
                    type: 'comment',
                    path: attachment.path,
                    fromInvoice,
                },
                headers: {
                    'authorization': `${sessionStorage.getItem('acctok')}`,
                },
                responseType: 'arraybuffer',
            })
            .then((response) => {
                /*
        window.open(
        URL.createObjectURL(
        new Blob( [ response.data ], {
        type: attachment.mimetype
        } )
        )
        );
        */
                //download
                downloadjs(response.data, attachment.filename, attachment.mimetype);
            });
    };

    const submitEmail = () => {
        setSaving(true);
        const formData = new FormData();
        attachments.forEach((file) => formData.append(`file`, file));
        formData.append('token', `${sessionStorage.getItem('acctok')}`);
        formData.append('taskId', id);
        formData.append('message', emailBody);
        formData.append('subject', subject.length === 0 ? taskTitle : subject);
        formData.append('internal', isInternal);
        formData.append('fromInvoice', fromInvoice ? fromInvoice : false);
        tos.forEach((to) => formData.append(`tos`, to.__isNew__ ? to.value : to.email));

        axios
            .post(`${REST_URL}/api/send-comment`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
            .then((response) => {
                if (response.data.ok) {
                    reset();
                    commentsRefetch({
                        task: id,
                        page,
                        limit,
                        fromInvoice,
                    });
                } else {
                    addLocalError({
                        message: response.data.error,
                        name: 'Task e-mail error',
                    });
                }
                setSaving(false);
            })
            .catch((err) => {
                setSaving(false);
                addLocalError(err);
            });
    };

    const getColor = (comment) => {
        if (comment.internal) return '#eebbbb';
        if (comment.user.id === taskRequesterId) return '#d3dfe8';
        return '#d5ebd4';
    };

    const getCurrentColor = () => {
        if (isInternal) return '#eebbbb';
        /*    if (currentUser.id === taskRequesterId)
            return '#d3dfe8';*/
        return '#d5ebd4';
    };

    const reset = () => {
        setAttachments([]);
        setEmailBody(`<br>${currentUser.signature.replace(/(?:\r\n|\r|\n)/g, '<br>')}`);
        setSubject(taskTitle);
        setTos([]);
        setAddCommentOpen(false);
    };

    if (commentsLoading) {
        return <Loading />;
    }
    const comments = commentsError ? [] : commentsData.comments;

    const newEmailForm = () => {
        return (
            <Empty>
                <FormGroup className="row m-b-10 m-t-10">
                    <Label className="m-r-20 center-hor m-l-0" style={{ width: 50 }}>
                        {t('to2')}:
                    </Label>
                    <div className="row align-items-center">
                        {tos.map((recipient) => (
                            <div className="align-items-center mail-to m-r-10" key={recipient.id}>
                                <span className="p-l-5 p-r-5">
                                    {recipient.id ? recipient.label : `<${recipient.label}>`}
                                </span>
                                {!isInternal && (
                                    <button
                                        className="btn-link m-r-5"
                                        onClick={() => {
                                            setTos(
                                                tos.filter((to) => recipient.label !== to.label)
                                            );
                                        }}
                                    >
                                        <span className="material-symbols-outlined">close</span>
                                    </button>
                                )}
                            </div>
                        ))}
                        {!openAddRecipient && !isInternal && (
                            <button
                                className="btn-link"
                                onClick={() => {
                                    setOpenAddRecipient(!openAddRecipient);
                                }}
                            >
                                {t('addCopy')}
                            </button>
                        )}
                        {openAddRecipient && (
                            <div className="row align-items-center">
                                <div
                                    style={{
                                        display: 'inline-grid',
                                        minWidth: '200px',
                                    }}
                                >
                                    <Creatable
                                        isMulti
                                        value={[]}
                                        onChange={(newData) => setTos([...tos, ...newData])}
                                        options={users
                                            .filter(
                                                (user) =>
                                                    !tos.find(
                                                        (recipient) => recipient.id === user.id
                                                    )
                                            )
                                            .map((user) => ({
                                                ...user,
                                                label: `${user.fullName} <${user.email}>`,
                                            }))}
                                        styles={pickSelectStyle()}
                                    />
                                </div>
                                <button
                                    className="btn-link"
                                    onClick={() => {
                                        setOpenAddRecipient(false);
                                    }}
                                >
                                    <span className="material-symbols-outlined">close</span>
                                </button>
                            </div>
                        )}
                    </div>
                    {!showInModal && (
                        <button
                            className="btn-link ml-auto btn-distance center-hor m-r-0 p-r-0"
                            onClick={() => {
                                setShowInModal(true);
                            }}
                        >
                            <span className="material-symbols-outlined">open_in_full</span>
                        </button>
                    )}
                    <button
                        className={classnames('btn-link-red center-hor m-r-0 p-r-0 p-l-0', {
                            'm-l-auto': showInModal,
                        })}
                        onClick={() => {
                            if (showInModal) {
                                setShowInModal(false);
                            } else {
                                reset();
                            }
                        }}
                    >
                        <span className="material-symbols-outlined bigger">close</span>
                    </button>
                </FormGroup>

                {false && (
                    <FormGroup className="row m-b-10">
                        <Label className="m-r-20 center-hor m-l-0" style={{ width: 50 }}>
                            {t('subject')}:
                        </Label>
                        <Input
                            className="form-control flex hidden-input"
                            type="text"
                            style={{
                                paddingLeft: '0px !important',
                            }}
                            placeholder={t('subjectPlaceholder')}
                            value={subject.length > 0 ? subject : taskTitle}
                            onChange={(e) => setSubject(e.target.value)}
                        />
                    </FormGroup>
                )}

                <FormGroup>
                    <Label className="m-l-0">{t('message')}</Label>
                    <CKEditor
                        value={emailBody}
                        onChange={(emailBody) => setEmailBody(emailBody)}
                        type="basic"
                    />
                </FormGroup>

                {hasError && <div style={{ color: 'red' }}>{t('emailNotSendError')}</div>}
                <div className="row">
                    {userRights.rights.internal && (
                        <Checkbox
                            className="btn-distance center-hor"
                            centerHor
                            label={t('internal')}
                            value={isInternal}
                            onChange={() => {
                                if (!isInternal) {
                                    setTos(
                                        users.filter(
                                            (user) =>
                                                user.id !== currentUser.id &&
                                                (user.id === taskRequesterId ||
                                                    taskAssignedIds.includes(user.id)) &&
                                                user.canReadInternal
                                        )
                                    );
                                }
                                setIsInternal(!isInternal);
                            }}
                        />
                    )}

                    <div className="center-hor">
                        <label
                            className="btn-link ver-align-middle clickable h-fit-content center-hor m-l-0 p-r-10"
                            htmlFor="uploadCommentAttachments"
                        >
                            <span className="material-symbols-outlined bigger">add</span>
                            {t('attachment')}
                        </label>

                        <input
                            type="file"
                            id="uploadCommentAttachments"
                            multiple={true}
                            style={{
                                display: 'none',
                            }}
                            onChange={(e) => {
                                if (e.target.files.length > 0) {
                                    let files = [...e.target.files];
                                    let newFiles = files.filter((file) => file.size < maximumSize);
                                    let totalSize = 0;
                                    [...attachments, ...newFiles].forEach(
                                        (file) => (totalSize += file.size)
                                    );
                                    if (
                                        files.length !== newFiles.length ||
                                        totalSize > maximumSize
                                    ) {
                                        setAttachmentError(t('maximumSizeExceeded'));
                                    } else {
                                        setAttachmentError(null);
                                        setAttachments([...attachments, ...newFiles]);
                                    }
                                }
                            }}
                        />
                    </div>
                    {attachments.map((attachment, index) => (
                        <div
                            className="comment-attachment center-hor"
                            style={{
                                height: '25px',
                                marginRight: '5px',
                                display: 'flex',
                                alignItems: 'center',
                            }}
                            key={index}
                        >
                            <span
                                style={{
                                    color: '#0078D4',
                                }}
                            >
                                {`${attachment.name} (${Math.round(
                                    parseInt(attachment.size) / 1024
                                )}kB)`}
                            </span>
                            <button
                                className="btn-link"
                                style={{
                                    height: '21px',
                                    padding: '0px',
                                }}
                                onClick={() => {
                                    if (window.confirm('Are you sure?')) {
                                        let newAttachments = [...attachments];
                                        newAttachments.splice(index, 1);
                                        setAttachments(newAttachments);
                                    }
                                }}
                            >
                                <span className="material-symbols-outlined p-r-0">close</span>
                            </button>
                        </div>
                    ))}

                    <button
                        className="btn center-hor m-l-auto"
                        disabled={
                            tos.length < 1 ||
                            emailBody === '' ||
                            saving ||
                            !userRights.rights.emails
                        }
                        onClick={() => {
                            submitEmail();
                        }}
                    >
                        {saving && <Spinner className="spinner m-r-5" style={{ color: 'white' }} />}
                        {saving ? t('adding') : t('add')}
                    </button>
                </div>
                {attachmentError && (
                    <div className="row align-items-center m-t-10 m-b-10">
                        <span class="material-symbols-outlined m-r-5 warning-big">warning</span>
                        <span className="warning-big">{attachmentError}</span>
                    </div>
                )}
            </Empty>
        );
    };

    return (
        <div>
            {!addCommentOpen && workingSmtpData && workingSmtpData.workingSmtp && (
                <button
                    className="btn-repeat w-100"
                    onClick={() => {
                        setShowInModal(false);
                        setTos(
                            users.filter(
                                (user) =>
                                    user.id !== currentUser.id &&
                                    (user.id === taskRequesterId ||
                                        taskAssignedIds.includes(user.id))
                            )
                        );
                        setAddCommentOpen(true);
                    }}
                >
                    {t('addComment')}
                </button>
            )}
            {(!workingSmtpData || !workingSmtpData.workingSmtp) && (
                <div className="row align-items-center m-t-10 m-b-10">
                    <span class="material-symbols-outlined m-r-5 warning-big">warning</span>
                    <span className="warning-big">{t('cannotAddCommentSmtpNotWorking')}</span>
                </div>
            )}
            {userRights.rights.emails && !disabled && addCommentOpen && (
                <div
                    className="add-comment"
                    style={{
                        backgroundColor: getCurrentColor(),
                    }}
                >
                    {newEmailForm()}
                </div>
            )}

            {userRights.rights.emails && !disabled && addCommentOpen && showInModal && (
                <Modal isOpen={showInModal}>
                    <ModalHeader>{t('addComment')}</ModalHeader>
                    <ModalBody>{newEmailForm()}</ModalBody>
                </Modal>
            )}

            {!isMulti &&
                comments.map((comment) => (
                    <div
                        key={comment.id}
                        className="comment"
                        style={{
                            backgroundColor: getColor(comment),
                        }}
                    >
                        <Email
                            getAttachment={getAttachment}
                            comment={comment}
                            sendEmail={(type, html, senders) => {
                                setAddCommentOpen(true);
                                if (type === 'reply') {
                                    setTos(senders);
                                } else if (type === 'forward') {
                                    setTos(
                                        users.filter(
                                            (user) =>
                                                user.id === taskRequesterId ||
                                                taskAssignedIds.includes(user.id)
                                        )
                                    );
                                } else {
                                    setTos(users.filter((user) => user.id === comment.user.id));
                                }
                                setSubject(comment.subject);
                                if (type === 'forward' || type === 'reply') {
                                    setEmailBody(
                                        `<body><br><hr style="height: 0px; border: 1px solid grey;"/>${html}</html>`
                                    );
                                } else {
                                    setEmailBody(
                                        `<body><br>${currentUser.signature.replace(
                                            /(?:\r\n|\r|\n)/g,
                                            '<br>'
                                        )}</body>`
                                    );
                                }
                            }}
                        />
                    </div>
                ))}
            <Pagination
                count={comments.length === 0 ? 0 : comments[0].messageCount}
                limit={limit}
                page={page}
                setPage={setPage}
                loading={commentsLoading}
            />
        </div>
    );
}
