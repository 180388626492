import React from 'react';
import { useMutation, useQuery } from '@apollo/client';

import CategoryEdit from './edit';

import Loading from 'components/loading';

import {
    GET_CATEGORY,
    UPDATE_CATEGORY,
    DELETE_CATEGORY,
} from 'cmdb/queries';

export default function EditCategoryLoader(props) {
    const { id, categories } = props;

    const { data: categoryData, loading: categoryLoading } =
        useQuery(GET_CATEGORY, {
            variables: {
                id,
            },
            fetchPolicy: 'network-only',
        });

    const [updateCategory] = useMutation(UPDATE_CATEGORY);
    const [deleteCategory] = useMutation(DELETE_CATEGORY);

    if (categoryLoading) {
        return <Loading />;
    }

    return (
        <CategoryEdit
            {...props}
            updateCategory={updateCategory}
            deleteCategory={deleteCategory}
            category={categoryData.cmdbCategory}
            categories={categories}
        />
    );
}
