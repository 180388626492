import React from 'react';

import { useQuery, useSubscription, useMutation } from '@apollo/client';

import SettingLoading from '../components/settingLoading';
import SettingListContainer from '../components/settingListContainer';
import TaskTypeAdd from './taskTypeAdd';
import TaskTypeEdit from './taskTypeEdit';

import DeleteReplacement from 'components/deleteReplacement';

import { orderArr, toSelArr } from 'helperFunctions';
import { useTranslation } from 'react-i18next';
import { itemAttributesFullfillsString } from '../components/helpers';
import { addLocalError } from 'apollo/localSchema/actions';

import { GET_TASK_TYPES, TASK_TYPES_SUBSCRIPTION, DELETE_TASK_TYPE } from 'helpdesk/queries';

export default function TaskTypeList() {
    const { t } = useTranslation();

    const [deleteTaskType] = useMutation(DELETE_TASK_TYPE);

    const {
        data: taskTypesData,
        loading: taskTypesLoading,
        refetch: taskTypesRefetch,
    } = useQuery(GET_TASK_TYPES, {
        fetchPolicy: 'network-only',
    });

    useSubscription(TASK_TYPES_SUBSCRIPTION, {
        onData: () => {
            taskTypesRefetch();
        },
    });

    // state
    const [taskTypeFilter, setTaskTypeFilter] = React.useState('');

    const [editedItemId, setEditedItemId] = React.useState(null);
    const [itemToDeleteId, setItemToDeleteId] = React.useState(null);

    if (taskTypesLoading) {
        return <SettingLoading />;
    }

    const taskTypes = orderArr(taskTypesData.taskTypes);

    const deleteTaskTypeFunc = (replacement) => {
        if (window.confirm(t('generalConfirmation'))) {
            deleteTaskType({
                variables: {
                    id: itemToDeleteId,
                    newId: parseInt(replacement.id),
                },
            })
                .then(() => {
                    setItemToDeleteId(null);
                })
                .catch((err) => {
                    addLocalError(err);
                });
        }
    };

    return (
        <SettingListContainer
            header={t('taskTypes')}
            addLabel={t('taskType')}
            filter={taskTypeFilter}
            setFilter={setTaskTypeFilter}
            AddComponent={TaskTypeAdd}
            EditComponent={TaskTypeEdit}
            editedItemId={editedItemId}
            setEditedItemId={setEditedItemId}
            itemRefetch={taskTypesRefetch}
        >
            <table className="table">
                <thead>
                    <tr>
                        <th>{t('title')}</th>
                        <th>{t('order')}</th>
                        <th width="100px">{t('actions')}</th>
                    </tr>
                </thead>
                <tbody>
                    {taskTypes
                        .filter((item) =>
                            itemAttributesFullfillsString(item, taskTypeFilter, ['title'])
                        )
                        .map((taskType) => (
                            <tr key={taskType.id}>
                                <td>{taskType.title}</td>
                                <td>{taskType.order}</td>
                                <td className="row">
                                    {taskTypes.length > 1 && (
                                        <button
                                            className="btn-link-red m-r-0 m-l-0 p-0"
                                            onClick={() => {
                                                setItemToDeleteId(taskType.id);
                                            }}
                                        >
                                            <span className="material-symbols-outlined">
                                                delete
                                            </span>
                                        </button>
                                    )}
                                    <button
                                        className="btn-link m-r-0 m-l-10 p-0"
                                        onClick={() => {
                                            setEditedItemId(taskType.id);
                                        }}
                                    >
                                        <span className="material-symbols-outlined">edit</span>
                                    </button>
                                </td>
                            </tr>
                        ))}
                </tbody>
            </table>

            <DeleteReplacement
                isOpen={itemToDeleteId ? true : false}
                label={t('taskType')}
                options={toSelArr(taskTypes.filter((taskType) => taskType.id !== itemToDeleteId))}
                close={() => setItemToDeleteId(null)}
                finishDelete={deleteTaskTypeFunc}
            />
        </SettingListContainer>
    );
}
