import { gql } from '@apollo/client';

//this one
export const INVOICE_COMPANIES = gql`
    query invoiceCompanies($fromDate: String!, $toDate: String!) {
        invoiceCompanies(fromDate: $fromDate, toDate: $toDate) {
            company {
                id
                title
                dph
                companyRents {
                    id
                    title
                    quantity
                    cost
                    price
                    total
                }
            }
            works
            trips
            materials
        }
    }
`;

//this one
export const COMPANY_INVOICE = gql`
    query companyInvoice($fromDate: String!, $toDate: String!, $companyId: Int!) {
        companyInvoice(fromDate: $fromDate, toDate: $toDate, companyId: $companyId) {
            materialTasks {
                id
                title
                materials {
                    id
                    title
                    quantity
                    price
                    total
                }
                requester {
                    id
                    fullName
                    email
                }
                assignedTo {
                    id
                    fullName
                    email
                }
                closeDate
            }
            totals {
                workHours
                tripHours
                materialPriceWithDPH
                materialPrice
            }
            tripTasks {
                id
                title
                workTrips {
                    id
                    type {
                        id
                        title
                    }
                    quantity
                }
                requester {
                    id
                    fullName
                    email
                }
                assignedTo {
                    id
                    fullName
                    email
                }
                closeDate
            }
            workTasks {
                id
                title
                subtasks {
                    id
                    title
                    type {
                        id
                        title
                    }
                    quantity
                }
                requester {
                    id
                    fullName
                    email
                }
                assignedTo {
                    id
                    fullName
                    email
                }
                closeDate
            }
        }
    }
`;

export const ALL_INVOICE_COMPANIES = gql`
    query allInvoiceCompanies {
        allInvoiceCompanies {
            title
            id
        }
    }
`;

export const COMPANIES_WITH_INVOICE = gql`
    query companiesWithInvoice($fromDate: String!, $toDate: String!) {
        companiesWithInvoice(fromDate: $fromDate, toDate: $toDate) {
            title
            id
        }
    }
`;

export const INVOICE_DATES_OF_COMPANY = gql`
    query invoiceDatesOfCompany($companyId: Int!) {
        invoiceDatesOfCompany(companyId: $companyId) {
            month
            year
        }
    }
`;
