import { gql } from '@apollo/client';

export const GET_SCHEME = gql`
    query cmdbScheme($companyId: Int!, $projectId: Int!) {
        cmdbScheme(
            companyId: $companyId
            projectId: $projectId
        ) {
            description
            file {
                id
                filename
                path
                mimetype
                encoding
                size
            }
        }
    }
`;

export const ADD_OR_UPDATE_CMDB_SCHEME = gql`
    mutation addOrUpdateCmdbScheme(
        $companyId: Int!
        $description: String!
        $projectId: Int!
    ) {
        addOrUpdateCmdbScheme(
            companyId: $companyId
            description: $description
            projectId: $projectId
        ) {
            id
        }
    }
`;
