import React from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import classnames from 'classnames';

import MultiSelect from 'components/MultiSelectNew';
import GeneralPopover from 'components/generalPopover';
import Empty from 'components/Empty';
import Select from 'react-select';
import { pickSelectStyle } from 'configs/components/select';
import { defaultFilter, getDefaultFilter } from 'configs/constants/filter';
import { useTranslation } from 'react-i18next';
import { translateAllSelectItems } from 'helperFunctions';
import {
    orderByValues,
    attributeLimitingRights,
    ganttAttributeLimitingRights,
} from 'configs/constants/tasks';
import { dashboard } from 'configs/constants/sidebar';

// breadcrums, layout switch
export default function CommandBar(props) {
    const {
        loading,
        tasklistLayout,
        setTasklistLayout,
        localFilter,
        setLocalFilter,
        localProject,
        setLocalProject,
        localWorkspace,
        canViewCalendar,
        displayValues: allDisplayValues,
        setPreference,
        ascending,
        orderBy,
        setOrderBy,
        setAscending,
        gantt,
        showSort,
        showPreferences,
        currentUser,
        isFilter,
    } = props;

    const navigate = useNavigate();
    const params = useParams();

    const { t } = useTranslation();

    //prop constants
    const displayValues = allDisplayValues
        ? allDisplayValues
              .filter((displayValue) => displayValue.type !== 'checkbox')
              .map((displayValue) => ({
                  ...displayValue,
                  id: displayValue.value,
              }))
        : [];

    //state
    const [layoutOpen, setLayoutOpen] = React.useState(false);
    const [columnPreferencesOpen, setColumnPreferencesOpen] = React.useState(false);

    const myRights = currentUser.role.accessRights;
    //fucs
    const openColumnPreferences = () => setColumnPreferencesOpen(!columnPreferencesOpen);

    const getPreferenceColumns = () => {
        if (localProject.project.id === null) {
            return displayValues;
        }
        let notAllowedColumns = (gantt ? ganttAttributeLimitingRights : attributeLimitingRights)
            .filter((limitingRight) => !limitingRight.right(localProject))
            .map((limitingRight) => limitingRight.preference);
        return displayValues.filter((column) => !notAllowedColumns.includes(column.value));
    };

    const getLayoutIcon = () => {
        switch (tasklistLayout) {
            case 0:
                return 'view_column';
            case 1:
                return 'list';
            case 2:
                return 'map';
            case 3:
                return 'calendar_month';
            case 4:
                return 'account_tree';
            case 5:
                return 'bar_chart';
            default:
                return 'settings';
        }
    };

    const getBreadcrumsData = () => {
        return [
            {
                type: 'workspace',
                show: true,
                data: localWorkspace,
                label: t(localWorkspace.title),
                onClick: () => {
                    setLocalProject(dashboard);
                    setLocalFilter(getDefaultFilter());
                    navigate(`/helpdesk/taskList/i/${defaultFilter}`);
                },
            },
            {
                type: 'project',
                show: localProject.id !== null || localFilter.id !== null,
                data: localProject,
                label: t(localProject.title),
                onClick: () => {
                    setLocalFilter(getDefaultFilter());
                    navigate(`/helpdesk/taskList/i/${defaultFilter}`);
                },
            },
            {
                type: 'filter',
                show: true,
                data: localFilter,
                label: t(localFilter.title === '' ? 'allTasks' : localFilter.title),
                onClick: () => {},
            },
        ];
    };

    const filteredBreadcrums = getBreadcrumsData().filter((breadcrum) => breadcrum.show);

    const showTasklistLayout =
        (localProject.id === null && (myRights.tasklistCalendar || myRights.tasklistLayout)) ||
        (localProject.id !== null &&
            (localProject.right.tasklistDnD || localProject.right.tasklistKalendar));

    return (
        <div className="task-list-commandbar m-l-30 m-r-45">
            <div className="breadcrum-bar center-hor">
                <div className="flex-row breadcrumbs">
                    {isFilter && (
                        <h2 className="clickable">
                            {localFilter.id === null
                                ? t('addFilter')
                                : `${t('filter')}: ${localFilter.title}`}
                        </h2>
                    )}
                    {!isFilter &&
                        filteredBreadcrums.map((breadcrum, index) => (
                            <h2 className="clickable m-r-8" key={index} onClick={breadcrum.onClick}>
                                {` ${index !== 0 && breadcrum.label ? '\\ ' : ' '}${
                                    breadcrum.label
                                } `}
                            </h2>
                        ))}
                </div>
            </div>

            <div className="ml-auto p-2 align-self-center">
                <div className="d-flex flex-row">
                    <div className={classnames('d-flex flex-row align-items-center ml-auto')}>
                        <div className="d-flex flex-row">
                            <div
                                className={classnames(
                                    'd-flex',
                                    'flex-row',
                                    'align-items-center',
                                    'ml-auto'
                                )}
                            >
                                {showSort && (
                                    <Empty>
                                        <div className="color-basic m-r-5 m-l-5">{t('sortBy')}</div>
                                        <div className="min-width-120 m-r-5">
                                            <Select
                                                placeholder={t('sortBy')}
                                                value={translateAllSelectItems(
                                                    orderByValues,
                                                    t
                                                ).find((order) => order.value === orderBy)}
                                                onChange={(order) => setOrderBy(order.value)}
                                                options={translateAllSelectItems(orderByValues, t)}
                                                styles={pickSelectStyle([
                                                    'noArrow',
                                                    'invisible',
                                                    'bolder',
                                                    'basic',
                                                    'right',
                                                    'segoe',
                                                ])}
                                            />
                                        </div>

                                        {ascending && (
                                            <button
                                                type="button"
                                                className="btn-link"
                                                onClick={() => setAscending(false)}
                                            >
                                                <span className="material-symbols-outlined">
                                                    expand_less
                                                </span>
                                            </button>
                                        )}

                                        {!ascending && (
                                            <button
                                                type="button"
                                                className="btn-link"
                                                onClick={() => setAscending(true)}
                                            >
                                                <span className="material-symbols-outlined">
                                                    expand_more
                                                </span>
                                            </button>
                                        )}
                                    </Empty>
                                )}
                                {showPreferences && (
                                    <div className="row">
                                        <button
                                            className="btn-link m-l-10"
                                            id="commandbar-column-preferences"
                                            onClick={openColumnPreferences}
                                        >
                                            <span className="material-symbols-outlined">
                                                table_chart
                                            </span>
                                        </button>
                                        <MultiSelect
                                            disabled={loading}
                                            className="center-hor"
                                            menuClassName="m-t-30"
                                            bodyClassName="p-l-10 p-r-10"
                                            direction="left"
                                            target="commandbar-column-preferences"
                                            useLegacy
                                            style={{}}
                                            header={t('selectTasklistColumns')}
                                            closeMultiSelect={() => setColumnPreferencesOpen(false)}
                                            showFilter={false}
                                            open={columnPreferencesOpen}
                                            items={getPreferenceColumns().filter(
                                                (displayValue) => !displayValue.permanent
                                            )}
                                            selected={getPreferenceColumns().filter(
                                                (displayValue) => displayValue.show
                                            )}
                                            onChange={(_, item, deleted) => {
                                                let newVisibility = {};
                                                displayValues
                                                    .filter(
                                                        (displayValue) => !displayValue.permanent
                                                    )
                                                    .forEach((displayValue) => {
                                                        if (displayValue.id !== item.id) {
                                                            if (displayValue.isCustomAttribute) {
                                                                if (
                                                                    !newVisibility[
                                                                        'customAttributes'
                                                                    ] ||
                                                                    newVisibility[
                                                                        'customAttributes'
                                                                    ].length === 0
                                                                ) {
                                                                    newVisibility[
                                                                        'customAttributes'
                                                                    ] = [
                                                                        {
                                                                            show: displayValue.show,
                                                                            customAttribute:
                                                                                displayValue.value,
                                                                        },
                                                                    ];
                                                                } else {
                                                                    newVisibility[
                                                                        'customAttributes'
                                                                    ].push({
                                                                        show: displayValue.show,
                                                                        customAttribute:
                                                                            displayValue.value,
                                                                    });
                                                                }
                                                            } else {
                                                                newVisibility[
                                                                    displayValue.visKey
                                                                        ? displayValue.visKey
                                                                        : displayValue.value
                                                                ] = displayValue.show;
                                                            }
                                                        } else if (
                                                            item.isCustomAttribute &&
                                                            newVisibility['customAttributes'] &&
                                                            newVisibility['customAttributes']
                                                                .length > 0
                                                        ) {
                                                            newVisibility['customAttributes'].push({
                                                                show: !deleted,
                                                                customAttribute: item.value,
                                                            });
                                                        } else if (
                                                            item.isCustomAttribute &&
                                                            (!newVisibility['customAttributes'] ||
                                                                newVisibility['customAttributes']
                                                                    .length === 0)
                                                        ) {
                                                            newVisibility['customAttributes'] = [
                                                                {
                                                                    show: !deleted,
                                                                    customAttribute: item.value,
                                                                },
                                                            ];
                                                        } else {
                                                            newVisibility[
                                                                displayValue.visKey
                                                                    ? displayValue.visKey
                                                                    : displayValue.value
                                                            ] = !deleted;
                                                        }
                                                    });
                                                setPreference(newVisibility);
                                            }}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>

                        {showTasklistLayout && (
                            <Empty>
                                <button
                                    className="btn-link center-hor m-l-10"
                                    id={`commandbar-layout-switch`}
                                    onClick={() => setLayoutOpen(true)}
                                >
                                    <span className="material-symbols-outlined p-r-0">
                                        {getLayoutIcon()}
                                    </span>
                                </button>
                                <GeneralPopover
                                    placement="bottom-start"
                                    className="overflow-auto max-height-200 min-width-0"
                                    target={`commandbar-layout-switch`}
                                    useLegacy
                                    reset={() => {}}
                                    submit={() => {}}
                                    open={layoutOpen}
                                    close={() => setLayoutOpen(false)}
                                    hideButtons
                                >
                                    <div className="btn-group-vertical" data-toggle="buttons">
                                        {false && (
                                            <label
                                                className={classnames(
                                                    {
                                                        'active': tasklistLayout === 0,
                                                    },
                                                    'btn-link text-left'
                                                )}
                                            >
                                                <input
                                                    type="radio"
                                                    name="options"
                                                    checked={tasklistLayout === 0}
                                                    onChange={() => {
                                                        setTasklistLayout(0);
                                                        setLayoutOpen(false);
                                                    }}
                                                />
                                                <span className="material-symbols-outlined p-r-5">
                                                    view_column
                                                </span>
                                                {t('taskColumn')}
                                            </label>
                                        )}
                                        <label
                                            className={classnames(
                                                {
                                                    'active': tasklistLayout === 1,
                                                },
                                                'btn-link text-left'
                                            )}
                                        >
                                            <input
                                                type="radio"
                                                name="options"
                                                checked={
                                                    tasklistLayout === 1 ||
                                                    (tasklistLayout === 2 &&
                                                        localProject.id === null)
                                                }
                                                onChange={() => {
                                                    const previuousLayout = tasklistLayout;
                                                    setTasklistLayout(1);
                                                    setLayoutOpen(false);
                                                    if (previuousLayout === 0) {
                                                        navigate(
                                                            `/helpdesk/taskList/i/${
                                                                params.listID
                                                                    ? params.listID
                                                                    : 'all'
                                                            }`
                                                        );
                                                    }
                                                }}
                                            />
                                            <span className="material-symbols-outlined p-r-5">
                                                list
                                            </span>
                                            {t('taskTable')}
                                        </label>
                                        {localProject.id !== null &&
                                            localProject.right.tasklistDnD && (
                                                <label
                                                    className={classnames(
                                                        {
                                                            'active': tasklistLayout === 2,
                                                        },
                                                        'btn-link text-left'
                                                    )}
                                                >
                                                    <input
                                                        type="radio"
                                                        name="options"
                                                        onChange={() => {
                                                            setTasklistLayout(2);
                                                            setLayoutOpen(false);
                                                        }}
                                                        checked={tasklistLayout === 2}
                                                    />
                                                    <span className="material-symbols-outlined p-r-5">
                                                        map
                                                    </span>
                                                    {t('dnd')}
                                                </label>
                                            )}
                                        {canViewCalendar && (
                                            <label
                                                className={classnames(
                                                    {
                                                        'active': tasklistLayout === 3,
                                                    },
                                                    'btn-link text-left'
                                                )}
                                            >
                                                <input
                                                    type="radio"
                                                    name="options"
                                                    onChange={() => {
                                                        setTasklistLayout(3);
                                                        setLayoutOpen(false);
                                                    }}
                                                    checked={tasklistLayout === 3}
                                                />
                                                <span className="material-symbols-outlined p-r-5">
                                                    calendar_month
                                                </span>
                                                {t('calendar')}
                                            </label>
                                        )}
                                        {localProject.id &&
                                            (myRights.tasklistLayout ||
                                                localProject.right.tasklistGantt) &&
                                            false && (
                                                <label
                                                    className={classnames(
                                                        {
                                                            'active': tasklistLayout === 4,
                                                        },
                                                        'btn-link text-left'
                                                    )}
                                                >
                                                    <input
                                                        type="radio"
                                                        name="options"
                                                        onChange={() => {
                                                            setTasklistLayout(4);
                                                            setLayoutOpen(false);
                                                        }}
                                                        checked={tasklistLayout === 4}
                                                    />
                                                    <span className="material-symbols-outlined p-r-5">
                                                        account_tree
                                                    </span>
                                                    {t('projectManagement')}
                                                </label>
                                            )}
                                        {localProject.id &&
                                            (myRights.tasklistLayout ||
                                                localProject.right.tasklistStatistics) &&
                                            false && (
                                                <label
                                                    className={classnames(
                                                        {
                                                            'active': tasklistLayout === 5,
                                                        },
                                                        'btn-link text-left'
                                                    )}
                                                >
                                                    <input
                                                        type="radio"
                                                        name="options"
                                                        onChange={() => {
                                                            setTasklistLayout(5);
                                                            setLayoutOpen(false);
                                                        }}
                                                        checked={tasklistLayout === 5}
                                                    />
                                                    <span className="material-symbols-outlined p-r-5">
                                                        bar_chart
                                                    </span>
                                                    {t('statistics')}
                                                </label>
                                            )}
                                    </div>
                                </GeneralPopover>
                            </Empty>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}
