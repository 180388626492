import React from 'react';

import { Nav, Label } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import settings from 'configs/constants/settings';
import { getMyData } from 'helperFunctions';
import DefaultCompany from './defaultCompany';

import { useTranslation } from 'react-i18next';
import { defaultFilter } from 'configs/constants/filter';

export default function SettingsSidebar(props) {
    //data & queries
    const { t } = useTranslation();

    const currentUser = getMyData();
    const accessRights = currentUser && currentUser.role ? currentUser.role.accessRights : {};

    return (
        <Nav vertical>
            <div className="sidebar-align p-t-14 p-b-7" style={{ display: 'flex' }}>
                <span className="material-symbols-outlined p-r-5">settings</span>
                <Label className="noselect m-b-0" style={{ font: '14px Segoe UI' }}>
                    {t('settings')}
                </Label>
            </div>
            <hr className="m-l-15 m-r-15 m-t-7 m-b-7" />
            {accessRights.companies && <DefaultCompany {...props} accessRights={accessRights} />}
            {settings
                .filter(
                    (setting) =>
                        accessRights[setting.value] ||
                        setting.value === 'webchat' ||
                        setting.value === 'taskTypes'
                )
                .map((setting) => (
                    <NavLink
                        key={setting.link}
                        className={({ isActive }) => `link-item ${isActive ? 'active' : ''}`}
                        to={`/helpdesk/settings/${setting.link}`}
                    >
                        {t(setting.title)}
                        {setting.value === 'aiSettings' && (
                            <span className="material-symbols-outlined bigger m-l-auto">
                                chevron_right
                            </span>
                        )}
                    </NavLink>
                ))}
            <NavLink
                key="back-to-tasks"
                className={() => `link-item text-highlight p-l-8`}
                to={`/helpdesk/taskList/i/${defaultFilter}`}
            >
                <span className="material-symbols-outlined bigger">chevron_left</span>
                {t('backToTasks')}
            </NavLink>
        </Nav>
    );
}
