import React from 'react';
import Checkbox from 'components/checkbox';

export default function MaterialsPrint( props ) {

  const {
    task,
    t
  } = props;

  const {
    materials
  } = task;

  const columns = [
    {
      label: null,
      key: 'done',
    },{
      label: t('title'),
      key: 'title',
    },{
      label: t('quantityShort'),
      key: 'quantity',
    },{
      label: t('price'),
      key: 'price',
    },{
      label: t('total'),
      key: 'total',
    } ];

    if (materials.length === 0){
      return null;
    }

    const materrialsToDisplay = [...materials].sort((s1,s2) => s1.order > s2.order ? 1 : -1);

  return (
    <div>
      <label>{t('materials')}</label>
    <table>
      <thead>
        <tr>
        {
          columns.map((column) => (
            <th key={column.key}>{column.label}</th>
          ))
        }
      </tr>
      </thead>
      <tbody>
        {
          materrialsToDisplay.map((material) => (
            <tr key={material.id}>
              {
                columns.map((column) => {
                  if (column.key === "done"){
                    return (
                      <td key={column.key}>
                        <Checkbox
                          readOnly={true}
                          className="m-t-5 segoe-blue-text"
                          value={ material.done }
                          />
                      </td>
                    )
                  }
                  if (column.key === "total"){
                    return (
                      <td key={column.key}>{(parseFloat( material.price ) * parseFloat( material.quantity )
                    ).toFixed( 2 ) + " €"}</td>
                    )
                  }
                  return (<td key={column.key}>{material[column.key]}</td>)
                })
              }
            </tr>
          ))
        }
      </tbody>
    </table>
  </div>
  )

}
