import React from 'react';

import { timestampToStringFNS } from 'helperFunctions';

import { useTranslation } from 'react-i18next';
import AgentPrint from './print';
import AgentExcel from './excel';

export default function AgentList(props) {
    const { agents, fromDate, toDate, setAgent } = props;

    const { t } = useTranslation();

    return (
        <div className="m-t-10 m-b-20 invoice-container">
            <table className="table row-highlight m-l-0 m-r-0">
                <thead>
                    <tr>
                        <th>{t('agent')}</th>
                        <th>{t('works')}</th>
                        <th>{t('trips')}</th>
                        <th>{t('exports')}</th>
                    </tr>
                </thead>
                <tbody>
                    {agents.map((agent) => (
                        <tr key={agent.user.id}>
                            <td
                                className="clickable"
                                onClick={() => setAgent(agent.user)}
                            >{`${agent.user.fullName} (${agent.user.email})`}</td>
                            <td className="clickable" onClick={() => setAgent(agent.user)}>
                                {agent.works}
                            </td>
                            <td className="clickable" onClick={() => setAgent(agent.user)}>
                                {agent.trips}
                            </td>
                            <td className="flex-row">
                                <AgentPrint
                                    agent={agent.user}
                                    filterData={{
                                        fromDate: fromDate.valueOf().toString(),
                                        toDate: toDate.valueOf().toString(),
                                    }}
                                />
                                <AgentExcel
                                    agent={agent}
                                    variables={{
                                        userId: agent.user.id,
                                        fromDate: fromDate.valueOf().toString(),
                                        toDate: toDate.valueOf().toString(),
                                    }}
                                />
                            </td>
                        </tr>
                    ))}
                    {agents.length === 0 && (
                        <tr key="no-items">
                            <td colSpan="6">{`${t('noAgents')} ${t(
                                'inDateRange'
                            ).toLowerCase()} ${timestampToStringFNS(
                                fromDate.valueOf()
                            )} - ${timestampToStringFNS(toDate.valueOf())}`}</td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
}
