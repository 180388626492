import React, { useMemo } from 'react';
import classnames from 'classnames';
import Select from 'react-select';
import { pickSelectStyle } from 'configs/components/select';

import Empty from 'components/Empty';
import DatePicker from 'components/DatePicker';
import Textarea from 'react-textarea-autosize';

import { toSelArr, translateSelectItem, translateAllSelectItems } from 'helperFunctions';

import {
    newSourceOptions,
    actionOptions,
    requesterOption,
    requesterCompanyOption,
    assignedOption,
    emptyOption,
    creatorOption,
    creatorCompanyOption,
    boolOptions,
    settingsForCustomAttributes,
} from 'configs/constants/automation';

import { useTranslation } from 'react-i18next';

export default function ActionTableRow(props) {
    const {
        allUsers,
        userGroups,
        statuses,
        tags,
        projects,
        lockedRequester,
        companies,
        action,
        index,
        actions,
        setActions,
        customAttributes,
        conditionsHaveOnlyRepeat,
    } = props;

    const { t } = useTranslation();

    const translatedNewSourceOptions = translateAllSelectItems(
        newSourceOptions,
        t,
        'value',
        'value'
    );
    const translatedActionOptions = translateAllSelectItems(actionOptions, t, 'translationKey');
    const translatedRequesterOption = translateSelectItem(requesterOption, t, 'translationKey');
    const translatedRequesterCompanyOption = translateSelectItem(
        requesterCompanyOption,
        t,
        'translationKey'
    );
    const translatedAssignedOption = translateSelectItem(assignedOption, t, 'translationKey');
    const translatedEmptyOption = translateSelectItem(emptyOption, t, 'translationKey');
    const translatedCreatorOption = translateSelectItem(creatorOption, t, 'translationKey');
    const translatedTaskCreatorCompanyOption = translateSelectItem(
        creatorCompanyOption,
        t,
        'translationKey'
    );
    const translatedBoolOptions = translateAllSelectItems(boolOptions, t, 'translationKey');

    const getTargetOptions = (source, condition) => {
        if (!source) {
            return [];
        }

        if (source.type) {
            return [
                ...source.selectValues.map((value) => ({
                    ...value,
                    label: value.value.substring(0, 1).toUpperCase() + value.value.substring(1),
                })),
            ];
        }

        switch (source.value) {
            case 'important':
                return translatedBoolOptions;
            case 'status':
                return toSelArr(statuses);
            case 'tags':
                if (condition && condition.value !== 'add') {
                    return [translatedEmptyOption, ...toSelArr(tags)];
                }
                return toSelArr(tags);
            case 'assignedTo':
                return [
                    translatedCreatorOption,
                    ...userGroups.map((group) => ({
                        label: group.user.fullName,
                        value: group.user.id,
                    })),
                ];
            case 'requester':
                return [
                    translatedCreatorOption,
                    ...(lockedRequester
                        ? userGroups.map((group) => ({
                              label: group.user.fullName,
                              value: group.user.id,
                          }))
                        : toSelArr(allUsers, 'fullName')),
                ];
            case 'company':
                return [
                    translatedTaskCreatorCompanyOption,
                    translatedRequesterCompanyOption,
                    ...companies,
                ];
            case 'project':
                return projects;
            case 'email':
            case 'notification':
                return [
                    translatedRequesterOption,
                    translatedAssignedOption,
                    translatedCreatorOption,
                    ...userGroups.map((group) => ({
                        label: group.user.fullName,
                        value: group.user.id,
                    })),
                ];
            case 'pendingChangeable':
                return translatedBoolOptions;
            default:
                return [];
        }
    };

    const fillAction = (action, newTarget) => {
        if (
            action.target &&
            action.action &&
            newTarget.inTimer.actions.includes(action.action.value)
        ) {
            return action.action;
        }
        if (newTarget.inTimer.actions.length === 1) {
            return translatedActionOptions.find(
                (option) => newTarget.inTimer.actions[0] === option.value
            );
        }
        return null;
    };

    const fillValue = (action, newAction) => {
        if (
            action.action &&
            ['set', 'add'].includes(action.action.value) &&
            ['set', 'add'].includes(newAction.value)
        ) {
            return action.value;
        }
        return null;
    };

    const fillValueFromTarget = (target) => {
        if (
            [
                'startsAt',
                'deadline',
                'pendingDate',
                'closeDate',
                'updatedDate',
                'statusChange',
            ].includes(target.value)
        ) {
            return [
                {
                    value: null,
                },
            ];
        }
        return null;
    };

    const mappedCustomAttributes = useMemo(() => {
        let mappedCustomAttributes = toSelArr(customAttributes);
        mappedCustomAttributes = mappedCustomAttributes.map((attribute) => ({
            ...attribute,
            ...settingsForCustomAttributes.find(
                (setting) =>
                    setting.type.substring('customAttribute'.length).toLowerCase() ===
                    (attribute.type.value ? attribute.type.value : attribute.type)
            ),
        }));
        return mappedCustomAttributes;
    }, [customAttributes]);

    const allSourceOptions = useMemo(() => {
        return [...translatedNewSourceOptions, ...mappedCustomAttributes];
    }, [translatedNewSourceOptions, mappedCustomAttributes]);

    const renderValueInput = () => {
        if (!action.target || !action.action) {
            return;
        }
        switch (action.target.inTimer.valueInputTypes[action.action.value]) {
            case 'select':
                return (
                    <Select
                        styles={pickSelectStyle(['size16'])}
                        options={getTargetOptions(action.target, action.action, true)}
                        value={action.value}
                        onChange={(value) => {
                            const newActions = actions.map((a, aIndex) => {
                                if (aIndex === index) {
                                    return {
                                        ...a,
                                        value: [value],
                                    };
                                }
                                return a;
                            });
                            setActions(newActions);
                        }}
                    />
                );
            case 'multiselect':
                return (
                    <Select
                        styles={pickSelectStyle(['size16'])}
                        options={getTargetOptions(action.target, action.action, true)}
                        isMulti
                        value={action.value}
                        onChange={(value) => {
                            const newActions = actions.map((a, aIndex) => {
                                if (aIndex === index) {
                                    return {
                                        ...a,
                                        value,
                                    };
                                }
                                return a;
                            });
                            setActions(newActions);
                        }}
                    />
                );
            case 'date':
                return (
                    <DatePicker
                        className={classnames('form-control')}
                        selected={action.value ? action.value[0].value : action.value}
                        hideTime
                        isClearable
                        onChange={(date) => {
                            const newActions = actions.map((a, aIndex) => {
                                if (aIndex === index) {
                                    return {
                                        ...a,
                                        value: isNaN(date.valueOf())
                                            ? null
                                            : [
                                                  {
                                                      value: date,
                                                  },
                                              ],
                                    };
                                }
                                return a;
                            });
                            setActions(newActions);
                        }}
                        placeholderText={t('date')}
                    />
                );
            case 'text':
                return (
                    <input
                        className="form-control"
                        value={action.value ? action.value[0].value : action.value}
                        type={'text'}
                        onChange={(e) => {
                            const newActions = actions.map((a, aIndex) => {
                                if (aIndex === index) {
                                    return {
                                        ...a,
                                        value: [
                                            {
                                                value: e.target.value,
                                            },
                                        ],
                                    };
                                }
                                return a;
                            });
                            setActions(newActions);
                        }}
                        placeholder={t('value')}
                    />
                );
            case 'number':
                return (
                    <input
                        className="form-control"
                        value={action.value ? action.value[0].value : action.value}
                        type={'number'}
                        onChange={(e) => {
                            const newActions = actions.map((a, aIndex) => {
                                if (aIndex === index) {
                                    return {
                                        ...a,
                                        value: [
                                            {
                                                value: e.target.value,
                                            },
                                        ],
                                    };
                                }
                                return a;
                            });
                            setActions(newActions);
                        }}
                        placeholder={t('value')}
                    />
                );
            case 'textarea':
                return (
                    <Textarea
                        minRows={2}
                        className="form-control"
                        value={action.value ? action.value[0].value : action.value}
                        onChange={(e) => {
                            const newActions = actions.map((a, aIndex) => {
                                if (aIndex === index) {
                                    return {
                                        ...a,
                                        value: [
                                            {
                                                value: e.target.value,
                                            },
                                        ],
                                    };
                                }
                                return a;
                            });
                            setActions(newActions);
                        }}
                        placeholder={t('value')}
                    />
                );
            default:
                return;
        }
    };

    return (
        <Empty key={action.id}>
            <tr>
                <td>
                    <Select
                        styles={pickSelectStyle(['size16'])}
                        options={allSourceOptions.filter(
                            (option) =>
                                option.inTimer.isActionOption &&
                                (conditionsHaveOnlyRepeat
                                    ? ['notification', 'email'].includes(option.value)
                                    : true)
                        )}
                        isDisabled={action.id > -1}
                        value={action.target}
                        onChange={(target) => {
                            const newActions = actions.map((a, aIndex) => {
                                if (aIndex === index) {
                                    return {
                                        ...a,
                                        target,
                                        action: fillAction(a, target),
                                        value: fillValueFromTarget(target),
                                    };
                                }
                                return a;
                            });
                            setActions(newActions);
                        }}
                    />
                </td>
                <td>
                    <Select
                        styles={pickSelectStyle(['size16'])}
                        options={
                            action.target
                                ? translatedActionOptions.filter((option) =>
                                      allSourceOptions
                                          .find((s) => {
                                              if (s.type) {
                                                  return s.type === action.target.type ? s : null;
                                              }
                                              return s.value === action.target.value ? s : null;
                                          })
                                          .inTimer.actions.includes(option.value)
                                  )
                                : []
                        }
                        value={action.action}
                        onChange={(newAction) => {
                            const newActions = actions.map((a, aIndex) => {
                                if (aIndex === index) {
                                    return {
                                        ...a,
                                        action: newAction,
                                        ...(action.id > -1
                                            ? {}
                                            : {
                                                  value: fillValue(a, newAction),
                                              }),
                                    };
                                }
                                return a;
                            });
                            setActions(newActions);
                        }}
                    />
                </td>
                <td width="33%">{renderValueInput()}</td>
                <td width="70px">
                    <button
                        className="btn-link-red m-l-auto"
                        onClick={(e) => {
                            e.preventDefault();
                            const newActions = actions.filter((a, aIndex) => aIndex !== index);
                            setActions(newActions);
                        }}
                    >
                        <span className="material-symbols-outlined p-r-0">close</span>
                    </button>
                </td>
            </tr>
            {action.notificationMessage && action.notificationMessage.length > 0 && (
                <tr key={action.id + 'note'}>
                    <td colSpan="5">
                        <Textarea
                            minRows={2}
                            className="form-control"
                            value={action.notificationMessage}
                            onChange={(e) => {
                                const newActions = actions.map((a, aIndex) => {
                                    if (aIndex === index) {
                                        return {
                                            ...a,
                                            notificationMessage: e.target.value,
                                        };
                                    }
                                    return a;
                                });
                                setActions(newActions);
                            }}
                            placeholder={t('notification')}
                        />
                    </td>
                </tr>
            )}
        </Empty>
    );
}
