import React from 'react';
import SubfolderAddLoader from './index';
import Empty from 'components/Empty';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { useTranslation } from 'react-i18next';

export default function FolderAddModal(props) {
    const { label, className } = props;

    const { t } = useTranslation();
    const [open, setOpen] = React.useState(false);

    return (
        <Empty>
            <button
                className={`btn-link ${className}`}
                onClick={() => setOpen(true)}
            >
                <span className="material-symbols-outlined bigger">
                    add
                </span>
                {label}
            </button>
            {open && (
                <Modal
                    isOpen={open}
                    className="small-modal"
                >
                    <ModalHeader>{`${t('add')} ${t(
                        'subfolder'
                    ).toLowerCase()}`}</ModalHeader>
                    <ModalBody>
                        <SubfolderAddLoader
                            {...props}
                            close={() => setOpen(false)}
                        />
                    </ModalBody>
                </Modal>
            )}
        </Empty>
    );
}
