import React from 'react';

import { useQuery, useSubscription, useMutation } from '@apollo/client';

import SettingLoading from '../components/settingLoading';
import SettingListContainer from '../components/settingListContainer';
import TripTypeAdd from './tripTypeAdd';
import TripTypeEdit from './tripTypeEdit';
import DeleteReplacement from 'components/deleteReplacement';

import { useTranslation } from 'react-i18next';
import { orderArr, toSelArr } from 'helperFunctions';
import { itemAttributesFullfillsString } from '../components/helpers';
import { addLocalError } from 'apollo/localSchema/actions';

import { GET_TRIP_TYPES, TRIP_TYPES_SUBSCRIPTION, DELETE_TRIP_TYPE } from 'helpdesk/queries';

export default function TripTypeListContainer() {
    const { t } = useTranslation();
    const [deleteTripType] = useMutation(DELETE_TRIP_TYPE);
    const {
        data: tripTypesData,
        loading: tripTypesLoading,
        refetch: tripTypesRefetch,
    } = useQuery(GET_TRIP_TYPES, {
        fetchPolicy: 'network-only',
    });

    useSubscription(TRIP_TYPES_SUBSCRIPTION, {
        onData: () => {
            tripTypesRefetch();
        },
    });

    // state
    const [tripTypeFilter, setTripTypeFilter] = React.useState('');
    const [editedItemId, setEditedItemId] = React.useState(null);
    const [itemToDeleteId, setItemToDeleteId] = React.useState(null);

    if (tripTypesLoading) {
        return <SettingLoading />;
    }

    const tripTypes = orderArr(tripTypesData.tripTypes);

    const deleteTripTypeFunc = (replacement) => {
        if (window.confirm(t('generalConfirmation'))) {
            deleteTripType({
                variables: {
                    id: itemToDeleteId,
                    newId: parseInt(replacement.id),
                },
            })
                .then(() => {
                    setItemToDeleteId(null);
                })
                .catch((err) => {
                    addLocalError(err);
                });
        }
    };

    return (
        <SettingListContainer
            header={t('tripTypes')}
            addLabel={t('tripType')}
            filter={tripTypeFilter}
            setFilter={setTripTypeFilter}
            AddComponent={TripTypeAdd}
            EditComponent={TripTypeEdit}
            editedItemId={editedItemId}
            setEditedItemId={setEditedItemId}
        >
            <table className="table">
                <thead>
                    <tr>
                        <th>{t('title')}</th>
                        <th width="50">{t('order')}</th>
                        <th width="100px">{t('actions')}</th>
                    </tr>
                </thead>
                <tbody>
                    {tripTypes
                        .filter((item) =>
                            itemAttributesFullfillsString(item, tripTypeFilter, ['title'])
                        )
                        .map((tripType) => (
                            <tr key={tripType.id}>
                                <td>{tripType.title}</td>
                                <td>{tripType.order}</td>
                                <td className="row">
                                    {tripTypes.length > 1 && (
                                        <button
                                            className="btn-link-red m-r-0 m-l-0 p-0"
                                            onClick={() => {
                                                setItemToDeleteId(tripType.id);
                                            }}
                                        >
                                            <span className="material-symbols-outlined">
                                                delete
                                            </span>
                                        </button>
                                    )}
                                    <button
                                        className="btn-link m-r-0 m-l-10 p-0"
                                        onClick={() => {
                                            setEditedItemId(tripType.id);
                                        }}
                                    >
                                        <span className="material-symbols-outlined">edit</span>
                                    </button>
                                </td>
                            </tr>
                        ))}
                </tbody>
            </table>
            <DeleteReplacement
                isOpen={itemToDeleteId ? true : false}
                label={t('tripType')}
                options={toSelArr(tripTypes.filter((tripType) => tripType.id !== itemToDeleteId))}
                close={() => setItemToDeleteId(null)}
                finishDelete={deleteTripTypeFunc}
            />
        </SettingListContainer>
    );
}
