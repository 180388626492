import React, { useState } from 'react';
import { Modal } from 'reactstrap';

import { useTranslation } from 'react-i18next';

export default function UserListContainer(props) {
    const {
        header,
        filter,
        setFilter,
        noAdd,
        addLabel,
        RightFilterComponent,
        AddComponent,
        EditComponent,
        children,
        editedItemId,
        setEditedItemId,
        itemRefetch,
    } = props;

    const [showAddModal, setShowAddModal] = useState(false);

    const { t } = useTranslation();

    return (
        <div className="content">
            <div className="p-t-20 p-r-10 p-l-10 scroll-visible fit-with-header settings-container">
                <div className="row m-b-20 m-r-30 m-l-30">
                    <h2>{header}</h2>
                </div>
                <div className="row align-items-center m-r-30 m-l-30 m-b-20">
                    <div
                        className="search-row m-r-20"
                        style={{ width: '20%' }}
                    >
                        <div className="search new-design">
                            <button
                                className="search-btn"
                                type="button"
                            >
                                <span className="material-symbols-outlined">
                                    search
                                </span>
                            </button>
                            <input
                                type="text"
                                className="form-control search-text"
                                value={filter}
                                onChange={(e) =>
                                    setFilter(
                                        e.target.value
                                    )
                                }
                                placeholder={t('search')}
                            />
                        </div>
                    </div>
                    {!noAdd && (
                        <button
                            className="btn-link"
                            onClick={() => {
                                setShowAddModal(true);
                            }}
                        >
                            <span className="material-symbols-outlined bigger">
                                add
                            </span>
                            {addLabel}
                        </button>
                    )}
                    {RightFilterComponent &&
                        RightFilterComponent}
                </div>
                {children}
            </div>
            <Modal
                isOpen={
                    showAddModal ||
                    (editedItemId ? true : false)
                }
            >
                {showAddModal && (
                    <AddComponent
                        setting
                        refetch={itemRefetch}
                        closeModal={() => {
                            setShowAddModal(false);
                        }}
                    />
                )}
                {editedItemId && (
                    <EditComponent
                        setting
                        refetch={itemRefetch}
                        closeModal={() => {
                            setEditedItemId(null);
                        }}
                        id={editedItemId}
                        projectID={editedItemId}
                        listId={editedItemId}
                    />
                )}
            </Modal>
        </div>
    );
}
