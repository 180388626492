import React from 'react';

import { Route, Routes, Outlet } from 'react-router-dom';
import { getMyData } from 'helperFunctions';

import SelectPage from '../components/SelectPage';
import PageHeader from '../components/PageHeader';
import Sidebar from './components/sidebar';
import ErrorMessages from 'components/errorMessages';
import NotificationList from 'components/notifications';

import AgentMonthlyReport from './monthly/agent';
import CompanyMonthlyReport from './monthly/company';

export default function Navigation() {
    const currentUser = getMyData();
    const accessRights = currentUser && currentUser.role ? currentUser.role.accessRights : {};

    return (
        <Routes>
            <Route
                path="invoices/*"
                element={
                    <div>
                        <div className="page-header">
                            <div className="center-ver row center flex">
                                <SelectPage />
                                <PageHeader />
                            </div>
                        </div>

                        <div className="row center center-ver">
                            <Sidebar canSeeVykazy={accessRights.vykazy} />
                            <div className="main">
                                <Outlet />
                            </div>
                        </div>
                    </div>
                }
            >
                <Route path="" element={<CompanyMonthlyReport />} />

                <Route
                    path="errorMessages"
                    element={accessRights.viewErrors ? <ErrorMessages /> : <AccessDenied />}
                />
                <Route path="notifications" element={<NotificationList />} />
                <Route path="notifications/:notificationID" element={<NotificationList />} />

                <Route path="monthly/companies" element={<CompanyMonthlyReport />} />
                <Route path="monthly/agents" element={<AgentMonthlyReport />} />
            </Route>

            <Route path="*" element={null} />
        </Routes>
    );
}
