import React from 'react';

import { useParams } from 'react-router-dom';

import { useQuery } from '@apollo/client';
import { NavItem, Nav } from 'reactstrap';
import Empty from 'components/Empty';

import SubfolderAdd from 'lanwiki/subfolders/add/modalButton';
import SubfolderEdit from 'lanwiki/subfolders/edit/modalButton';
import FolderEdit from 'lanwiki/folders/edit/modalButton';

import classnames from 'classnames';

import { useTranslation } from 'react-i18next';

import {
    L_SIDEBAR_FOLDER,
    GET_PROJECT,
} from 'apollo/queries';

import {
    FOLDERS,
    COMBINED,
} from 'configs/constants/subfolders';

import { getMyData } from 'helperFunctions';

export default function SubfolderSidebar(props) {
    const {
        folders,
        parentFolder,
        navHighlight,
        handleNavItemClick,
        showArchived,
        foldersRefetch,
    } = props;

    const params = useParams();

    const { t } = useTranslation();
    const currentUser = getMyData();
    const userIsAdmin =
        currentUser && currentUser.role
            ? currentUser.role.level === 0
            : false;

    const { data: projectData } = useQuery(GET_PROJECT);
    const localProject = projectData.localProject;

    const { data: sidebarFolderData } = useQuery(
        L_SIDEBAR_FOLDER
    );

    const sidebarFolderId =
        sidebarFolderData.lSidebarFolder === null
            ? null
            : sidebarFolderData.lSidebarFolder.id;

    const subfolders = parentFolder.subfolders
        ? parentFolder.subfolders
        : parentFolder.childrenSubfolders;

    return (
        <Empty>
            <Nav vertical className="m-t-15">
                <Empty>
                    <NavItem
                        className={classnames(
                            'row full-width sidebar-item align-items-center noselect',
                            {
                                'active':
                                    [
                                        FOLDERS,
                                        COMBINED,
                                    ].includes(
                                        navHighlight
                                    ) &&
                                    ((parentFolder.subfolders &&
                                        parentFolder.id ===
                                            parseInt(
                                                params.folderID
                                            ) &&
                                        !params.subfolderID) ||
                                        (!parentFolder.subfolders &&
                                            parentFolder.id ===
                                                parseInt(
                                                    params.subfolderID
                                                ))),
                            }
                        )}
                    >
                        <button
                            className="btn-link m-l-12"
                            style={{
                                ...([
                                    FOLDERS,
                                    COMBINED,
                                ].includes(navHighlight) &&
                                ((parentFolder.subfolders &&
                                    parentFolder.id ===
                                        parseInt(
                                            params.folderID
                                        ) &&
                                    !params.subfolderID) ||
                                    (!parentFolder.subfolders &&
                                        parentFolder.id ===
                                            parseInt(
                                                params.subfolderID
                                            )))
                                    ? {}
                                    : { color: '#3c3c3c' }),
                            }}
                            onClick={() => {
                                if (
                                    parentFolder.subfolders
                                ) {
                                    handleNavItemClick(
                                        null,
                                        null,
                                        null
                                    );
                                } else {
                                    if (
                                        parentFolder.parentFolder
                                    ) {
                                        handleNavItemClick(
                                            parentFolder.parentFolder,
                                            null,
                                            null
                                        );
                                    } else {
                                        handleNavItemClick(
                                            null,
                                            parentFolder.parentSubfolder,
                                            null
                                        );
                                    }
                                }
                            }}
                        >
                            <span className="material-symbols-outlined bigger">
                                chevron_left
                            </span>
                        </button>
                        <span
                            className={classnames(
                                'clickable sidebar-menu-item-2 link',
                                {
                                    'active':
                                        [
                                            FOLDERS,
                                            COMBINED,
                                        ].includes(
                                            navHighlight
                                        ) &&
                                        ((parentFolder.subfolders &&
                                            parentFolder.id ===
                                                parseInt(
                                                    params.folderID
                                                ) &&
                                            !params.subfolderID) ||
                                            (!parentFolder.subfolders &&
                                                parentFolder.id ===
                                                    parseInt(
                                                        params.subfolderID
                                                    ))),
                                }
                            )}
                            onClick={() => {
                                if (parentFolder.myRights) {
                                    handleNavItemClick(
                                        parentFolder,
                                        null,
                                        null
                                    );
                                } else {
                                    handleNavItemClick(
                                        null,
                                        parentFolder,
                                        null
                                    );
                                }
                            }}
                        >
                            {parentFolder.title}
                        </span>
                        {parentFolder.mainFolder &&
                            parentFolder.mainFolder.myRights
                                .manage &&
                            (parentFolder.parentFolder ||
                                parentFolder.parentSubfolder) &&
                            parentFolder.id ===
                                sidebarFolderId &&
                            parentFolder.id ===
                                sidebarFolderId && (
                                <SubfolderEdit
                                    id={parentFolder.id}
                                    folders={folders}
                                    className="m-r-15"
                                />
                            )}
                        {parentFolder.order &&
                            ((parentFolder.subfolders &&
                                parentFolder.id ===
                                    parseInt(
                                        params.folderID
                                    ) &&
                                !params.subfolderID) ||
                                (!parentFolder.subfolders &&
                                    parentFolder.id ===
                                        parseInt(
                                            params.subfolderID
                                        ))) && (
                                <FolderEdit
                                    id={parentFolder.id}
                                    folders={folders}
                                    className="m-r-15"
                                    foldersRefetch={
                                        foldersRefetch
                                    }
                                />
                            )}
                    </NavItem>
                    {subfolders.map((subfolder) => (
                        <NavItem
                            key={subfolder.id}
                            className={classnames(
                                'row full-width sidebar-item align-items-center noselect',
                                {
                                    'active':
                                        [
                                            FOLDERS,
                                            COMBINED,
                                        ].includes(
                                            navHighlight
                                        ) &&
                                        parseInt(
                                            params.subfolderID
                                        ) === subfolder.id,
                                }
                            )}
                        >
                            <span
                                className={classnames(
                                    'clickable sidebar-menu-item link',
                                    {
                                        'active':
                                            [
                                                FOLDERS,
                                                COMBINED,
                                            ].includes(
                                                navHighlight
                                            ) &&
                                            parseInt(
                                                params.subfolderID
                                            ) ===
                                                subfolder.id,
                                    }
                                )}
                                onClick={() => {
                                    handleNavItemClick(
                                        null,
                                        subfolder,
                                        null
                                    );
                                }}
                            >
                                <span className="material-symbols-outlined p-r-5">
                                    folder_open
                                </span>
                                {subfolder.title}
                            </span>
                            {subfolder.mainFolder.myRights
                                .manage &&
                                subfolder.childrenSubfolders
                                    .length === 0 &&
                                parseInt(
                                    params.subfolderID
                                ) === subfolder.id && (
                                    <SubfolderEdit
                                        id={subfolder.id}
                                        folders={folders}
                                        className={
                                            parentFolder.layer >=
                                                2 ||
                                            showArchived
                                                ? 'm-r-15'
                                                : ''
                                        }
                                    />
                                )}
                            {subfolder.childrenSubfolders
                                .length > 0 && (
                                <button
                                    className="btn-link ml-auto m-r-15"
                                    style={{
                                        color: '#3c3c3c',
                                    }}
                                    onClick={() => {
                                        handleNavItemClick(
                                            null,
                                            subfolder,
                                            null
                                        );
                                    }}
                                >
                                    <span className="material-symbols-outlined bigger">
                                        chevron_right
                                    </span>
                                </button>
                            )}

                            {!showArchived &&
                                subfolder.childrenSubfolders
                                    .length === 0 &&
                                parseInt(
                                    params.subfolderID
                                ) === subfolder.id &&
                                parentFolder.layer < 2 &&
                                (localProject.right
                                    .lanwikiCreateFolder ||
                                    userIsAdmin) && (
                                    <SubfolderAdd
                                        parentVariables={{
                                            subfolderId:
                                                subfolder.id,
                                        }}
                                        className="m-r-15"
                                    />
                                )}
                        </NavItem>
                    ))}
                </Empty>
            </Nav>
            {!showArchived &&
                (localProject.right.lanwikiCreateFolder ||
                    userIsAdmin) && (
                    <SubfolderAdd
                        label={t('subfolder')}
                        className="m-l-12"
                        parentVariables={
                            parentFolder.order
                                ? {
                                      folderId:
                                          parentFolder.id,
                                  }
                                : {
                                      subfolderId:
                                          parentFolder.id,
                                  }
                        }
                    />
                )}
        </Empty>
    );
}
