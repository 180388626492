import React, { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { sortBy } from 'helperFunctions';
import ProjectFilterEdit from './projectFilterEdit';
import ProjectFilterAdd from './projectFilterAdd';
import { getGroupsProblematicAttributes } from '../../helpers';

import { GET_BASIC_USERS, GET_BASIC_COMPANIES } from 'helpdesk/queries';
import { useTranslation } from 'react-i18next';

export default function ProjectFilters(props) {
    //data
    const {
        groups,
        statuses,
        tags,
        customAttributes,
        filters,
        addFilter,
        deleteFilter,
        updateFilter,
    } = props;

    const { t } = useTranslation();
    /*
    const {
      data: taskTypesData,
      loading: taskTypesLoading
    } = useQuery( GET_TASK_TYPES );
  */
    const { data: usersData, loading: usersLoading } = useQuery(GET_BASIC_USERS);

    const { data: companiesData, loading: companiesLoading } = useQuery(GET_BASIC_COMPANIES);

    const dataLoading =
        /*  taskTypesLoading ||*/
        usersLoading || companiesLoading;

    const translatedGroups = useMemo(() => {
        return groups.map((group) => ({
            ...group,
            title: t(group.title),
        }));
    }, [groups]);

    return (
        <div className=" project-settings">
            <table className="table bkg-white m-t-5">
                <thead>
                    <tr>
                        <th>{t('projectFilterName')}</th>
                        <th>{t('description')}</th>
                        <th>{t('groups')}</th>
                        <th className="text-center">{t('order')}</th>
                        <th width="150">{t('actions')}</th>
                    </tr>
                </thead>
                <tbody>
                    {sortBy(filters, [{ asc: true, key: 'order' }]).map((projectFilter) => (
                        <tr key={projectFilter.id}>
                            <td>{projectFilter.title}</td>
                            <td>{projectFilter.description}</td>
                            <td>
                                {getGroupsProblematicAttributes(
                                    groups,
                                    projectFilter,
                                    customAttributes,
                                    t
                                ).length !== 0 && (
                                    <span className="material-symbols-outlined text-danger">
                                        error
                                    </span>
                                )}
                                {translatedGroups
                                    .filter((group) =>
                                        projectFilter.groups.some((groupId) => group.id === groupId)
                                    )
                                    .map((group) => group.title)
                                    .join(', ')}
                            </td>
                            <td className="text-center">{projectFilter.order}</td>
                            <td>
                                <div className="row">
                                    <ProjectFilterEdit
                                        allGroups={translatedGroups}
                                        allStatuses={statuses}
                                        allTags={tags}
                                        customAttributes={customAttributes}
                                        allUsers={dataLoading ? [] : usersData.basicUsers}
                                        allCompanies={
                                            dataLoading ? [] : companiesData.basicCompanies
                                        }
                                        disabled={dataLoading}
                                        filter={projectFilter}
                                        updateFilter={(updatedFilter) =>
                                            updateFilter(updatedFilter)
                                        }
                                    />
                                    <button
                                        className="btn-link-red m-l-auto"
                                        onClick={() => deleteFilter(projectFilter.id)}
                                    >
                                        <span className="material-symbols-outlined p-r-0">
                                            close
                                        </span>
                                    </button>
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <ProjectFilterAdd
                allGroups={translatedGroups}
                allStatuses={statuses}
                allTags={tags}
                customAttributes={customAttributes}
                allUsers={dataLoading ? [] : usersData.basicUsers}
                allCompanies={dataLoading ? [] : companiesData.basicCompanies}
                disabled={dataLoading}
                addProjectFilter={addFilter}
            />
        </div>
    );
}
