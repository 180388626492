import React from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import {
    useQuery,
    useMutation,
    useSubscription,
} from '@apollo/client';
import LanwikiPageForm from '../form';
import Loading from 'components/loading';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import {
    toSelArr,
    extractImages,
    replacePlaceholdersWithLinks,
    getDeletedImages,
    childrenSubfoldersFlat,
} from 'helperFunctions';

import { REST_URL } from 'configs/restAPI';

import { GET_PROJECT } from 'apollo/queries';

import {
    GET_TAGS,
    TAGS_SUBSCRIPTION,
    GET_FOLDERS,
    FOLDERS_SUBSCRIPTION,
    GET_PAGE,
    UPDATE_PAGE,
    DELETE_PAGE,
} from 'lanwiki/queries';

export default function PageEditContainer() {
    const navigate = useNavigate();
    const params = useParams();

    const { t } = useTranslation();

    const { data: projectData } = useQuery(GET_PROJECT);
    const localProject = projectData.localProject;

    const {
        data: tagsData,
        loading: tagsLoading,
        refetch: tagsRefetch,
    } = useQuery(GET_TAGS, {
        variables: {
            projectId: localProject.project.id,
        },
        fetchPolicy: 'network-only',
    });

    useSubscription(TAGS_SUBSCRIPTION, {
        onData: () => {
            tagsRefetch({
                projectId: localProject.project.id,
            });
        },
    });

    const {
        data: foldersData,
        loading: foldersLoading,
        refetch: foldersRefetch,
    } = useQuery(GET_FOLDERS, {
        variables: {
            projectId: localProject.project.id,
        },
        fetchPolicy: 'network-only',
    });

    useSubscription(FOLDERS_SUBSCRIPTION, {
        onData: () => {
            foldersRefetch({
                projectId: localProject.project.id,
            });
        },
    });

    const { data: pageData, loading: pageLoading } =
        useQuery(GET_PAGE, {
            variables: {
                id: parseInt(params.pageID),
            },
            fetchPolicy: 'network-only',
        });

    //mutations
    const [updatePage] = useMutation(UPDATE_PAGE);
    const [deletePage] = useMutation(DELETE_PAGE);
    const [showEdit, setShowEdit] = React.useState(false);
    const [saving, setSaving] = React.useState(false);

    const deletePageFunc = () => {
        if (
            window.confirm(t('comfirmDeletingLanwikiPage'))
        ) {
            setSaving(true);
            deletePage({
                variables: {
                    id: page.id,
                },
            })
                .then(() => {
                    setSaving(false);
                    navigate(
                        window.location.pathname.substring(
                            0,
                            window.location.pathname.lastIndexOf(
                                '/'
                            )
                        )
                    );
                })
                .catch((e) => {
                    setSaving(false);
                    console.log(e);
                });
        }
    };

    const loading =
        tagsLoading || foldersLoading || pageLoading;

    if (loading) {
        return <Loading />;
    }
    const tags = tagsData.lanwikiTags;
    const folders = foldersData.lanwikiFolders;
    const page = pageData.lanwikiPage;
    const myRights = page.myRights;

    return (
        <div>
            <LanwikiPageForm
                id={page.id}
                edit={true}
                disabled={
                    !myRights.write ||
                    (page.folder && page.folder.archived) ||
                    !showEdit
                }
                close={() => setShowEdit(false)}
                savePage={(
                    data,
                    setSaving,
                    afterUpdate
                ) => {
                    setSaving(true);
                    data.deletedImages = getDeletedImages(
                        data.body,
                        page.images,
                        'get-lw-file'
                    );
                    const separatedData = extractImages(
                        data.body
                    );
                    if (separatedData.files.length > 0) {
                        const formData = new FormData();
                        separatedData.files.forEach(
                            (file) =>
                                formData.append(
                                    `file`,
                                    file
                                )
                        );
                        formData.append(
                            'token',
                            `${sessionStorage.getItem(
                                'acctok'
                            )}`
                        );
                        formData.append(
                            'lanwikiId',
                            data.id
                        );
                        axios
                            .post(
                                `${REST_URL}/api/lw-upload-text-images`,
                                formData,
                                {
                                    headers: {
                                        'Content-Type':
                                            'multipart/form-data',
                                    },
                                }
                            )
                            .then((response) => {
                                if (!response.data.ok) {
                                    console.log(
                                        response.data
                                    );
                                    setSaving(false);
                                    return;
                                }
                                const newBody =
                                    replacePlaceholdersWithLinks(
                                        separatedData.value,
                                        response.data
                                            .attachments,
                                        'get-lw-file'
                                    );
                                data.body = newBody;
                                updatePage({
                                    variables: data,
                                })
                                    .then(() => {
                                        setSaving(false);
                                        afterUpdate();
                                    })
                                    .catch((e) => {
                                        console.log(e);
                                        setSaving(false);
                                    });
                            });
                    } else {
                        updatePage({ variables: data })
                            .then(() => {
                                setSaving(false);
                                afterUpdate();
                            })
                            .catch((e) => {
                                console.log(e);
                                setSaving(false);
                            });
                    }
                }}
                deletePage={deletePageFunc}
                allTags={toSelArr(tags)}
                allFolders={toSelArr(folders)}
                allSubfolders={toSelArr(folders)
                    .map((folder) =>
                        folder.subfolders
                            .map((childSubfolder) =>
                                childrenSubfoldersFlat(
                                    childSubfolder
                                ).flat()
                            )
                            .flat()
                    )
                    .flat()}
                saving={saving}
                showEdit={showEdit}
                setShowEdit={setShowEdit}
                myRights={myRights}
                navigate={navigate}
                params={params}
                page={page}
            />
        </div>
    );
}
