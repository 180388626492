export default {
  notifications: {
    en: 'Notifications',
    sk: 'Upozornenia',
    cz: 'Oznámení',
    de: 'Benachrichtigungen',
  },
  noNotifications: {
    en: 'There are no notifications!',
    sk: 'Nemáte žiadne upozornenia!',
    cz: 'Nejsou žádná oznámení!',
    de: 'Es gibt keine Benachrichtigungen!',
  },
  goToNotifications: {
    en: 'Go to notifications',
    sk: 'Prejsť na upozornenia',
    cz: 'Přejděte na oznámení',
    de: 'Gehen Sie zu Benachrichtigungen',
  },
  moreUnread: {
    en: 'more unread',
    sk: 'ďalších neprečítaných',
    cz: 'více nepřečtených',
    de: 'eher ungelesen',
  },
  cancelNotifications: {
    en: 'Cancel notifications',
    sk: 'Zrušiť upozornenia',
    cz: 'Zrušit oznámení',
    de: 'Benachrichtigungen abbrechen',
  },
  cancelMailNotifications: {
    en: 'Cancel email notifications',
    sk: 'Zrušiť upozornenia cez email',
    cz: 'Zrušit e-mailová upozornění',
    de: 'E-Mail-Benachrichtigungen abbrechen',
  },
  allowMailNotifications: {
    en: 'Allow email notifications',
    sk: 'Posielať upozornenia cez email',
    cz: 'Povolit e-mailová upozornění',
    de: 'E-Mail-Benachrichtigungen zulassen',
  },
  systemMessage: {
    en: 'System message',
    sk: 'Systémová správa',
    cz: 'Systémová zpráva',
    de: 'Systemnachricht',
  },
  time: {
    en: 'Time',
    sk: 'Čas',
    cz: 'Čas',
    de: 'Zeit',
  },
}
