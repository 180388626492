import React from 'react';
import { timestampToStringFNS } from 'helperFunctions';
import { useTranslation } from 'react-i18next';
import CompanyPrint from './print';
import CompanyExcel from './excel';

export default function CompanyList(props) {
    const { companies, fromDate, toDate, setCompany } = props;

    const { t } = useTranslation();

    return (
        <div className="m-t-10 m-b-20 invoice-container">
            <table className="table row-highlight m-l-0 m-r-0">
                <thead>
                    <tr>
                        <th>{t('companyName')}</th>
                        <th>{t('works')}</th>
                        <th>{t('trips')}</th>
                        <th>{t('materials')}</th>
                        <th>{t('rentedItems')}</th>
                        <th width="100">{t('exports')}</th>
                    </tr>
                </thead>
                <tbody>
                    {companies.map((companyData) => (
                        <tr key={companyData.company.id}>
                            <td className="clickable" onClick={() => setCompany(companyData)}>
                                {companyData.company.title}
                            </td>
                            <td className="clickable" onClick={() => setCompany(companyData)}>
                                {companyData.works}
                            </td>
                            <td className="clickable" onClick={() => setCompany(companyData)}>
                                {companyData.trips}
                            </td>
                            <td className="clickable" onClick={() => setCompany(companyData)}>
                                {companyData.materials}
                            </td>
                            <td className="clickable" onClick={() => setCompany(companyData)}>
                                {companyData.company.companyRents.reduce(
                                    (acc, companyRent) => acc + companyRent.quantity,
                                    0
                                )}
                            </td>
                            <td className="flex-row">
                                <CompanyPrint
                                    company={companyData.company}
                                    variables={{
                                        companyId: companyData.company.id,
                                        fromDate: fromDate.valueOf().toString(),
                                        toDate: toDate.valueOf().toString(),
                                    }}
                                />
                                <CompanyExcel
                                    company={companyData.company}
                                    variables={{
                                        companyId: companyData.company.id,
                                        fromDate: fromDate.valueOf().toString(),
                                        toDate: toDate.valueOf().toString(),
                                    }}
                                />
                            </td>
                        </tr>
                    ))}
                    {companies.length === 0 && (
                        <tr key="no-items">
                            <td colSpan="6">{`${t('noInvoiceableCompanies')} ${t(
                                'inDateRange'
                            ).toLowerCase()} ${timestampToStringFNS(
                                fromDate.valueOf()
                            )} - ${timestampToStringFNS(toDate.valueOf())}`}</td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
}
