import React from 'react';
import GeneralPopover from './generalPopover';

export default function MultiSelect(props) {
    const {
        bodyClassName,
        disabled,
        showFilter,
        bodyStyle,
        header,
        target,
        closeMultiSelect,
        open,
        items,
        selected,
        onChange,
        useLegacy,
    } = props;

    const [filter, setFilter] = React.useState('');
    const [random] = React.useState(
        (Math.random() * 100000).toFixed(0)
    );
    const coloredItems =
        items && items.length !== 0
            ? Object.hasOwn(items[0], 'color')
            : false;
    const renderPopover = () => (
        <GeneralPopover
            placement="bottom-start"
            className="overflow-auto max-height-400"
            target={
                target !== undefined
                    ? target
                    : `savepoint-${random}`
            }
            header={`${header ? header : ''}`}
            reset={() => {}}
            submit={closeMultiSelect}
            open={open}
            close={closeMultiSelect}
            useLegacy={useLegacy}
        >
            <div
                className={`${
                    bodyClassName ? bodyClassName : ''
                }`}
                style={bodyStyle ? bodyStyle : {}}
            >
                {showFilter !== false && (
                    <input
                        className="form-control"
                        placeholder="Filter"
                        disabled={disabled}
                        value={filter}
                        onChange={(e) =>
                            setFilter(e.target.value)
                        }
                    />
                )}
                {!items && (
                    <span className="message error-message">{` Provided items are of value ${items}`}</span>
                )}
                {items &&
                    items
                        .filter((item) =>
                            item.label
                                .toLowerCase()
                                .includes(
                                    filter.toLowerCase()
                                )
                        )
                        .map((item) => (
                            <div
                                key={item.id}
                                className="multiselect-item"
                                onClick={() => {
                                    if (disabled) {
                                        return;
                                    }
                                    const removed =
                                        selected.some(
                                            (selected) =>
                                                selected.id ===
                                                item.id
                                        );
                                    const newItems = removed
                                        ? selected.filter(
                                              (selected) =>
                                                  selected.id !==
                                                  item.id
                                          )
                                        : [
                                              ...selected,
                                              item,
                                          ];
                                    onChange(
                                        newItems,
                                        item,
                                        removed
                                    );
                                }}
                            >
                                {selected.some(
                                    (selected) =>
                                        selected.id ===
                                        item.id
                                ) ? (
                                    <span
                                        className="material-symbols-outlined"
                                        style={{
                                            color: 'green',
                                        }}
                                    >
                                        check_circle
                                    </span>
                                ) : (
                                    <span
                                        className="material-symbols-outlined clickable p-r-0"
                                        style={{
                                            color: 'red',
                                        }}
                                    >
                                        close
                                    </span>
                                )}
                                <span
                                    className="m-r-5 p-l-5 p-r-5"
                                    style={
                                        coloredItems
                                            ? {
                                                  backgroundColor:
                                                      item.color,
                                                  color: 'white',
                                                  borderRadius: 3,
                                                  fontWeight:
                                                      'normal',
                                              }
                                            : {}
                                    }
                                >
                                    {item.label}
                                </span>
                            </div>
                        ))}
            </div>
        </GeneralPopover>
    );

    if (target !== undefined) {
        return renderPopover();
    }

    return (
        <div>
            <span id={`savepoint-${random}`} />
            {renderPopover()}
        </div>
    );
}
