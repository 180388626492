import React from 'react';

import {
    useMutation,
    useQuery,
    useApolloClient,
    useSubscription,
} from '@apollo/client';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import {
    NavLink,
    NavItem,
    Nav,
    TabContent,
    TabPane,
} from 'reactstrap';

import Loading from 'components/loading';
import SettingsInput from '../components/settingsInput';
import SettingsTextarea from '../components/settingsTextarea';
import UserManagement from './userManagement';
import DeleteReplacement from 'components/deleteReplacement';

import { toSelArr } from 'helperFunctions';
import { addLocalError } from 'apollo/localSchema/actions';

import {
    UPDATE_WORKSPACE,
    DELETE_WORKSPACE,
    GET_WORKSPACES,
    GET_WORKSPACE,
} from 'helpdesk/queries';
import { getMyData } from 'helperFunctions';

import {
    GET_BASIC_USERS,
    USERS_SUBSCRIPTION,
} from 'helpdesk/queries';

export default function WorkspaceEdit(props) {
    const {
        id,
        closeModal,
        onSuccessUpdate,
        onSuccessDelete,
    } = props;

    const { t } = useTranslation();
    const client = useApolloClient();

    const currentUser = getMyData();

    const allWorkspaces = toSelArr(
        client.readQuery({
            query: GET_WORKSPACES,
        }).workspaces
    );

    const {
        data: workspaceData,
        loading: workspaceLoading,
        refetch: workspaceRefetch,
    } = useQuery(GET_WORKSPACE, {
        variables: {
            id,
        },
        fetchPolicy: 'network-only',
    });

    const {
        data: usersData,
        loading: usersLoading,
        refetch: usersRefetch,
    } = useQuery(GET_BASIC_USERS, {
        fetchPolicy: 'network-only',
    });

    useSubscription(USERS_SUBSCRIPTION, {
        onData: () => {
            usersRefetch();
        },
    });

    const [updateWorkspace] = useMutation(UPDATE_WORKSPACE);
    const [deleteWorkspace] = useMutation(DELETE_WORKSPACE);

    //state
    const [title, setTitle] = React.useState('');
    const [description, setDescription] =
        React.useState('');
    const [workspaceRights, setWorkspaceRights] =
        React.useState([]);
    const [saving, setSaving] = React.useState(false);
    const [dataChanged, setDataChanged] =
        React.useState(false);

    const [openTab, setOpenTab] =
        React.useState('description');
    const [openDelete, setOpenDelete] =
        React.useState(false);

    // sync
    React.useEffect(() => {
        if (!workspaceLoading && !usersLoading) {
            setData();
        }
    }, [workspaceLoading, usersLoading]);

    React.useEffect(() => {
        workspaceRefetch({
            id,
        }).then(setData);
    }, [id]);

    // functions
    const setData = () => {
        if (workspaceLoading || usersLoading) {
            return;
        }
        setTitle(workspaceData.workspace.title);
        setDescription(workspaceData.workspace.description);
        setWorkspaceRights(
            workspaceData.workspace.workspaceRights.map(
                (right) => ({
                    addProjects: right.addProjects,
                    editWorkspace: right.editWorkspace,
                    userId: right.user.id,
                })
            )
        );
        setDataChanged(false);
    };

    const updateWorkspaceFunc = () => {
        setSaving(true);

        updateWorkspace({
            variables: {
                id,
                title,
                description,
                workspaceRights,
            },
        })
            .then((response) => {
                onSuccessUpdate(response);
                setOpenDelete(false);
            })
            .catch((err) => {
                addLocalError(err);
            });

        setSaving(false);
        setDataChanged(false);
    };

    const deleteWorkspaceFunc = (replacement) => {
        if (window.confirm(t('generalConfirmation'))) {
            deleteWorkspace({
                variables: {
                    id,
                    newId: parseInt(replacement.id),
                },
            })
                .then(() => {
                    onSuccessDelete();
                })
                .catch((err) => {
                    addLocalError(err);
                });
        }
    };

    if (workspaceLoading || usersLoading) {
        return <Loading />;
    }

    return (
        <div className="p-20">
            <h2 className="m-b-20">
                {`${t('edit')} ${t(
                    'workspace'
                ).toLowerCase()}`}
            </h2>
            <Nav tabs className="no-border m-b-25">
                <NavItem className="noselect">
                    <NavLink
                        className={classnames(
                            {
                                active:
                                    openTab ===
                                    'description',
                            },
                            'clickable',
                            ''
                        )}
                        onClick={() =>
                            setOpenTab('description')
                        }
                    >
                        {t('description')}
                    </NavLink>
                </NavItem>
                <NavItem className="noselect">
                    <NavLink>|</NavLink>
                </NavItem>
                <NavItem className="noselect">
                    <NavLink
                        className={classnames(
                            {
                                active: openTab === 'users',
                            },
                            'clickable',
                            ''
                        )}
                        onClick={() => setOpenTab('users')}
                    >
                        {t('users')}
                    </NavLink>
                </NavItem>
            </Nav>

            <TabContent activeTab={openTab}>
                <TabPane tabId={'description'}>
                    <SettingsInput
                        required
                        label={t('workspaceTitle')}
                        id="title"
                        value={title}
                        onChange={(e) => {
                            setTitle(e.target.value);
                            setDataChanged(true);
                        }}
                    />

                    <SettingsTextarea
                        label={t('workspaceDescription')}
                        id="description"
                        inputClassName="form-control"
                        value={description}
                        onChange={(e) => {
                            setDescription(e.target.value);
                        }}
                    />
                </TabPane>

                <TabPane tabId={'users'}>
                    <UserManagement
                        users={usersData.basicUsers}
                        myId={currentUser.id}
                        workspaceRights={workspaceRights}
                        setWorkspaceRights={
                            setWorkspaceRights
                        }
                    />
                </TabPane>
            </TabContent>
            <DeleteReplacement
                isOpen={openDelete}
                label={t('workspace')}
                options={toSelArr(
                    allWorkspaces.filter(
                        (workspace) => workspace.id !== id
                    )
                )}
                close={() => setOpenDelete(false)}
                finishDelete={deleteWorkspaceFunc}
            />

            <div className="form-buttons-row">
                <button
                    className="btn-link btn-distance"
                    onClick={closeModal}
                >
                    {t('cancel')}
                </button>

                <button
                    className="btn-red m-r-0 m-l-0 p-0"
                    onClick={() => {
                        setOpenDelete(true);
                    }}
                >
                    <span className="material-symbols-outlined">
                        delete
                    </span>
                    {t('delete')}
                </button>

                <div className="ml-auto message m-r-10">
                    {dataChanged && (
                        <div className="message error-message">
                            {t('saveBeforeLeaving')}
                        </div>
                    )}
                    {!dataChanged && (
                        <div className="message success-message">
                            {t('saved')}
                        </div>
                    )}
                </div>

                <button
                    className="btn m-t-5"
                    disabled={saving}
                    onClick={updateWorkspaceFunc}
                >
                    {saving
                        ? `${t('saving')}...`
                        : `${t('save')} ${t(
                              'workspace'
                          ).toLowerCase()}`}
                </button>
            </div>
        </div>
    );
}
