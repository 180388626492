import React from 'react';

import Empty from 'components/Empty';
import CustomAttributeEntry from './entry';

export default function CustomAttributesList(props) {
    const { customAttributes } = props;

    return (
        <Empty>
            {customAttributes.map((customAttribute) => (
                <CustomAttributeEntry
                    key={customAttribute.id}
                    {...props}
                    customAttribute={customAttribute}
                />
            ))}
        </Empty>
    );
}
