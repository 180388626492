import { gql } from '@apollo/client';

export const INVOICE_TASKS = gql`
    mutation invoiceTasks(
        $fromDate: String!
        $toDate: String!
        $companyId: Int!
        $taskIds: [Int]!
    ) {
        invoiceTasks(
            fromDate: $fromDate
            toDate: $toDate
            companyId: $companyId
            taskIds: $taskIds
        )
    }
`;

export const INVOICE = gql`
    query invoice(
        $fromDate: String!
        $toDate: String!
        $companyId: Int!
    ) {
        invoice(
            fromDate: $fromDate
            toDate: $toDate
            companyId: $companyId
        ) {
            materialTasks {
                id
                title
            }
            totals {
                workHours
                tripHours
                materialPriceWithDPH
                materialPrice
            }
            tripTasks {
                id
                title
            }
            workTasks {
                id
                title
            }
        }
    }
`;
