import React from 'react';

import { useMutation, useQuery } from '@apollo/client';

import { FormGroup, Label } from 'reactstrap';
import Loading from 'components/loading';
import Select from 'react-select';
import Checkbox from 'components/checkbox';
import Switch from 'components/switch';
import PasswordChange from './passChange';
import SettingsInput from '../components/settingsInput';
import { useTranslation } from 'react-i18next';

import languages from 'configs/constants/languages';
import { pickSelectStyle } from 'configs/components/select';
import { addLocalError } from 'apollo/localSchema/actions';
import { isEmail, toSelArr, getMyData } from 'helperFunctions';

import {
    GET_USER,
    UPDATE_USER,
    SET_USER_ACTIVE,
    GET_ROLES,
    GET_BASIC_COMPANIES,
} from 'helpdesk/queries';

export default function UserEdit(props) {
    const { id, closeModal } = props;

    const { t } = useTranslation();

    const currentUser = getMyData();

    const {
        data: userData,
        loading: userLoading,
        refetch: userRefetch,
    } = useQuery(GET_USER, {
        variables: {
            id,
        },
        fetchPolicy: 'network-only',
    });

    const { data: rolesData, loading: rolesLoading } = useQuery(GET_ROLES, {
        fetchPolicy: 'network-only',
    });

    const { data: companiesData, loading: companiesLoading } = useQuery(GET_BASIC_COMPANIES, {
        fetchPolicy: 'network-only',
    });

    const [updateUser] = useMutation(UPDATE_USER);
    const [setUserActive] = useMutation(SET_USER_ACTIVE);

    //state
    const [active, setActive] = React.useState(true);
    const [email, setEmail] = React.useState('');
    const [name, setName] = React.useState('');
    const [surname, setSurname] = React.useState('');
    const [phone, setPhone] = React.useState('');
    const [receiveNotifications, setReceiveNotifications] = React.useState(false);
    const [signature, setSignature] = React.useState('');
    const [role, setRole] = React.useState({});
    const [company, setCompany] = React.useState({});
    const [language, setLanguage] = React.useState(languages[0]);
    const [passwordChangeOpen, setPasswordChangeOpen] = React.useState(false);

    const [saving, setSaving] = React.useState(false);
    const [dataChanged, setDataChanged] = React.useState(false);

    // sync

    React.useEffect(() => {
        setData();
    }, [userLoading]);

    React.useEffect(() => {
        userRefetch({
            id,
        }).then((userData) => {
            setData(userData.data);
        });
    }, [id]);

    // functions
    const setData = (newData) => {
        if (userLoading) {
            return;
        }
        const user = newData ? newData.user : userData.user;
        setActive(user.active);
        setEmail(user.email);
        setName(user.name);
        setSurname(user.surname);
        setPhone(user.phone);
        setReceiveNotifications(user.receiveNotifications);
        setSignature(
            user.signature ? user.signature : `${user.name} ${user.surname}, ${user.company.title}`
        );
        setRole({
            ...user.role,
            label: user.role.title,
            value: user.role.id,
        });
        setCompany({
            ...user.company,
            label: user.company.title,
            value: user.company.id,
        });
        setLanguage(languages.find((language) => language.value === user.language));
        setDataChanged(false);
    };

    const updateUserFunc = () => {
        setSaving(true);
        let data = {
            id,
            email,
            name,
            surname,
            phone,
            receiveNotifications,
            signature,
            roleId: role.id,
            companyId: company.id,
            language: language.value,
        };
        if (active !== userData.user.active) {
            deactivateUser(active);
        }
        updateUser({
            variables: data,
        })
            .then(() => {
                setSaving(false);
                setDataChanged(false);
                closeModal();
            })
            .catch((err) => {
                setSaving(false);
                addLocalError(err);
            });
    };

    const deactivateUser = () => {
        setUserActive({
            variables: {
                id,
                active,
            },
        });
    };

    const dataLoading = userLoading || rolesLoading || companiesLoading || !currentUser;

    if (dataLoading) {
        return <Loading />;
    }

    const cannotSave = () => {
        return (
            saving ||
            surname?.length === 0 ||
            !isEmail(email) ||
            !role ||
            !company ||
            (companiesData.basicCompanies ? companiesData.basicCompanies.length === 0 : false)
        );
    };

    const currentUserLevel = currentUser.role.level;
    const roles = toSelArr(rolesData.roles).filter(
        (role) =>
            role.level > currentUserLevel ||
            role.id === userData.user.role.id ||
            (currentUserLevel === 0 && role.level === 0)
    );
    const companies = toSelArr(companiesData.basicCompanies);

    const isDisabled = currentUserLevel !== 0 && currentUserLevel >= role.level;

    return (
        <div>
            <div className="p-20">
                <h2 className="m-b-20">{`${t('edit')} ${t('user2').toLowerCase()}`}</h2>

                <Switch
                    value={active}
                    onChange={() => {
                        setActive(!active);
                        setDataChanged(true);
                    }}
                    label={t('active')}
                    disabled={id === currentUser.id}
                    labelClassName="text-normal font-normal"
                    simpleSwitch
                />

                <FormGroup>
                    <Label for="role">
                        {t('role')}
                        <span className="warning-big">*</span>
                    </Label>
                    <Select
                        styles={pickSelectStyle()}
                        isDisabled={isDisabled}
                        options={roles}
                        value={role}
                        onChange={(role) => {
                            setRole(role);
                            setDataChanged(true);
                        }}
                    />
                </FormGroup>

                <SettingsInput
                    label={t('name')}
                    id="name"
                    disabled={isDisabled}
                    value={name}
                    onChange={(e) => {
                        setName(e.target.value);
                        setDataChanged(true);
                    }}
                />

                <SettingsInput
                    required
                    label={t('surname')}
                    id="surname"
                    disabled={isDisabled}
                    value={surname}
                    onChange={(e) => {
                        setSurname(e.target.value);
                        setDataChanged(true);
                    }}
                />

                <SettingsInput
                    label={t('phone')}
                    id="phone"
                    value={phone}
                    onChange={(e) => {
                        setPhone(e.target.value);
                    }}
                />

                <SettingsInput
                    required
                    label={`${t('email')}/${t('login')}`}
                    id="email"
                    type="email"
                    disabled={isDisabled}
                    value={email}
                    onChange={(e) => {
                        setEmail(e.target.value);
                        setDataChanged(true);
                    }}
                />

                <FormGroup>
                    <Label for="language">{t('language')}</Label>
                    <Select
                        styles={pickSelectStyle()}
                        options={languages}
                        value={language}
                        isDisabled={isDisabled}
                        onChange={(lang) => {
                            setLanguage(lang);
                            setDataChanged(true);
                        }}
                    />
                </FormGroup>

                <Checkbox
                    className="m-b-5 p-l-0"
                    value={receiveNotifications}
                    label={t('receiveNotifications')}
                    disabled={isDisabled}
                    onChange={() => {
                        setReceiveNotifications(!receiveNotifications);
                        setDataChanged(true);
                    }}
                />

                <FormGroup>
                    <Label for="company">
                        {t('company')}
                        <span className="warning-big">*</span>
                    </Label>
                    <Select
                        styles={pickSelectStyle()}
                        isDisabled={isDisabled}
                        options={companies}
                        value={company}
                        onChange={(e) => {
                            setCompany(e);
                            setDataChanged(true);
                        }}
                    />
                </FormGroup>

                <SettingsInput
                    label={t('signature')}
                    id="signature"
                    type="textarea"
                    disabled={isDisabled}
                    value={signature}
                    onChange={(e) => {
                        setSignature(e.target.value);
                        setDataChanged(true);
                    }}
                />

                {!isDisabled && (
                    <div className="form-buttons-row">
                        <button className="btn-link btn-distance" onClick={closeModal}>
                            {t('cancel')}
                        </button>
                        <button
                            className="btn-link btn-distance"
                            disabled={saving || isDisabled}
                            onClick={() => {
                                setPasswordChangeOpen(true);
                            }}
                        >
                            {t('changePassword')}
                        </button>

                        <div className="ml-auto message p-t-0 m-r-10">
                            {dataChanged && (
                                <div className="message error-message">
                                    {t('saveBeforeLeaving')}
                                </div>
                            )}
                            {!dataChanged && (
                                <div className="message success-message">{t('saved')}</div>
                            )}
                        </div>

                        <button className="btn" disabled={cannotSave()} onClick={updateUserFunc}>
                            {saving
                                ? `${t('saving')}...`
                                : `${t('save')} ${t('user2').toLowerCase()}`}
                        </button>
                    </div>
                )}
                <PasswordChange
                    submitPass={(pass) => {
                        if (pass !== null) {
                            updateUser({
                                variables: {
                                    id,
                                    password: pass,
                                },
                            });
                        }
                        setPasswordChangeOpen(false);
                    }}
                    isOpen={passwordChangeOpen}
                />
            </div>
        </div>
    );
}
