import React, { useState, useEffect } from 'react';
import { fromUnixToDate } from 'helperFunctions';
import { useQuery } from '@apollo/client';
import Loading from 'components/loading';
import * as fns from 'date-fns';
import { useTranslation } from 'react-i18next';
import { GET_AUTOFILL_FOR_TASK } from 'helpdesk/queries';

const SOLUTION_ADDED = 0;
const CANNOT_CREATE_UNASSIGNED_SUBTASK = 1;

export default function AIAutofills(props) {
    const {
        taskId,
        taskCreatedAt,
        taskClosedDate,
        taskSubtaskValues,
        taskHasAutofill,
        applyAISolution,
        title,
        className,
    } = props;

    const { t } = useTranslation();

    const [applyResponse, setApplyResponse] = useState('');
    const [showApplyButton, setShowApplyButton] = useState(true);

    const {
        data: aiAutofillData,
        loading: aiAutofillLoading,
        refetch: aiAutofillRefetch,
    } = useQuery(GET_AUTOFILL_FOR_TASK, {
        variables: {
            taskId,
        },
        fetchPolicy: 'network-only',
    });

    useEffect(() => {
        aiAutofillRefetch({
            taskId,
        });
    }, [title]);

    if (aiAutofillLoading) {
        return <Loading />;
    }

    if (!aiAutofillData.getAutofillForTask) {
        return null;
    }

    //const showOverMaxSinceCreationNotifications = moment(parseInt(taskClosedDate)).diff(moment(parseInt(taskCreatedAt)), 'hours') > aiAutofillData.getAutofillForTask.maxSinceCreation;
    const showOverMaxSinceCreationNotifications =
        fns.differenceInHours(fromUnixToDate(taskClosedDate), fromUnixToDate(taskCreatedAt)) >
        aiAutofillData.getAutofillForTask.maxSinceCreation;
    const showOverMaxSubtaskValuesNotifications =
        taskSubtaskValues > aiAutofillData.getAutofillForTask.maxSubtasksValues;

    return (
        <div className={className}>
            <div className="ai-autofill-recommendation row">
                <div>
                    <span className="material-symbols-outlined">account_tree</span>
                </div>
                <div>
                    <div>
                        <label>{`${t('foundSolution')} ${
                            aiAutofillData.getAutofillForTask
                                ? aiAutofillData.getAutofillForTask.title
                                : 'Untitled'
                        }`}</label>
                    </div>
                    {aiAutofillData.getAutofillForTask.subtasks.length === 0 && (
                        <span>{t('noSolution')}</span>
                    )}
                    {aiAutofillData.getAutofillForTask.subtasks.length > 0 && (
                        <span>{t('recommendsSolution')}</span>
                    )}
                    {aiAutofillData.getAutofillForTask.subtasks.length > 0 && (
                        <ol>
                            {aiAutofillData.getAutofillForTask.subtasks.map((subtask) => (
                                <li key={subtask.id}>{`${subtask.title} (${t('quantity')}: ${
                                    subtask.quantity
                                })`}</li>
                            ))}
                        </ol>
                    )}
                    {!taskHasAutofill &&
                        aiAutofillData.getAutofillForTask.subtasks.length > 0 &&
                        showApplyButton && (
                            <button
                                className="btn"
                                onClick={() => {
                                    const response = applyAISolution(
                                        aiAutofillData.getAutofillForTask.subtasks
                                    );
                                    switch (response) {
                                        case SOLUTION_ADDED:
                                            setApplyResponse(t('addedSolution'));
                                            setShowApplyButton(false);
                                            break;
                                        case CANNOT_CREATE_UNASSIGNED_SUBTASK:
                                            setShowApplyButton(t('cannotCreateUnassignedSubtask'));
                                            break;
                                        default:
                                    }
                                }}
                            >
                                {t('applySolution')}
                            </button>
                        )}
                    {taskHasAutofill &&
                        aiAutofillData.getAutofillForTask.subtasks.length > 0 &&
                        showApplyButton && <span>{t('taskAlreadyHasAppliedAutofill')}</span>}
                    {applyResponse.length > 0 && <span className="message">{applyResponse}</span>}
                </div>
            </div>

            {showOverMaxSinceCreationNotifications && (
                <div className="ai-autofill-recommendation row">
                    <div>
                        <span className="material-symbols-outlined">account_tree</span>
                    </div>
                    <div>
                        <div>
                            <label>{t('lanTaskAI')}</label>
                        </div>
                        <span>{t('overMaxSinceCreationNotifications')}</span>
                    </div>
                </div>
            )}

            {showOverMaxSubtaskValuesNotifications && (
                <div className="ai-autofill-recommendation row">
                    <div>
                        <span className="material-symbols-outlined">account_tree</span>
                    </div>
                    <div>
                        <div>
                            <label>{t('lanTaskAI')}</label>
                        </div>
                        <span>{t('overMaxSubtaskValuesNotifications')}</span>
                    </div>
                </div>
            )}
        </div>
    );
}
