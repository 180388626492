import React, { useState } from 'react';

import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import Loading from 'components/loading';
import Switch from 'components/switch';

import {
    timestampToStringFNS,
    fromDateToUnix,
} from 'helperFunctions';

import { useTranslation } from 'react-i18next';

const tasks = [
    {
        id: 1,
        title: 'notifyIfOverMaxInterval',
        createdAt: fromDateToUnix(new Date()),
        active: true,
    },
    {
        id: 2,
        title: 'notifyIfOverMaxSubtaskValues',
        createdAt: fromDateToUnix(new Date()),
        active: true,
    },
];

export default function AINotifications() {
    const { t } = useTranslation();

    const [editingTask, setEditingTask] = useState(null);

    let aiSettingLoading = false;

    if (aiSettingLoading) {
        return <Loading />;
    }

    return (
        <div className="scroll-visible p-20 fit-with-header project-settings">
            <h2 className="m-b-20">
                {`${t('aiNotifications')}`}
            </h2>

            <table className="table bkg-white m-t-5">
                <thead>
                    <tr>
                        <th className="font-bold">
                            {t('name')}
                        </th>
                        <th className="font-bold">
                            {t('createdDate')}
                        </th>
                        <th className="font-bold">
                            {t('active')}
                        </th>
                        <th
                            width="150"
                            className="font-bold"
                        >
                            {t('actions')}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {tasks.map((task) => (
                        <tr key={task.id}>
                            <td>{t(task.title)}</td>
                            <td>
                                {timestampToStringFNS(
                                    task.createdAt
                                )}
                            </td>
                            <td>
                                {task.active
                                    ? t('true')
                                    : t('false')}
                            </td>
                            <td>
                                <button
                                    className="btn-link"
                                    style={{
                                        height: 'auto',
                                    }}
                                    onClick={() => {
                                        setEditingTask(
                                            task
                                        );
                                    }}
                                >
                                    <span className="material-symbols-outlined">
                                        edit
                                    </span>
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            <Modal isOpen={editingTask !== null}>
                <ModalHeader>
                    {editingTask
                        ? t(editingTask.title)
                        : null}
                </ModalHeader>
                <ModalBody>
                    <h1></h1>
                    <Switch
                        value={true}
                        onChange={() => {}}
                        label={t('active')}
                        labelClassName="text-normal font-normal"
                        simpleSwitch
                    />
                    <div className="form-buttons-row">
                        <button
                            className="btn-link"
                            onClick={() =>
                                setEditingTask(null)
                            }
                        >
                            {t('close')}
                        </button>
                        <button
                            className="btn ml-auto"
                            onClick={() =>
                                setEditingTask(null)
                            }
                        >
                            {t('save')}
                        </button>
                    </div>
                </ModalBody>
            </Modal>
        </div>
    );
}
