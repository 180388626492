import React, { useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import classnames from 'classnames';
import AutofillForm from './ai-solutionsForm';

import Loading from 'components/loading';
import { addLocalError } from 'apollo/localSchema/actions';

import { timestampToStringFNS } from 'helperFunctions';

import { useTranslation } from 'react-i18next';

import {
    AI_AUTOFILLS,
    ADD_AI_AUTOFILL,
    UPDATE_AI_AUTOFILL,
    DELETE_AI_AUTOFILL,
    ADD_AI_AUTOFILL_SUBTASK,
    UPDATE_AI_AUTOFILL_SUBTASK,
    DELETE_AI_AUTOFILL_SUBTASK,
} from 'helpdesk/queries';

export default function AISolutions() {
    const { t } = useTranslation();

    const [addAIAutofill] = useMutation(ADD_AI_AUTOFILL);
    const [updateAIAutofill] = useMutation(
        UPDATE_AI_AUTOFILL
    );
    const [deleteAIAutofill] = useMutation(
        DELETE_AI_AUTOFILL
    );

    const [addAIAutofillSubtask] = useMutation(
        ADD_AI_AUTOFILL_SUBTASK
    );
    const [updateAIAutofillSubtask] = useMutation(
        UPDATE_AI_AUTOFILL_SUBTASK
    );
    const [deleteAIAutofillSubtask] = useMutation(
        DELETE_AI_AUTOFILL_SUBTASK
    );

    const [editingAutofill, setEditingAutofill] =
        useState(null);
    const [openForm, setOpenForm] = useState(false);

    const {
        data: aiAutofillsData,
        loading: aiAutofillsLoading,
        refetch: aiAutofillsRefetch,
    } = useQuery(AI_AUTOFILLS);

    const addAIAutofillFunc = (variables) => {
        addAIAutofill({
            variables,
        })
            .then(() => {
                setOpenForm(false);
                setEditingAutofill(null);
                aiAutofillsRefetch();
            })
            .catch((err) => {
                addLocalError(err);
            });
    };

    const updateAIAutofillFunc = (variables) => {
        variables.addedSubtasks.forEach((item) => {
            addAIAutofillSubtask({
                variables: item,
            });
        });

        variables.updatedSubtasks.forEach((item) => {
            updateAIAutofillSubtask({
                variables: item,
            });
        });

        variables.deletedSubtasks.forEach((item) => {
            deleteAIAutofillSubtask({
                variables: {
                    id: item,
                },
            });
        });

        updateAIAutofill({
            variables: {
                id: variables.id,
                active: variables.active,
                title: variables.title,
                maxSinceCreation:
                    variables.maxSinceCreation,
                maxSubtasksValues:
                    variables.maxSubtasksValues,
                maxSinceCreationActive: true,
                maxSubtasksValuesActive: true,
            },
        })
            .then(() => {
                setOpenForm(false);
                setEditingAutofill(null);
                aiAutofillsRefetch();
            })
            .catch((err) => {
                addLocalError(err);
            });
    };

    if (aiAutofillsLoading) {
        return <Loading />;
    }

    return (
        <div className="scroll-visible p-20 fit-with-header  project-settings">
            <h2 className="m-b-20">{`${t(
                'aiSolutions'
            )}`}</h2>

            <div className="form-buttons-row m-b-10">
                <button
                    className={classnames('btn')}
                    onClick={() => {
                        setEditingAutofill(null);
                        setOpenForm(true);
                    }}
                >
                    <span className="material-symbols-outlined bigger">
                        add
                    </span>
                    {t('autofill')}
                </button>
            </div>

            <table className="table bkg-white m-t-5">
                <thead>
                    <tr>
                        <th className="font-bold">
                            {t('name')}
                        </th>
                        <th className="font-bold">
                            {t('createdDate')}
                        </th>
                        <th className="font-bold">
                            {t('active')}
                        </th>
                        <th
                            width="150"
                            className="font-bold"
                        >
                            {t('actions')}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {aiAutofillsData.aiAutofills.map(
                        (autofill) => (
                            <tr key={autofill.id}>
                                <td>{autofill.title}</td>
                                <td>
                                    {timestampToStringFNS(
                                        autofill.createdAt
                                    )}
                                </td>
                                <td>
                                    {autofill.active
                                        ? t('true')
                                        : t('false')}
                                </td>
                                <td className="row">
                                    <button
                                        className="btn-link"
                                        style={{
                                            height: 'auto',
                                        }}
                                        onClick={() => {
                                            setEditingAutofill(
                                                autofill
                                            );
                                            setOpenForm(
                                                true
                                            );
                                        }}
                                    >
                                        <span className="material-symbols-outlined">
                                            edit
                                        </span>
                                    </button>
                                    <button
                                        className="btn-link-red"
                                        style={{
                                            height: 'auto',
                                        }}
                                        onClick={() => {
                                            if (
                                                window.confirm(
                                                    t(
                                                        'generalConfirmation'
                                                    )
                                                )
                                            ) {
                                                deleteAIAutofill(
                                                    {
                                                        variables:
                                                            {
                                                                id: autofill.id,
                                                            },
                                                    }
                                                )
                                                    .then(
                                                        () => {
                                                            aiAutofillsRefetch();
                                                        }
                                                    )
                                                    .catch(
                                                        (
                                                            err
                                                        ) => {
                                                            addLocalError(
                                                                err
                                                            );
                                                        }
                                                    );
                                            }
                                        }}
                                    >
                                        <span className="material-symbols-outlined p-r-0">
                                            close
                                        </span>
                                    </button>
                                </td>
                            </tr>
                        )
                    )}
                </tbody>
            </table>

            {openForm && (
                <AutofillForm
                    autofillId={
                        editingAutofill
                            ? editingAutofill.id
                            : null
                    }
                    onSave={
                        editingAutofill
                            ? updateAIAutofillFunc
                            : addAIAutofillFunc
                    }
                    onClose={() => {
                        setEditingAutofill(null);
                        setOpenForm(false);
                    }}
                />
            )}
        </div>
    );
}
