import React from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import Loading from 'components/loading';
import CommandBar from '../components/commandBar';
import ItemRender from '../components/columnItemRender';
import TaskEdit from '../../edit';
import TaskEmpty from '../../taskEmpty';
import classnames from 'classnames';

import Search from '../components/search';
import ActiveSearch from '../components/activeSearch';
import Pagination from '../components/pagination';

export default function ColumnList(props) {
    const { loading, link, tasks } = props;

    const navigate = useNavigate();
    const params = useParams();

    const taskID = parseInt(params.taskID);
    return (
        <div>
            <CommandBar {...props} />

            <Search {...props} />
            <ActiveSearch {...props} includeGlobalSearch />

            <div className="row p-0 task-container">
                <div className="p-0" style={{ width: '30%' }}>
                    <div className="scroll-visible fit-with-header-and-commandbar-list task-list">
                        {loading && <Loading />}
                        {!loading &&
                            tasks.map((task) => (
                                <ul
                                    className={classnames('taskCol', 'clickable', 'list-unstyled', {
                                        'selected-item': taskID === task.id,
                                    })}
                                    style={{
                                        borderLeft:
                                            '3px solid ' +
                                            (task.status
                                                ? task.status.color
                                                    ? task.status.color
                                                    : 'white'
                                                : 'white'),
                                    }}
                                    onClick={() => {
                                        navigate(link + '/' + task.id);
                                    }}
                                    key={task.id}
                                >
                                    <ItemRender task={task} />
                                </ul>
                            ))}
                        {!loading && tasks.length > 0 && <Pagination {...props} shortForm />}
                        {!loading && tasks.length === 0 && (
                            <div className="center-ver" style={{ textAlign: 'center' }}>
                                Neboli nájdené žiadne výsledky pre tento filter
                            </div>
                        )}
                    </div>
                </div>
                {!isNaN(taskID) && tasks.some((task) => task.id === taskID) && (
                    <TaskEdit columns={true} />
                )}
                {(isNaN(taskID) || !tasks.some((task) => task.id === taskID)) && <TaskEmpty />}
            </div>
        </div>
    );
}
