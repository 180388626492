import React from 'react';

import {
    useMutation,
    useQuery,
    useApolloClient,
} from '@apollo/client';

import { FormGroup, Label } from 'reactstrap';
import Select from 'react-select';
import SettingsInput from '../components/settingsInput';
import SettingsHiddenInput from '../components/settingsHiddenInput';
import Loading from 'components/loading';
import ErrorMessage from 'components/errorMessage';
import Checkbox from 'components/checkbox';

import wellKnownOptions from 'configs/constants/wellKnown';
import { toSelArr } from 'helperFunctions';
import { pickSelectStyle } from 'configs/components/select';
import { addLocalError } from 'apollo/localSchema/actions';
import { useTranslation } from 'react-i18next';

import {
    GET_SMTPS,
    GET_SMTP,
    UPDATE_SMTP,
    TEST_SMTP,
} from 'helpdesk/queries';

export default function SMTPEdit(props) {
    const { id, closeModal } = props;

    const { t } = useTranslation();
    const client = useApolloClient();
    const allSMTPs = toSelArr(
        client.readQuery({
            query: GET_SMTPS,
        }).smtps
    );

    const {
        data: smtpData,
        loading: smtpLoading,
        refetch: smtpRefetch,
    } = useQuery(GET_SMTP, {
        variables: {
            id,
        },
        fetchPolicy: 'network-only',
        notifyOnNetworkStatusChange: true,
    });

    const [updateSmtp] = useMutation(UPDATE_SMTP);
    const [testSmtp] = useMutation(TEST_SMTP);

    //state
    const [title, setTitle] = React.useState('');
    const [order, setOrder] = React.useState(0);
    const [def, setDef] = React.useState(false);
    const [host, setHost] = React.useState('');
    const [port, setPort] = React.useState(465);
    const [username, setUsername] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [rejectUnauthorized, setRejectUnauthorized] =
        React.useState(false);
    const [secure, setSecure] = React.useState(true);

    const [saving, setSaving] = React.useState(false);
    const [dataChanged, setDataChanged] =
        React.useState(false);

    const [tested, setTested] = React.useState(false);
    const [wellKnown, setWellKnown] = React.useState(
        wellKnownOptions[0]
    );

    const wellKnownBlock = wellKnown.id !== null;

    // sync
    React.useEffect(() => {
        if (!smtpLoading) {
            const smtp = smtpData.smtp;
            setTitle(smtp.title);
            setOrder(smtp.order);
            setDef(smtp.def);
            setHost(smtp.host);
            setPort(smtp.port);
            setUsername(smtp.username);
            setPassword(smtp.password);
            setRejectUnauthorized(smtp.rejectUnauthorized);
            setSecure(smtp.secure);
            setWellKnown(
                wellKnownOptions.find(
                    (option) => option.id === smtp.wellKnown
                )
            );
            setTested(false);
            setDataChanged(false);
        }
    }, [smtpLoading]);

    React.useEffect(() => {
        smtpRefetch({
            id,
        });
    }, [id]);

    // functions
    const updateSMTPFunc = () => {
        setSaving(true);
        updateSmtp({
            variables: {
                id,
                title,
                order: order !== '' ? parseInt(order) : 0,
                def,
                host,
                port: port !== '' ? parseInt(port) : 0,
                username,
                password,
                rejectUnauthorized,
                secure,
                wellKnown: wellKnown.value,
            },
        })
            .then((response) => {
                const updatedSMTP = {
                    ...response.data.updateSmtp,
                };
                if (def) {
                    client.writeQuery({
                        query: GET_SMTPS,
                        data: {
                            smtps: [
                                ...allSMTPs.map((SMTP) => {
                                    if (SMTP.id === id) {
                                        return {
                                            ...updatedSMTP,
                                            def: true,
                                        };
                                    } else {
                                        return {
                                            ...SMTP,
                                            def: false,
                                        };
                                    }
                                }),
                            ],
                        },
                    });
                } else {
                    client.writeQuery({
                        query: GET_SMTPS,
                        data: {
                            smtps: [
                                ...allSMTPs.filter(
                                    (SMTP) => SMTP.id !== id
                                ),
                                updatedSMTP,
                            ],
                        },
                    });
                }
                closeModal();
            })
            .catch((err) => {
                addLocalError(err);
            });

        setSaving(false);
        setDataChanged(false);
    };

    const startTest = () => {
        let newSmtps = [...allSMTPs];
        let smtpIndex = newSmtps.findIndex(
            (smtp) => smtp.id === id
        );
        newSmtps[smtpIndex].currentlyTested = true;
        client.writeQuery({
            query: GET_SMTPS,
            data: {
                smtps: newSmtps,
            },
        });
        setTested(true);
        testSmtp({
            variables: {
                id,
            },
        });
    };

    const cannotSave = () =>
        (wellKnownBlock &&
            (saving ||
                password === '' ||
                username === '')) ||
        (!wellKnownBlock &&
            (saving ||
                title === '' ||
                host === '' ||
                port === '' ||
                password === '' ||
                username === ''));

    if (smtpLoading) {
        return <Loading />;
    }

    return (
        <div className="p-20">
            <h2 className="m-b-20">{`${t('edit')} ${t(
                'smtp'
            )}`}</h2>

            <Checkbox
                className="m-b-5 p-l-0"
                value={def}
                onChange={() => {
                    setDef(!def);
                    setDataChanged(true);
                }}
                label={t('default')}
            />

            <SettingsInput
                required={!wellKnownBlock}
                label={t('title')}
                id="title"
                value={title}
                onChange={(e) => {
                    setTitle(e.target.value);
                    setDataChanged(true);
                }}
            />

            <FormGroup>
                <Label>{t('wellKnown')}</Label>
                <Select
                    styles={pickSelectStyle()}
                    options={wellKnownOptions}
                    value={wellKnown}
                    onChange={(wellKnown) => {
                        setWellKnown(wellKnown);
                        setDataChanged(true);
                    }}
                />
            </FormGroup>

            <SettingsInput
                required={!wellKnownBlock}
                label={t('host')}
                id="host"
                value={host}
                onChange={(e) => {
                    setHost(e.target.value);
                    setDataChanged(true);
                }}
            />

            <SettingsInput
                required={!wellKnownBlock}
                label={t('port')}
                id="port"
                value={port}
                onChange={(e) => {
                    setPort(e.target.value);
                    setDataChanged(true);
                }}
            />

            <Checkbox
                className="m-b-5 p-l-0"
                label={t('secure')}
                value={secure}
                onChange={() => {
                    setSecure(!secure);
                    setDataChanged(true);
                }}
            />

            <SettingsInput
                required
                label={t('username')}
                id="username"
                value={username}
                onChange={(e) => {
                    setUsername(e.target.value);
                    setDataChanged(true);
                }}
            />

            <SettingsHiddenInput
                required
                label={t('password')}
                id="password"
                value={password}
                onChange={(e) => {
                    setPassword(e.target.value);
                    setDataChanged(true);
                }}
            />

            <Checkbox
                className="m-b-5 p-l-0"
                label={t('rejectUnauthorized')}
                value={rejectUnauthorized}
                onChange={() => {
                    setRejectUnauthorized(
                        !rejectUnauthorized
                    );
                    setDataChanged(true);
                }}
            />

            <ErrorMessage
                className="row align-items-center m-b-10 m-t-10"
                show={true}
                message={
                    smtpData.smtp.errorMessage
                        ? smtpData.smtp.errorMessage
                        : t('undefinedError')
                }
            />
            <div className="flex-row">
                <button
                    className="btn-link btn-distance"
                    onClick={closeModal}
                >
                    {t('cancel')}
                </button>
                {dataChanged && (
                    <div className="error-message center-hor m-l-auto m-r-10 p-5">
                        {t('saveBeforeLeaving')}
                    </div>
                )}
                {!dataChanged && (
                    <div className="success-message center-hor m-l-auto m-r-10 p-5">
                        {t('saved')}
                    </div>
                )}
                {!smtpData.smtp.currentlyTested && (
                    <button
                        className="btn btn-distance"
                        disabled={saving || tested}
                        onClick={startTest}
                    >
                        {t('testSmtp')}
                    </button>
                )}

                <button
                    className="btn"
                    disabled={cannotSave()}
                    onClick={updateSMTPFunc}
                >
                    {saving
                        ? `${t('saving')} ${t('smtp')}...`
                        : `${t('save')} ${t('smtp')}`}
                </button>
            </div>
        </div>
    );
}
