import React from 'react';
import { useMutation, useQuery } from '@apollo/client';
import classnames from 'classnames';

import { FormGroup, Label } from 'reactstrap';
import Loading from 'components/loading';
import Select from 'react-select';

import {
    translateAllSelectItems,
    translateSelectItem,
} from 'helperFunctions';
import { pickSelectStyle } from 'configs/components/select';
import { addLocalError } from 'apollo/localSchema/actions';

import {
    AI_SETTING,
    UPDATE_AI_SETTING,
} from 'helpdesk/queries';

import { useTranslation } from 'react-i18next';

export default function AISettings() {
    const { t } = useTranslation();

    const {
        data: aiSettingData,
        loading: aiSettingLoading,
    } = useQuery(AI_SETTING);

    const aiSortOptions = [
        {
            value: 'combined',
            labelId: 'combined',
        },
        {
            value: 'elu',
            labelId: 'elu',
        },
        {
            value: 'pure',
            labelId: 'pure',
        },
        {
            value: 'selu',
            labelId: 'selu',
        },
        {
            value: 'tanh',
            labelId: 'tanh',
        },
    ];

    const [updateAISetting] = useMutation(
        UPDATE_AI_SETTING
    );

    //state
    const [sort, setSort] = React.useState(
        aiSortOptions[0]
    );

    const [saving, setSaving] = React.useState(false);

    //functions
    const update = () => {
        setSaving(true);
        updateAISetting({
            variables: {
                sortType: sort.value,
            },
        })
            .then(() => {
                setSaving(false);
            })
            .catch((err) => {
                addLocalError(err);
                setSaving(false);
            });
    };

    React.useEffect(() => {
        if (!aiSettingLoading) {
            setSort(
                aiSortOptions.find(
                    (option) =>
                        option.value ===
                        aiSettingData.aiSetting.sortType
                )
            );
        }
    }, [aiSettingLoading]);

    if (aiSettingLoading) {
        return <Loading />;
    }

    return (
        <div className="scroll-visible p-20 fit-with-header">
            <h2 className="m-b-20">{`${t('aiSort')}`}</h2>
            <FormGroup>
                <Label for="sort">
                    {t('aiSort2')}
                    <span className="warning-big">*</span>
                </Label>
                <Select
                    styles={pickSelectStyle()}
                    options={translateAllSelectItems(
                        aiSortOptions,
                        t
                    )}
                    value={translateSelectItem(sort, t)}
                    onChange={(sort) => setSort(sort)}
                />
            </FormGroup>

            <div className="form-buttons-row">
                <button
                    className={classnames('btn')}
                    disabled={saving}
                    onClick={update}
                >
                    {saving
                        ? `${t('saving')}...`
                        : `${t('save')} ${t(
                              'settings'
                          ).toLowerCase()} ${t(
                              'ai2'
                          ).toLowerCase()}`}
                </button>
            </div>
        </div>
    );
}
