export default {
  deleteReplacementInfo1: {
    en: "Please choose",
    sk: 'Prosím vyberte',
    cz: 'Prosím vyber si',
    de: 'Please choose',
  },
  deleteReplacementInfo2: {
    en: "to replace the deleted one",
    sk: 'ako náhradu vymazaného',
    cz: 'na nahrazení smazanýho',
    de: 'um die gelöschte zu ersetzen',
  },
}
