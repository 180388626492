export const dashboard = {
    project: {
        id: null,
        title: 'allProjects',
        label: 'allProjects',
        labelId: 'allProjects',
        value: null,
    },
    id: null,
    title: 'allProjects',
    label: 'allProjects',
    labelId: 'allProjects',
    value: null,
};

export const allWorkspaces = {
    id: null,
    title: 'allWorkspaces',
    label: 'allWorkspaces',
    labelId: 'allWorkspaces',
    value: null,
    myRights: {
        addProjects: false,
        editWorkspace: false,
    },
};

export const addProject = {
    project: {
        id: -1,
        title: 'Project',
        label: 'Project',
        labelId: 'project',
        value: -1,
    },
    id: -1,
    title: 'Project',
    label: 'Project',
    labelId: 'project',
    value: -1,
};
export const allMilestones = {
    id: null,
    title: 'All milestones',
    label: 'All milestones',
    labelId: 'allMilestones',
    value: null,
    __typename: 'Milestone',
};
export const addMilestone = {
    id: -1,
    title: 'Milestone',
    label: 'Milestone',
    labelId: 'milestone',
    value: -1,
};
export const noMilestone = {
    id: null,
    value: null,
    title: 'None',
    label: 'None',
    labelId: 'none',
    startsAt: null,
};
export const addUser = {
    id: -1,
    title: 'User',
    label: 'User',
    labelId: 'user',
    value: -1,
};
export const addCompany = {
    id: -1,
    title: 'Company',
    label: 'Company',
    labelId: 'company',
    value: -1,
};
