import React from 'react';

import { useQuery, useSubscription, useMutation } from '@apollo/client';
import SettingLoading from '../components/settingLoading';
import SettingListContainer from '../components/settingListContainer';
import PublicFilterAdd from './publicFilterAdd';
import PublicFilterEdit from './publicFilterEdit';

import { textIncluded, orderArr, toSelArr } from 'helperFunctions';
import { useTranslation } from 'react-i18next';

import {
    GET_BASIC_ROLES,
    ROLES_SUBSCRIPTION,
    GET_PUBLIC_FILTERS,
    FILTERS_SUBSCRIPTION,
    DELETE_FILTER,
} from 'helpdesk/queries';
import { setFilter, addLocalError } from 'apollo/localSchema/actions';
import { getEmptyGeneralFilter } from 'configs/constants/filter';

export default function PublicFilterList() {
    const { t } = useTranslation();

    const [deleteFilter] = useMutation(DELETE_FILTER);

    const {
        data: publicFiltersData,
        loading: publicFilterLoading,
        refetch: publicFilterRefetch,
    } = useQuery(GET_PUBLIC_FILTERS, {
        fetchPolicy: 'network-only',
    });

    useSubscription(FILTERS_SUBSCRIPTION, {
        onData: () => {
            publicFilterRefetch();
        },
    });

    const {
        data: rolesData,
        loading: rolesLoading,
        refetch: rolesRefetch,
    } = useQuery(GET_BASIC_ROLES, {
        fetchPolicy: 'network-only',
    });

    useSubscription(ROLES_SUBSCRIPTION, {
        onData: () => {
            rolesRefetch();
        },
    });

    // state
    const [search, setSearch] = React.useState('');
    const [roleFilter, setRoleFilter] = React.useState('all');
    const [editedItemId, setEditedItemId] = React.useState(null);

    const deleteFilterFunc = (id) => {
        if (window.confirm(t('generalConfirmation'))) {
            deleteFilter({
                variables: {
                    id,
                },
            })
                .then(() => {
                    setFilter(getEmptyGeneralFilter());
                })
                .catch((err) => {
                    addLocalError(err);
                });
        }
    };

    const dataLoading = publicFilterLoading || rolesLoading;

    if (dataLoading) {
        return <SettingLoading />;
    }

    const getFilteredFilters = () => {
        if (publicFilterLoading) {
            return [];
        }
        return publicFiltersData.publicFilters.filter(
            (filter) =>
                textIncluded(filter.title, search) &&
                (roleFilter === 'all' ||
                    (roleFilter === 'none' &&
                        (filter.roles === undefined || filter.roles.length === 0)) ||
                    (filter.roles !== undefined &&
                        filter.roles.some((role) => roleFilter === role.id)))
        );
    };

    const PublicFiltersSort = (
        <div className="d-flex flex-row align-items-center ml-auto">
            <div className="color-basic m-r-5 m-l-5">{t('sortBy')}</div>
            <select
                value={roleFilter}
                className="invisible-select font-bold text-highlight"
                onChange={(e) => setRoleFilter(e.target.value)}
            >
                <option value="all">{t('allFilters')}</option>
                {orderArr(toSelArr(rolesData.basicRoles)).map((role) => (
                    <option value={role.id} key={role.id}>
                        {role.title}
                    </option>
                ))}
                <option value="none">{t('withoutRole')}</option>
            </select>
        </div>
    );

    return (
        <SettingListContainer
            header={`${t('publicFilters')}`}
            addLabel={t('publicFilter2')}
            filter={search}
            setFilter={setSearch}
            RightFilterComponent={PublicFiltersSort}
            AddComponent={PublicFilterAdd}
            EditComponent={PublicFilterEdit}
            editedItemId={editedItemId}
            setEditedItemId={setEditedItemId}
        >
            <table className="table">
                <thead>
                    <tr>
                        <th>{t('title')}</th>
                        <th>{t('order')}</th>
                        <th width="100px">{t('actions')}</th>
                    </tr>
                </thead>
                <tbody>
                    {getFilteredFilters().map((filter) => (
                        <tr
                            key={filter.id}
                            style={{
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                            }}
                        >
                            <td
                                style={{
                                    maxWidth: '300px',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                }}
                            >
                                {filter.title}
                            </td>
                            <td
                                style={{
                                    maxWidth: '300px',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                }}
                            >
                                {filter.order}
                            </td>
                            <td className="row">
                                <button
                                    className="btn-link-red m-r-0 m-l-0 p-0"
                                    onClick={() => {
                                        deleteFilterFunc(filter.id);
                                    }}
                                >
                                    <span className="material-symbols-outlined">delete</span>
                                </button>
                                <button
                                    className="btn-link m-r-0 m-l-10 p-0"
                                    onClick={() => {
                                        setEditedItemId(filter.id);
                                    }}
                                >
                                    <span className="material-symbols-outlined">edit</span>
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </SettingListContainer>
    );
}
