import React from 'react';
import { useMutation, useQuery } from '@apollo/client';
import Loading from 'components/loading';
import FolderAdd from './add';
import { getMyData } from 'helperFunctions';
import {
    GET_LANWIKI_USERS,
    ADD_FOLDER,
} from 'lanwiki/queries';
import { GET_PROJECT } from 'apollo/queries';

export default function AddFolderLoader(props) {
    const currentUser = getMyData();

    const { data: projectData } = useQuery(GET_PROJECT);
    const localProject = projectData.localProject;

    const { data: usersData, loading: usersLoading } =
        useQuery(GET_LANWIKI_USERS, {
            variables: {
                projectId: localProject.project.id,
            },
            fetchPolicy: 'network-only',
        });
    const [addFolder] = useMutation(ADD_FOLDER);

    if (usersLoading) {
        return <Loading />;
    }

    return (
        <FolderAdd
            {...props}
            addFolder={addFolder}
            users={usersData.lanwikiUsers}
            currentUser={currentUser}
            projectId={localProject.project.id}
        />
    );
}
