import React from 'react';

import { useNavigate } from 'react-router-dom';
import Empty from 'components/Empty';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { useTranslation } from 'react-i18next';

export default function DeleteFolderModal(props) {
    const { saving, deleteFolder, id } = props;

    const navigate = useNavigate();

    const { t } = useTranslation();

    const [open, setOpen] = React.useState(false);
    const [deleting, setDeleting] = React.useState(false);

    const deleteFolderFunc = () => {
        setDeleting(true);
        deleteFolder({
            variables: {
                id,
            },
        })
            .then(() => {
                setDeleting(false);
                navigate(`/lanwiki/i/all`);
            })
            .catch((e) => {
                console.log(e);
                setDeleting(false);
            });
    };

    return (
        <Empty>
            <button
                className="btn-red ml-auto m-r-10 p-l-5 p-r-5"
                disabled={saving}
                onClick={() => setOpen(true)}
            >
                {`${t('delete')} ${t('subfolder').toLowerCase()}`}
            </button>
            {open && (
                <Modal isOpen={open} className="small-modal">
                    <ModalHeader>{`${t('delete')} ${t('subfolder').toLowerCase()}`}</ModalHeader>
                    <ModalBody>
                        <div className="p-20">
                            <div className="m-t-20 row">
                                <button className="btn-link-cancel" onClick={() => setOpen(false)}>
                                    {t('close')}
                                </button>
                                <button
                                    className="btn ml-auto"
                                    disabled={deleting}
                                    onClick={deleteFolderFunc}
                                >
                                    {!saving
                                        ? `${t('delete')} ${t('subfolder').toLowerCase()}`
                                        : `${t('deleting')} ${t('subfolder').toLowerCase()}`}
                                </button>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
            )}
        </Empty>
    );
}
