import React from 'react';

import { useNavigate } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';

import {
    Label,
    Input,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
} from 'reactstrap';
/*
import {
  getMyData,
} from 'helperFunctions';
*/
import {
    setFilter,
    addLocalError,
} from 'apollo/localSchema/actions';

import { GET_FILTER } from 'apollo/queries';

import {
    ADD_FILTER,
    UPDATE_FILTER,
} from 'helpdesk/queries';

import { useTranslation } from 'react-i18next';

export default function FilterAdd(props) {
    //data & queries
    const { filter, projectId, addTitle, close } = props;

    const navigate = useNavigate();
    //queries

    const { t } = useTranslation();

    const [addFilter] = useMutation(ADD_FILTER);

    const { data: filterData } = useQuery(GET_FILTER);

    const [updateFilter] = useMutation(UPDATE_FILTER);
    const id = filterData.localFilter.id;

    //state
    const [title, setTitle] = React.useState(
        id ? filterData.localFilter.title : ''
    );

    const [saving, setSaving] = React.useState(false);
    const [opened, setOpened] = React.useState(false);

    React.useEffect(() => {
        let filter = filterData.localFilter;
        setTitle(id ? filter.title : '');
    }, [id]);

    //const currentUser = getMyData();

    //const canCreatePublicFilters = currentUser.role.accessRights.publicFilters;
    //const canCreateCustomFilters = currentUser.role.accessRights.customFilters;

    const addFilterFunc = () => {
        setSaving(true);
        if (id === null || id === undefined) {
            addFilter({
                variables: {
                    title,
                    dashboard: projectId === null,
                    projectId,
                    filter,
                },
            })
                .then((response) => {
                    setFilter({
                        ...response.data.addFilter,
                        __typename: 'BasicFilter',
                    });
                    navigate(
                        `/helpdesk/taskList/i/${response.data.addFilter.id}`
                    );
                })
                .catch((err) => {
                    addLocalError(err);
                });
        } else {
            updateFilter({
                variables: {
                    id,
                    title,
                    dashboard: projectId === null,
                    projectId,
                    filter,
                },
            })
                .then((response) => {
                    setFilter({
                        ...response.data.updateFilter,
                        __typename: 'BasicFilter',
                    });
                })
                .catch((err) => {
                    addLocalError(err);
                });
        }
        close();
        setOpened(false);
        setSaving(false);
    };

    return (
        <div className="filter-add-btn">
            <button
                className="btn-link"
                disabled={projectId === null}
                onClick={() => setOpened(!opened)}
            >
                <span className="material-symbols-outlined">
                    save
                </span>
            </button>

            <Modal
                style={{ width: '800px' }}
                isOpen={opened}
            >
                <ModalHeader>
                    {`${
                        projectId
                            ? t('addFilterToProject')
                            : t('addGeneralFilter')
                    } - ${addTitle}`}
                </ModalHeader>
                <ModalBody>
                    {/* Filter name */}
                    <Label>{t('filterTitle')}</Label>
                    <Input
                        type="text"
                        className="from-control m-t-5 m-b-5"
                        placeholder={t(
                            'filterTitlePlaceholder'
                        )}
                        autoFocus
                        value={title}
                        onChange={(e) => {
                            setTitle(e.target.value);
                        }}
                    />
                </ModalBody>
                <ModalFooter>
                    <button
                        className="mr-auto btn-link"
                        disabled={saving}
                        onClick={() => setOpened(!opened)}
                    >
                        {t('close')}
                    </button>

                    <button
                        className="btn"
                        disabled={
                            saving ||
                            title === '' ||
                            /*!global && !dashboard && */ !projectId
                        }
                        onClick={addFilterFunc}
                    >
                        {id !== null
                            ? saving
                                ? `${saving}...`
                                : t('saveFilter')
                            : saving
                            ? `${t('adding')}...`
                            : t('addFilter')}
                    </button>
                </ModalFooter>
            </Modal>
        </div>
    );
}
