import React, { useEffect } from 'react';
import { FormGroup, Label, Input, InputGroup, Button } from 'reactstrap';

import Empty from 'components/Empty';
import EditPasswordErrors from './showErrors';
import Textarea from 'react-textarea-autosize';
import DatePicker from 'components/DatePicker';
import classnames from 'classnames';

import { timestampToStringFNS, toSelItem, getMyData, fromUnixToDate } from 'helperFunctions';
import { useTranslation } from 'react-i18next';

export default function PasswordForm(props) {
    const {
        folderId,
        close,
        savePassword,
        disabled,
        passwordData,
        allFolders,
        canDeletePassword,
        deletePasswordFunc,
    } = props;

    const { t } = useTranslation();

    const currentUser = getMyData();

    const potentialFolder = allFolders.find((f) => f.id === folderId);
    const [title, setTitle] = React.useState(passwordData ? passwordData.title : '');
    const [login, setLogin] = React.useState(passwordData ? passwordData.login : '');
    const [, setFolder] = React.useState(
        folderId && potentialFolder ? toSelItem(potentialFolder) : null
    );
    const [password, setPassword] = React.useState(passwordData ? passwordData.password : '');
    const [passwordCheck, setPasswordCheck] = React.useState(
        passwordData ? passwordData.password : ''
    );
    const [url, setUrl] = React.useState(passwordData ? passwordData.url : '');
    const [expireDate, setExpireDate] = React.useState(
        passwordData && passwordData.expireDate ? fromUnixToDate(passwordData.expireDate) : null
    );
    const [note, setNote] = React.useState(passwordData ? passwordData.note : '');
    const [isPrivate] = React.useState(passwordData ? passwordData.isPrivate : false);

    const [updatedAt, setUpdatedAt] = React.useState(passwordData ? passwordData.updatedAt : '');
    const [updatedBy, setUpdatedBy] = React.useState(passwordData ? passwordData.updatedBy : '');
    const [showPassword, setShowPassword] = React.useState(false);
    const [viewPassword, setViewPassword] = React.useState(false);
    const [showPasswordCheck, setShowPasswordCheck] = React.useState(false);
    const [showErrors, setShowErrors] = React.useState(false);
    const [saving, setSaving] = React.useState(false);

    const [wasCopied, setWasCopied] = React.useState(false);

    useEffect(() => {
        const potentialFolder = allFolders.find((f) => f.id === folderId);
        if (folderId && potentialFolder) {
            setFolder(toSelItem(potentialFolder));
        }
    }, [folderId, allFolders]);

    const cannotSave = () => {
        return saving || title.length === 0 || password !== passwordCheck;
    };

    const saveOrAddPassword = () => {
        if (disabled) {
            return;
        }
        if (cannotSave()) {
            setShowErrors(true);
        } else {
            let data = {
                title,
                login,
                password,
                url,
                expireDate: expireDate ? expireDate.valueOf() + '' : null,
                note,
                isPrivate,
            };
            setUpdatedAt(new Date().valueOf());
            setUpdatedBy({
                ...currentUser,
            });
            savePassword(
                {
                    ...data,
                    id: passwordData.id,
                },
                setSaving,
                close
            );
        }
    };

    return (
        <Empty>
            <div
                className={classnames(
                    {
                        'p-b-20': disabled,
                    },
                    'fit-with-header-and-lanwiki-commandbar p-t-20 p-l-20 p-r-20 p-b-0'
                )}
                style={{ backgroundColor: '#f2f1f1' }}
            >
                <FormGroup>
                    <Label htmlFor="title">{t('title')}</Label>
                    {disabled && <div>{title.length === 0 ? t('noTitle') : title}</div>}
                    {!disabled && (
                        <Input
                            id="title"
                            className="form-control"
                            placeholder={t('titlePlaceholder')}
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                        />
                    )}
                </FormGroup>

                <FormGroup>
                    <Label htmlFor="login">{t('login2')}</Label>
                    {disabled && <div>{login.length === 0 ? t('noLogin') : login}</div>}
                    {!disabled && (
                        <Input
                            id="login"
                            className="form-control"
                            autoComplete="new-password"
                            placeholder={t('login2Placeholder')}
                            value={login}
                            onChange={(e) => setLogin(e.target.value)}
                        />
                    )}
                </FormGroup>

                <FormGroup>
                    <div className="row">
                        <Label htmlFor="password" className="mr-auto">
                            {t('password')}
                        </Label>
                        {wasCopied && <span className="inline-success-message">{t('copied')}</span>}

                        <button
                            className="btn-link m-r-5"
                            style={{
                                height: 'fit-content',
                            }}
                            onClick={() => {
                                navigator.clipboard.writeText(password);
                                setWasCopied(true);
                                setTimeout(() => {
                                    setWasCopied(false);
                                }, 3000);
                            }}
                        >
                            <span className="material-symbols-outlined">content_copy</span>
                        </button>
                        {disabled && (
                            <div>
                                <button
                                    className="btn-link m-r-5"
                                    style={{
                                        height: 'fit-content',
                                    }}
                                    onClick={() => setViewPassword(!viewPassword)}
                                >
                                    <span className="material-symbols-outlined p-r-0">
                                        {!viewPassword ? 'visibility' : 'visibility_off'}
                                    </span>
                                </button>
                            </div>
                        )}
                    </div>

                    {viewPassword && (
                        <span className="p-r-10">
                            {password.length === 0 ? t('noPassword') : password}
                        </span>
                    )}
                    {!viewPassword && disabled && (
                        <span className="p-r-10">
                            {password.length === 0 ? t('noPassword') : '**************'}
                        </span>
                    )}

                    {!disabled && (
                        <InputGroup>
                            <Input
                                type={showPassword ? 'text' : 'password'}
                                autoComplete="new-password"
                                className="from-control"
                                placeholder={t('passwordPlaceholder')}
                                value={password}
                                id="password"
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            <Button
                                className="clickable"
                                onClick={() => setShowPassword(!showPassword)}
                            >
                                <span className="material-symbols-outlined p-r-0">
                                    {!showPassword ? 'visibility' : 'visibility_off'}
                                </span>
                            </Button>
                        </InputGroup>
                    )}
                </FormGroup>

                {!disabled && (
                    <FormGroup>
                        <Label htmlFor="passwordCheck">{t('passwordCheck')}</Label>
                        <InputGroup>
                            <Input
                                type={showPasswordCheck ? 'text' : 'password'}
                                className="from-control"
                                placeholder={t('passwordCheckPlaceholder')}
                                value={passwordCheck}
                                id="passwordCheck"
                                onChange={(e) => setPasswordCheck(e.target.value)}
                            />
                            <Button
                                className="clickable"
                                onClick={() => setShowPasswordCheck(!showPasswordCheck)}
                            >
                                <span className="material-symbols-outlined p-r-0">
                                    {!showPasswordCheck ? 'visibility' : 'visibility_off'}
                                </span>
                            </Button>
                        </InputGroup>
                    </FormGroup>
                )}

                <FormGroup>
                    <div className="row">
                        <Label htmlFor="url">{t('url')}</Label>
                        {disabled && url.length > 0 && (
                            <div className="m-l-auto">
                                <button
                                    className="btn-link m-r-5"
                                    style={{
                                        height: 'fit-content',
                                    }}
                                    onClick={() => {
                                        window.open('//' + url, '_blank');
                                    }}
                                >
                                    <span className="material-symbols-outlined p-r-0">
                                        open_in_new
                                    </span>
                                </button>
                            </div>
                        )}
                    </div>
                    {disabled && (
                        <div>
                            {url.length === 0 ? (
                                t('noUrl')
                            ) : (
                                <a href={`${url}`} target="_blank" rel="noopener noreferrer">
                                    {url}
                                </a>
                            )}
                        </div>
                    )}
                    {!disabled && (
                        <Input
                            id="url"
                            className="form-control"
                            placeholder={t('urlPlaceholder')}
                            value={url}
                            onChange={(e) => setUrl(e.target.value)}
                        />
                    )}
                </FormGroup>

                <FormGroup>
                    <Label htmlFor="expireDate">{t('expireDate')}</Label>
                    {disabled && (
                        <div className="disabled-info">
                            {expireDate
                                ? timestampToStringFNS(expireDate.valueOf())
                                : t('noExpireDate')}
                        </div>
                    )}
                    {!disabled && (
                        <div className="flex-input">
                            <DatePicker
                                className={classnames('form-control')}
                                selected={expireDate}
                                hideTime
                                isClearable
                                onChange={(date) => {
                                    setExpireDate(isNaN(date.valueOf()) ? null : date);
                                }}
                                placeholderText={t('expireDatePlaceholder')}
                            />
                        </div>
                    )}
                </FormGroup>

                <FormGroup>
                    <Label htmlFor="content">{t('note')}</Label>
                    {disabled && (
                        <FormGroup>
                            <div>{note}</div>
                        </FormGroup>
                    )}
                    {!disabled && (
                        <Textarea
                            className="form-control"
                            id="descriptionLabel"
                            placeholder={t('enterNote')}
                            minRows={4}
                            value={note}
                            onChange={(e) => setNote(e.target.value)}
                        />
                    )}
                </FormGroup>

                {disabled && (
                    <FormGroup>
                        <Label>
                            {isPrivate ? (
                                <div>
                                    <span className="material-symbols-outlined">lock_person</span>
                                    <span>{t('passwordIsPrivate')}</span>
                                </div>
                            ) : (
                                t('passwordIsPublic')
                            )}
                        </Label>
                    </FormGroup>
                )}

                <EditPasswordErrors
                    title={title}
                    login={login}
                    password={password}
                    passwordCheck={passwordCheck}
                    show={showErrors}
                />

                <FormGroup>
                    <Label>{t('createdBy')}</Label>
                    <div>{passwordData.createdBy ? `${passwordData.createdBy.fullName}` : ''}</div>
                </FormGroup>

                <FormGroup>
                    <Label>{t('createdDate')}</Label>
                    <div>
                        {passwordData.createdAt ? timestampToStringFNS(passwordData.createdAt) : ''}
                    </div>
                </FormGroup>

                <FormGroup>
                    <Label>{t('changedBy')}</Label>
                    <div>{updatedBy ? `${updatedBy.fullName}` : ''}</div>
                </FormGroup>

                <FormGroup>
                    <Label>{t('changedAt')}</Label>
                    <div>{updatedAt ? timestampToStringFNS(updatedAt) : ''}</div>
                </FormGroup>

                {canDeletePassword && disabled && (
                    <button
                        className="btn-link-red p-l-5 p-r-5 btn-distance center-hor"
                        onClick={deletePasswordFunc}
                    >
                        <span className="material-symbols-outlined">delete</span>
                        {t('delete')}
                    </button>
                )}
            </div>

            {!disabled && (
                <div className="button-bar row stick-to-bottom">
                    <div className="center-ver row">
                        <div>
                            <button
                                className="btn-link-red p-l-5 p-r-5 btn-distance center-hor"
                                onClick={close}
                            >
                                <span className="material-symbols-outlined">cancel</span>
                                {t('cancel')}
                            </button>
                        </div>
                        <div>
                            <button
                                className="btn btn-distance center-hor"
                                disabled={cannotSave() && showErrors}
                                onClick={saveOrAddPassword}
                            >
                                <span className="material-symbols-outlined">save</span>
                                {saving ? `${t('saving')}...` : `${t('save')}`}
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </Empty>
    );
}
