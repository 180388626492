import { gql } from '@apollo/client';

const folderRights = `
folderRights{
  read
  write
  manage
  user{
    id
    email
    fullName
  }
}
`;

const myRights = `
myRights {
  read
  write
  manage
}
`;

const folder = `
id
title
order
description
${folderRights}
parentFolder{
  id
  title
  ${folderRights}
  ${myRights}
}
${myRights}
`;

export const GET_FOLDERS = gql`
    query passFolders(
      $projectId: Int!
    ){
        passFolders (
          projectId: $projectId
        ) {
          ${folder}
          childrenFolders{
            ${folder}
          }
        }
    }
`;

export const GET_FOLDER = gql`
  query passFolder(
    $id: Int!
  ){
    passFolder (
      id: $id
    ){
      ${folder}
      childrenFolders{
        ${folder}
      }
    }
  }
`;

export const GET_PASS_USERS = gql`
    query passUsers($projectId: Int!) {
        passUsers(projectId: $projectId) {
            id
            email
            fullName
            role {
                accessRights {
                    pass
                }
            }
        }
    }
`;

export const ADD_FOLDER = gql`
    mutation addPassFolder(
        $title: String!
        $order: Int!
        $description: String!
        $folderRights: [PassFolderRightInput]!
        $parentFolderId: Int
        $projectId: Int
    ) {
        addPassFolder(
            title: $title
            order: $order
            description: $description
            folderRights: $folderRights
            parentFolderId: $parentFolderId
            projectId: $projectId
        ) {
            id
        }
    }
`;

export const UPDATE_FOLDER = gql`
    mutation updatePassFolder(
        $id: Int!
        $title: String!
        $order: Int!
        $description: String!
        $folderRights: [PassFolderRightInput]!
    ) {
        updatePassFolder(
            id: $id
            title: $title
            order: $order
            description: $description
            folderRights: $folderRights
        ) {
            id
        }
    }
`;

export const DELETE_FOLDER = gql`
    mutation deletePassFolder($id: Int!, $newId: Int) {
        deletePassFolder(id: $id, newId: $newId) {
            id
        }
    }
`;

export const FOLDERS_SUBSCRIPTION = gql`
    subscription passFolderSubscription {
        passFolderSubscription
    }
`;
