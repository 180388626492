export default {
  email: {
    en: 'E-mail',
    sk: 'E-mail',
    cz: 'E-mail',
    de: 'E-mail',
  },
  emailPlaceholder: {
    en: 'Enter e-mail',
    sk: 'Zadajte e-mail',
    cz: 'Zadejte e-mail',
    de: 'Email eingeben',
  },
  password: {
    en: 'Password',
    sk: 'Heslo',
    cz: 'Heslo',
    de: 'Passwort',
  },
  passwordPlaceholder: {
    en: 'Enter password',
    sk: 'Zadajte heslo',
    cz: 'Zadejte heslo',
    de: 'Passwort eingeben',
  },
  user: {
    en: 'User',
    sk: 'Používateľ',
    cz: 'Uživatel',
    de: 'Benutzer',
  },
  subject: {
    en: 'Subject',
    sk: 'Predmet',
    cz: 'Předmět',
    de: 'Thema',
  },
  body: {
    en: 'Body',
    sk: 'Telo správy',
    cz: 'Tělo zprávy',
    de: 'Körper',
  },
  statusAction: {
    en: 'Status action',
    sk: 'Akcia statusu',
    cz: 'Stavová akce',
    de: 'Statusaktion',
  },
  statusActionLabel: {
    en: 'Select status action',
    sk: 'Vyberte akciu statusu',
    cz: 'Vyberte akci stavu',
    de: 'Statusaktion auswählen',
  },
  workDescription: {
    en: 'Work description',
    sk: 'Popis práce',
    cz: 'Work description',
    de: 'Work description',
  },
  hours: {
    en: 'Hours',
    sk: 'Hodiny',
    cz: 'Hodiny',
    de: 'Der Dienststunden',
  },
  unit: {
    en: 'Unit',
    sk: 'Jednotka',
    cz: 'Jednotka',
    de: 'Einheit',
  },
  rolesLabel: {
    en: 'Choose roles',
    sk: 'Vyberte role',
    cz: 'Vyberte role',
    de: 'Rollen auswählen',
  },
  selectReccomended: {
    en: 'Select reccomended',
    sk: 'Výber odporúčaný',
    cz: 'Vyberte doporučeno',
    de: 'Wählen Sie empfohlen aus',
  },
  description: {
    en: 'Description',
    sk: 'Popis',
    cz: 'Popis',
    de: 'Beschreibung',
  },
  enter: {
    en: 'Enter',
    sk: 'Zadajte',
    cz: 'Zadejte',
    de: 'Eintreten',
  },
  color: {
    en: 'Color',
    sk: 'Farba',
    cz: 'Farba',
    de: 'Farbe',
  },
  actions: {
    en: 'Actions',
    sk: 'Akcie',
    cz: 'Akce',
    de: 'Actions',
  },
}
