export const columnsToShowTrips = [
    'id',
    'title',
    'requester',
    'assignedTo',
    'closeDate',
    'tripType',
    'quantity',
];

export const columnsToShowWorks = [
    'id',
    'title',
    'requester',
    'assignedTo',
    'closeDate',
    'taskType',
    'quantity',
];

export const columnsToShowMaterials = [
    'id',
    'title',
    'requester',
    'assignedTo',
    'closeDate',
    'material',
    'quantity',
    'pricePerQuantity',
    'total',
];
