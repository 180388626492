import React from 'react';

import { FormGroup, Label } from 'reactstrap';
import SettingsInput from '../components/settingsInput';
import SettingsTextarea from '../components/settingsTextarea';
import SettingsHiddenInput from '../components/settingsHiddenInput';
import Checkbox from 'components/checkbox';
import Select from 'react-select';
import Creatable from 'react-select/creatable';

import { filterUnique } from 'helperFunctions';
import { pickSelectStyle } from 'configs/components/select';

import { useTranslation } from 'react-i18next';

export default function IMAPAdd(props) {
    const {
        onSave,
        onClose,
        onTestImap,
        tested,
        saving,
        roles,
        projects,
        companies,
        label,
        newData,
        setNewData,
        imap,
    } = props;

    const { t } = useTranslation();

    //state
    const [title, setTitle] = React.useState('');
    const [order, setOrder] = React.useState(0);
    const [host, setHost] = React.useState('');
    const [port, setPort] = React.useState(465);
    const [username, setUsername] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [rejectUnauthorized, setRejectUnauthorized] = React.useState(false);
    const [tls, setTls] = React.useState(true);
    const [active, setActive] = React.useState(true);
    const [destination, setDestination] = React.useState('');
    const [ignoredRecievers, setIgnoredRecievers] = React.useState([]);
    const [previousIgnoredRecievers, setPreviousIgnoredRecievers] = React.useState([]);
    const [ignoredRecieversDestination, setIgnoredRecieversDestination] = React.useState('');
    const [role, setRole] = React.useState(null);
    const [company, setCompany] = React.useState(null);
    const [project, setProject] = React.useState(null);
    const [taskCreationSubject, setTaskCreationSubject] = React.useState(
        t('defaultTaskCreationSubject')
    );
    const [taskCreationMessage, setTaskCreationMessage] = React.useState(
        t('defaultTaskCreationMessage')
    );

    React.useEffect(() => {
        if (imap) {
            setTitle(imap.title);
            setOrder(imap.order);
            setHost(imap.host);
            setPort(imap.port);
            setUsername(imap.username);
            setPassword(imap.password);
            setRejectUnauthorized(imap.rejectUnauthorized);
            setTls(imap.tls);
            setActive(imap.active);
            setDestination(imap.destination);
            setIgnoredRecievers(
                imap.ignoredRecievers.split(' ').map((item) => ({
                    label: item,
                    value: item,
                }))
            );
            setPreviousIgnoredRecievers(
                imap.ignoredRecievers.split(' ').map((item) => ({
                    label: item,
                    value: item,
                }))
            );
            setIgnoredRecieversDestination(imap.ignoredRecieversDestination);
            setRole(roles.find((role) => role.id === imap.role.id));
            setCompany(companies.find((company) => company.id === imap.company.id));
            setProject(projects.find((project) => project.id === imap.project.id));
            setTaskCreationMessage(imap.taskCreationMessage);
            setTaskCreationSubject(imap.taskCreationSubject);
        }
    }, [imap]);

    const cannotSave = () =>
        saving ||
        title === '' ||
        host === '' ||
        port === '' ||
        username === '' ||
        role === null ||
        company === null ||
        project === null ||
        ignoredRecieversDestination === '' ||
        taskCreationMessage === '' ||
        taskCreationSubject === '' ||
        destination === '';

    return (
        <div className="p-t-20 p-b-20">
            <h2 className="m-b-20">{label}</h2>

            <Checkbox
                className="m-b-5 p-l-0"
                value={active}
                onChange={() => {
                    setActive(!active);
                    setNewData(true);
                }}
                label={t('active')}
            />

            <SettingsInput
                required
                label={t('title')}
                id="title"
                value={title}
                onChange={(e) => {
                    setTitle(e.target.value);
                    setNewData(true);
                }}
            />

            <SettingsInput
                label={t('order')}
                id="order"
                value={order}
                onChange={(e) => {
                    setOrder(e.target.value);
                    setNewData(true);
                }}
            />

            <SettingsInput
                required
                label={t('host')}
                id="host"
                value={host}
                onChange={(e) => {
                    setHost(e.target.value);
                    setNewData(true);
                }}
            />

            <SettingsInput
                required
                label={t('port')}
                id="port"
                type="number"
                value={port}
                onChange={(e) => {
                    setPort(e.target.value);
                    setNewData(true);
                }}
            />

            <SettingsInput
                required
                label={t('username')}
                id="username"
                value={username}
                onChange={(e) => {
                    setUsername(e.target.value);
                    setNewData(true);
                }}
            />

            <SettingsHiddenInput
                required
                label={t('password')}
                id="password"
                value={password}
                onChange={(e) => {
                    setPassword(e.target.value);
                    setNewData(true);
                }}
            />

            <Checkbox
                className="m-b-5 p-l-0"
                value={tls}
                onChange={() => {
                    setTls(!tls);
                    setNewData(true);
                }}
                label={t('tls')}
            />

            <SettingsInput
                required
                label={t('destination')}
                id="destination"
                value={destination}
                onChange={(e) => {
                    setDestination(e.target.value);
                    setNewData(true);
                }}
            />

            <FormGroup>
                <Label for="ignoredRecievers">{t('ignoredRecievers')}</Label>
                <Creatable
                    isMulti
                    value={ignoredRecievers}
                    onChange={(ignoredRecievers) => {
                        setIgnoredRecievers(ignoredRecievers);
                        setPreviousIgnoredRecievers(
                            filterUnique([...ignoredRecievers, ...previousIgnoredRecievers])
                        );
                        setNewData(true);
                    }}
                    options={previousIgnoredRecievers}
                    styles={pickSelectStyle()}
                />
            </FormGroup>

            <SettingsInput
                required
                label={t('destination')}
                id="ignoredRecieversDestination"
                placeholder={t('ignoredDestinationPlaceholder')}
                value={ignoredRecieversDestination}
                onChange={(e) => {
                    setIgnoredRecieversDestination(e.target.value);
                    setNewData(true);
                }}
            />

            <Checkbox
                className="m-b-5 p-l-0"
                value={rejectUnauthorized}
                onChange={() => {
                    setRejectUnauthorized(!rejectUnauthorized);
                    setNewData(true);
                }}
                label={t('rejectUnauthorized')}
            />

            <FormGroup>
                <Label for="role">
                    {t('usersRole')}
                    <span className="warning-big">*</span>
                </Label>
                <Select
                    styles={pickSelectStyle()}
                    options={roles}
                    value={role}
                    onChange={(role) => {
                        setRole(role);
                        setNewData(true);
                    }}
                />
            </FormGroup>

            <FormGroup>
                <Label for="project">
                    {t('usersCompany')}
                    <span className="warning-big">*</span>
                </Label>
                <Select
                    styles={pickSelectStyle()}
                    options={companies}
                    value={company}
                    onChange={(company) => {
                        setCompany(company);
                        setNewData(true);
                    }}
                />
            </FormGroup>

            <FormGroup>
                <Label for="project">
                    {t('tasksProject')}
                    <span className="warning-big">*</span>
                </Label>
                <Select
                    styles={pickSelectStyle()}
                    options={projects}
                    value={project}
                    onChange={(project) => {
                        setProject(project);
                        setNewData(true);
                    }}
                />
            </FormGroup>

            <SettingsInput
                required
                label={t('taskCreationSubject')}
                id="taskCreationSubject"
                value={taskCreationSubject}
                onChange={(e) => {
                    setTaskCreationSubject(e.target.value);
                    setNewData(true);
                }}
            />

            <SettingsTextarea
                required
                label={t('taskCreationMessage')}
                id="taskCreationMessage"
                inputClassName="form-control"
                value={taskCreationMessage}
                onChange={(e) => {
                    setTaskCreationMessage(e.target.value);
                    setNewData(true);
                }}
            />

            <span className="message">{t('availableTaskCreationMessageTags')}</span>

            <div className="form-buttons-row">
                {onClose && (
                    <button className="btn-link" onClick={onClose}>
                        {t('cancel')}
                    </button>
                )}

                <div className="ml-auto message m-r-10 row">
                    {cannotSave() && (
                        <div className="message error-message ml-auto m-r-14">
                            {t('fillAllRequiredInformation')}
                        </div>
                    )}
                    {newData && (
                        <div className="message error-message">{t('saveBeforeLeaving')}</div>
                    )}
                    {!newData && imap && (
                        <div className="message success-message">{t('saved')}</div>
                    )}
                </div>

                {onTestImap && (
                    <button
                        className="btn btn-distance"
                        disabled={saving || tested}
                        onClick={onTestImap}
                    >
                        {t('testImap')}
                    </button>
                )}

                <button
                    className="btn"
                    disabled={cannotSave()}
                    onClick={() => {
                        const data = {
                            active,
                            title,
                            order: order !== '' ? parseInt(order) : 0,
                            host,
                            port: port !== '' ? parseInt(port) : 465,
                            username,
                            password,
                            rejectUnauthorized,
                            tls,
                            destination,
                            ignoredRecievers: ignoredRecievers.map((item) => item.label).join(' '),
                            ignoredRecieversDestination,
                            projectId: project.id,
                            roleId: role.id,
                            companyId: company.id,
                            taskCreationSubject,
                            taskCreationMessage,
                        };
                        onSave(data);
                    }}
                >
                    {saving ? `${t('saving')} ${t('imap')}...` : `${t('save')} ${t('imap')}`}
                </button>
            </div>
        </div>
    );
}
