import React from 'react';
import ReactDOM from 'react-dom/client';

import Navigation from 'navigation';
import Login from 'components/login';
import initializeTranslations from 'configs/translations';
import 'react-datepicker/dist/react-datepicker.css';
import { setDefaultLocale, registerLocale } from 'react-datepicker';
import { ApolloProvider, useQuery } from '@apollo/client';
import createClient from 'apollo/createClient';
import sk from 'date-fns/locale/sk';
import { GET_IS_LOGGED_IN } from 'apollo/queries';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-material-symbols/dist/outlined.css';
import 'scss/index.scss';

initializeTranslations();
registerLocale('sk', sk);
setDefaultLocale('sk');

const root = ReactDOM.createRoot(document.getElementById('root'));

const client = createClient();
const IsLoggedIn = () => {
    const { data } = useQuery(GET_IS_LOGGED_IN);
    return data.isLoggedIn ? <Navigation /> : <Login />;
};

root.render(
    <ApolloProvider client={client}>
        <IsLoggedIn />
    </ApolloProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//import reportWebVitals from './reportWebVitals';
//reportWebVitals();
