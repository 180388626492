import axios from 'axios';
import { REST_URL } from 'configs/restAPI';

export const base64ToImg = (dataurl, filename) => {
    const arr = dataurl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    let u8arr = new Uint8Array(n);

    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], `${filename}.${mime.substring(mime.indexOf('/') + 1, mime.length)}`, {
        type: mime,
    });
};

async function insertImage(image, type) {
    try {
        let blob = await axios.get(`${REST_URL}/api/get-attachments`, {
            params: {
                type,
                path: image.path,
                fronInvoice: false,
            },
            headers: {
                'authorization': `${sessionStorage.getItem('acctok')}`,
            },
            responseType: 'blob',
        });
        return URL.createObjectURL(blob.data);
    } catch (error) {
        console.log(error);
    }
}

export async function insertImages(originalHtml, textAttachments, setData, type) {
    let newHtml = originalHtml + '';

    for (const image of textAttachments) {
        const imageSource = await insertImage(image, type);
        newHtml = newHtml.replace(`cid:${image.textId}`, imageSource);
    }

    setData(newHtml);
}
