import React from 'react';
import { useLazyQuery } from '@apollo/client';
import Excel from 'exceljs';
import FileSaver from 'file-saver';
import * as fns from 'date-fns';

import { formatDate } from 'helperFunctions';
import { AGENT_INVOICE } from 'invoices/queries';

export default function AgentExcel(props) {
    const { variables, agent } = props;
    const fromDate = new Date(parseInt(variables.fromDate));
    const toDate = new Date(parseInt(variables.toDate));

    const [fetchInvoice, { data: invoiceData, loading: invoiceLoading }] = useLazyQuery(
        AGENT_INVOICE,
        {
            onCompleted: () => {
                downloadExcel(invoiceData.agentInvoice);
            },
        }
    );

    const downloadExcel = async (invoice) => {
        const filename = `Výkaz ${agent.user.fullName} ${fns.getYear(fromDate)}/${fns.getMonth(
            fromDate
        )}`;

        const h1 = {
            bold: true,
            size: 16,
        };
        const h2 = {
            bold: true,
            size: 14,
        };
        const h3 = {
            bold: true,
        };

        const ws = new Excel.Workbook();
        const sheet = ws.addWorksheet('Vykaz');
        const widthMultiplier = 5;
        sheet.columns = [
            {
                width: 11 * widthMultiplier,
            },
            {
                width: 8 * widthMultiplier,
            },
            {
                width: 4 * widthMultiplier,
            },
            {
                width: 4 * widthMultiplier,
            },
            {
                width: 3 * widthMultiplier,
            },
            {
                width: 10 * widthMultiplier,
            },
            {
                width: 4 * widthMultiplier,
            },
            {
                width: 3 * widthMultiplier,
            },
            {
                width: 3 * widthMultiplier,
            },
            {
                width: 3 * widthMultiplier,
            },
        ];

        sheet.addRows([
            ['Mesačný výkaz agenta'],
            [`Agent ${agent.user.fullName}(${agent.user.email})`],
            [`Obdobie od ${formatDate(fromDate)} do: ${formatDate(toDate)}`],
            [],
            ['Práce'],
            ['ID', 'Názov úlohy', 'Zadal', 'Firma', 'Close date', 'Popis práce', 'Hodiny'],
        ]);
        setMultipleCellsStyle(['A1', 'A6'], h1, sheet);
        setMultipleCellsStyle(['A5'], h2, sheet);
        setMultipleCellsStyle(['A6', 'B6', 'C6', 'D6', 'E6', 'F6', 'G6', 'H6', 'I6'], h3, sheet);

        //Prace
        invoice.workTasks.forEach((task) => {
            sheet.addRows(
                task.subtasks.map((subtask, index) => {
                    if (index === 0) {
                        return [
                            task.id,
                            task.title,
                            task.requester.fullName,
                            task.company.title,
                            formatDate(task.closeDate),
                            subtask.title,
                            subtask.quantity,
                        ];
                    }
                    let row = [];
                    row[6] = subtask.title;
                    row[7] = subtask.quantity;
                    return row;
                })
            );
        });
        sheet.addRow([]);
        sheet.addRow(['Typ', 'Počet hodín']);
        invoice.taskTypeTotals.forEach((total) => {
            sheet.addRow([total.title, total.quantity]);
        });
        sheet.addRow(['Spolu počet hodín:', invoice.totals.workHours]);
        setLastRowStyle(['A', 'B'], sheet, h3);
        //Koniec Prace
        sheet.addRow([]);

        //Zaciatok vyjazdov
        sheet.addRow(['Výjazdy']);
        setLastRowStyle(['A'], sheet, h1);
        sheet.addRow(['ID', 'Názov úlohy', 'Zadal', 'Rieši', 'Close date', 'Výjazd', 'Mn.']);
        setLastRowStyle(['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H'], sheet, h3);
        invoice.tripTasks.forEach((task) => {
            sheet.addRows(
                task.workTrips.map((trip, index) => {
                    if (index === 0) {
                        return [
                            task.id,
                            task.title,
                            task.requester.fullName,
                            task.company.title,
                            formatDate(parseInt(task.closeDate)),
                            trip.type.title,
                            trip.quantity,
                        ];
                    }
                    let row = [];
                    row[6] = trip.type.title;
                    row[7] = trip.quantity;
                    return row;
                })
            );
        });
        sheet.addRow([]);
        sheet.addRow(['Typ', 'ks']);
        invoice.tripTypeTotals.forEach((total) => {
            sheet.addRow([total.title, total.quantity]);
        });
        sheet.addRow(['Spolu počet výjazdov:', invoice.totals.tripHours]);
        setLastRowStyle(['A', 'B'], sheet, h3);
        //Koniec vyjazdov
        sheet.addRow([]);

        //STIAHNUTE
        ws.xlsx
            .writeBuffer()
            .then((buffer) => FileSaver.saveAs(new Blob([buffer]), `${filename}.xlsx`));
    };

    const setLastRowStyle = (range, sheet, style) => {
        range.forEach((letter) => {
            sheet.getCell(`${letter}${sheet.rowCount}`).font = style;
        });
    };

    /*
    const mergerLastRow = (range, sheet) => {
        sheet.mergeCells(`${range[0]}${sheet.rowCount}:${range[1]}${sheet.rowCount}`);
    };
    */

    const setMultipleCellsStyle = (cells, style, sheet) => {
        cells.forEach((cell) => {
            sheet.getCell(`${cell}`).font = style;
        });
    };

    return (
        <button
            className="btn-link"
            disabled={invoiceLoading}
            onClick={() => {
                if (invoiceData && !invoiceLoading) {
                    downloadExcel(invoiceData.agentInvoice);
                } else {
                    fetchInvoice({ variables });
                }
            }}
        >
            Excel
        </button>
    );
}
