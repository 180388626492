import React from 'react';
import FolderEditLoader from './index';
import Empty from 'components/Empty';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { useTranslation } from 'react-i18next';

export default function FolderEditModal(props) {
    const { className } = props;
    const { t } = useTranslation();
    const [open, setOpen] = React.useState(false);

    return (
        <Empty>
            <button
                className={'btn-link ml-auto ' + className}
                onClick={() => setOpen(true)}
            >
                <span className="material-symbols-outlined">
                    settings
                </span>
            </button>
            {open && (
                <Modal
                    isOpen={open}
                    className="small-modal"
                >
                    <ModalHeader className="p-l-20">{`${t(
                        'edit'
                    )} ${t(
                        'folder'
                    ).toLowerCase()}`}</ModalHeader>
                    <ModalBody>
                        <FolderEditLoader
                            {...props}
                            close={() => setOpen(false)}
                        />
                    </ModalBody>
                </Modal>
            )}
        </Empty>
    );
}
