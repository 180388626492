import React from 'react';

import { useNavigate } from 'react-router-dom';

import { NavItem, Nav } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

export default function Sidebar() {
    //data

    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <div className="sidebar">
            <div className="scrollable fit-with-header">
                <Nav vertical>
                    <NavItem className="noselect">
                        <span
                            className={classnames(
                                'clickable sidebar-menu-item link'
                            )}
                            onClick={() => {
                                navigate(
                                    `/helpdesk/taskList/i/all`
                                );
                            }}
                        >
                            <span className="material-symbols-outlined bigger">
                                chevron_left
                            </span>
                            {t('backToTasks')}
                        </span>
                    </NavItem>
                    <NavItem
                        className={classnames(
                            'row full-width sidebar-item noselect',
                            {
                                'active':
                                    window.location.pathname.includes(
                                        '/companies'
                                    ),
                            }
                        )}
                    >
                        <span
                            className={classnames(
                                'clickable sidebar-menu-item link',
                                {
                                    'active':
                                        window.location.pathname.includes(
                                            '/companies'
                                        ),
                                }
                            )}
                            onClick={() => {
                                navigate(
                                    `/invoices/monthly/companies`
                                );
                            }}
                        >
                            <span className="material-symbols-outlined p-r-5">
                                apartment
                            </span>
                            {t('companies')}
                        </span>
                    </NavItem>
                    <NavItem
                        className={classnames(
                            'row full-width sidebar-item noselect',
                            {
                                'active':
                                    window.location.pathname.includes(
                                        '/agents'
                                    ),
                            }
                        )}
                    >
                        <span
                            className={classnames(
                                'clickable sidebar-menu-item link',
                                {
                                    'active':
                                        window.location.pathname.includes(
                                            '/agents'
                                        ),
                                }
                            )}
                            onClick={() => {
                                navigate(
                                    `/invoices/monthly/agents`
                                );
                            }}
                        >
                            <span className="material-symbols-outlined p-r-5">
                                account_circle
                            </span>
                            {t('agents')}
                        </span>
                    </NavItem>
                </Nav>
                <hr className="m-b-10 m-t-10" />
                {false && (
                    <Nav vertical>
                        <NavItem
                            className={classnames(
                                'row full-width sidebar-item noselect',
                                {
                                    'active':
                                        window.location.pathname.includes(
                                            '/invoices'
                                        ) &&
                                        !window.location.pathname.includes(
                                            '/monthly'
                                        ),
                                }
                            )}
                        >
                            <span
                                className={classnames(
                                    'clickable sidebar-menu-item link',
                                    {
                                        'active':
                                            window.location.pathname.includes(
                                                '/invoices'
                                            ) &&
                                            !window.location.pathname.includes(
                                                '/monthly'
                                            ),
                                    }
                                )}
                                onClick={() => {
                                    navigate(`/invoices`);
                                }}
                            >
                                <span className="material-symbols-outlined p-r-5">
                                    description
                                </span>
                                {t('invoices')}
                            </span>
                        </NavItem>
                    </Nav>
                )}
            </div>
        </div>
    );
}
