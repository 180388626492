import React from 'react';

import {
    useQuery,
    useSubscription,
    useMutation,
} from '@apollo/client';

import SettingLoading from '../components/settingLoading';
import SettingListContainer from '../components/settingListContainer';
import RoleAdd from './roleAdd';
import RoleEdit from './roleEdit';
import DeleteReplacement from 'components/deleteReplacement';

import {
    orderArr,
    toSelArr,
    getMyData,
} from 'helperFunctions';
import { useTranslation } from 'react-i18next';
import { itemAttributesFullfillsString } from '../components/helpers';
import { addLocalError } from 'apollo/localSchema/actions';

import {
    GET_ROLES,
    ROLES_SUBSCRIPTION,
    DELETE_ROLE,
} from 'helpdesk/queries';

export default function RolesList() {
    const { t } = useTranslation();

    const currentUser = getMyData();

    const currentUserLevel = currentUser
        ? currentUser.role.level
        : null;

    const [deleteRole] = useMutation(DELETE_ROLE);

    const {
        data: rolesData,
        loading: rolesLoading,
        refetch: rolesRefetch,
    } = useQuery(GET_ROLES, {
        fetchPolicy: 'network-only',
    });

    useSubscription(ROLES_SUBSCRIPTION, {
        onData: () => {
            rolesRefetch();
        },
    });

    // state
    const [roleFilter, setRoleFilter] = React.useState('');
    const [editedItemId, setEditedItemId] =
        React.useState(null);
    const [itemToDeleteId, setItemToDeleteId] =
        React.useState(null);

    if (rolesLoading) {
        return <SettingLoading />;
    }

    const roles = orderArr(rolesData.roles);

    const deleteRoleFunc = (replacement) => {
        if (window.confirm(t('generalConfirmation'))) {
            deleteRole({
                variables: {
                    id: itemToDeleteId,
                    newId: parseInt(replacement.id),
                },
            })
                .then(() => {
                    setItemToDeleteId(null);
                })
                .catch((err) => {
                    addLocalError(err);
                });
        }
    };

    return (
        <SettingListContainer
            header={t('roles')}
            addLabel={t('role')}
            filter={roleFilter}
            setFilter={setRoleFilter}
            AddComponent={RoleAdd}
            EditComponent={RoleEdit}
            editedItemId={editedItemId}
            setEditedItemId={setEditedItemId}
            itemRefetch={rolesRefetch}
        >
            <table className="table">
                <thead>
                    <tr>
                        <th>{t('title')}</th>
                        <th>{t('level')}</th>
                        <th>{t('order')}</th>
                        <th width="100px">
                            {t('actions')}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {roles
                        .filter((role) =>
                            itemAttributesFullfillsString(
                                role,
                                roleFilter,
                                ['title', 'level', 'order']
                            )
                        )
                        .map((role) => (
                            <tr key={role.id}>
                                <td>{role.title}</td>
                                <td>{role.level}</td>
                                <td>{role.order}</td>
                                <td className="row">
                                    {roles.length > 1 &&
                                        (currentUserLevel ===
                                            null ||
                                            currentUserLevel <=
                                                role.level) && (
                                            <button
                                                className="btn-link-red m-r-0 m-l-0 p-0"
                                                onClick={() => {
                                                    setItemToDeleteId(
                                                        role.id
                                                    );
                                                }}
                                            >
                                                <span className="material-symbols-outlined">
                                                    delete
                                                </span>
                                            </button>
                                        )}
                                    <button
                                        className="btn-link m-r-0 m-l-10 p-0"
                                        onClick={() => {
                                            setEditedItemId(
                                                role.id
                                            );
                                        }}
                                    >
                                        <span className="material-symbols-outlined">
                                            edit
                                        </span>
                                    </button>
                                </td>
                            </tr>
                        ))}
                </tbody>
            </table>
            <DeleteReplacement
                isOpen={itemToDeleteId ? true : false}
                label={t('role')}
                options={toSelArr(
                    roles.filter(
                        (role) => role.id !== itemToDeleteId
                    )
                )}
                close={() => setItemToDeleteId(null)}
                finishDelete={deleteRoleFunc}
            />
        </SettingListContainer>
    );
}
