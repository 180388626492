import React from 'react';

import StatusAndDateFilter from './filter';
import AgentList from './agentList';
import AgentInvoice from './agentInvoice';

import { useTranslation } from 'react-i18next';

export default function AgentReportsLoader() {
    const { t } = useTranslation();

    const [filterData, setFilterData] =
        React.useState(null);
    const [agent, setAgent] = React.useState(null);

    return (
        <div className="scrollable fit-with-header p-20">
            <h2>{t('agents')}</h2>

            <StatusAndDateFilter
                onTrigger={(fromDate, toDate) => {
                    setFilterData({
                        fromDate: fromDate
                            .valueOf()
                            .toString(),
                        toDate: toDate.valueOf().toString(),
                    });
                    setAgent(null);
                }}
            />
            {filterData !== null && (
                <AgentList
                    filterData={filterData}
                    setAgent={setAgent}
                />
            )}
            {agent !== null && (
                <AgentInvoice
                    agent={agent}
                    filterData={filterData}
                />
            )}
        </div>
    );
}
