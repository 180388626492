import { gql } from '@apollo/client';

//this one
export const INVOICE_AGENTS = gql`
    query invoiceAgents(
        $fromDate: String!
        $toDate: String!
    ) {
        invoiceAgents(
            fromDate: $fromDate
            toDate: $toDate
        ) {
            user {
                id
                name
                surname
                fullName
                email
            }
            works
            trips
        }
    }
`;

//this one
export const AGENT_INVOICE = gql`
    query agentInvoice(
        $fromDate: String!
        $toDate: String!
        $userId: Int!
    ) {
        agentInvoice(
            fromDate: $fromDate
            toDate: $toDate
            userId: $userId
        ) {
            totals {
                tripHours
                workHours
            }
            tripTypeTotals {
                quantity
                title
            }
            taskTypeTotals {
                quantity
                title
            }
            workTasks {
                id
                title
                requester {
                    fullName
                }
                company {
                    title
                }
                status {
                    title
                    color
                }
                closeDate
                subtasks {
                    id
                    title
                    quantity
                    type {
                        id
                        title
                    }
                }
            }
            tripTasks {
                id
                title
                requester {
                    fullName
                }
                company {
                    title
                }
                status {
                    title
                    color
                }
                closeDate
                workTrips {
                    id
                    quantity
                    type {
                        id
                        title
                    }
                }
            }
        }
    }
`;
