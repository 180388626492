export default {
    scheme: {
        en: 'Scheme',
        sk: 'Schéma',
        cz: 'Schéma',
        de: 'Das Schema',
    },
    scheme2: {
        en: 'Scheme',
        sk: 'Schému',
        cz: 'Schéma',
        de: 'Das Schema',
    },
    noSchemeUploaded: {
        en: 'No scheme uploaded',
        sk: 'Bez nahranej schémy',
        cz: 'Nebylo nahráno žádné schéma',
        de: 'Kein Schema hochgeladen',
    },
    currentScheme: {
        en: 'Current scheme',
        sk: 'Momentálna schéma',
        cz: 'Aktuální schéma',
        de: 'Aktuelles Schema',
    },
    companyWithoutScheme: {
        en: 'Missing scheme and description',
        sk: 'Chýba schéma a popis',
        cz: 'Chybí schéma a popis',
        de: 'Schema und Beschreibung fehlen',
    },
    chooseFile: {
        en: 'Choose a file',
        sk: 'Vybrať súbor',
        cz: 'Vyberte soubor',
        de: 'Wähle eine Datei aus',
    },
};
