import React from 'react';
import ReportsTable from './reportsTable';
import ModalTaskEdit from 'helpdesk/task/edit/modalEdit';

import { timestampToDateFNS } from 'helperFunctions';

import { columnsToShowTrips, columnsToShowWorks, columnsToShowMaterials } from './tableConfig';
import { useTranslation } from 'react-i18next';

export default function CompanyInvoice(props) {
    const { invoice, companyData, fromDate, toDate, invoiceRefetch } = props;

    const company = companyData.company;

    const { t } = useTranslation();

    const [editedTask, setEditedTask] = React.useState(null);

    const onClickTask = (task) => {
        setEditedTask(task);
    };

    return (
        <div className="invoice-container">
            <h2>{t('invoiceOfCompany')}</h2>
            <div className="flex-row m-b-10">
                <div>
                    {t('company')}: {company.title}
                    <br />
                    {` ${t('period')} ${t('from').toLowerCase()}: ${timestampToDateFNS(
                        fromDate
                    )} ${t('to').toLowerCase()}: ${timestampToDateFNS(toDate)} `}
                </div>
            </div>

            <div className="m-b-30">
                <h4>{t('works')}</h4>

                <ReportsTable
                    tasks={invoice.workTasks.filter((task) => task.subtasks.length > 0)}
                    columnsToShow={columnsToShowWorks}
                    onClickTask={onClickTask}
                />

                <p className="m-0">{`${t('totalHours')}: ${invoice.totals.workHours}`}</p>
            </div>
            <div className="m-b-30">
                <h4>{t('trips')}</h4>

                <ReportsTable
                    tasks={invoice.tripTasks.filter((task) => task.workTrips.length > 0)}
                    columnsToShow={columnsToShowTrips}
                    onClickTask={onClickTask}
                />

                <p className="m-0">{`${t('tripTotal')}: ${invoice.totals.tripHours}`}</p>
            </div>

            <div className="m-b-30">
                <h4>{t('material')}</h4>

                <ReportsTable
                    tasks={invoice.materialTasks}
                    columnsToShow={columnsToShowMaterials}
                    onClickTask={onClickTask}
                />
                <p className="m-0">
                    {`${t('totalPriceWithoutTax')}: ${invoice.totals.materialPrice.toFixed(2)} ${t(
                        'euro'
                    ).toLowerCase()}`}
                </p>
                <p className="m-0 m-b-10">
                    {`${t('totalPriceWithTax')}: ${invoice.totals.materialPriceWithDPH.toFixed(
                        2
                    )} ${t('euro').toLowerCase()}`}
                </p>
            </div>

            <ModalTaskEdit
                opened={editedTask !== null}
                taskID={editedTask ? editedTask.id : null}
                closeModal={() => {
                    setEditedTask(null);
                    invoiceRefetch();
                }}
                fromInvoice={true}
            />
        </div>
    );
}
