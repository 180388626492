import * as fns from 'date-fns';

export const timestampToInput = (timestamp) => {
    return timestamp !== null && timestamp !== '' && timestamp !== undefined
        ? new Date(timestamp).toISOString().replace('Z', '')
        : '';
};

export const inputToTimestamp = (input) => {
    return isNaN(new Date(input).valueOf()) || input === '' ? '' : new Date(input).valueOf();
};

export const toDate = (date) => {
    if (typeof date === 'string' && !isNaN(parseInt(date))) {
        date = new Date(parseInt(date));
    } else if (typeof date === 'number' || date === 'string') {
        date = new Date(date);
    }
    if (date.valueOf === undefined || isNaN(date.valueOf())) {
        return null;
    }
    return date;
};

export const formatDate = (date, format = 'HH:mm dd.MM.yyyy', t = null) => {
    date = toDate(date);
    if (date === null) {
        if (t) {
            return t('noDate');
        }
        return 'No date';
    }
    return fns.format(date, format);
};

export const toMillisec = (number, time) => {
    switch (time) {
        case 'seconds':
            return number * 1000;
        case 'minutes':
            return number * 60 * 1000;
        case 'hours':
            return number * 60 * 60 * 1000;
        case 'days':
            return number * 24 * 60 * 60 * 1000;
        default:
            return number;
    }
};

export const fromMillisec = (number, time) => {
    switch (time) {
        case 'seconds':
            return +(number / 1000).toFixed(2);
        case 'minutes':
            return +(number / 60 / 1000).toFixed(2);
        case 'hours':
            return +(number / 60 / 60 / 1000).toFixed(2);
        case 'days':
            return +(number / 24 / 60 / 60 / 1000).toFixed(2);
        default:
            return number;
    }
};

export const getDateClock = (date) => {
    return fns.format(date, 'HH:mm');
};
