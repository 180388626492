import { gql } from '@apollo/client';

export const GET_STATUS_TEMPLATES = gql`
    query {
        statusTemplates {
            title
            id
            action
            order
            color
        }
    }
`;
export const GET_STATUS_TEMPLATE = gql`
    query statusTemplate($id: Int!) {
        statusTemplate(id: $id) {
            id
            title
            color
            action
            order
        }
    }
`;

export const GET_STATUSES_X = gql`
    query statuses($id: Int!) {
        statuses(id: $id) {
            title
            id
            action
            order
            color
        }
    }
`;

export const ADD_STATUS_TEMPLATE = gql`
    mutation addStatusTemplate(
        $title: String!
        $order: Int!
        $color: String!
        $action: StatusAllowedType!
    ) {
        addStatusTemplate(
            title: $title
            order: $order
            color: $color
            action: $action
        ) {
            id
            title
            order
        }
    }
`;

export const UPDATE_STATUS_TEMPLATE = gql`
    mutation updateStatusTemplate(
        $id: Int!
        $title: String!
        $order: Int!
        $color: String!
        $action: StatusAllowedType!
    ) {
        updateStatusTemplate(
            id: $id
            title: $title
            color: $color
            action: $action
            order: $order
        ) {
            id
            title
            order
        }
    }
`;

export const DELETE_STATUS_TEMPLATE = gql`
    mutation deleteStatusTemplate($id: Int!) {
        deleteStatusTemplate(id: $id) {
            id
        }
    }
`;

export const SET_USER_STATUSES = gql`
    mutation setUserStatuses($ids: [Int]!) {
        setUserStatuses(ids: $ids) {
            id
        }
    }
`;

export const STATUS_TEMPLATE_SUBSCRIPTION = gql`
    subscription statusTemplateSubscription {
        statusTemplateSubscription
    }
`;
