import * as fns from 'date-fns';

export const fromUnixToDate = (unix) =>
    unix !== null && unix !== undefined ? fns.fromUnixTime(parseInt(unix) / 1000) : null;

export const fromDateToUnix = (date) => (date !== null ? fns.getUnixTime(date) * 1000 : null);

export const timestampToStringFNS = (date, format = '') => {
    if (typeof date === 'string' && !isNaN(parseInt(date))) {
        date = new Date(parseInt(date));
    } else if (typeof date === 'number' || date === 'string') {
        date = new Date(date);
    }
    if (isNaN(date.valueOf())) {
        return 'Not a date';
    }
    if (format) {
        return fns.format(date, format);
    }
    return fns.format(date, 'HH:mm dd.MM.y');
};

export const timeRangeToStringFNS = (fromDate, toDate, noDateMessage) => {
    if (fromDate && toDate) {
        return `
      ${
          fns.format(fromDate, 'dd.M.y') === fns.format(toDate, 'dd.M.y')
              ? fns.format(fromDate, 'HH:mm')
              : fns.format(fromDate, 'HH:mm dd.MM.y')
      } -
      ${fns.format(toDate, 'HH:mm d.MM.y')}
    `;
    }
    return noDateMessage ? noDateMessage : `Invalid date`;
};

export const timestampToDateFNS = (timestamp) => {
    let date = fns.fromUnixTime(timestamp / 1000);
    return fns.format(date, 'd.M.y');
};

export const timestampToHoursAndMinutesFNS = (timestamp) => {
    let date = fns.fromUnixTime(timestamp / 1000);
    return date.getHours() + ':' + (date.getMinutes() < 10 ? '0' : '') + date.getMinutes();
};

export const afterNowFNS = (unix) => {
    return unix > fns.getUnixTime(new Date()) * 1000;
};

export const getDayRangeFNS = (date, getDate = false) => {
    if (getDate) {
        return {
            start: date === null ? null : fns.startOfDay(date),
            end: date === null ? null : fns.endOfDay(date),
        };
    } else {
        return {
            start: date === null ? null : fns.getUnixTime(fns.startOfDay(date)) * 1000,
            end: date === null ? null : fns.getUnixTime(fns.endOfDay(date)) * 1000,
        };
    }
};

export const getDatesDifferenceInHoursFNS = (fromDate, toDate) => {
    if (fromDate === null || toDate === null) {
        return null;
    }
    return fns.differenceInHours(toDate, fromDate);
};
