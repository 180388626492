export default {
    cmdb: {
        en: 'LanCMDB',
        sk: 'LanCMDB',
        cz: 'LanCMDB',
        de: 'LanCMDB',
    },
    assets: {
        en: 'Assets',
        sk: 'Aktíva',
        cz: 'Aktiva',
        de: 'Vermögenswerte',
    },
    allCompanies: {
        en: 'All companies',
        sk: 'Všetky firmy',
        cz: 'Všechny společnosti',
        de: 'Alle Unternehmen',
    },
};
