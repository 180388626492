import React from 'react';

import { useMutation, useQuery } from '@apollo/client';

import { useTranslation } from 'react-i18next';

import Loading from 'components/loading';
import SettingsInput from '../components/settingsInput';

import { addLocalError } from 'apollo/localSchema/actions';

import { GET_TASK_TYPE, UPDATE_TASK_TYPE } from 'helpdesk/queries';

export default function TaskTypeEdit(props) {
    const { id, closeModal } = props;

    const { t } = useTranslation();

    const {
        data: taskTypeData,
        loading: taskTypeLoading,
        refetch: taskTypeRefetch,
    } = useQuery(GET_TASK_TYPE, {
        variables: {
            id,
        },
        fetchPolicy: 'network-only',
    });

    const [updateTaskType] = useMutation(UPDATE_TASK_TYPE);

    //state
    const [title, setTitle] = React.useState('');
    const [order, setOrder] = React.useState(0);
    const [saving, setSaving] = React.useState(false);

    const [dataChanged, setDataChanged] = React.useState(false);

    // sync
    React.useEffect(() => {
        if (!taskTypeLoading) {
            setData();
        }
    }, [taskTypeLoading]);

    React.useEffect(() => {
        taskTypeRefetch({
            id,
        }).then(setData);
    }, [id]);

    // functions
    const setData = () => {
        if (taskTypeLoading) {
            return;
        }
        const taskType = taskTypeData.taskType;
        setTitle(taskType.title);
        setOrder(taskType.order);
        setDataChanged(false);
    };

    const updateTaskTypeFunc = () => {
        setSaving(true);

        updateTaskType({
            variables: {
                id,
                title,
                order: order !== '' ? parseInt(order) : 0,
            },
        })
            .then(() => {
                closeModal();
            })
            .catch((err) => {
                addLocalError(err);
            });

        setSaving(false);
        setDataChanged(false);
    };

    if (taskTypeLoading) {
        return <Loading />;
    }

    return (
        <div className="p-20">
            <h2 className="m-b-20">{`${t('edit')} ${t('taskType').toLowerCase()}`}</h2>

            <SettingsInput
                required
                label={t('taskTypeTitle')}
                id="title"
                value={title}
                onChange={(e) => {
                    setTitle(e.target.value);
                    setDataChanged(true);
                }}
            />

            <SettingsInput
                label={t('order')}
                placeholder={t('lowerMeansFirst')}
                id="order"
                type="number"
                value={order}
                onChange={(e) => {
                    setOrder(e.target.value);
                    setDataChanged(true);
                }}
            />

            <div className="form-buttons-row">
                <button className="btn-link btn-distance" onClick={closeModal}>
                    {t('cancel')}
                </button>

                <div className="ml-auto message m-r-10">
                    {dataChanged && (
                        <div className="message error-message">{t('saveBeforeLeaving')}</div>
                    )}
                    {!dataChanged && <div className="message success-message">{t('saved')}</div>}
                </div>

                <button className="btn" disabled={saving} onClick={updateTaskTypeFunc}>
                    {saving ? `${t('saving')}...` : `${t('save')} ${t('taskType').toLowerCase()}`}
                </button>
            </div>
        </div>
    );
}
