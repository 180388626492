import { gql } from '@apollo/client';

export const GET_PROJECT_STATISTICS = gql`
    query projectStatistics(
        $dateFrom: String!
        $dateTo: String!
        $projectId: Int!
    ) {
        projectStatistics(
            dateFrom: $dateFrom
            dateTo: $dateTo
            projectId: $projectId
        ) {
            taskId
            date
            value
        }
    }
`;

export const GET_COMPANY_STATISTICS = gql`
    query companyStatistics(
        $dateFrom: String!
        $dateTo: String!
        $companyId: Int!
    ) {
        companyStatistics(
            dateFrom: $dateFrom
            dateTo: $dateTo
            companyId: $companyId
        ) {
            taskId
            date
            value
        }
    }
`;

export const GET_TASK_STATISTICS = gql`
    query taskStatistics(
        $dateFrom: String!
        $dateTo: String!
        $projectId: Int!
        $dateType: StatisticsDateTypeEnum!
    ) {
        taskStatistics(
            dateFrom: $dateFrom
            dateTo: $dateTo
            projectId: $projectId
            dateType: $dateType
        ) {
            taskId
            date
            value
        }
    }
`;
