export default {
  selectPrefferedDateRange: {
    en: 'Select preffered date range',
    sk: 'Vyberte preferovaný rozsah dátumov',
    cz: 'Vyberte preferované časové období',
    de: 'Wählen Sie den bevorzugten Zeitraum aus',
  },
  selectMonthAndYear: {
    en: 'Select month and year',
    sk: 'Vyberte mesiac a rok',
    cz: 'Vyberte měsíc a rok',
    de: 'Monat und Jahr auswählen',
  },
}
