import React from 'react';
import { useMutation } from '@apollo/client';
import CommandBar from '../components/commandBar';
import Search from '../components/search';
import ActiveSearch from '../components/activeSearch';
import InvoicedColumn from './invoicedColumn';
import StatusColumn from './statusColumn';
import * as fns from 'date-fns';
import { fromUnixToDate } from 'helperFunctions';
import { DragDropContext } from 'react-beautiful-dnd';
import { addLocalError } from 'apollo/localSchema/actions';

import { UPDATE_TASK } from 'helpdesk/queries';

const limit = 20;

export default function TaskListDnD(props) {
    const { localProject, localFilter } = props;

    let statuses = localProject.project.statuses;

    const filterStatuses = localFilter.filter.statuses;
    if (filterStatuses.length > 0) {
        statuses = statuses.filter((status) =>
            filterStatuses.some((status2) => status.id === status2.id)
        );
    }

    const [updateTask] = useMutation(UPDATE_TASK);
    const [changedTask, setChangedTask] = React.useState(null);
    const [fakeChanges, setFakeChanges] = React.useState([]);
    const [tasks, setTasks] = React.useState([]);

    //create fake tasks, mark taskId and status of fake task

    const onDragEnd = (props) => {
        const { source, destination, draggableId } = props;

        if (
            source !== null &&
            destination !== null &&
            parseInt(source.droppableId) === parseInt(destination.droppableId)
        ) {
            return;
        }

        const originalStatus = statuses.find(
            (status) => status.id === parseInt(source.droppableId)
        );
        const targetStatus = statuses.find(
            (status) => status.id === parseInt(destination.droppableId)
        );
        const task = tasks.find((task) => task.id === parseInt(draggableId));
        setFakeChanges([
            ...fakeChanges.filter((fakeChange) => fakeChange.task.id !== task.id),
            {
                task,
                originalStatus: parseInt(source.droppableId),
                targetStatus: parseInt(destination.droppableId),
                targetUpdate: false,
                originUpdate: false,
            },
        ]);
        let updateData = {
            id: task.id,
            status: targetStatus.id,
        };
        if (targetStatus.action === 'PendingDate') {
            updateData.pendingDate = fromUnixToDate(fns.addDays(new Date(), 1));
            updateData.pendingChangable = true;
        } else if (targetStatus.action === 'CloseDate' || targetStatus.action === 'CloseInvalid') {
            updateData.important = false;
        }
        updateTaskFunc(originalStatus, targetStatus, updateData);
    };

    const updateTaskFunc = (originalStatus, newStatus, updateData) => {
        updateTask({
            variables: updateData,
        })
            .then(() => {
                setChangedTask({
                    originalStatus,
                    newStatus,
                    id: updateData.id,
                });
            })
            .catch((err) => {
                addLocalError(err);
            });
    };

    return (
        <div className="relative">
            <CommandBar {...props} showSort />
            <div className="scroll-visible overflow-x fit-with-header-and-commandbar task-container">
                <Search {...props} />
                <ActiveSearch {...props} includeGlobalSearch />
                <div
                    className="flex-row m-l-30 fit-with-header-and-commandbar-dnd"
                    style={{ width: '800px' }}
                >
                    <DragDropContext onDragEnd={onDragEnd}>
                        {statuses
                            .filter((status) => status.action !== 'Invoiced')
                            .map((status) => (
                                <StatusColumn
                                    {...props}
                                    disabled={!localProject.attributeRights.status.edit}
                                    addStatusTasks={(newTasks) =>
                                        setTasks([
                                            ...tasks.filter((task) =>
                                                newTasks.every((task2) => task.id !== task2.id)
                                            ),
                                            ...newTasks,
                                        ])
                                    }
                                    change={changedTask}
                                    fakeChanges={fakeChanges}
                                    setFakeChanges={setFakeChanges}
                                    status={status}
                                    key={status.id}
                                    limit={limit}
                                />
                            ))}
                    </DragDropContext>
                    <InvoicedColumn {...props} limit={limit} />
                </div>
            </div>
        </div>
    );
}
