import React from 'react';
import { useQuery, useSubscription } from '@apollo/client';

import { useTranslation } from 'react-i18next';

import { useParams } from 'react-router-dom';

import PagesList from './list';
import {
    setLGlobalStringFilter,
    setLLocalStringFilter,
    clearLLocalStringFilter,
} from 'apollo/localSchema/actions';
import {
    L_SIDEBAR_TAG,
    L_SIDEBAR_FOLDER,
    L_LOCAL_STRING_FILTER,
    L_GLOBAL_STRING_FILTER,
    GET_PROJECT,
} from 'apollo/queries';

import {
    GET_PAGES,
    PAGES_SUBSCRIPTION,
} from 'lanwiki/queries';

export default function LanwikiPagesLoader(props) {
    const params = useParams();

    const { t } = useTranslation();

    const { data: projectData } = useQuery(GET_PROJECT);
    const localProject = projectData.localProject;

    const page = params.page ? parseInt(params.page) : 1;
    const limit = 30;

    const { data: sidebarTagData } =
        useQuery(L_SIDEBAR_TAG);
    const { data: sidebarFolderData } = useQuery(
        L_SIDEBAR_FOLDER
    );
    const { data: localStringFilterData } = useQuery(
        L_LOCAL_STRING_FILTER
    );
    const { data: globalStringFilterData } = useQuery(
        L_GLOBAL_STRING_FILTER
    );

    const folder =
        sidebarFolderData.lSidebarFolder == null
            ? {
                  id: null,
                  title: t('allFolders'),
              }
            : sidebarFolderData.lSidebarFolder;

    const tag =
        sidebarTagData.lSidebarTag == null
            ? {
                  id: null,
                  title: t('allTags'),
              }
            : sidebarTagData.lSidebarTag;

    const tagId = tag.id;
    const folderId = folder.id;
    const localStringFilter =
        localStringFilterData.lLocalStringFilter;
    const globalStringFilter =
        globalStringFilterData.lGlobalStringFilter;

    const {
        data: pagesData,
        loading: pagesLoading,
        refetch: pagesRefetch,
    } = useQuery(GET_PAGES, {
        variables: {
            tagId,
            projectId: localProject.project.id,
            folderId: folder.myRights ? folder.id : null,
            subfolderId:
                folder.myRights || folder.id === null
                    ? null
                    : folder.id,
            archived:
                folderId === null ? false : folder.archived,
            page: params.page ? parseInt(params.page) : 1,
            limit,
            stringFilter: globalStringFilter,
        },
        fetchPolicy: 'network-only',
    });

    useSubscription(PAGES_SUBSCRIPTION, {
        onData: () => {
            pagesRefetch({
                tagId,
                projectId: localProject.project.id,
                folderId: folder.myRights
                    ? folder.id
                    : null,
                subfolderId:
                    folder.myRights || folder.id === null
                        ? null
                        : folder.id,
                page: params.page
                    ? parseInt(params.page)
                    : 1,
                limit,
                stringFilter: globalStringFilter,
            });
        },
    });

    React.useEffect(() => {
        pagesRefetch({
            tagId,
            projectId: localProject.project.id,
            folderId: folder.myRights ? folder.id : null,
            subfolderId:
                folder.myRights || folder.id === null
                    ? null
                    : folder.id,
            page: params.page ? parseInt(params.page) : 1,
            limit,
            stringFilter: globalStringFilter,
        });
    }, [tagId, folderId, params.page, globalStringFilter]);

    const pages =
        pagesLoading || !pagesData
            ? []
            : pagesData.lanwikiPages.pages;
    const count =
        pagesLoading || !pagesData
            ? 0
            : pagesData.lanwikiPages.count;

    return (
        <PagesList
            {...props}
            loading={pagesLoading}
            pages={pages}
            count={count}
            page={page}
            limit={limit}
            folderId={folder.myRights ? folder.id : null}
            subfolderId={
                folder.myRights || folder.id === null
                    ? null
                    : folder.id
            }
            mainFolderId={
                folder.myRights || folder.id === null
                    ? null
                    : folder.mainFolder.id
            }
            tagId={tagId}
            tag={tag}
            folder={folder}
            pagesRefetch={() => {
                pagesRefetch({
                    tagId,
                    projectId: localProject.project.id,
                    folderId: folder.myRights
                        ? folder.id
                        : null,
                    subfolderId:
                        folder.myRights ||
                        folder.id === null
                            ? null
                            : folder.id,
                    page: params.page
                        ? parseInt(params.page)
                        : 1,
                    limit,
                    stringFilter: globalStringFilter,
                });
            }}
            setGlobalStringFilter={setLGlobalStringFilter}
            setLocalStringFilter={setLLocalStringFilter}
            clearLocalStringFilter={clearLLocalStringFilter}
            localStringFilter={localStringFilter}
            globalStringFilter={globalStringFilter}
        />
    );
}
