import React from 'react';

import TimerAutomation from './timers';
import EventAutomation from './events';

export default function Automation( props ) {

  return (
    <div>
      <TimerAutomation {...props}/>
      <div className="m-b-20"></div>
      <EventAutomation {...props} />
    </div>
  );
}
