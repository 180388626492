export default [ {
    label: "SK",
    value: "sk"
  },
  {
    label: "ENG",
    value: "en"
  },
  {
    label: "CZ",
    value: "cz"
  },
  {
    label: "DE",
    value: "de"
  }
]
