import React from 'react';
import { Tooltip } from 'reactstrap';
import { timestampToStringFNS } from './date-fns';

const ImportantIcon = function ImportantIcon(props) {
    const { item } = props;
    const [tooltipOpen, setTooltipOpen] =
        React.useState(false);
    return (
        <span
            className="material-symbols-outlined"
            style={{ color: '#ffc107' }}
            id={`important-${item.id}`}
        >
            star_rate
            <Tooltip
                placement="top"
                target={`important-${item.id}`}
                isOpen={tooltipOpen}
                toggle={() => setTooltipOpen(!tooltipOpen)}
            >
                Task is important!
            </Tooltip>
        </span>
    );
};

const InvoicedIcon = function InvoicedIcon(props) {
    const { item } = props;
    const [tooltipOpen, setTooltipOpen] =
        React.useState(false);
    return (
        <span>
            <span
                className="material-symbols-outlined"
                id={`invoiced-${item.id}`}
            >
                draft
            </span>
            <Tooltip
                placement="top"
                target={`invoiced-${item.id}`}
                isOpen={tooltipOpen}
                toggle={() => setTooltipOpen(!tooltipOpen)}
            >
                Task is invoiced!
            </Tooltip>
        </span>
    );
};

export const getItemDisplayValue = (item, value) => {
    if (
        !item[value.value] &&
        (value.type === 'important' ||
            value.type === 'invoiced' ||
            value.type === 'checkbox')
    ) {
        return null;
    }
    if (
        value.type !== 'customAttribute' &&
        !item[value.value] &&
        !['boolean', 'attribute', 'custom'].includes(
            value.type
        )
    ) {
        return '---';
    }
    if (value.type === 'object') {
        if (value.value === 'status') {
            return (
                <span
                    className="label label-info"
                    style={{
                        backgroundColor: item[value.value]
                            ? item[value.value].color
                            : 'white',
                    }}
                >
                    {item[value.value]
                        ? item[value.value].title
                        : 'No status'}
                </span>
            );
        }
        return item[value.value].title;
    } else if (value.type === 'attribute') {
        return item[value.obj][value.value];
    } else if (value.type === 'text') {
        return item[value.value];
    } else if (value.type === 'custom') {
        return value.func(item);
    } else if (value.type === 'url') {
        return (
            <a
                onClick={(e) => e.stopPropagation()}
                href={item[value.value]}
                target="_blank"
                without="without"
                rel="noopener noreferrer"
            >
                {item[value.value] ? item[value.value] : ''}
            </a>
        );
    } else if (value.type === 'int') {
        return parseInt(item[value.value]);
    } else if (value.type === 'boolean') {
        return item[value.value] ? 'Yes' : 'No';
    } else if (value.type === 'list') {
        return value.func(item[value.value]);
    } else if (value.type === 'date') {
        return timestampToStringFNS(item[value.value]);
    } else if (value.type === 'user') {
        return (
            item[value.value].name +
            ' ' +
            item[value.value].surname
        );
    } else if (value.type === 'important') {
        return <ImportantIcon item={item} />;
    } else if (value.type === 'invoiced') {
        return <InvoicedIcon item={item} />;
    } else if (value.type === 'checkbox') {
        return null;
    } else if (value.type === 'customAttribute') {
        let displayValue = item.customAttributes.find(
            (customAttribute) =>
                customAttribute.customAttribute.id ===
                value.id
        );
        if (!displayValue) {
            return '';
        }
        if (
            ['text', 'textarea'].includes(value.valueType)
        ) {
            return displayValue.text;
        }
        if (['number'].includes(value.valueType)) {
            return displayValue.number;
        }
        if (
            ['select', 'multiselect'].includes(
                value.valueType
            )
        ) {
            return displayValue.selectValues
                .map((select) => select.value)
                .join(', ');
        }
    } else {
        return 'Error';
    }
};
