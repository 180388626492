import React from 'react';

import { useParams } from 'react-router-dom';

import {
    useMutation,
    useQuery,
    useSubscription,
} from '@apollo/client';
import { toSelArr, getMyData } from 'helperFunctions';

import ProjectEdit from './projectEdit';

import {
    GET_PROJECTS,
    GET_MY_PROJECTS,
    GET_PROJECT,
    UPDATE_PROJECT,
    DELETE_PROJECT,
    GET_NUMBER_OF_TASKS,
    DELETE_PROJECT_ATTACHMENT,
    GET_BASIC_COMPANIES,
    GET_BASIC_USERS,
    USERS_SUBSCRIPTION,
} from 'helpdesk/queries';

export default function ProjectEditLoader(props) {
    //data & queries
    const { closeModal, projectID, tabID } = props;

    const params = useParams();

    const id = closeModal ? projectID : parseInt(params.id);
    const tabId = closeModal ? tabID : params.tabId;

    const {
        data: projectData,
        loading: projectLoading,
        refetch,
    } = useQuery(GET_PROJECT, {
        variables: {
            id,
        },
        fetchPolicy: 'network-only',
    });

    const [updateProject] = useMutation(UPDATE_PROJECT);
    const [deleteProjectAttachment] = useMutation(
        DELETE_PROJECT_ATTACHMENT
    );

    const [deleteProject, { client }] =
        useMutation(DELETE_PROJECT);

    const {
        data: companiesData,
        loading: companiesLoading,
    } = useQuery(GET_BASIC_COMPANIES, {
        fetchPolicy: 'network-only',
    });

    const {
        data: usersData,
        loading: usersLoading,
        refetch: usersRefetch,
    } = useQuery(GET_BASIC_USERS, {
        fetchPolicy: 'network-only',
    });

    useSubscription(USERS_SUBSCRIPTION, {
        onData: () => {
            usersRefetch();
        },
    });
    /*
  const {
    data: taskTypesData,
    loading: taskTypesLoading
  } = useQuery( GET_TASK_TYPES, {
    fetchPolicy: 'network-only',
  } );
  */

    const {
        data: numberOfTasksData,
        loading: numberOfTasksLoading,
        error: numberOfTasksError,
    } = useQuery(GET_NUMBER_OF_TASKS, {
        fetchPolicy: 'network-only',
        variables: {
            projectId: id,
        },
    });

    let allProjects = [];
    if (closeModal) {
        allProjects = toSelArr(
            client
                .readQuery({
                    query: GET_MY_PROJECTS,
                })
                .myProjects.map(
                    (projectData) => projectData.project
                )
        );
    } else {
        allProjects = toSelArr(
            client.readQuery({
                query: GET_PROJECTS,
            }).projects
        );
    }
    const filteredProjects = allProjects.filter(
        (project) => project.id !== id
    );
    const theOnlyOneLeft = allProjects.length === 1;
    const currentUser = getMyData();

    const dataLoading =
        projectLoading ||
        companiesLoading ||
        usersLoading ||
        !currentUser;

    const newProps = {
        ...props,
        projectData,
        projectLoading,
        refetch,
        updateProject,
        deleteProjectAttachment,
        deleteProject,
        client,
        companiesData,
        companiesLoading,
        usersData,
        usersLoading,
        numberOfTasksData,
        numberOfTasksLoading,
        numberOfTasksError,
        allProjects,
        filteredProjects,
        theOnlyOneLeft,
        currentUser,
        dataLoading,
        id,
        tabId,
    };

    return <ProjectEdit {...newProps} />;
}
