import { gql } from '@apollo/client';

export const workspace = `
    id
    title
    myRights {
        addProjects
        editWorkspace
    }
`;

export const GET_WORKSPACES = gql`
query {
    workspaces {
        ${workspace}
    }
}
`;

export const GET_WORKSPACE = gql`
query workspace($id: Int!) {
    workspace (
      id: $id
    ) {
        ${workspace}
        description
        workspaceRights{
            addProjects
            editWorkspace
            user{
                id
                email
                fullName
            }

        }
    }
}
`;

export const ADD_WORKSPACE = gql`
    mutation addWorkspace($title: String!, $description: String!, $workspaceRights: [WorkspaceRightInput]!) {
        addWorkspace(title: $title,
            description: $description,
            workspaceRights: $workspaceRights) {
                ${workspace}
        }
    }
`;

export const UPDATE_WORKSPACE = gql`
    mutation updateWorkspace($id: Int!, $title: String!, $description: String!, $workspaceRights: [WorkspaceRightInput]!) {
        updateWorkspace(id: $id, title: $title,
            description: $description,
            workspaceRights: $workspaceRights) {
                ${workspace}
        }
    }
`;

export const DELETE_WORKSPACE = gql`
    mutation deleteWorkspace($id: Int!, $newId: Int) {
        deleteWorkspace(id: $id, newId: $newId) {
            id
        }
    }
`;

export const WORKSPACES_SUBSCRIPTION = gql`
    subscription workspacesSubscription {
        workspacesSubscription
    }
`;
