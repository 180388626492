import React from 'react';

import { useMutation } from '@apollo/client';
import classnames from 'classnames';

import SettingsInput from '../components/settingsInput';
import { useTranslation } from 'react-i18next';

import { addLocalError } from 'apollo/localSchema/actions';

import { ADD_TRIP_TYPE } from 'helpdesk/queries';

export default function TripTypeAdd(props) {
    const { closeModal } = props;

    const { t } = useTranslation();

    const [addTripType] = useMutation(ADD_TRIP_TYPE);

    //state
    const [title, setTitle] = React.useState('');
    const [order, setOrder] = React.useState(0);
    const [saving, setSaving] = React.useState(false);

    //functions
    const addTripTypeFunc = () => {
        setSaving(true);
        addTripType({
            variables: {
                title,
                order: order !== '' ? parseInt(order) : 0,
            },
        })
            .then(() => {
                closeModal();
            })
            .catch((err) => {
                addLocalError(err);
            });
        setSaving(false);
    };

    const cannotSave = () => {
        return saving || title.length === 0;
    };

    return (
        <div className="p-20">
            <h2 className="m-b-20">{`${t('add')} ${t('tripType').toLowerCase()}`}</h2>

            <SettingsInput
                required
                label={t('tripTypeTitle')}
                id="title"
                value={title}
                onChange={(e) => {
                    setTitle(e.target.value);
                }}
            />

            <SettingsInput
                label={t('order')}
                placeholder={t('lowerMeansFirst')}
                id="order"
                value={order}
                onChange={(e) => {
                    setOrder(e.target.value);
                }}
            />

            <div className="form-buttons-row">
                <button className="btn-link" onClick={closeModal}>
                    {t('cancel')}
                </button>
                {cannotSave() && (
                    <div className="message error-message ml-auto m-r-14">
                        {t('fillAllRequiredInformation')}
                    </div>
                )}
                <button
                    className={classnames('btn', {
                        'ml-auto': !cannotSave(),
                    })}
                    disabled={cannotSave()}
                    onClick={addTripTypeFunc}
                >
                    {saving ? `${t('adding')}...` : `${t('add')} ${t('tripType').toLowerCase()}`}
                </button>
            </div>
        </div>
    );
}
