import React from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import { filterUnique, timestampToStringFNS } from 'helperFunctions';
import Select from 'react-select';
import { pickSelectStyle } from 'configs/components/select';
import { Label, FormGroup } from 'reactstrap';
import DatePicker from 'components/DatePicker';
import Loading from 'components/loading';
import * as fns from 'date-fns';

import {
    AreaChart,
    Area,
    CartesianGrid,
    XAxis,
    YAxis,
    Tooltip,
    ResponsiveContainer,
} from 'recharts';

import { GET_COMPANY_STATISTICS } from 'helpdesk/queries';

export default function CustomerStatistics(props) {
    const { companies } = props;
    const { t } = useTranslation();

    const [company, setCompany] = React.useState(companies[0]);
    const [dateFrom, setDateFrom] = React.useState(fns.startOfMonth(new Date()));
    const [dateTo, setDateTo] = React.useState(fns.endOfMonth(new Date()));

    const {
        data: statisticsData,
        loading: statisticsLoading,
        refetch: statisticsRefetch,
    } = useQuery(GET_COMPANY_STATISTICS, {
        fetchPolicy: 'network-only',
        variables: {
            companyId: company.id,
            dateFrom: dateFrom.valueOf().toString(),
            dateTo: dateTo.valueOf().toString(),
        },
    });

    React.useEffect(() => {
        statisticsRefetch({
            companyId: company.id,
            dateFrom: dateFrom.valueOf().toString(),
            dateTo: dateTo.valueOf().toString(),
        });
    }, [company, dateFrom, dateTo]);

    const statistics = statisticsLoading || !statisticsData ? [] : statisticsData.companyStatistics;
    const graphData = [
        {
            date: timestampToStringFNS(dateFrom, 'dd.MM.'),
            value: 0,
        },
        ...statistics.map((statistic) => ({
            date: timestampToStringFNS(statistic.date, 'dd.MM.'),
            value: statistic.value,
        })),
        {
            date: timestampToStringFNS(dateTo, 'dd.MM.'),
            value: 0,
        },
    ];

    const CustomTooltip = ({ payload, active }) => {
        if (active) {
            return (
                <div
                    style={{
                        border: '0px',
                        outline: 'none',
                    }}
                >{`${payload[0].value}h`}</div>
            );
        }

        return null;
    };

    return (
        <div className="m-t-20">
            <FormGroup className="flex-row width-550">
                <Label className="center-hor m-r-10 width-150">{t('provider')}</Label>
                <Select
                    styles={pickSelectStyle(['flex'])}
                    options={companies}
                    value={company}
                    onChange={(value) => {
                        setCompany(value);
                    }}
                />
            </FormGroup>
            <FormGroup className="flex-row width-550">
                <Label className="center-hor m-r-10 width-150">{t('period')}</Label>
                <div className="flex-row">
                    <Label className="center-hor m-r-10">{t('from')}:</Label>
                    <DatePicker
                        className="form-control"
                        selected={dateFrom}
                        hideTime={true}
                        onChange={(date) => {
                            setDateFrom(date);
                        }}
                    />
                    <Label className="center-hor m-l-10 m-r-10">{t('to')}:</Label>
                    <DatePicker
                        className="form-control"
                        selected={dateTo}
                        hideTime={true}
                        onChange={(date) => {
                            setDateTo(date);
                        }}
                    />
                </div>
            </FormGroup>
            {statisticsLoading && <Loading />}
            {!statisticsLoading && (
                <ResponsiveContainer width="100%" height={300}>
                    <AreaChart
                        data={graphData}
                        margin={{
                            top: 10,
                            right: 30,
                            left: 0,
                            bottom: 0,
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="date" />
                        <YAxis />
                        <Tooltip
                            content={<CustomTooltip />}
                            wrapperStyle={{
                                padding: 10,
                                backgroundColor: '#ccc',
                                border: '0px',
                                outline: 'none',
                            }}
                        />
                        <Area type="monotone" dataKey="value" stroke="#8884d8" fill="#8884d8" />
                    </AreaChart>
                </ResponsiveContainer>
            )}
            {!statisticsLoading && (
                <div>
                    <div className="flex-row m-t-50">
                        <div className="statistics-box">
                            {t('totalClosedTasks')}
                            <div className="statistics-box-value">
                                {filterUnique(statistics, 'taskId').length}
                            </div>
                        </div>
                        <div className="statistics-box">
                            {t('totalHoursOfClosedTasks')}
                            <div className="statistics-box-value">
                                {`${statistics.reduce((acc, cur) => acc + cur.value, 0)} ${t(
                                    'hoursShort'
                                )}`}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
