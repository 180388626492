import React from 'react';
import {
    useQuery,
    useMutation,
    useSubscription,
} from '@apollo/client';
import { toSelArr } from 'helperFunctions';
import Empty from 'components/Empty';
import { Modal, ModalBody } from 'reactstrap';
import LanpassPasswordForm from 'lanpass/passwords/form';
import { useTranslation } from 'react-i18next';

import {
    GET_FOLDERS,
    FOLDERS_SUBSCRIPTION,
    ADD_PASSWORD,
} from 'lanpass/queries';

import { GET_PROJECT } from 'apollo/queries';

export default function PasswordAddContainer(props) {
    const { folderId, subfolderId } = props;

    const { t } = useTranslation();

    const { data: projectData } = useQuery(GET_PROJECT);
    const localProject = projectData.localProject;

    const {
        data: foldersData,
        loading: foldersLoading,
        refetch: foldersRefetch,
    } = useQuery(GET_FOLDERS, {
        fetchPolicy: 'network-only',
        variables: {
            archived: false,
            projectId: localProject.project.id,
        },
    });

    useSubscription(FOLDERS_SUBSCRIPTION, {
        onData: () => {
            foldersRefetch({
                projectId: localProject.project.id,
            });
        },
    });

    //mutations
    const [addPassword] = useMutation(ADD_PASSWORD);

    //state
    const [open, setOpen] = React.useState(false);

    const folders = foldersLoading
        ? []
        : foldersData.passFolders;

    return (
        <Empty>
            <button
                className="btn sidebar-btn"
                onClick={() => {
                    setOpen(true);
                }}
            >
                <span className="material-symbols-outlined bigger">
                    add
                </span>
                {`${t('password')}`}
            </button>
            <Modal
                isOpen={open}
                className="task-add-container"
                modalClassName="overflow-x-auto"
            >
                <ModalBody>
                    {open && (
                        <LanpassPasswordForm
                            edit={false}
                            addPassword={(
                                data,
                                setSaving,
                                afterAdd
                            ) => {
                                setSaving(true);
                                addPassword({
                                    variables: {
                                        ...data,
                                    },
                                })
                                    .then(() => {
                                        setSaving(false);
                                        afterAdd();
                                    })
                                    .catch((e) => {
                                        console.log(e);
                                        setSaving(false);
                                    });
                            }}
                            close={() => setOpen(false)}
                            allFolders={toSelArr(folders)}
                            folderId={folderId}
                            subfolderId={subfolderId}
                        />
                    )}
                </ModalBody>
            </Modal>
        </Empty>
    );
}
