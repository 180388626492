import React from 'react';
import {
  FormGroup,
  Label,
  Input
} from 'reactstrap';

import {
  useTranslation
} from "react-i18next";

export default function TextNumberSpecificFields( props ) {
  const {
    type,
    defaultValue,
    setDefaultValue
  } = props;

  const {
    t
  } = useTranslation();

  const actualType = typeof type === "string" ? type : type.value;

  return (
        <FormGroup>
          <Label>{t('defaultValue')}</Label>
          <Input
            className="form-control"
            value={defaultValue}
            type={
              ['number'].includes(actualType) ? 'number' : (
                actualType === 'textarea' ? 'textarea' : 'text'
              )
            }
            onChange={e => setDefaultValue(["text", "textarea"].includes(actualType) ? {text: e.target.value.replace("↵", "\n")} : {number: parseFloat(e.target.value)})}
            />
        </FormGroup>
  );
}
