export default {
  deleteAllItems: {
    en: 'Delete all items in category',
    sk: 'Vymazať všetky položky v kategórii',
    cz: 'Smazat všechny položky v kategorii',
    de: 'Alle Artikel in der Kategorie löschen',
  },
  cmdbItem: {
    en: 'Item',
    sk: 'Položka',
    cz: 'Položka',
    de: 'Artikel',
  },
  cmdbItem2: {
    en: 'Item',
    sk: 'Položku',
    cz: 'Položku',
    de: 'Artikel',
  },
  cmdbItems: {
    en: 'Items',
    sk: 'Položky',
    cz: 'Položky',
    de: 'Artikel',
  },
  cmdbItems2: {
    en: 'Items',
    sk: 'Položiek',
    cz: 'Položek',
    de: 'Artikel',
  },
  noDescriptionLabel: {
    en: 'No description label',
    sk: 'Bez označenia popisu',
    cz: 'Bez označení popisu',
    de: 'Kein Beschreibungsetikett',
  },
  noBackupLabel: {
    en: 'No backup label',
    sk: 'Bez označenia zálohy',
    cz: 'Bez označení zálohy',
    de: 'Kein Backup-Label',
  },
  noMonitoringLabel: {
    en: 'No monitoring label',
    sk: 'Bez označenia monitoringu',
    cz: 'Bez označení sledování',
    de: 'Kein Überwachungsetikett',
  },
  noNote: {
    en: 'No note',
    sk: 'Bez poznámky',
    cz: 'Bez poznámky',
    de: 'Keine Notiz',
  },
  active2: {
    en: 'Active',
    sk: 'Aktívne',
    cz: 'Aktivní',
    de: 'Aktiv',
  },
  inactive2: {
    en: 'Inactive',
    sk: 'Neaktívne',
    cz: 'Neaktivní',
    de: 'Inaktiv',
  },
  placement: {
    en: 'Placement',
    sk: 'Lokácia',
    cz: 'Umístění',
    de: 'Platzierung',
  },
  placementPlaceholder: {
    en: 'Enter placement',
    sk: 'Zadajte lokáciu',
    cz: 'Zadejte umístění',
    de: 'Platzierung eingeben',
  },
  noPlacement: {
    en: 'No placement',
    sk: 'Bez lokácie',
    cz: 'Bez umístění',
    de: 'Keine Platzierung',
  },
  hardwareModel: {
    en: 'Hardware/Model',
    sk: 'Hardware/Model',
    cz: 'Hardware/Model',
    de: 'Hardware/Modell',
  },
  noHardwareModel: {
    en: 'No hardware/model',
    sk: 'Bez hardware/modelu',
    cz: 'Bez hardware/model',
    de: 'Keine Hardware/Modell',
  },
  hardwareModelPlaceholder: {
    en: 'Enter hardware/model',
    sk: 'Zadajte hardware/model',
    cz: 'Zadejte hardware/model',
    de: 'Hardware/Modell eingeben',
  },
  serialNumber: {
    en: 'Serial number',
    sk: 'Sériové číslo',
    cz: 'Sériové číslo',
    de: 'Seriennummer',
  },
  noSerialNumber: {
    en: 'No serial number',
    sk: 'Bez sériového čísla',
    cz: 'Bez sériového čísla',
    de: 'Keine Seriennummer',
  },
  serialNumberPlaceholder: {
    en: 'Enter Serial number',
    sk: 'Zadajte Sériové číslo',
    cz: 'Zadejte Sériové číslo',
    de: 'Seriennummer eingeben',
  },
  installDate: {
    en: 'Install date',
    sk: 'Dátum inštalácie',
    cz: 'Datum instalace',
    de: 'Installationsdatum',
  },
  noInstallDate: {
    en: 'No install date',
    sk: 'Bez dátumu inštalácie',
    cz: 'Bez datumu instalace',
    de: 'Kein Installationsdatum',
  },
  installDatePlaceholder: {
    en: 'Select install date',
    sk: 'Vyberte dátum inštalácie',
    cz: 'Vyberte datum instalace',
    de: 'Wählen Sie das Installationsdatum aus',
  },
  expireDate: {
    en: 'Expiry date',
    sk: 'Dátum vypršania',
    cz: 'Datum vypršení platnosti',
    de: 'Ablaufdatum',
  },
  noExpireDate: {
    en: 'No expiry date',
    sk: 'Bez dátumu vypršania',
    cz: 'Bez datumu vypršení platnosti',
    de: 'Kein Ablaufdatum',
  },
  expireDatePlaceholder: {
    en: 'Select expire date',
    sk: 'Vyberte dátum vypršania',
    cz: 'Vyberte datumu vypršení platnosti',
    de: 'Ablaufdatum auswählen',
  },
  nic: {
    en: 'NIC',
    sk: 'NIC',
    cz: 'NIC',
    de: 'NIC',
  },
  nicPlaceholder: {
    en: 'Enter NIC',
    sk: 'Zadajte NIC',
    cz: 'Zadejte NIC',
    de: 'NIC eingeben',
  },
  ip: {
    en: 'IP',
    sk: 'IP',
    cz: 'IP',
    de: 'IP',
  },
  ips: {
    en: 'IPs',
    sk: 'IPs',
    cz: 'IPs',
    de: 'IPs',
  },
  ipPlaceholder: {
    en: 'Enter IP',
    sk: 'Zadajte IP',
    cz: 'Zadejte IP',
    de: 'IP eingeben',
  },
  mask: {
    en: 'Mask',
    sk: 'Maska',
    cz: 'Maska',
    de: 'Maske',
  },
  maskPlaceholder: {
    en: 'Enter mask',
    sk: 'Zadajte masku',
    cz: 'Zadejte masku',
    de: 'Maske eingeben',
  },
  gateway: {
    en: 'Gateway',
    sk: 'Brána',
    cz: 'Brána',
    de: 'Die Einfahrt',
  },
  gatewayPlaceholder: {
    en: 'Enter gateway',
    sk: 'Zadajte bránu',
    cz: 'Zadejte bránu',
    de: 'Gateway eingeben',
  },
  dns: {
    en: 'DNS',
    sk: 'DNS',
    cz: 'DNS',
    de: 'DNS',
  },
  dnsPlaceholder: {
    en: 'Enter DNS',
    sk: 'Zadajte DNS',
    cz: 'Zadejte DNS',
    de: 'DNS eingeben',
  },
  vlan: {
    en: 'VLAN',
    sk: 'VLAN',
    cz: 'VLAN',
    de: 'VLAN',
  },
  vlanPlaceholder: {
    en: 'Enter VLAN',
    sk: 'Zadajte VLAN',
    cz: 'Zadejte VLAN',
    de: 'VLAN eingeben',
  },
  note: {
    en: 'Note',
    sk: 'Poznámka',
    cz: 'Poznámka',
    de: 'Notiz',
  },
  notePlaceholder: {
    en: 'Enter note',
    sk: 'Zadajte poznámku',
    cz: 'Zadejte poznámku',
    de: 'Notiz eingeben',
  },
  address: {
    en: 'Address',
    sk: 'Adresa',
    cz: 'Adresa',
    de: 'Adresse',
  },
  address2: {
    en: 'Address',
    sk: 'Adresu',
    cz: 'Adresu',
    de: 'Adresse',
  },
  noCMDBItems: {
    en: 'No items',
    sk: 'Bez položiek',
    cz: 'Bez položek',
    de: 'No items',
  },
  comfirmDeletingCmdbItem: {
    en: 'Are you sure you want to delete this item?',
    sk: 'Ste si istý, že chcete zmazať túto položku?',
    cz: 'Opravdu chcete tuto položku smazat?',
    de: 'Möchten Sie dieses Element wirklich löschen?',
  },

  cmdbItemPasswordMissingTitle: {
    en: 'Password missing title',
    sk: 'Heslu chýba názov',
    cz: 'Chybí název hesla',
    de: 'Titel des Kennworts fehlt',
  },
  cmdbItemPasswordWithoutTitle: {
    en: 'Password without title',
    sk: 'Heslo bez názvu',
    cz: 'Heslo bez názvu',
    de: 'Passwort ohne Titel',
  },
  andUsername: {
    en: 'and username',
    sk: 'a prihlásením',
    cz: 'a přihlášením',
    de: 'und Benutzername',
  },
}
