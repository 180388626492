export const translateSelectItem = ( selectItem, t, attribute ) => {
  let translation = selectItem.labelId ? t( selectItem.labelId ) : t( selectItem.label );
  if (attribute){
    translation = t(selectItem[attribute]);
  }
  return {
    ...selectItem,
    label: translation,
  }
}

export const translateAllSelectItems = ( selectItems, t, attribute ) => {
  return selectItems.map( ( selectItem ) => {
    let translation = selectItem.labelId ? t( selectItem.labelId ) : t( selectItem.label );
    if (attribute){
      translation = t(selectItem[attribute]);
    }
    return ({
      ...selectItem,
      label: translation,
    });
  } )
}
