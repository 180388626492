import React from 'react';

import { useParams } from 'react-router-dom';

import { useQuery, useSubscription } from '@apollo/client';

import ItemsList from './list';

import {
    setCmdbGlobalStringFilter,
    setCmdbLocalStringFilter,
    clearCmdbLocalStringFilter,
} from 'apollo/localSchema/actions';

import {
    CMDB_SIDEBAR_COMPANY,
    CMDB_SIDEBAR_CATEGORY,
    CMDB_LOCAL_STRING_FILTER,
    CMDB_GLOBAL_STRING_FILTER,
    GET_PROJECT,
} from 'apollo/queries';

import {
    GET_ITEMS,
    ITEMS_SUBSCRIPTION,
} from 'cmdb/queries';

import { useTranslation } from 'react-i18next';

export default function CmdbItemsLoader(props) {
    const params = useParams();

    const { t } = useTranslation();

    const page = params.page ? parseInt(params.page) : 1;
    const limit = 30;

    const { data: projectData } = useQuery(GET_PROJECT);
    const localProject = projectData.localProject;

    const { data: sidebarCompanyData } = useQuery(
        CMDB_SIDEBAR_COMPANY
    );
    const { data: sidebarCategoryData } = useQuery(
        CMDB_SIDEBAR_CATEGORY
    );
    const { data: localStringFilterData } = useQuery(
        CMDB_LOCAL_STRING_FILTER
    );
    const { data: globalStringFilterData } = useQuery(
        CMDB_GLOBAL_STRING_FILTER
    );

    const [sort, setSort] = React.useState({
        id: 'id',
        value: 'id',
        label: t('id'),
        title: t('id'),
    });

    const company =
        sidebarCompanyData.cmdbSidebarCompany === null
            ? {
                  id: null,
                  title: t('allCompanies'),
              }
            : sidebarCompanyData.cmdbSidebarCompany;

    const category =
        sidebarCategoryData.cmdbSidebarCategory === null
            ? {
                  id: null,
                  title: t('allCategories'),
              }
            : sidebarCategoryData.cmdbSidebarCategory;

    const companyId = company.id;
    const categoryId = category.id;
    const projectId = localProject.project.id;
    const localStringFilter =
        localStringFilterData.cmdbLocalStringFilter;
    const globalStringFilter =
        globalStringFilterData.cmdbGlobalStringFilter;

    const {
        data: itemsData,
        loading: itemsLoading,
        refetch: itemsRefetch,
    } = useQuery(GET_ITEMS, {
        variables: {
            companyId,
            categoryId,
            projectId,
            page: params.page ? parseInt(params.page) : 1,
            limit,
            stringFilter: globalStringFilter,
            sort: sort.id,
        },
        fetchPolicy: 'network-only',
    });

    useSubscription(ITEMS_SUBSCRIPTION, {
        onData: () => {
            itemsRefetch({
                companyId,
                categoryId,
                projectId,
                page: params.page
                    ? parseInt(params.page)
                    : 1,
                limit,
                stringFilter: globalStringFilter,
                sort: sort.id,
            });
        },
    });

    React.useEffect(() => {
        itemsRefetch({
            companyId,
            categoryId,
            projectId,
            page: params.page ? parseInt(params.page) : 1,
            limit,
            stringFilter: globalStringFilter,
            sort: sort.id,
        });
    }, [
        companyId,
        categoryId,
        projectId,
        params.page,
        globalStringFilter,
    ]);

    const items =
        itemsLoading || !itemsData
            ? []
            : itemsData.cmdbItems.items;
    const count =
        itemsLoading || !itemsData
            ? 0
            : itemsData.cmdbItems.count;

    return (
        <ItemsList
            {...props}
            loading={itemsLoading}
            items={items}
            count={count}
            page={page}
            limit={limit}
            company={company}
            category={category}
            companyId={companyId}
            categoryId={categoryId}
            projectId={projectId}
            sort={sort}
            setSort={setSort}
            itemsRefetch={() => {
                itemsRefetch({
                    variables: {
                        companyId,
                        categoryId,
                        projectId,
                        page: params.page
                            ? parseInt(params.page)
                            : 1,
                        limit,
                        stringFilter: globalStringFilter,
                        sort: sort.id,
                    },
                });
            }}
            setGlobalStringFilter={
                setCmdbGlobalStringFilter
            }
            setLocalStringFilter={setCmdbLocalStringFilter}
            clearLocalStringFilter={
                clearCmdbLocalStringFilter
            }
            localStringFilter={localStringFilter}
            globalStringFilter={globalStringFilter}
        />
    );
}
