import React from 'react';

import {
    useMutation,
    useSubscription,
    useQuery,
} from '@apollo/client';
import classnames from 'classnames';
import {
    NavLink,
    NavItem,
    Nav,
    TabContent,
    TabPane,
} from 'reactstrap';

import SettingsInput from '../components/settingsInput';
import SettingsTextarea from '../components/settingsTextarea';
import UserManagement from './userManagement';
import { useTranslation } from 'react-i18next';

import { addLocalError } from 'apollo/localSchema/actions';

import { ADD_WORKSPACE } from 'helpdesk/queries';
import { getMyData } from 'helperFunctions';

import {
    GET_BASIC_USERS,
    USERS_SUBSCRIPTION,
} from 'helpdesk/queries';
import Loading from 'components/loading';

export default function WorkspaceAdd(props) {
    const { closeModal, onSuccess } = props;

    const { t } = useTranslation();

    const currentUser = getMyData();

    const [addWorkspace] = useMutation(ADD_WORKSPACE);

    const {
        data: usersData,
        loading: usersLoading,
        refetch: usersRefetch,
    } = useQuery(GET_BASIC_USERS, {
        fetchPolicy: 'network-only',
    });

    useSubscription(USERS_SUBSCRIPTION, {
        onData: () => {
            usersRefetch();
        },
    });

    //state
    const [title, setTitle] = React.useState('');
    const [description, setDescription] =
        React.useState('');
    const [workspaceRights, setWorkspaceRights] =
        React.useState([
            {
                addProjects: true,
                editWorkspace: true,
                userId: currentUser.id,
            },
        ]);

    const [openTab, setOpenTab] =
        React.useState('description');
    const [saving, setSaving] = React.useState(false);

    //functions
    const addWorkspaceFunc = () => {
        setSaving(true);
        addWorkspace({
            variables: {
                title,
                description,
                workspaceRights,
            },
        })
            .then((response) => {
                onSuccess(response);
            })
            .catch((err) => {
                addLocalError(err);
            });
        setSaving(false);
    };

    const cannotSave = () => {
        return saving || title.length === 0;
    };

    if (usersLoading) {
        return <Loading />;
    }

    return (
        <div className="p-20">
            <h2 className="m-b-20">
                {`${t('add')} ${t(
                    'workspace'
                ).toLowerCase()}`}
            </h2>

            <Nav tabs className="no-border m-b-25">
                <NavItem className="noselect">
                    <NavLink
                        className={classnames(
                            {
                                active:
                                    openTab ===
                                    'description',
                            },
                            'clickable',
                            ''
                        )}
                        onClick={() =>
                            setOpenTab('description')
                        }
                    >
                        {t('description')}
                    </NavLink>
                </NavItem>
                <NavItem className="noselect">
                    <NavLink>|</NavLink>
                </NavItem>
                <NavItem className="noselect">
                    <NavLink
                        className={classnames(
                            {
                                active: openTab === 'users',
                            },
                            'clickable',
                            ''
                        )}
                        onClick={() => setOpenTab('users')}
                    >
                        {t('users')}
                    </NavLink>
                </NavItem>
            </Nav>

            <TabContent activeTab={openTab}>
                <TabPane tabId={'description'}>
                    <SettingsInput
                        required
                        label={t('workspaceTitle')}
                        id="title"
                        value={title}
                        onChange={(e) => {
                            setTitle(e.target.value);
                        }}
                    />

                    <SettingsTextarea
                        label={t('workspaceDescription')}
                        id="description"
                        inputClassName="form-control"
                        value={description}
                        onChange={(e) => {
                            setDescription(e.target.value);
                        }}
                    />
                </TabPane>

                <TabPane tabId={'users'}>
                    <UserManagement
                        users={usersData.basicUsers}
                        myId={currentUser.id}
                        workspaceRights={workspaceRights}
                        setWorkspaceRights={
                            setWorkspaceRights
                        }
                    />
                </TabPane>
            </TabContent>

            <div className="form-buttons-row">
                <button
                    className="btn-link"
                    onClick={closeModal}
                >
                    {t('cancel')}
                </button>
                {cannotSave() && (
                    <div className="message error-message ml-auto m-r-14">
                        {t('fillAllRequiredInformation')}
                    </div>
                )}
                <button
                    className={classnames('btn', {
                        'ml-auto': !cannotSave(),
                    })}
                    disabled={cannotSave()}
                    onClick={addWorkspaceFunc}
                >
                    {saving
                        ? `${t('adding')}...`
                        : `${t('add')} ${t(
                              'workspace'
                          ).toLowerCase()}`}
                </button>
            </div>
        </div>
    );
}
