import React from 'react';

import {
    useQuery,
    useMutation,
    useSubscription,
} from '@apollo/client';

import { useNavigate, useParams } from 'react-router-dom';

import axios from 'axios';

import ItemForm from 'cmdb/items/form';
import Loading from 'components/loading';

import { REST_URL } from 'configs/restAPI';

import {
    GET_BASIC_COMPANIES,
    COMPANIES_SUBSCRIPTION,
} from 'helpdesk/queries';

import { GET_PROJECT } from 'apollo/queries';

import {
    GET_CATEGORIES,
    CATEGORIES_SUBSCRIPTION,
    GET_ITEM,
    UPDATE_ITEM,
    DELETE_ITEM,
} from 'cmdb/queries';

import {
    toSelArr,
    extractImages,
    replacePlaceholdersWithLinks,
    getDeletedImages,
    getMyData,
} from 'helperFunctions';

import { useTranslation } from 'react-i18next';

export default function ItemEditContainer() {
    const navigate = useNavigate();
    const params = useParams();

    const { t } = useTranslation();
    const currentUser = getMyData();

    const { data: projectData } = useQuery(GET_PROJECT);
    const localProject = projectData.localProject;

    const {
        data: companiesData,
        loading: companiesLoading,
        refetch: companiesRefetch,
    } = useQuery(GET_BASIC_COMPANIES, {
        fetchPolicy: 'network-only',
    });

    useSubscription(COMPANIES_SUBSCRIPTION, {
        onData: () => {
            companiesRefetch();
        },
    });

    const {
        data: categoriesData,
        loading: categoriesLoading,
        refetch: categoriesRefetch,
    } = useQuery(GET_CATEGORIES, {
        variables: {
            projectId: localProject.project.id,
        },
        fetchPolicy: 'network-only',
    });

    useSubscription(CATEGORIES_SUBSCRIPTION, {
        onData: () => {
            categoriesRefetch({
                projectId: localProject.project.id,
            });
        },
    });

    const { data: itemData, loading: itemLoading } =
        useQuery(GET_ITEM, {
            variables: {
                id: parseInt(params.itemID),
            },
            fetchPolicy: 'network-only',
        });

    //mutations
    const [updateItem] = useMutation(UPDATE_ITEM);
    const [deleteItem] = useMutation(DELETE_ITEM);
    const [showEdit, setShowEdit] = React.useState(false);
    const [saving, setSaving] = React.useState(false);

    const loading =
        companiesLoading ||
        categoriesLoading ||
        itemLoading;

    if (loading) {
        return <Loading />;
    }

    const companies = companiesData.basicCompanies;
    const categories = categoriesData.cmdbCategories;
    const item = itemData.cmdbItem;

    const userIsAdmin =
        currentUser && currentUser.role
            ? currentUser.role.level === 0
            : false;

    return (
        <div>
            {!showEdit && (
                <div className="task-add-layout row">
                    <button
                        type="button"
                        disabled={saving}
                        className="btn-link task-add-layout-button btn-distance"
                        onClick={() => {
                            navigate(-1);
                        }}
                    >
                        <span className="material-symbols-outlined">
                            arrow_back
                        </span>
                        {t('back')}
                    </button>
                    {(localProject.right.cmdbWrite ||
                        userIsAdmin) && (
                        <button
                            type="button"
                            disabled={saving}
                            className="btn-link task-add-layout-button btn-distance"
                            onClick={() => {
                                setShowEdit(true);
                            }}
                        >
                            <span className="material-symbols-outlined">
                                edit
                            </span>
                            {t('edit')}
                        </button>
                    )}
                    {(localProject.right.cmdbWrite ||
                        userIsAdmin) && (
                        <button
                            type="button"
                            className="btn-link task-add-layout-button btn-distance"
                            onClick={() => {
                                if (
                                    window.confirm(
                                        t(
                                            'comfirmDeletingCmdbItem'
                                        )
                                    )
                                ) {
                                    setSaving(true);
                                    deleteItem({
                                        variables: {
                                            id: item.id,
                                        },
                                    })
                                        .then(() => {
                                            setSaving(
                                                false
                                            );
                                            navigate(-1);
                                        })
                                        .catch((e) => {
                                            setSaving(
                                                false
                                            );
                                            console.log(e);
                                        });
                                }
                            }}
                        >
                            <span className="material-symbols-outlined">
                                delete
                            </span>
                            {t('delete')}
                        </button>
                    )}
                </div>
            )}
            <ItemForm
                id={item.id}
                edit={true}
                saveItem={async (
                    data,
                    setSaving,
                    afterUpdate
                ) => {
                    setSaving(true);
                    data.deletedImages = [
                        ...getDeletedImages(
                            data.description,
                            item.descriptionImages,
                            'get-cmdb-file'
                        ),
                        ...getDeletedImages(
                            data.backup,
                            item.backupImages,
                            'get-cmdb-file'
                        ),
                        ...getDeletedImages(
                            data.monitoring,
                            item.monitoringImages,
                            'get-cmdb-file'
                        ),
                    ];
                    const separatedDescriptionData =
                        extractImages(data.description);
                    const separatedBackupData =
                        extractImages(data.backup);
                    const separatedMonitoringData =
                        extractImages(data.monitoring);
                    let allFileUploads = [];
                    if (
                        separatedDescriptionData.files
                            .length > 0
                    ) {
                        allFileUploads.push({
                            type: 'descriptionFile',
                            location: 'description',
                            text: separatedDescriptionData.value,
                            files: separatedDescriptionData.files,
                        });
                    }
                    if (
                        separatedBackupData.files.length > 0
                    ) {
                        allFileUploads.push({
                            type: 'backupFile',
                            location: 'backup',
                            text: separatedBackupData.value,
                            files: separatedBackupData.files,
                        });
                    }
                    if (
                        separatedMonitoringData.files
                            .length > 0
                    ) {
                        allFileUploads.push({
                            type: 'monitoringFile',
                            location: 'monitoring',
                            text: separatedMonitoringData.value,
                            files: separatedMonitoringData.files,
                        });
                    }
                    if (allFileUploads.length > 0) {
                        let responses = null;
                        try {
                            responses = await Promise.all(
                                allFileUploads.map(
                                    (fileUpload) => {
                                        const formData =
                                            new FormData();
                                        fileUpload.files.forEach(
                                            (file) =>
                                                formData.append(
                                                    `file`,
                                                    file
                                                )
                                        );
                                        formData.append(
                                            'token',
                                            `${sessionStorage.getItem(
                                                'acctok'
                                            )}`
                                        );
                                        formData.append(
                                            'id',
                                            data.id
                                        );
                                        formData.append(
                                            'type',
                                            fileUpload.type
                                        );
                                        return axios.post(
                                            `${REST_URL}/api/cmdb-upload-text-images`,
                                            formData,
                                            {
                                                headers: {
                                                    'Content-Type':
                                                        'multipart/form-data',
                                                },
                                            }
                                        );
                                    }
                                )
                            );
                        } catch (e) {
                            console.log(e);
                            return;
                        }
                        if (
                            responses.some(
                                (response) =>
                                    !response.data.ok
                            )
                        ) {
                            responses.forEach(
                                (response, index) => {
                                    if (!response.data.ok) {
                                        console.log(
                                            allFileUploads[
                                                index
                                            ].type,
                                            response.data
                                        );
                                    }
                                }
                            );
                            setSaving(false);
                            return;
                        }
                        let changedCKTexts = {
                            description:
                                separatedDescriptionData.value,
                            backup: separatedBackupData.value,
                            monitoring:
                                separatedMonitoringData.value,
                        };
                        responses.forEach(
                            (response, index) => {
                                const fileUpload =
                                    allFileUploads[index];
                                changedCKTexts[
                                    fileUpload.location
                                ] =
                                    replacePlaceholdersWithLinks(
                                        fileUpload.text,
                                        response.data
                                            .attachments,
                                        'get-cmdb-file'
                                    );
                            }
                        );
                        data = {
                            ...data,
                            ...changedCKTexts,
                        };
                        updateItem({ variables: data })
                            .then(() => {
                                setSaving(false);
                                afterUpdate();
                            })
                            .catch((e) => {
                                console.log(e);
                                setSaving(false);
                            });
                    } else {
                        updateItem({ variables: data })
                            .then(() => {
                                setSaving(false);
                                afterUpdate();
                            })
                            .catch((e) => {
                                console.log(e);
                                setSaving(false);
                            });
                    }
                }}
                disabled={!showEdit}
                close={() => setShowEdit(false)}
                companies={toSelArr(companies)}
                categories={toSelArr(categories)}
                item={item}
            />
        </div>
    );
}
