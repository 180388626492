import { defaultFilter } from 'configs/constants/filter';
import React from 'react';

import { useNavigate } from 'react-router-dom';

export default function Reroute() {
    const navigate = useNavigate();
    React.useEffect(() => {
        navigate(`/helpdesk/taskList/i/${defaultFilter}`);
    });
    return null;
}
