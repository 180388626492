import { gql } from '@apollo/client';

export const subfolder = `
    id
    createdAt
    updatedAt
    title
    mainFolder {
      id
      title
      myRights {
        active
        read
        write
        manage
      }
    }
    parentFolder {
      id
      title
    }
    parentSubfolder {
      id
      title
    }
`;

export const GET_SUBFOLDER = gql`
query lanwikiSubfolder(
  $id: Int!
){
  lanwikiSubfolder (
    id: $id
  ){
    ${subfolder}
    childrenSubfolders{
      ${subfolder}
      childrenSubfolders{
        ${subfolder}
        childrenSubfolders{
          ${subfolder}
        }
      }
    }
  }
}
`;

export const ADD_SUBFOLDER = gql`
    mutation addLanwikiSubfolder(
        $title: String!
        $folderId: Int
        $subfolderId: Int
    ) {
        addLanwikiSubfolder(
            title: $title
            folderId: $folderId
            subfolderId: $subfolderId
        ) {
            id
        }
    }
`;

export const UPDATE_SUBFOLDER = gql`
    mutation updateLanwikiSubfolder(
        $id: Int!
        $title: String!
    ) {
        updateLanwikiSubfolder(id: $id, title: $title) {
            id
        }
    }
`;

export const DELETE_SUBFOLDER = gql`
    mutation deleteLanwikiSubfolder($id: Int!) {
        deleteLanwikiSubfolder(id: $id) {
            id
        }
    }
`;
