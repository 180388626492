import React from 'react';

import { useQuery, useSubscription, useMutation } from '@apollo/client';
import SettingLoading from '../components/settingLoading';
import SettingListContainer from '../components/settingListContainer';
import { itemAttributesFullfillsString } from '../components/helpers';

import ProjectAdd from './projectAdd';
import ProjectEdit from './projectEdit';
import { useTranslation } from 'react-i18next';
import { GET_PROJECTS, PROJECTS_SUBSCRIPTION, DELETE_PROJECT } from 'helpdesk/queries';
import { addLocalError } from 'apollo/localSchema/actions';

export default function ProjectsList() {
    const { t } = useTranslation();

    const [deleteProject] = useMutation(DELETE_PROJECT);

    const {
        data: projectsData,
        loading: projectsLoading,
        refetch: projectsRefetch,
    } = useQuery(GET_PROJECTS, {
        fetchPolicy: 'network-only',
    });

    // state
    const [projectFilter, setProjectFilter] = React.useState('');

    useSubscription(PROJECTS_SUBSCRIPTION, {
        onData: () => {
            projectsRefetch();
        },
    });

    const [editedItemId, setEditedItemId] = React.useState(null);

    if (projectsLoading) {
        return <SettingLoading />;
    }

    const projects = projectsData.projects;

    const deleteProjectFunc = (id) => {
        if (window.confirm(t('deleteProjectMessage'))) {
            deleteProject({
                variables: {
                    id,
                },
            }).catch((err) => {
                addLocalError(err);
            });
        }
    };

    const getProjectStat = (project) => {
        let color = 'red';
        let text = t('no');
        let iconName = 'cancel';
        if (project.right && project.right.projectWrite) {
            color = 'green';
            text = t('yes');
            iconName = 'check_circle';
        }
        return (
            <span
                style={{
                    color,
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                <span className="material-symbols-outlined p-r-5">{iconName}</span>
                {` ${text}`}
            </span>
        );
    };

    return (
        <SettingListContainer
            header={t('projects')}
            addLabel={t('project')}
            filter={projectFilter}
            setFilter={setProjectFilter}
            AddComponent={ProjectAdd}
            EditComponent={ProjectEdit}
            editedItemId={editedItemId}
            setEditedItemId={setEditedItemId}
        >
            <table className="table">
                <thead>
                    <tr>
                        <th>{t('title')}</th>
                        <th>{t('access')}</th>
                        <th width="100px">{t('actions')}</th>
                    </tr>
                </thead>
                <tbody>
                    {projects
                        .filter((item) =>
                            itemAttributesFullfillsString(item, projectFilter, ['title'])
                        )
                        .map((project) => (
                            <tr key={project.id}>
                                <td>{project.title}</td>
                                <td>{getProjectStat(project)}</td>
                                <td className="row">
                                    <button
                                        className="btn-link-red m-r-0 m-l-0 p-0"
                                        onClick={() => {
                                            deleteProjectFunc(project.id);
                                        }}
                                    >
                                        <span className="material-symbols-outlined">delete</span>
                                    </button>
                                    <button
                                        className="btn-link m-r-0 m-l-10 p-0"
                                        onClick={() => {
                                            setEditedItemId(project.id);
                                        }}
                                    >
                                        <span className="material-symbols-outlined">edit</span>
                                    </button>
                                </td>
                            </tr>
                        ))}
                </tbody>
            </table>
        </SettingListContainer>
    );
}
