import React from 'react';
import { translateSelectItem } from 'helperFunctions';
import { useTranslation } from 'react-i18next';
import ProjectSingleAttribute from './singleAttribute';
import {
    emptyUserValue,
    emptyCompanyValue,
    emptyStatus,
    emptyAssigned,
} from 'configs/constants/projects';

export const noSelect = {
    label: 'None',
    labelId: 'none',
    title: 'None',
    value: null,
    id: null,
};

export default function ProjectAttributes(props) {
    //data
    const {
        statuses,
        users,
        assignableUsers,
        allTags,
        companies,
        groups,
        setGroups,
        attributes,
        setAttributes,
    } = props;

    const { t } = useTranslation();

    React.useEffect(() => {
        if (
            attributes.assigned.value.some(
                (user1) => !assignableUsers.some((user2) => user1.id === user2.id)
            )
        ) {
            setAttributes({
                ...attributes,
                assigned: {
                    ...attributes.assigned,
                    value: attributes.assigned.value.filter((user1) =>
                        assignableUsers.some((user2) => user1.id === user2.id)
                    ),
                },
            });
        }
    }, [assignableUsers]);

    return (
        <div>
            <ProjectSingleAttribute
                label={t('status')}
                roles={groups}
                right="status"
                defColored
                defSelectValues={statuses}
                defEmptyValue={translateSelectItem(emptyStatus, t)}
                attribute={attributes.status}
                onChangeAttribute={(value) => {
                    if (!attributes.status.fixed && value.fixed && value.value === null) {
                        setAttributes({
                            ...attributes,
                            status: {
                                ...value,
                                value: statuses.find((status) => status.action === 'IsNew'),
                            },
                        });
                    } else {
                        setAttributes({ ...attributes, status: value });
                    }
                }}
                onChangeRight={(roles) => setGroups(roles)}
            />

            <ProjectSingleAttribute
                label={t('tags')}
                roles={groups}
                right="tags"
                defColored
                defIsMulti
                defSelectValues={allTags}
                attribute={attributes.tags}
                onChangeAttribute={(value) => {
                    setAttributes({ ...attributes, tags: value });
                }}
                onChangeRight={(roles) => setGroups(roles)}
            />

            <ProjectSingleAttribute
                label={t('assignedTo')}
                roles={groups}
                right="assigned"
                defIsMulti
                defSelectValues={assignableUsers}
                defEmptyValue={translateSelectItem(emptyAssigned, t)}
                attribute={attributes.assigned}
                onChangeAttribute={(value) => {
                    setAttributes({ ...attributes, assigned: value });
                }}
                onChangeRight={(roles) => {
                    setGroups(roles);
                }}
            />

            <ProjectSingleAttribute
                label={t('requester')}
                roles={groups}
                right="requester"
                defSelectValues={users}
                defEmptyValue={translateSelectItem(emptyUserValue, t)}
                attribute={attributes.requester}
                onChangeAttribute={(value) => {
                    setAttributes({ ...attributes, requester: value });
                }}
                onChangeRight={(roles) => setGroups(roles)}
            />

            <ProjectSingleAttribute
                label={t('company')}
                roles={groups}
                right="company"
                defSelectValues={companies}
                defEmptyValue={translateSelectItem(emptyCompanyValue, t)}
                attribute={attributes.company}
                onChangeAttribute={(value) => {
                    setAttributes({ ...attributes, company: value });
                }}
                onChangeRight={(roles) => setGroups(roles)}
            />
            <ProjectSingleAttribute
                label={t('repeat')}
                roles={groups}
                right="repeat"
                noDef
                noRequired
                onChangeRight={(roles) => setGroups(roles)}
            />

            <ProjectSingleAttribute
                label={t('startsAt')}
                roles={groups}
                right="startsAt"
                canBeRequired
                dataType="date"
                attribute={attributes.startsAt}
                onChangeAttribute={(value) => {
                    setAttributes({ ...attributes, startsAt: value });
                }}
                onChangeRight={(roles) => setGroups(roles)}
            />

            <ProjectSingleAttribute
                label={t('deadline')}
                roles={groups}
                right="deadline"
                canBeRequired
                dataType="date"
                attribute={attributes.deadline}
                onChangeAttribute={(value) => {
                    setAttributes({ ...attributes, deadline: value });
                }}
                onChangeRight={(roles) => setGroups(roles)}
            />
        </div>
    );
}
