export default {
    repeatEvery: {
        en: 'Repeat every',
        sk: 'Opakovať každý',
        cz: 'Opakujte každý',
        de: 'Wiederhole jeden',
    },
    noRepeat: {
        en: 'No repeat',
        sk: 'Bez opakovania',
        cz: 'Žádné opakování',
        de: 'Keine Wiederholung',
    },
    repeat: {
        en: 'Repeat',
        sk: 'Opakovanie',
        cz: 'Opakovat',
        de: 'Wiederholen',
    },
    none: {
        en: 'None',
        sk: 'Žiadny',
        cz: 'Žádný',
        de: 'Keiner',
    },
    day: {
        en: 'Day',
        sk: 'Deň',
        cz: 'Den',
        de: 'Tag',
    },
    week: {
        en: 'Week',
        sk: 'Týždeň',
        cz: 'Týden',
        de: 'Woche',
    },
    month: {
        en: 'Month',
        sk: 'Mesiac',
        cz: 'Měsíc',
        de: 'Monat',
    },
    repetition: {
        en: 'Repetition',
        sk: 'Opakovanie',
        cz: 'Opakování',
        de: 'Wiederholung',
    },
    startDate: {
        en: 'Start date',
        sk: 'Dátum začiatku',
        cz: 'Počáteční datum',
        de: 'Anfangsdatum',
    },
    noStartDate: {
        en: 'No start date',
        sk: 'Bez dátumu začiatku',
        cz: 'Žádné datum zahájení',
        de: 'Kein Startdatum',
    },
    plannedAt: {
        en: 'Planned at',
        sk: 'Plánovanie',
        cz: 'Plánováno na',
        de: 'Geplant um',
    },
    plannedAtPlaceholder: {
        en: 'Enter planned date',
        sk: 'Zadajte plánovanie',
        cz: 'Zadejte plánované datum',
        de: 'Geplantes Datum eingeben',
    },
    noPlannedAt: {
        en: 'No planned date',
        sk: 'Bez plánovania',
        cz: 'Žádné plánované datum',
        de: 'Kein geplantes Datum',
    },
    taskCreationDate: {
        en: 'Task creation date',
        sk: 'Dátum vytvorenia úlohy',
        cz: 'Datum vytvoření úkolu',
        de: 'Erstellungsdatum der Aufgabe',
    },
    number: {
        en: 'Number',
        sk: 'Číslo',
        cz: 'Číslo',
        de: 'Nummer',
    },
    enterNumber: {
        en: 'Enter number',
        sk: 'Zadajte číslo',
        cz: 'Vložte číslo',
        de: 'Nummer eingeben',
    },
    warningMustBeMoreThan0: {
        en: 'Must be bigger than 0',
        sk: 'Musí byť viac ako 0',
        cz: 'Musí být větší než 0',
        de: 'Muss größer als 0 sein',
    },
    active: {
        en: 'Active',
        sk: 'Zapnutý',
        cz: 'Aktivní',
        de: 'Aktiv',
    },
    noStatus: {
        en: 'No status',
        sk: 'Bez statusu',
        cz: 'Žádný stav',
        de: 'Kein Status',
    },
    repeatTiming: {
        en: 'Repeat timing',
        sk: 'Opakovanie',
        cz: 'Opakujte načasování',
        de: 'Zeitmessung wiederholen',
    },
    deleteRepeatMessage: {
        en: 'Are you sure you want to delete this repeat?',
        sk: 'Naozaj chcete zmazať túto opakovanú úlohu?',
        cz: 'Opravdu chcete toto opakování smazat?',
        de: 'Möchten Sie diese Wiederholung wirklich löschen?',
    },
    addRepeat: {
        en: 'Add repeat',
        sk: 'Pridať opakovanie',
        cz: 'Přidejte opakování',
        de: 'Wiederholung hinzufügen',
    },
    editRepeat: {
        en: 'Edit repeat',
        sk: 'Upraviť opakovanie',
        cz: 'Upravit opakování',
        de: 'Wiederholung bearbeiten',
    },
    taskTitlePlaceholder: {
        en: 'Enter task name',
        sk: 'Zadajte názov úlohy',
        cz: 'Zadejte název úkolu',
        de: 'Aufgabennamen eingeben',
    },
    pendingDate: {
        en: 'Pending date',
        sk: 'Dátum odloženia',
        cz: 'Nevyřízené datum',
        de: 'Ausstehendes Datum',
    },
    pendingDateLabel: {
        en: 'No pending date',
        sk: 'Bez dátumu odloženia',
        cz: 'Žádné nevyřízené datum',
        de: 'Kein anstehendes Datum',
    },
    taskNotAssigned: {
        en: 'Task not assigned',
        sk: 'Úloha nepriradená',
        cz: 'Úkol nebyl přidělen',
        de: 'Aufgabe nicht zugewiesen',
    },
    closedAt: {
        en: 'Closed at',
        sk: 'Uzatvorené dňa',
        cz: 'Zavřeno v',
        de: 'Geschlossen um',
    },
    title: {
        en: 'Title',
        sk: 'Názov',
        cz: 'Titul',
        de: 'Titel',
    },
    titleLabel: {
        en: 'Enter title',
        sk: 'Zadajte názov',
        cz: 'Zadejte název',
        de: 'Titel eingeben',
    },
    project: {
        en: 'Project',
        sk: 'Projekt',
        cz: 'Projekt',
        de: 'Projekt',
    },
    noProject: {
        en: 'No project',
        sk: 'Žiadnz projekt',
        cz: 'Žádný projekt',
        de: 'Kein Projekt',
    },
    projectLabel: {
        en: 'Enter project',
        sk: 'Zadajte projekt',
        cz: 'Zadejte projekt',
        de: 'Projekt eingeben',
    },
    tags: {
        en: 'Tags',
        sk: 'Tagy',
        cz: 'Tagy',
        de: 'Stichworte',
    },
    noTags: {
        en: 'No tags',
        sk: 'Žiadne tagy',
        cz: 'Žádné značky',
        de: 'Keine Tags',
    },
    tag: {
        en: 'Tag',
        sk: 'Tag',
        cz: 'Štítek',
        de: 'Schild',
    },
    works: {
        en: 'Works',
        sk: 'Práce',
        cz: 'Práce',
        de: 'Arbeit',
    },
    trip: {
        en: 'Work trip',
        sk: 'Výjazd',
        cz: 'Pracovní cesta',
        de: 'Arbeitsreise',
    },
    trips: {
        en: 'Trips',
        sk: 'Výjazdy',
        cz: 'Výlety',
        de: 'Reisen',
    },
    vat: {
        en: 'VAT',
        sk: 'DPH',
        cz: 'DPH',
        de: 'MwSt',
    },
    priceWithVat: {
        en: 'Price with VAT',
        sk: 'Cena s DPH',
        cz: 'Cena s DPH',
        de: 'Preis mit MwSt',
    },
    priceWithoutVat: {
        en: 'Price without VAT',
        sk: 'Cena bez DPH',
        cz: 'Cena bez DPH',
        de: 'Preis ohne MwSt',
    },
    taskTitle: {
        en: 'Task title',
        sk: 'Názov úlohy',
        cz: 'Název úkolu',
        de: 'Aufgabentitel',
    },
    requester: {
        en: 'Requester',
        sk: 'Zadal',
        cz: 'Žadatel',
        de: 'Anforderer',
    },
    noRequester: {
        en: 'No requester',
        sk: 'Žiadny zadávateľ',
        cz: 'Žádný žadatel',
        de: 'Kein Anforderer',
    },
    requesterPlaceholder: {
        en: 'Enter requester',
        sk: 'Zadajte žiadateľa',
        cz: 'Zadejte žadatele',
        de: 'Anforderer eingeben',
    },
    assignedTo: {
        en: 'Assigned to',
        sk: 'Rieši',
        cz: 'Přiřazen',
        de: 'Zugewiesen an',
    },
    company: {
        en: 'Company',
        sk: 'Firma',
        cz: 'Společnost',
        de: 'Gesellschaft',
    },
    noCompany: {
        en: 'No company',
        sk: 'Žiadna firma',
        cz: 'Žádná společnost',
        de: 'Kein Unternehmen',
    },
    companyPlaceholder: {
        en: 'Select company',
        sk: 'Zadajte firmu',
        cz: 'Vyberte společnost',
        de: 'Unternehmen auswählen',
    },
    status: {
        en: 'Status',
        sk: 'Status',
        cz: 'Postavení',
        de: 'Status',
    },
    statusPlaceholder: {
        en: 'Status required',
        sk: 'Status povinný',
        cz: 'Stav je vyžadován',
        de: 'Status erforderlich',
    },
    statusDate: {
        en: 'Status date',
        sk: 'Status dátum',
        cz: 'Datum stavu',
        de: 'Status Datum',
    },
    closeDate: {
        en: 'Close date',
        sk: 'Dátum uzavretia',
        cz: 'Datum uzavření',
        de: 'Abschlussdatum',
    },
    scheduledDate: {
        en: 'Scheduled date',
        sk: 'Dátum naplánovania',
        cz: 'Plánovaný termín',
        de: 'Geplantes Datum',
    },
    createdAt: {
        en: 'Created at',
        sk: 'Vytvorené',
        cz: 'Vytvořeno v',
        de: 'Hergestellt in',
    },
    deadline: {
        en: 'Deadline',
        sk: 'Deadline',
        cz: 'Uzávěrka',
        de: 'Termin',
    },
    deadlinePlaceholder: {
        en: 'No deadline',
        sk: 'Bez deadlinu',
        cz: 'Žádný termín',
        de: 'Keine Frist',
    },
    /*
    workType: {
        en: 'Work type',
        sk: 'Typ práce',
        cz: 'Typ práce',
        de: 'Arbeitstyp',
  },*/
    taskTypePlaceholder: {
        en: 'Select task type',
        sk: 'Vyberte typ úlohy',
        cz: 'Vyberte typ úkolu',
        de: 'Aufgabentyp auswählen',
    } /*
  pausal: {
    en: 'Pausal',
    sk: 'Paušál',
    cz: 'Paušál',
    de: 'Pausal',
  },*/,
    important: {
        en: 'Important',
        sk: 'Dôležité',
        cz: 'Důležité',
        de: 'Wichtig',
    },
    /*  overtime: {
      en: 'Overtime',
      sk: 'Mimo pracovného času',
      cz: 'Přesčas',
      de: 'Im Laufe der Zeit',
    },
    overtimeShort: {
      en: 'Overtime',
      sk: 'Mimo PH',
      cz: 'Přesčas',
      de: 'Im Laufe der Zeit',
    },*/
    taskDescription: {
        en: 'Task description',
        sk: 'Popis úlohy',
        cz: 'Popis ulohy',
        de: 'Aufgabenbeschreibung',
    },
    noTaskDescription: {
        en: "Task doesn't have description",
        sk: 'Úloha nemá popis',
        cz: 'Úkol nemá popis',
        de: 'Aufgabe hat keine Beschreibung',
    },
    attachment: {
        en: 'Attachment',
        sk: 'Príloha',
        cz: 'Příloha',
        de: 'Anhang',
    },
    shortSubtaskTitle: {
        en: 'Short subtask title',
        sk: 'Názov podúlohy',
        cz: 'Krátký název podúkolu',
        de: 'Kurzer Titel der Teilaufgabe',
    },
    newShortSubtaskTitle: {
        en: 'New short subtask title',
        sk: 'Názov novej podúlohy',
        cz: 'Nový krátký název dílčího úkolu',
        de: 'Neuer kurzer Unteraufgabentitel',
    },
    subtask: {
        en: 'Subtask',
        sk: 'Práca',
        cz: 'Dílčí úkol',
        de: 'Unteraufgabe',
    },
    shortSubtask: {
        en: 'Short subtask',
        sk: 'Podúloha',
        cz: 'Krátký dílčí úkol',
        de: 'Kurze Teilaufgabe',
    },
    shortSubtasks: {
        en: 'Short subtasks',
        sk: 'Podúlohy',
        cz: 'Krátké dílčí úkoly',
        de: 'Kurze Teilaufgaben',
    },
    repeatAutoupdateMessage: {
        en: 'New Repeat time has been loaded, you can just save the changes or edit repeat!',
        sk: 'Nový čas opakovanej úlohy bol načítavaný, ak vám vyhovuje, možete tieto zmeny uložiť alebo upraviť!',
        cz: 'Nový čas opakování byl načten, stačí uložit změny nebo upravit opakování!',
        de: 'Neue Wiederholungszeit wurde geladen, Sie können die Änderungen einfach speichern oder die Wiederholung bearbeiten!',
    },
    updateRepeat: {
        en: 'Update repeat',
        sk: 'Aktualizovať opakovanie',
        cz: 'Opakování aktualizace',
        de: 'Aktualisierung wiederholen',
    },
    createRepeat: {
        en: 'Create repeat',
        sk: 'Vytvoriť opakovanie',
        cz: 'Vytvořte opakování',
        de: 'Wiederholung erstellen',
    },
    tagsPlaceholder: {
        en: 'Select tags',
        sk: 'Vyberte tagy',
        cz: 'Vyberte značky',
        de: 'Tags auswählen',
    },
    providedItemsProblem: {
        en: 'Provided items are of value',
        sk: 'Dodané veci majú hodnotu',
        cz: 'Dodané položky mají hodnotu',
        de: 'Zur Verfügung gestellte Gegenstände sind von Wert',
    },
    fromTotalOf: {
        en: 'from total of',
        sk: 'z celkového počtu',
        cz: 'z celkového počtu',
        de: 'von insgesamt',
    },
    to2: {
        en: 'To',
        sk: 'Príjemca',
        cz: 'Příjemce',
        de: 'Zu',
    },
    emailNotSendError: {
        en: 'E-mail failed to send! Check console for more information',
        sk: 'E-mail sa neodoslal! Prosím skontrolujte koznolu pre viac informácii',
        cz: 'Odeslání e-mailu se nezdařilo! Další informace naleznete v konzole',
        de: 'E-Mail konnte nicht gesendet werden! Weitere Informationen finden Sie in der Konsole',
    },
    from2: {
        en: 'From',
        sk: 'Odosielateľ',
        cz: 'Odesílatel',
        de: 'Aus',
    },
    unknownUser: {
        en: 'Unknown user',
        sk: 'Neznámy používateľ',
        cz: 'Unknown user',
        de: 'Unbekannter Benutzer',
    },
    internalMessage: {
        en: 'Internal message',
        sk: 'Interná správa',
        cz: 'Interní zpráva',
        de: 'Interne Nachricht',
    },
    internal: {
        en: 'Internal',
        sk: 'Interná',
        cz: 'Vnitřní',
        de: 'Intern',
    },
    comment: {
        en: 'Comment',
        sk: 'Komentár',
        cz: 'Komentář',
        de: 'Kommentar',
    },
    comments2: {
        en: 'Comments',
        sk: 'Komentárov',
        cz: 'Komentáře',
        de: 'Kommentare',
    },
    message: {
        en: 'Message',
        sk: 'Správa',
        cz: 'Zpráva',
        de: 'Nachricht',
    },
    subjectPlaceholder: {
        en: 'Enter subject',
        sk: 'Zajate predmet',
        cz: 'Zadejte předmět',
        de: 'Betreff eingeben',
    },
    taskTitleCantBeEmpty: {
        en: "Task title can't be empty!",
        sk: 'Názov úlohy nemôže byť prázdny',
        cz: 'Název úkolu nemůže být prázdný!',
        de: 'Aufgabentitel darf nicht leer sein!',
    },
    taskProjectIsMissing: {
        en: 'Task project is missing!',
        sk: 'Chýba projekt úlohy!',
        cz: 'Chybí úkolový projekt!',
        de: 'Aufgabenprojekt fehlt!',
    },
    taskMustBeAssigned: {
        en: 'Task must be assigned to someone!',
        sk: 'Úloha musí byť priradená niekomu!',
        cz: 'Úkol musí být někomu přidělen!',
        de: 'Aufgabe muss jemandem zugewiesen werden!',
    },
    taskStatusMissing: {
        en: 'Task status is missing!',
        sk: 'Úlohe chýba status!',
        cz: 'Chybí stav úkolu!',
        de: 'Aufgabenstatus fehlt!',
    },
    taskAssignedWrongFixed: {
        en: 'Assigned is fixed but either assigned user is not you, empty or set value of project',
        sk: 'Fixné pole priradené používateľovi nie ste vy, prázdna hodnota alebo predvolená hodnota projektu',
        cz: 'Přiřazený je pevně daný, ale buď přiřazeným uživatelem nejste vy, prázdný nebo nastavená hodnota projektu',
        de: 'Zugewiesen ist fest, aber entweder ist der zugewiesene Benutzer nicht Sie, leer oder der festgelegte Wert des Projekts',
    },
    isRequiredButEmpty: {
        en: 'is required but empty',
        sk: 'je povinný ale prázdny',
        cz: 'je povinný, ale prázdný',
        de: 'ist erforderlich, aber leer',
    },
    withoutTaskTypeCantCreateWorksAndTrips: {
        en: "Without task type you can't create works and trips!",
        sk: 'Bez typu úlohy nemôžte vytvárať práce a výjazdy!',
        cz: 'Bez typu úkolu nemůžete vytvářet práce a výlety!',
        de: 'Ohne Aufgabentyp können Sie keine Arbeiten und Reisen erstellen!',
    },
    taskWasntAssignedToAnyoneCantCreateWorksTrips: {
        en: "Task wasn't assigned to anyone, you can't create works and trips!",
        sk: 'Úloha nebola nikomu priradená a preto neviete vytvárať práce a výjazdy!',
        cz: 'Úkol nebyl nikomu přidělen, nemůžete vytvářet práce a výlety!',
        de: 'Die Aufgabe wurde niemandem zugewiesen, Sie können keine Arbeiten und Reisen erstellen!',
    },
    assignedToIsNotVisibleCantCreateWorksTrips: {
        en: "You cannot see who is assigned to this task and so can't create works and trips!",
        sk: 'Nemáte právo vidieť komu bola úloha priradená a preto neviete vytvárať práce a výjazdy!',
        cz: 'Nevidíte, kdo je přiřazen k tomuto úkolu, a tak nemůžete vytvářet práce a cesty!',
        de: 'Sie können nicht sehen, wer dieser Aufgabe zugeordnet ist, und können daher keine Arbeiten und Reisen erstellen!',
    },
    unspecifiedChangeMessage: {
        en: 'Unspecified change was made.',
        sk: 'Nešpecifikovaná zmena bola vykonaná.',
        cz: 'Byla provedena blíže nespecifikovaná změna.',
        de: 'Es wurde eine nicht näher bezeichnete Änderung vorgenommen.',
    },
    history: {
        en: 'History',
        sk: 'História',
        cz: 'Dějiny',
        de: 'Geschichte',
    },
    changesMadeBy: {
        en: 'Changes made by',
        sk: 'Zmeny vykonal',
        cz: 'Změny provedené uživatelem',
        de: 'Änderungen vorgenommen von',
    },
    historyIsEmpty: {
        en: 'History is empty',
        sk: 'História je prázdna',
        cz: 'Historie je prázdná',
        de: 'Die Geschichte ist leer',
    },
    assignedTaskToSomeone: {
        en: 'Assign the task to someone',
        sk: 'Priraďte niekomu úlohu',
        cz: 'Přidělte úkol někomu',
        de: 'Weisen Sie die Aufgabe jemandem zu',
    },
    pickTaskType: {
        en: 'Pick task type',
        sk: 'Vyberte typ úlohy',
        cz: 'Vyberte typ úkolu',
        de: 'Aufgabentyp auswählen',
    },
    pickCompany: {
        en: 'Pick company',
        sk: 'Vyberte firmu',
        cz: 'Vyberte společnost',
        de: 'Unternehmen auswählen',
    },
    first: {
        en: 'First',
        sk: 'Najprv',
        cz: 'První',
        de: 'Zuerst',
    },
    and: {
        en: 'And',
        sk: 'A',
        cz: 'A',
        de: 'Und',
    },
    noScheduledDate: {
        en: 'No scheduled date',
        sk: 'Bez dátumu naplánovania',
        cz: 'Žádné plánované datum',
        de: 'Kein geplanter Termin',
    },
    noEndDate: {
        en: 'No end',
        sk: 'Bez konca',
        cz: 'Bez konce',
        de: 'Kein Ende',
    },
    done: {
        en: 'Done',
        sk: 'Dokončené',
        cz: 'Hotovo',
        de: 'Fertig',
    },
    price: {
        en: 'Price',
        sk: 'Cena',
        cz: 'Cena',
        de: 'Preis',
    },
    quantity: {
        en: 'Quantity',
        sk: 'Množstvo',
        cz: 'Množství',
        de: 'Menge',
    },
    enterTitle: {
        en: 'Enter title',
        sk: 'Zadajte názov',
        cz: 'Zadejte název',
        de: 'Titel eingeben',
    },
    total: {
        en: 'Total',
        sk: 'Spolu',
        cz: 'Celkový',
        de: 'Gesamt',
    },
    addNote: {
        en: 'Add note',
        sk: 'Pridať poznámku',
        cz: 'Přidat poznámku',
        de: 'Notiz hinzufügen',
    },
    budget: {
        en: 'Budget',
        sk: 'Rozpočet',
        cz: 'Rozpočet',
        de: 'Budget',
    },
    work: {
        en: 'Work',
        sk: 'Práca',
        cz: 'Práce',
        de: 'Arbeit',
    },
    discount: {
        en: 'Discount',
        sk: 'Zľava',
        cz: 'Sleva',
        de: 'Rabatt',
    },
    selectTaskFromTheLeft: {
        en: 'Pick a task from the list to the left.',
        sk: 'Vyberte úloha zo zoznamu naľavo.',
        cz: 'Vyberte úkol ze seznamu nalevo.',
        de: 'Wählen Sie eine Aufgabe aus der Liste auf der linken Seite aus.',
    },
    createNewTask: {
        en: 'Create new task',
        sk: 'Vytvoriť novú úlohu',
        cz: 'Vytvořte nový úkol',
        de: 'Neue Aufgabe erstellen',
    },
    selectProject: {
        en: 'Select project',
        sk: 'Vyberte projekt',
        cz: 'Vyberte projekt',
        de: 'Projekt auswählen',
    },
    layout: {
        en: 'Layout',
        sk: 'Rozloženie',
        cz: 'Rozložení',
        de: 'Layout',
    },
    newTaskTitlePlaceholder: {
        en: 'Enter new task name',
        sk: 'Zadajte názov novej úlohy',
        cz: 'Zadejte nový název úkolu',
        de: 'Geben Sie den neuen Aufgabennamen ein',
    },
    noCloseDate: {
        en: 'No close date',
        sk: 'Bez dátumu uzavretia',
        cz: 'Žádné datum uzavření',
        de: 'Kein Schlusstermin',
    },
    taskUnassigned: {
        en: 'Task unassigned',
        sk: 'Úloha nepriradená',
        cz: 'Úkol nepřiřazen',
        de: 'Aufgabe nicht zugewiesen',
    },
    selectRecommended: {
        en: 'Select recommended',
        sk: 'Výber odporúčaný',
        cz: 'Vyberte doporučené',
        de: 'Empfohlen auswählen',
    },
    selectRequired: {
        en: 'Select required',
        sk: 'Výber povinný',
        cz: 'Vyberte požadované',
        de: 'Erforderlich auswählen',
    },
    /*
  selectType: {
    en: 'Select type',
    sk: 'Vyberte typ',
    cz: 'Vyberte typ',
    de: 'Art auswählen',
  },
  */
    noTaskType: {
        en: 'No task type',
        sk: 'Bez typu úlohy',
        cz: 'Žádný typ úkolu',
        de: 'Kein Aufgabentyp',
    },
    startsAt: {
        en: 'Starts at',
        sk: 'Začiatok',
        cz: 'Začíná v',
        de: 'Startet um',
    },
    creating: {
        en: 'Creating',
        sk: 'Vytvára sa',
        cz: 'Vytváření',
        de: 'Erstellen',
    },
    createTask: {
        en: 'Create task',
        sk: 'Vytvoriť úlohu',
        cz: 'Vytvořit úkol',
        de: 'Aufgabe erstellen',
    },
    startsAtPlaceholder: {
        en: 'No start date',
        sk: 'Bez dátumu začiatku',
        cz: 'Žádné datum zahájení',
        de: 'Kein Startdatum',
    },
    back: {
        en: 'Back',
        sk: 'Späť',
        cz: 'Zpět',
        de: 'Der Rücken',
    },
    taskInvoiced: {
        en: 'Task invoiced',
        sk: 'Úloha vykázaná',
        cz: 'Úkol fakturován',
        de: 'Aufgabe in Rechnung gestellt',
    },
    createdBy: {
        en: 'Created by',
        sk: 'Vytvoril',
        cz: 'Vytvořil',
        de: 'Erstellt von',
    },
    atDate: {
        en: 'at',
        sk: 'o',
        cz: 'o',
        de: 'bei',
    },
    noPendingDate: {
        en: 'No pending date',
        sk: 'Bez dátumu odloženia',
        cz: 'Žádné nevyřízené datum',
        de: 'Kein anstehendes Datum',
    },
    statusChangedAt: {
        en: 'Status changed at',
        sk: 'Status zmenený',
        cz: 'Stav změněn v',
        de: 'Status geändert um',
    },
    addUserLabel: {
        en: '+ Add user',
        sk: '+ Pridať používateľa',
        cz: '+ Přidat uživatele',
        de: '+ Benutzer hinzufügen',
    },
    addCompanyLabel: {
        en: '+ Add company',
        sk: '+ Pridať firmu',
        cz: '+ Přidat společnost',
        de: '+ Unternehmen hinzufügen',
    },
    /*  pausalSubtasks: {
      en: 'Pausal subtasks',
      sk: 'Paušál prác',
      cz: 'Pozastavení dílčích úkolů',
      de: 'Pausierende Unteraufgaben',
    },
    pausalTrips: {
      en: 'Pausal trips',
      sk: 'Paušál výjazdov',
      cz: 'Přestávkové výlety',
      de: 'Pausenfahrten',
    },*/
    comments: {
        en: 'Comments',
        sk: 'Komentáre',
        cz: 'Komentáře',
        de: 'Kommentare',
    },
    addUser: {
        en: 'Add user',
        sk: 'Pridať používateľa',
        cz: 'Přidat uživatele',
        de: 'Nutzer hinzufügen',
    },
    priceWithoutTax: {
        en: 'Price without tax',
        sk: 'Cena bez DPH',
        cz: 'Cena bez daně',
        de: 'Preis ohne MwSt',
    },
    priceWithTax: {
        en: 'Price with tax',
        sk: 'Cena s DPH',
        cz: 'Cena s daní',
        de: 'Preis mit MwSt',
    },
    taskStack: {
        en: 'Task stack',
        sk: 'Úlohy',
        cz: 'Zásobník úkolů',
        de: 'Aufgabenstapel',
    },
    moveOnlyThisEventHere: {
        en: 'Move only this event here',
        sk: 'Presunúť iba túto udalosť sem',
        cz: 'Sem přesunout pouze tuto událost',
        de: 'Verschieben Sie nur dieses Ereignis hierher',
    },
    editRepeatTemplateOfThisEvent: {
        en: 'Edit repeat template of this event',
        sk: 'Upraviť opakovanú úlohu tejto udalosti',
        cz: 'Upravit šablonu opakování této události',
        de: 'Wiederholungsvorlage dieses Termins bearbeiten',
    },
    repeatDetails: {
        en: 'Repeat details',
        sk: 'Podrobnosti opakovania',
        cz: 'Opakujte podrobnosti',
        de: 'Einzelheiten wiederholen',
    },
    repeatId: {
        en: 'Repeat id',
        sk: 'ID opakovania',
        cz: 'ID opakovaní',
        de: 'ID wiederholen',
    },
    repeatedEvery: {
        en: 'Repeated every',
        sk: 'Opakované každý',
        cz: 'Opakovaně každý',
        de: 'Jeden wiederholt',
    },
    taskWillBeNamed: {
        en: 'Task title will be named',
        sk: 'Názov úlohy bude',
        cz: 'Název úkolu bude pojmenován',
        de: 'Der Aufgabentitel wird benannt',
    },
    repeatOptions: {
        en: 'Repeat options',
        sk: 'Možnosti opakovanej úlohy',
        cz: 'Opakování možností',
        de: 'Optionen wiederholen',
    },
    repeatTimeOptions: {
        en: 'Repeat time options',
        sk: 'Možnosti udalosti opakovanej úlohy',
        cz: 'Možnosti opakování času',
        de: 'Zeitoptionen wiederholen',
    },
    editRepeatTemplateToThisTime: {
        en: 'Edit repeat template to this time',
        sk: 'Upraviť opakovanú úlohu na tento čas',
        cz: 'Upravte šablonu opakování na tento čas',
        de: 'Wiederholvorlage bis zu diesem Zeitpunkt bearbeiten',
    },
    repeatTask: {
        en: 'Repeat task',
        sk: 'Opakovaná úloha',
        cz: 'Opakujte úkol',
        de: 'Aufgabe wiederholen',
    },
    every: {
        en: 'Every',
        sk: 'Každý',
        cz: 'Každý',
        de: 'Jeder',
    },
    userCantBeAssigned: {
        en: "User can't be assigned!",
        sk: 'Používateľ nemôže byť priradený!',
        cz: 'Uživatele nelze přiřadit!',
        de: 'Benutzer kann nicht zugewiesen werden!',
    },
    openRepeat: {
        en: 'Open repeat',
        sk: 'Otvoriť opakovanie',
        cz: 'Otevřít opakování',
        de: 'Wiederholung öffnen',
    },
    triggerRepeatCreateTask: {
        en: 'Trigger repeat and create task',
        sk: 'Spustiť opakovanie a vytvoriť úlohu',
        cz: 'Spusťte opakování a vytvořte úkol',
        de: 'Wiederholen auslösen und Aufgabe erstellen',
    },
    taskWasOriginallyNamed: {
        en: 'Task was originally named',
        sk: 'Úloha bola pôvodne pomenovaná',
        cz: 'Úkol byl původně pojmenován',
        de: 'Aufgabe wurde ursprünglich benannt',
    },
    openRepeatTask: {
        en: 'Open repeat task',
        sk: 'Otvoriť úlohu z opakovania',
        cz: 'Otevřete opakování úkolu',
        de: 'Wiederholungsaufgabe öffnen',
    },
    invoicedTasks: {
        en: 'Invoiced tasks',
        sk: 'Vykázané úlohy',
        cz: 'Fakturované úkoly',
        de: 'Abgerechnete Aufgaben',
    },
    noResultsForThisFilter: {
        en: 'No results were found for this filter',
        sk: 'Neboli nájdené žiadne výsledky pre tento filter',
        cz: 'Pro tento filtr nebyly nalezeny žádné výsledky',
        de: 'Für diesen Filter wurden keine Ergebnisse gefunden',
    },
    taskAndId: {
        en: 'Task & ID',
        sk: 'Úloha a ID',
        cz: 'Úkol a ID',
        de: 'Aufgabe & ID',
    },
    searchedPhrases: {
        en: 'Searched phrases',
        sk: 'Hľadané výrazy',
        cz: 'Hledané fráze',
        de: 'Gesuchte Phrasen',
    },
    globalSearch: {
        en: 'Global search',
        sk: 'Globálne vyhľadávanie',
        cz: 'Globální vyhledávání',
        de: 'Globale Suche',
    },
    unknownStatus: {
        en: 'Unknown status',
        sk: 'Neznámy status',
        cz: 'Neznámý stav',
        de: 'Unbekannter Zustand',
    },
    unknown: {
        en: 'Unknown',
        sk: 'Neznámy',
        cz: 'Neznámý',
        de: 'Unbekannt',
    },
    selectTasklistColumns: {
        en: 'Select tasklist columns',
        sk: 'Vyberte stĺpce tabuľky úloh',
        cz: 'Vyberte sloupce seznamu úkolů',
        de: 'Spalten der Aufgabenliste auswählen',
    },
    updatedAt: {
        en: 'Last change',
        sk: 'Posledná zmena',
        cz: 'Poslední změna',
        de: 'Letzte Bearbeitung',
    },
    id: {
        en: 'ID',
        sk: 'ID',
        cz: 'ID',
        de: 'ID',
    },
    searchInIDAndTaskTitle: {
        en: 'Search in ID and task title',
        sk: 'Hľadať podľa ID a názvu úlohy',
        cz: 'Hledejte v ID a názvu úkolu',
        de: 'Suche in ID und Aufgabentitel',
    },
    invoiced: {
        en: 'Invoiced',
        sk: 'Vykázané',
        cz: 'Fakturováno',
        de: 'In Rechnung gestellt',
    },
    worksAssignedTo: {
        en: 'Work assigned to',
        sk: 'Prácu rieši',
        cz: 'Práce přidělená',
        de: 'Arbeit zugeteilt',
    },
    materialsWithoutTax: {
        en: 'Materials without tax',
        sk: 'Materiále bez DPH',
        cz: 'Materiály bez daně',
        de: 'Materialien ohne MwSt',
    },
    materialsWithTax: {
        en: 'Materials with tax',
        sk: 'Materiále s DPH',
        cz: 'Materiály s daní',
        de: 'Materialien mit MwSt',
    },
    of: {
        en: 'of',
        sk: 'z',
        cz: 'z',
        de: 'von',
    },
    tasks2: {
        en: 'Tasks',
        sk: 'Úloh',
        cz: 'Úkoly',
        de: 'Aufgaben',
    },
    deleteSelectedTasksConfirmation: {
        en: 'Are you sure you want to delete checked tasks?',
        sk: 'Ste si istý že chcete vymazať vybrané úlohy?',
        cz: 'Opravdu chcete smazat zaškrtnuté úkoly?',
        de: 'Möchten Sie markierte Aufgaben wirklich löschen?',
    },
    notAllTasksDeletedMessage: {
        en: "were deleted. Some tasks couln't be deleted. This includes",
        sk: 'boli vymazané. Niektoré úlohy sa nedali zmazať. Tieto úlohy sú',
        cz: 'byly smazány. Některé úkoly nelze smazat. To zahrnuje',
        de: 'wurden gelöscht. Einige Aufgaben konnten nicht gelöscht werden. Das beinhaltet',
    },
    pleaseFirstPickTasksToDelete: {
        en: 'Please first pick tasks to delete!',
        sk: 'Prosím najprv vyberte úlohy na zmazanie!',
        cz: 'Nejprve prosím vyberte úkoly, které chcete smazat!',
        de: 'Bitte wählen Sie zuerst Aufgaben zum Löschen aus!',
    },
    pleaseFirstPickTasksToEdit: {
        en: 'Please first pick tasks to edit!',
        sk: 'Prosím najprv vyberte úlohy na úpravu!',
        cz: 'Nejprve prosím vyberte úkoly, které chcete upravit!',
        de: 'Bitte wählen Sie zuerst Aufgaben zum Bearbeiten aus!',
    },
    clearSearch: {
        en: 'Clear search',
        sk: 'Zrušiť vyhľadávanie',
        cz: 'Vymazat vyhledávání',
        de: 'Saubere Suche',
    },
    repeatSearch: {
        en: 'Repeat search',
        sk: 'Opakovať vyhľadávanie',
        cz: 'Opakujte hledání',
        de: 'Suche wiederholen',
    },
    noDeadline: {
        en: 'No deadline',
        sk: 'Bez deadlinu',
        cz: 'Žádný termín',
        de: 'Keine Frist',
    },
    noStartsAt: {
        en: 'No start date',
        sk: 'Bez dátumu začiatku',
        cz: 'Žádné datum zahájení',
        de: 'Kein Startdatum',
    },
    deadlineSinceCreation: {
        en: 'Deadline (since creation in days)',
        sk: 'Deadline (od vytvorenia v dňoch)',
        cz: 'Termín (od vytvoření ve dnech)',
        de: 'Frist (seit Erstellung in Tagen)',
    },
    deadlineSinceCreationPlaceholder: {
        en: 'Number of days since creation',
        sk: 'Počet dní od vytvorenia',
        cz: 'Počet dní od vytvoření',
        de: 'Anzahl der Tage seit der Erstellung',
    },
};
