import React from 'react';
import Checkbox from 'components/checkbox';
import { Label, FormGroup } from 'reactstrap';
import Select from 'react-select';
import { pickSelectStyle } from 'configs/components/select';
import { toSelArr, updateArrayItem } from 'helperFunctions';

import { useTranslation } from 'react-i18next';

export default function UserManagement(props) {
    const {
        users,
        myId,
        workspaceRights,
        setWorkspaceRights,
    } = props;

    const { t } = useTranslation();

    const [newUser, setNewUser] = React.useState(null);

    const addNewUser = () => {
        setWorkspaceRights([
            ...workspaceRights,
            {
                userId: newUser.id,
                addProjects: true,
                editWorkspace: true,
            },
        ]);
        setNewUser(null);
    };

    const updateUserRight = (
        workspaceRight,
        attribute,
        value
    ) => {
        setWorkspaceRights(
            updateArrayItem(
                workspaceRights,
                {
                    ...workspaceRight,
                    [attribute]: value,
                },
                'userId'
            )
        );
    };

    const removeUserRight = (userId) => {
        setWorkspaceRights(
            workspaceRights.filter(
                (workspaceRight) =>
                    workspaceRight.userId !== userId
            )
        );
    };

    return (
        <div>
            <h3>{t('userRights')}</h3>
            <FormGroup>
                <Label>{t('user')}</Label>
                <div className="row">
                    <div className="flex">
                        <Select
                            value={newUser}
                            styles={pickSelectStyle()}
                            onChange={(e) => setNewUser(e)}
                            options={toSelArr(
                                users,
                                'fullName'
                            ).filter(
                                (user) =>
                                    !workspaceRights.some(
                                        (workspaceRight) =>
                                            workspaceRight.userId ===
                                            user.id
                                    )
                            )}
                        />
                    </div>
                    <button
                        className="btn m-l-10"
                        disabled={newUser === null}
                        onClick={addNewUser}
                    >
                        {t('add')}
                    </button>
                </div>
            </FormGroup>

            <table className="table bkg-white m-t-20">
                <thead>
                    <tr>
                        <th>{t('user')}</th>
                        <th className="text-center">
                            {t('addProjects')}
                        </th>
                        <th className="text-center">
                            {t('editWorkspace')}
                        </th>
                        <th
                            className="text-center"
                            width="50"
                        >
                            {t('actions')}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {workspaceRights.map(
                        (workspaceRight) => (
                            <tr key={workspaceRight.userId}>
                                <td>
                                    {
                                        users.find(
                                            (user) =>
                                                user.id ===
                                                workspaceRight.userId
                                        ).fullName
                                    }
                                </td>
                                <td>
                                    <Checkbox
                                        centerHor
                                        centerVer
                                        value={
                                            workspaceRight.addProjects
                                        }
                                        disabled={
                                            workspaceRight.userId ===
                                            myId
                                        }
                                        disableLabel
                                        onChange={() =>
                                            updateUserRight(
                                                workspaceRight,
                                                'addProjects',
                                                !workspaceRight.addProjects
                                            )
                                        }
                                    />
                                </td>
                                <td>
                                    <Checkbox
                                        centerHor
                                        centerVer
                                        value={
                                            workspaceRight.editWorkspace
                                        }
                                        disabled={
                                            workspaceRight.userId ===
                                            myId
                                        }
                                        disableLabel
                                        onChange={() => {
                                            updateUserRight(
                                                workspaceRight,
                                                'editWorkspace',
                                                !workspaceRight.editWorkspace
                                            );
                                        }}
                                    />
                                </td>
                                <td>
                                    <button
                                        className="btn-link m-l-10"
                                        disabled={
                                            workspaceRight.userId ===
                                            myId
                                        }
                                        onClick={() => {
                                            removeUserRight(
                                                workspaceRight.userId
                                            );
                                        }}
                                    >
                                        <span className="material-symbols-outlined">
                                            close
                                        </span>
                                    </button>
                                </td>
                            </tr>
                        )
                    )}
                </tbody>
            </table>
        </div>
    );
}
