import React from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import {
    TabContent,
    TabPane,
    Nav,
    NavItem,
    NavLink,
    Modal,
    ModalBody,
    ModalHeader,
} from 'reactstrap';
import DatePicker from 'components/DatePicker';
import Empty from 'components/Empty';
import CustomAttributesList from '../components/customAttributes';

import classnames from 'classnames';
import * as fns from 'date-fns';

import { noMilestone } from 'configs/constants/sidebar';

import Repeat from 'helpdesk/components/repeat/repeatFormInput';

import ShortSubtasks from '../components/shortSubtasks';
import Description from '../components/description';
import Vykazy from '../components/vykazy';
import Comments from '../components/comments';
import TaskHistory from '../components/taskHistory';
import ErrorDisplay from '../components/errorDisplay/editTaskErrorDisplay';
import AIAutofills from '../components/aiAutofills';
import TaskFormSidebarItem from '../components/taskFormSidebarItem';
import TaskFormSidebarDatePicker from '../components/taskFormSidebarDatePicker';

import AddUserToGroup from 'helpdesk/settings/projects/addUserToGroup';

import TaskAdd from '../add';
import TaskPrint from '../components/print';

import UserAdd from 'helpdesk/settings/users/userAdd';
import CompanyAdd from 'helpdesk/settings/companies/companyAdd';

import {
    toSelArr,
    toSelItem,
    timestampToStringFNS,
    updateArrayItem,
    translateSelectItem,
    fromUnixToDate,
    fromDateToUnix,
} from 'helperFunctions';
import { addLocalError } from 'apollo/localSchema/actions';
import { GET_TASK, GET_TASKS } from 'helpdesk/queries';

import { getEmptyAttributeRights, backendCleanRights } from 'configs/constants/projects';
import { useTranslation } from 'react-i18next';

import { defaultVykazyChanges } from '../constants';
import 'scss/direct/task-ckeditor.scss';
import { MaterialSymbol } from 'react-material-symbols';

export default function TaskEdit(props) {
    //data & queries
    const {
        columns,
        closeModal,
        inModal,
        id,
        task,
        currentUser,
        accessRights,
        companies,
        users,
        tripTypes,
        taskTypes,
        projects,
        saving,
        setSaving,
        filterValues,
        originalProjectId,
        filterId,
        deleteTaskFunc,
        addCompanyToList,
        addAttachments,
        removeAttachment,
        updateCasheStorage,
        updateTask,
        client,
        fromInvoice,
        applyAISolution,
    } = props;

    const navigate = useNavigate();
    const params = useParams();

    const { t } = useTranslation();

    const invoiced = task.invoiced;

    //state
    const [assignedTo, setAssignedTo] = React.useState([]);
    const [closeDate, setCloseDate] = React.useState(null);
    const [company, setCompany] = React.useState(null);
    const [startsAt, setStartsAt] = React.useState(null);
    const [deadline, setDeadline] = React.useState(null);
    const [description, setDescription] = React.useState('');
    const [important, setImportant] = React.useState(false);
    const [milestone] = React.useState([translateSelectItem(noMilestone, t)]);
    const [openUserAdd, setOpenUserAdd] = React.useState(false);
    const [openCompanyAdd, setOpenCompanyAdd] = React.useState(false);
    const [pendingChangable, setPendingChangable] = React.useState(false);
    const [pendingDate, setPendingDate] = React.useState(null);
    const [potentialPendingStatus, setPotentialPendingStatus] = React.useState(null);
    const [project, setProject] = React.useState(null);
    const [requester, setRequester] = React.useState(null);
    const [status, setStatus] = React.useState(null);
    const [showDescription, setShowDescription] = React.useState(false);
    const [tags, setTags] = React.useState([]);
    const [title, setTitle] = React.useState('');
    const [ganttOrder, setGanttOrder] = React.useState(0);

    const [newAddedUser, setNewAddedUser] = React.useState(null);
    const [taskTitleEdited, setTaskTitleEdited] = React.useState(false);

    const [customAttributes, setCustomAttributes] = React.useState([]);

    const [changes, setChanges] = React.useState({});
    const [, setVykazyChanges] = React.useState(defaultVykazyChanges);
    const [vykazyChanged, setVykazyChanged] = React.useState(false);

    const userRights = project
        ? {
              rights: project.right,
              attributeRights: project.attributeRights,
          }
        : backendCleanRights();

    const [toggleTab, setToggleTab] = React.useState(userRights.rights.viewComments ? 1 : 2);

    const projectAttributes = project
        ? project.project.projectAttributes
        : getEmptyAttributeRights();

    // sync
    React.useEffect(() => {
        const project =
            task.project === null
                ? null
                : projects.find((project) => project.id === task.project.id);
        const assignableUserIds = users
            .filter(
                (user) =>
                    project &&
                    project.usersWithRights.some(
                        (userData) => userData.assignable && userData.user.id === user.id
                    )
            )
            .map((user) => user.id);
        setChanges({});
        setVykazyChanges(defaultVykazyChanges);
        setAssignedTo(
            toSelArr(task.assignedTo, 'fullName').filter((user) =>
                assignableUserIds.includes(user.id)
            )
        );
        setCloseDate(fromUnixToDate(task.closeDate));
        setStartsAt(fromUnixToDate(task.startsAt));
        setDeadline(fromUnixToDate(task.deadline));
        setDescription(task.description);
        setImportant(task.important);
        setPendingChangable(task.pendingChangable);
        setPendingDate(fromUnixToDate(task.pendingDate));
        const status = task.status ? toSelItem(task.status) : null;
        setStatus(status);
        setTags(toSelArr(task.tags));
        setCompany(task.company ? toSelItem(task.company) : null);
        setRequester(
            task.requester
                ? {
                      ...task.requester,
                      value: task.requester.id,
                      label: task.requester.fullName,
                  }
                : null
        );
        setProject(project);
        setTitle(task.title);
        setGanttOrder(task.ganttOrder);
    }, [id, task]);

    React.useEffect(() => {
        updateToProjectRules(project);
    }, [project]);

    React.useEffect(() => {
        if (!project) {
            return;
        }

        let newCustomAttributes = [];
        project.project.viewCustomAttributes.forEach((item) => {
            let matchingTaskValue = task.customAttributes.find(
                (customAttribute) => item.id === customAttribute.customAttribute.id
            );

            if (!matchingTaskValue) {
                matchingTaskValue = {};
            }

            const value = {
                text: matchingTaskValue.text,
                number: matchingTaskValue.number,
                selectValues: matchingTaskValue.selectValues,
            };

            let newAttribute = {
                ...item,
                value,
                label: item.title.substring(0, 1).toUpperCase() + item.title.substring(1),
                canEdit: project.project.editCustomAttributes.some(
                    (customAttribute) => item.id === customAttribute.id
                ),
                isEdit: true,
            };
            delete newAttribute.__typename;
            newCustomAttributes.push(newAttribute);
        });

        setCustomAttributes(newCustomAttributes.sort((a1, a2) => (a1.order < a2.order ? -1 : 1)));
    }, [project, task]);

    const updateToProjectRules = (project) => {
        if (!project) {
            return;
        }
        const userRights = {
            rights: project.right,
            attributeRights: project.attributeRights,
        };

        const projectAttributes = project.project.projectAttributes;

        const projectUsers = users.filter((user) =>
            project.usersWithRights.some((userData) => userData.user.id === user.id)
        );
        const assignableUsers = users.filter((user) =>
            project.usersWithRights.some(
                (userData) => userData.assignable && userData.user.id === user.id
            )
        );
        const projectRequesters = project.lockedRequester ? projectUsers : users;
        const statuses = toSelArr(project.project.statuses);
        //check def required and fixed and change is needed (CHECK IF IT ALREADY ISNT SET) and can see the attribute
        let changes = {};
        //Status
        if (userRights.attributeRights.status.view) {
            if (projectAttributes.status.fixed) {
                if (
                    projectAttributes.status.value &&
                    status.id !== projectAttributes.status.value.id
                ) {
                    changeStatus(
                        statuses.find((status) => status.id === projectAttributes.status.value.id)
                    );
                }
            }
        }

        //Tags
        if (userRights.attributeRights.tags.view) {
            if (projectAttributes.tags.fixed) {
                let tagIds = projectAttributes.tags.value.map((t) => t.id);
                if (tags.length !== tagIds.length || tags.some((tag) => !tagIds.includes(tag.id))) {
                    setTags(project.tags.filter((item) => tagIds.includes(item.id)));
                    changes.tags = tagIds;
                }
            }
        }

        //Assigned to
        if (userRights.attributeRights.assigned.view) {
            if (projectAttributes.assigned.fixed) {
                let newAssignedTo = assignableUsers.filter((user1) =>
                    projectAttributes.assigned.value.some((user2) => user1.id === user2.id)
                );
                if (newAssignedTo.length === 0 && userRights.attributeRights.assigned.edit) {
                    newAssignedTo = assignableUsers.filter((user) => user.id === currentUser.id);
                }
                if (
                    newAssignedTo.length !== assignedTo.length ||
                    newAssignedTo.some((user1) => assignedTo.some((user2) => user1.id !== user2.id))
                ) {
                    changes.assignedTo = newAssignedTo.map((user) => user.id);
                }
                setAssignedTo(newAssignedTo);
            }
        }

        //Requester
        let potentialRequester = null;
        if (userRights.attributeRights.requester.view) {
            if (projectAttributes.requester.fixed) {
                if (projectAttributes.requester.value) {
                    potentialRequester = projectRequesters.find(
                        (user) => user.id === projectAttributes.requester.value.id
                    );
                } else {
                    potentialRequester = projectRequesters.find(
                        (user) => user.id === currentUser.id
                    );
                }
                if (
                    potentialRequester &&
                    (requester === null || requester.id !== potentialRequester.id)
                ) {
                    setRequester(potentialRequester);
                    changes.requester = potentialRequester.id;
                }
            }
        }

        //Company
        let potentialCompany = null;
        if (userRights.attributeRights.company.view) {
            if (projectAttributes.company.fixed) {
                if (projectAttributes.company.value) {
                    potentialCompany = companies.find(
                        (company) => company.id === projectAttributes.company.value.id
                    );
                } else if (potentialRequester) {
                    potentialCompany = companies.find(
                        (company) => company.id === potentialRequester.company.id
                    );
                }
                if (potentialCompany && (company === null || company.id !== potentialCompany.id)) {
                    setCompany(potentialCompany);
                    changes.company = company.id;
                }
            }
        }
        //Starts at
        if (userRights.attributeRights.startsAt.view) {
            if (
                projectAttributes.startsAt.fixed &&
                startsAt.valueOf().toString() !== projectAttributes.startsAt.value
            ) {
                setDeadline(fromUnixToDate(projectAttributes.startsAt.value));
                changes.startsAt = projectAttributes.startsAt.value;
            }
        }

        //Deadline
        if (userRights.attributeRights.deadline.view) {
            if (
                projectAttributes.deadline.fixed &&
                deadline.valueOf().toString() !== projectAttributes.deadline.value
            ) {
                setDeadline(fromUnixToDate(projectAttributes.deadline.value));
                changes.deadline = projectAttributes.deadline.value;
            }
        }
        //save all
        if (Object.keys(changes).length > 0) {
            autoUpdateTask(changes);
        }
    };

    //constants
    const canAddUser = accessRights.users;
    const canAddCompany = accessRights.companies;
    const availableProjects = projects.filter((project) => project.right.taskProjectWrite);
    const assignedTos = project
        ? users.filter((user) =>
              project.usersWithRights.some(
                  (userData) => userData.assignable && userData.user.id === user.id
              )
          )
        : [];

    const requesters =
        project && project.project.lockedRequester
            ? toSelArr(
                  project.usersWithRights.map((userWithRights) => userWithRights.user),
                  'fullName'
              )
            : users;
    //const milestones = [ noMilestone ].concat( ( project ? toSelArr( project.project.milestones ) : [] ) );

    //functions
    const getCantSave = (change = {}) => {
        const compare = {
            title,
            status,
            project,
            tags,
            assignedTo,
            saving,
            ...change,
        };
        return (
            compare.title === '' ||
            compare.status === null ||
            compare.project === null ||
            invoiced ||
            (compare.assignedTo.length === 0 &&
                userRights.attributeRights.assigned.view &&
                !projectAttributes.assigned.fixed) ||
            compare.saving
        );
    };

    const autoUpdateTask = (change, passFunc = null) => {
        if (getCantSave(change)) {
            setChanges({
                ...changes,
                ...change,
            });
            return;
        }
        setSaving(true);
        let variables = {
            id,
            ...changes,
            ...change,
            fromInvoice,
        };

        updateTask({
            variables,
        })
            .then((response) => {
                setChanges({});
                //update task
                const originalTask = client.readQuery({
                    query: GET_TASK,
                    variables: {
                        id,
                        fromInvoice,
                    },
                }).task;

                const updatedTask = {
                    ...originalTask,
                    ...response.data.updateTask,
                };
                client.writeQuery({
                    query: GET_TASK,
                    variables: {
                        id,
                        fromInvoice,
                    },
                    data: {
                        task: updatedTask,
                    },
                });
                try {
                    //update tasks if project changed or not
                    let execTasks = client.readQuery({
                        query: GET_TASKS,
                        variables: {
                            filterId,
                            filter: filterValues,
                            projectId: originalProjectId,
                        },
                    }).tasks;

                    if (project.id !== originalProjectId && originalProjectId !== null) {
                        client.writeQuery({
                            query: GET_TASKS,
                            variables: {
                                filterId,
                                filter: filterValues,
                                projectId: originalProjectId,
                            },
                            data: {
                                tasks: {
                                    ...execTasks,
                                    tasks: execTasks.tasks.filter((task) => task.id !== id),
                                },
                            },
                        });
                    } else {
                        client.writeQuery({
                            query: GET_TASKS,
                            variables: {
                                filterId,
                                filter: filterValues,
                                projectId: originalProjectId,
                            },
                            data: {
                                tasks: {
                                    ...execTasks,
                                    tasks: updateArrayItem(execTasks.tasks, updatedTask),
                                },
                            },
                        });
                    }
                } catch (e) {}
                passFunc && passFunc();
            })
            .catch((err) => {
                setChanges({
                    ...changes,
                    ...change,
                });
                addLocalError(err);
            });

        setSaving(false);
    };

    //vykazyTable
    const subtasks = task.subtasks.map((item) => ({
        ...item,
        assignedTo: toSelItem(item.assignedTo, 'fullName'),
        /*  type: item.type ? toSelItem( item.type ) : null*/
    }));
    const workTrips = task.workTrips.map((item) => ({
        ...item,
        assignedTo: toSelItem(item.assignedTo, 'fullName'),
        type: toSelItem(item.type),
    }));
    const materials = task.materials.map((item) => ({
        ...item,
    }));
    const canCopy = userRights.rights.addTask && !getCantSave();

    const getTaskData = () => ({
        shortSubtasks: task.shortSubtasks,
        subtasks: task.subtasks.map((item) => ({
            ...item,
            assignedTo: toSelItem(item.assignedTo, 'fullName'),
            type: item.type ? toSelItem(item.type) : null,
        })),
        workTrips: task.workTrips.map((item) => ({
            ...item,
            assignedTo: toSelItem(item.assignedTo, 'fullName'),
            type: toSelItem(item.type),
        })),
        materials: task.materials,
        assignedTo,
        closeDate,
        company,
        startsAt,
        deadline,
        description,
        taskDescriptionAttachments: task.taskDescriptionAttachments,
        taskAttachments: task.taskAttachments,
        important,
        milestone,
        pendingChangable,
        pendingDate,
        potentialPendingStatus,
        project,
        requester,
        status,
        tags,
        title,
        ganttOrder,
        customAttributes,
    });

    //Value Change
    const changeProject = (project) => {
        let variables = {
            id,
            fromInvoice,
            project: project.id,
        };
        setSaving(true);
        updateTask({
            variables,
        }).finally(() => {
            setSaving(false);
        });
    };

    const changeStatus = (status) => {
        if (status.action === 'PendingDate') {
            setStatus(status);
            setPendingDate(fns.addDays(new Date(), 1));
            setPotentialPendingStatus(status);
            setPendingChangable(true);
            autoUpdateTask({
                status: status.id,
                pendingDate: fromDateToUnix(fns.addDays(new Date(), 1)) + '',
                pendingChangable: true,
            });
        } else if (status.action === 'CloseDate' || status.action === 'Invalid') {
            setStatus(status);
            setImportant(false);
            setCloseDate(new Date());
            autoUpdateTask({
                status: status.id,
                closeDate: fromDateToUnix(new Date()) + '',
                important: false,
            });
        } else {
            setStatus(status);
            autoUpdateTask({
                status: status.id,
            });
        }
    };

    const changeRequester = (requester) => {
        if (requester.id === -1) {
            setOpenUserAdd(true);
        } else {
            setRequester(requester);
            autoUpdateTask({
                requester: requester.id,
            });
        }
    };

    const changeCompany = (company) => {
        if (company.id === -1) {
            setOpenCompanyAdd(true);
        } else {
            setCompany(company);
            /*  setPausal( parseInt( company.taskWorkPausal ) > 0 ? translateAllSelectItems( booleanSelects, t )[ 1 ] : translateAllSelectItems( booleanSelects, t )[ 0 ] );*/
            autoUpdateTask({
                company: company.id,
                /*  pausal: parseInt( company.taskWorkPausal ) > 0*/
            });
        }
    };

    const changeCustomAttributes = (newCustomAttributes) => {
        setCustomAttributes(newCustomAttributes);
        autoUpdateTask({
            customAttributes: newCustomAttributes.map((item) => ({
                text: item.value.text,
                number: item.value.number,
                selectValues: item.value.selectValues
                    .map((value) => value.id)
                    .filter((value) => value !== null),
                customAttribute: item.id,
            })),
        });
    };

    //render
    const renderSide = () => {
        const sideAttributes = [
            {
                show: true,
                label: t('project'),
                required: true,
                showEdit: userRights.rights.taskProjectWrite && !invoiced,
                value: project ? project.title : t('noProject'),
                editValue: project,
                onChangeEditValue: changeProject,
                placeholder: t('selectProject'),
                selectOptions: availableProjects,
                selectStyles: ['noArrow', 'required'],
                valueContainerStyle: null,
                selectIsDisabled: false,
                selectIsMulti: false,
            },
            {
                show: userRights.attributeRights.status.view,
                label: t('status'),
                required: true,
                showEdit:
                    !projectAttributes.status.fixed &&
                    userRights.attributeRights.status.edit &&
                    !invoiced,
                value: status ? status.label : t('none'),
                editValue: status,
                onChangeEditValue: changeStatus,
                placeholder: t('statusPlaceholder'),
                selectOptions: (project ? toSelArr(project.project.statuses) : []).filter(
                    (status) => status.action !== 'Invoiced'
                ),
                selectStyles: ['noArrow', 'colored', 'required'],
                valueContainerStyle: status
                    ? {
                          backgroundColor: status.color,
                          color: 'white',
                          fontWeight: 'bolder',
                      }
                    : {},
                selectIsDisabled: false,
                selectIsMulti: false,
            },
            {
                show: userRights.attributeRights.requester.view,
                label: t('requester'),
                required: true,
                showEdit:
                    !projectAttributes.requester.fixed &&
                    userRights.attributeRights.requester.edit &&
                    !invoiced,
                value: requester ? requester.label : t('none'),
                editValue: requester,
                onChangeEditValue: changeRequester,
                placeholder: t('requesterPlaceholder'),
                selectOptions: (canAddUser
                    ? [
                          translateSelectItem(
                              {
                                  id: -1,
                                  title: '+ Add user',
                                  body: 'add',
                                  labelId: 'addUserLabel',
                                  label: '+ Add user',
                                  value: null,
                              },
                              t
                          ),
                      ]
                    : []
                ).concat(requesters),
                selectStyles: ['noArrow', 'required'],
                valueContainerStyle: null,
                selectIsDisabled:
                    projectAttributes.requester.fixed || !userRights.attributeRights.requester.edit,
                selectIsMulti: false,
            },
            {
                show: userRights.attributeRights.company.view,
                label: t('company'),
                required: true,
                showEdit:
                    !projectAttributes.company.fixed &&
                    userRights.attributeRights.company.edit &&
                    !invoiced,
                value: company ? company.label : t('none'),
                editValue: company,
                onChangeEditValue: changeCompany,
                placeholder: t('companyPlaceholder'),
                selectOptions: (canAddCompany
                    ? [
                          translateSelectItem(
                              {
                                  id: -1,
                                  title: '+ Add company',
                                  body: 'add',
                                  labelId: 'addCompanyLabel',
                                  label: '+ Add company',
                                  value: null,
                              },
                              t
                          ),
                      ]
                    : []
                ).concat(companies),
                selectStyles: ['noArrow', 'required'],
                valueContainerStyle: null,
                selectIsDisabled: false,
                selectIsMulti: false,
            },
            {
                show: userRights.attributeRights.assigned.view,
                label: t('assignedTo'),
                required: true,
                showEdit:
                    !projectAttributes.assigned.fixed &&
                    userRights.attributeRights.assigned.edit &&
                    !invoiced,
                value:
                    assignedTo.length === 0
                        ? t('taskUnassigned')
                        : assignedTo.map((user) => user.label).join(', '),
                editValue: assignedTo,
                onChangeEditValue: (users) => {
                    if (users.some((u) => u.id === -1)) {
                        setOpenUserAdd(true);
                    } else {
                        setAssignedTo(users);
                        autoUpdateTask({
                            assignedTo: users.map((user) => user.id),
                        });
                    }
                },
                placeholder: t('selectRecommended'),
                selectOptions: (canAddUser
                    ? [
                          translateSelectItem(
                              {
                                  id: -1,
                                  title: '+ Add user',
                                  body: 'add',
                                  labelId: 'addUserLabel',
                                  label: '+ Add user',
                                  value: null,
                              },
                              t
                          ),
                      ]
                    : []
                ).concat(assignedTos),
                selectStyles: ['noArrow', 'required'],
                valueContainerStyle: null,
                selectIsDisabled: false,
                selectIsMulti: true,
            },
            {
                show: userRights.attributeRights.startsAt.view,
                label: t('plannedAt'),
                required: false,
                showEdit:
                    !projectAttributes.startsAt.fixed &&
                    userRights.attributeRights.startsAt.edit &&
                    !invoiced,
                value: startsAt ? timestampToStringFNS(startsAt.valueOf()) : t('noPlannedAt'),
                editValue: startsAt,
                onChangeEditValue: (date) => {
                    setStartsAt(isNaN(date.valueOf()) ? null : date);
                    autoUpdateTask({
                        startsAt: isNaN(date.valueOf()) ? null : date.valueOf().toString(),
                    });
                },
                placeholder: t('plannedAtPlaceholder'),
                selectOptions: null,
                selectStyles: null,
                valueContainerStyle: null,
                selectIsDisabled: false,
                selectIsMulti: false,
            },
            {
                show: userRights.attributeRights.deadline.view,
                label: t('deadline'),
                required: false,
                showEdit:
                    !projectAttributes.deadline.fixed &&
                    userRights.attributeRights.deadline.edit &&
                    !invoiced,
                value: deadline ? timestampToStringFNS(deadline.valueOf()) : t('noDeadline'),
                editValue: deadline,
                onChangeEditValue: (date) => {
                    setDeadline(isNaN(date.valueOf()) ? null : date);
                    autoUpdateTask({
                        deadline: isNaN(date.valueOf()) ? null : date.valueOf().toString(),
                    });
                },
                placeholder: t('deadlinePlaceholder'),
                selectOptions: null,
                selectStyles: null,
                valueContainerStyle: null,
                selectIsDisabled: false,
                selectIsMulti: false,
            },
        ];

        //used sidebar
        return (
            <div
                className={classnames('task-edit-right', 'p-t-10 p-l-13 p-r-20 p-b-20', {
                    'width-250': columns,
                })}
                style={{ backgroundColor: '#f2f1f1' }}
            >
                <div>
                    {inModal && (
                        <div className="task-edit-buttons row m-b-10 justify-content-fe">
                            {userRights.rights.deleteTask && !invoiced && (
                                <button
                                    type="button"
                                    className="btn-link-red btn-distance p-0"
                                    onClick={deleteTaskFunc}
                                >
                                    <span className="material-symbols-outlined">delete</span>
                                </button>
                            )}
                            {project && canCopy && (
                                <TaskAdd
                                    project={project.id}
                                    duplicateTask={getTaskData()}
                                    disabled={!canCopy}
                                    noText
                                />
                            )}
                            <button
                                type="button"
                                className="btn-link p-r-00"
                                onClick={() => closeModal(vykazyChanged)}
                            >
                                <span className="material-symbols-outlined p-r-0">close</span>
                            </button>
                        </div>
                    )}
                    {sideAttributes
                        .filter((attribute) => attribute.show)
                        .map((attribute) => (
                            <Empty key={attribute.label}>
                                {attribute.selectOptions && (
                                    <TaskFormSidebarItem key={attribute.label} {...attribute} />
                                )}
                                {!attribute.selectOptions && (
                                    <TaskFormSidebarDatePicker
                                        key={attribute.label}
                                        {...attribute}
                                    />
                                )}
                            </Empty>
                        ))}

                    {/*repeat*/}
                    {userRights.attributeRights.repeat.view &&
                        (userRights.attributeRights.repeat.edit || task.repeat) && (
                            <div>
                                <Repeat
                                    vertical
                                    duplicateTask={!task.repeat ? getTaskData() : null}
                                    taskID={id}
                                    repeat={task.repeat}
                                    repeatTime={task.repeatTime}
                                    layout={2}
                                />
                            </div>
                        )}
                    <CustomAttributesList
                        {...props}
                        customAttributes={customAttributes}
                        project={project}
                        setCustomAttributes={changeCustomAttributes}
                    />
                </div>
            </div>
        );
    };

    const renderMain = () => {
        return (
            <div
                className={classnames({
                    'fit-with-header-and-commandbar-columns': columns && !inModal,
                    'task-edit-left': !columns,
                    'task-edit-left-columns': columns,
                })}
            >
                {!inModal && renderCommandbar()}
                {renderMainTop()}
                {renderDescriptionAttachmentsTags()}
                {false && userRights.rights.taskSubtasksRead && (
                    <ShortSubtasks
                        edit
                        taskID={id}
                        setSaving={setSaving}
                        updateCasheStorage={updateCasheStorage}
                        disabled={!userRights.rights.taskSubtasksWrite || invoiced}
                        fromInvoice={fromInvoice}
                        shortSubtasks={task.shortSubtasks}
                        className={'p-l-20 p-r-20'}
                    />
                )}
                {(userRights.rights.taskWorksRead ||
                    userRights.rights.taskWorksAdvancedRead ||
                    userRights.rights.taskMaterialsRead) && (
                    <Vykazy
                        edit
                        task={id}
                        invoiced={invoiced}
                        fromInvoice={fromInvoice}
                        autoApproved={project ? project.project.autoApproved : false}
                        userRights={userRights}
                        currentUser={currentUser}
                        assignedTo={assignedTo}
                        company={company}
                        setChanged={() => setVykazyChanged(true)}
                        updateCasheStorage={updateCasheStorage}
                        works={subtasks}
                        trips={workTrips}
                        taskTypes={taskTypes}
                        tripTypes={tripTypes}
                        setMaterials
                        materials={materials}
                        className={'p-l-20 p-r-20'}
                    />
                )}
                {renderComments()}
                <AIAutofills
                    taskId={id}
                    applyAISolution={applyAISolution}
                    taskCreatedAt={task.createdAt}
                    taskClosedDate={closeDate}
                    taskSubtaskValues={task.subtasks
                        .map((subtask) => subtask.quantity)
                        .reduce((s1, s2) => s1 + s2, 0)}
                    title={task.title}
                    className={'p-l-30 p-r-30'}
                />
                <ErrorDisplay
                    {...getTaskData()}
                    userRights={userRights}
                    projectAttributes={projectAttributes}
                    className={'p-l-30 p-r-30'}
                />
                {currentUser.role.accessRights.users && !invoiced && (
                    <Empty>
                        <Modal isOpen={openUserAdd} className="modal-without-borders">
                            <ModalHeader> {t('addUser')} </ModalHeader>
                            <ModalBody>
                                <UserAdd
                                    closeModal={() => setOpenUserAdd(false)}
                                    addUserToList={(user) => {
                                        setNewAddedUser(user);
                                    }}
                                />
                            </ModalBody>
                        </Modal>
                        {project && project.id && userRights.rights.projectWrite && (
                            <AddUserToGroup
                                user={newAddedUser}
                                disabled={!userRights.rights.projectWrite}
                                projectID={project.id}
                                finish={() => setNewAddedUser(null)}
                            />
                        )}
                    </Empty>
                )}
                {currentUser.role.accessRights.companies && !invoiced && (
                    <Modal isOpen={openCompanyAdd} className="modal-without-borders">
                        <ModalBody>
                            <CompanyAdd
                                closeModal={() => setOpenCompanyAdd(false)}
                                addCompanyToList={addCompanyToList}
                            />
                        </ModalBody>
                    </Modal>
                )}
            </div>
        );
    };

    const renderCommandbar = () => {
        return (
            <div className="task-add-layout row" style={{ backgroundColor: '#d3dfe8' }}>
                {!columns && !inModal && (
                    <button
                        type="button"
                        className="btn-link task-add-layout-button btn-distance"
                        onClick={() => {
                            navigate(`/helpdesk/taskList/i/${params.filterID}`);
                        }}
                    >
                        <span className="material-symbols-outlined p-r-5">arrow_back</span>
                        {t('back')}
                    </button>
                )}
                {project && canCopy && (
                    <TaskAdd
                        projectID={project.id}
                        duplicateTask={getTaskData()}
                        disabled={!canCopy}
                    />
                )}
                <TaskPrint
                    taskID={id}
                    task={task}
                    t={t}
                    customAttributes={customAttributes}
                    isLoaded={true}
                />
                {userRights.rights.deleteTask && !invoiced && (
                    <button
                        type="button"
                        className="btn-link task-add-layout-button btn-distance"
                        onClick={deleteTaskFunc}
                    >
                        <span className="material-symbols-outlined p-r-5">delete</span>{' '}
                        {t('delete')}
                    </button>
                )}
                {invoiced && (
                    <div className="inline-warning-message center-hor"> {t('taskInvoiced')} </div>
                )}
                <span className="ml-auto">
                    {inModal && (
                        <button
                            type="button"
                            className="btn-link-cancel task-add-layout-button p-l-10 p-r-10 m-r-10"
                            onClick={() => closeModal(vykazyChanged)}
                        >
                            <span className="material-symbols-outlined p-r-5">close</span>
                        </button>
                    )}
                </span>
            </div>
        );
    };

    const renderMainTop = () => {
        return (
            <div className="p-l-20 p-r-20">
                <div className="d-flex m-t-10 m-b-10">
                    {userRights.rights.taskImportant && (
                        <button
                            type="button"
                            style={{
                                color: '#ffc107',
                            }}
                            className="btn-link center-hor m-r-10"
                            disabled={invoiced}
                            onClick={() => {
                                setImportant(!important);
                                autoUpdateTask({
                                    important: !important,
                                });
                            }}
                        >
                            <MaterialSymbol icon="star_rate" size={24} fill={important} />
                        </button>
                    )}
                    <h2
                        className={classnames(
                            {
                                'm-r-5': taskTitleEdited,
                            },
                            'center-hor',
                            ''
                        )}
                    >
                        {id}:
                    </h2>
                    {taskTitleEdited && (
                        <span className="center-hor flex m-r-15">
                            <input
                                type="text"
                                disabled={!userRights.rights.taskTitleWrite || invoiced}
                                value={title}
                                className="task-title-input text-extra-slim form-control m-t-4 m-b-3"
                                onChange={(e) => {
                                    setTitle(e.target.value);
                                }}
                                onBlur={() => {
                                    autoUpdateTask({
                                        title,
                                    });
                                }}
                                placeholder={t('taskTitlePlaceholder')}
                            />
                        </span>
                    )}
                    {!taskTitleEdited && (
                        <span className="task-title-input text-extra-slim m-l-7"> {title} </span>
                    )}
                    {userRights.rights.taskTitleWrite && !invoiced && (
                        <button className="btn-link m-t-10">
                            <span
                                className="material-symbols-outlined"
                                onClick={() => setTaskTitleEdited(!taskTitleEdited)}
                            >
                                {taskTitleEdited && 'save'}
                                {!taskTitleEdited && 'edit'}
                            </span>
                        </button>
                    )}
                    {invoiced && inModal && (
                        <div className="inline-warning-message center-hor">{t('taskInvoiced')}</div>
                    )}
                </div>
                <hr className="m-t-5 m-b-2" />
                <div
                    className="ml-auto center-hor"
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <div className="task-info">
                        <span> {task.createdBy ? `${t('createdBy')} ` : ''} </span>
                        <span className="bolder">
                            {task.createdBy
                                ? `${task.createdBy.name} ${task.createdBy.surname}`
                                : ''}
                        </span>
                        <span> {task.createdBy ? ` ${t('atDate')} ` : t('createdAt')} </span>
                        <span className="bolder">
                            {task.createdAt ? timestampToStringFNS(task.createdAt) : ''}
                        </span>
                    </div>
                    {renderStatusDate()}
                </div>
            </div>
        );
    };

    const renderStatusDate = () => {
        if (!userRights.attributeRights.status.view || !status) {
            return null;
        }
        if (status && status.action === 'PendingDate') {
            const datepickerDisabled =
                !userRights.attributeRights.status.edit || !pendingChangable || invoiced;
            return (
                <div className="task-info ml-auto row align-items-center">
                    <span>{`${t('pendingDate')}:`}</span>
                    {datepickerDisabled ? (
                        <span className="bolder center-hor m-l-3">
                            {pendingDate ? timestampToStringFNS(pendingDate.valueOf()) : ''}
                        </span>
                    ) : (
                        <DatePicker
                            className="form-control hidden-input bolder p-0 text-right width-95"
                            selected={pendingDate}
                            disabled={datepickerDisabled}
                            onChange={(date) => {
                                setPendingDate(date);
                                if (date.valueOf() !== null) {
                                    autoUpdateTask({
                                        pendingDate: date.valueOf().toString(),
                                    });
                                }
                            }}
                            placeholderText={t('noPendingDate')}
                        />
                    )}
                </div>
            );
        }

        if (
            status &&
            (status.action === 'CloseDate' ||
                status.action === 'Invoiced' ||
                status.action === 'CloseInvalid')
        ) {
            const datepickerDisabled =
                (status.action !== 'CloseDate' && status.action !== 'CloseInvalid') ||
                !userRights.attributeRights.status.edit ||
                invoiced;
            return (
                <div className="task-info ml-auto row align-items-center">
                    <span>{`${t('closedAt')}:`}</span>
                    {datepickerDisabled ? (
                        <span className="bolder center-hor m-l-3">
                            {closeDate ? timestampToStringFNS(closeDate.valueOf()) : ''}
                        </span>
                    ) : (
                        <DatePicker
                            className="form-control hidden-input bolder p-0 text-right width-95"
                            selected={closeDate}
                            disabled={datepickerDisabled}
                            onChange={(date) => {
                                setCloseDate(date);
                                if (date.valueOf() !== null) {
                                    autoUpdateTask({
                                        closeDate: date.valueOf().toString(),
                                    });
                                }
                            }}
                            placeholderText={t('noPendingDate')}
                        />
                    )}
                </div>
            );
        }
        return (
            <div className="task-info ml-auto">
                <span className="center-hor bolder">
                    {task.statusChange
                        ? `${t('statusChangedAt')} ` + timestampToStringFNS(task.statusChange)
                        : ''}
                </span>
            </div>
        );
    };

    const renderDescriptionAttachmentsTags = () => {
        if (!userRights.rights.taskDescriptionRead && !userRights.rights.taskAttachmentsRead) {
            return null;
        }

        return (
            <Description
                taskId={id}
                description={description}
                setDescription={setDescription}
                showDescription={showDescription}
                setShowDescription={setShowDescription}
                showEditBtn={userRights.rights.taskDescriptionWrite && !invoiced}
                showSave={true}
                onEditBtnClick={() => {
                    setShowDescription(!showDescription);
                }}
                onSaveBtnClick={() => {
                    autoUpdateTask({
                        description,
                    });
                    setShowDescription(!showDescription);
                }}
                showAddAttachmentBtn={userRights.rights.taskAttachmentsWrite && !invoiced}
                showEditTagsBtn={userRights.attributeRights.tags.edit && !invoiced}
                showTags={userRights.attributeRights.tags.view}
                showBasicDescription={false}
                showAttachments={userRights.rights.taskAttachmentsRead}
                attachmentsDisabled={!userRights.rights.taskAttachmentsWrite || invoiced}
                tags={tags}
                onChangeTags={(tags) => {
                    setTags(tags);
                    autoUpdateTask({
                        tags: tags.map((tag) => tag.id),
                    });
                }}
                userRights={userRights}
                invoiced={invoiced}
                autoUpdateTask={autoUpdateTask}
                projectAttributes={projectAttributes}
                project={project.project}
                attachments={task.taskAttachments}
                descriptionAttachments={task.taskDescriptionAttachments}
                addAttachments={addAttachments}
                removeAttachment={removeAttachment}
            />
        );
    };

    const renderComments = () => {
        if (!userRights.rights.history && !userRights.rights.viewComments) {
            return null;
        }
        return (
            <div className="form-section p-l-20 p-r-20">
                <div className="form-section-rest">
                    <Nav tabs className="no-border m-b-20 m-t-10">
                        {userRights.rights.viewComments && (
                            <NavItem className="noselect">
                                <NavLink
                                    className={classnames(
                                        {
                                            active: toggleTab === 1,
                                        },
                                        'clickable',
                                        ''
                                    )}
                                    onClick={() => setToggleTab(1)}
                                >
                                    {t('comments')}
                                </NavLink>
                            </NavItem>
                        )}
                        {userRights.rights.history && userRights.rights.viewComments && (
                            <NavItem className="noselect">
                                <NavLink className="m-r-5 m-l-5">|</NavLink>
                            </NavItem>
                        )}
                        {userRights.rights.history && (
                            <NavItem className="noselect">
                                <NavLink
                                    className={classnames(
                                        {
                                            active: toggleTab === 2,
                                        },
                                        'clickable',
                                        ''
                                    )}
                                    onClick={() => setToggleTab(2)}
                                >
                                    {t('history')}
                                </NavLink>
                            </NavItem>
                        )}
                    </Nav>
                    <TabContent activeTab={toggleTab}>
                        <TabPane tabId={1}>
                            {userRights.rights.viewComments && (
                                <Comments
                                    disabled={invoiced}
                                    id={id}
                                    userRights={userRights}
                                    users={users.map((user) => ({
                                        ...user,
                                        canReadInternal: project ? project.right.internal : false,
                                    }))}
                                    fromInvoice={fromInvoice}
                                    taskAssignedIds={
                                        assignedTo.length ? assignedTo.map((user) => user.id) : []
                                    }
                                    taskRequesterId={requester ? requester.id : null}
                                    taskTitle={`${id}: ${title}`}
                                />
                            )}
                        </TabPane>
                        {userRights.rights.history && (
                            <TabPane tabId={2}>
                                {userRights.rights.history && (
                                    <TaskHistory task={task} fromInvoice={fromInvoice} />
                                )}
                            </TabPane>
                        )}
                    </TabContent>
                </div>
            </div>
        );
    };

    return (
        <div
            className={classnames(
                {
                    'task-edit-width': !inModal && !columns,
                },
                'flex max-height-400'
            )}
        >
            <div
                className={classnames({
                    'fit-with-header': !columns && !inModal,
                    'scroll-visible': !inModal,
                    'overflow-x-auto': inModal,
                })}
            >
                <div
                    className="row"
                    style={{
                        minHeight: columns ? 'calc(100vh - 450px)' : 'calc(100vh - 70px)',
                    }}
                >
                    {renderMain()} {renderSide()}
                </div>
            </div>
        </div>
    );
}
