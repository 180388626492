import React from 'react';
import GroupAdd from './groupAdd';
import GroupEdit from './groupEdit';
import { defaultGroups } from 'configs/constants/projects';
import { useTranslation } from 'react-i18next';

let fakeID = -(defaultGroups.length + 1);

export default function ProjectGroups(props) {
    const { groups, addGroup, updateGroup, deleteGroup } = props;

    const { t } = useTranslation();

    const [editedGroup, setEditedGroup] = React.useState(null);

    return (
        <div className="project-settings">
            <table className="table bkg-white">
                <thead>
                    <tr>
                        <th>{t('groupTitle')}</th>
                        <th>{t('description')}</th>
                        <th width="100" className="text-center">
                            {t('order')}
                        </th>
                        <th width="100">{t('actions')}</th>
                    </tr>
                </thead>
                <tbody>
                    {groups
                        .sort((g1, g2) => (parseInt(g1.order) > parseInt(g2.order) ? 1 : -1))
                        .map((group) => (
                            <tr key={group.id}>
                                <td>{t(group.title)}</td>
                                <td>
                                    {group.description.length > 0
                                        ? group.description
                                        : 'No description'}
                                </td>
                                <td className="text-center">{group.order}</td>
                                {!group.def && (
                                    <td>
                                        <button
                                            className="btn-link"
                                            onClick={() => setEditedGroup(group)}
                                        >
                                            {t('edit')}
                                        </button>
                                        <button
                                            className="btn-link-red"
                                            onClick={() => deleteGroup(group.id)}
                                        >
                                            <span className="material-symbols-outlined p-r-0">
                                                close
                                            </span>
                                        </button>
                                    </td>
                                )}
                                {group.def && <td>{t('default')}</td>}
                            </tr>
                        ))}
                </tbody>
            </table>
            <GroupAdd
                submit={addGroup}
                getFakeId={() => fakeID--}
                reccomendedOrder={groups.length - 1}
            />
            <GroupEdit
                open={editedGroup !== null}
                closeModal={() => setEditedGroup(null)}
                group={editedGroup}
                updateGroup={updateGroup}
            />
        </div>
    );
}
