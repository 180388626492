import React from 'react';
import Select from 'react-select';
import { FormGroup, Label, Input } from 'reactstrap';
import { useNavigate, useParams } from 'react-router-dom';

import CKEditor from 'components/CKEditor';
import Empty from 'components/Empty';
import AddPageErrors from './add/showErrors';
import EditPageErrors from './edit/showErrors';
import { pickSelectStyle } from 'configs/components/select';
import { timestampToStringFNS, toSelArr, toSelItem, childrenSubfoldersFlat } from 'helperFunctions';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

export default function LanwikiPageForm(props) {
    const {
        edit,
        close,
        allFolders,
        allSubfolders,
        allTags,
        tagId,
        id,
        disabled,
        page,
        //add specific
        addPage,
        folderId,
        sidebarFolder,
        //edit specific
        savePage,
        myRights,
        showEdit,
        setShowEdit,
        deletePage,
    } = props;

    const navigate = useNavigate();
    const params = useParams();

    const { t } = useTranslation();

    const [title, setTitle] = React.useState(page ? page.title : '');

    const setInitialFolder = () => {
        if (page) {
            if (page.folder) {
                return allFolders.find((folder) => folder.id === page.folder.id);
            }
            if (page.subfolder) {
                return allFolders.find((folder) => folder.id === page.subfolder.mainFolder.id);
            }
            return null;
        } else {
            if (folderId === null) {
                return allFolders[0];
            }
            return allFolders.find(
                (folder) =>
                    (folder.id === folderId && sidebarFolder.order) ||
                    (!sidebarFolder.order && folder.id === sidebarFolder.mainFolder.id)
            );
        }
    };

    const setInitialSubfolder = () => {
        if (page) {
            if (page.subfolder) {
                return allSubfolders.find((subfolder) => subfolder.id === page.subfolder.id);
            }
            return null;
        } else {
            return allSubfolders.find(
                (subfolder) => subfolder.id === folderId && !sidebarFolder.order
            );
        }
    };

    const [folder, setFolder] = React.useState(setInitialFolder());
    const [subfolder, setSubfolder] = React.useState(setInitialSubfolder());
    const [tags, setTags] = React.useState(
        page
            ? allTags.filter((tag) => page.tags.some((tag2) => tag2.id === tag.id))
            : tagId === null
            ? []
            : allTags.filter((tag) => tag.id === tagId)
    );
    const [body, setBody] = React.useState(page ? page.body : '');
    const [bodyImages, setBodyImages] = React.useState([]);

    const [showErrors, setShowErrors] = React.useState(false);
    const [saving, setSaving] = React.useState(false);

    const cannotSave = () => {
        return saving || title.length === 0 || !folder || !folder.id;
    };

    const saveOrAddPage = () => {
        if (disabled) {
            return;
        }
        if (cannotSave()) {
            setShowErrors(true);
        } else {
            let data = {
                title,
                folderId: subfolder ? null : folder.id,
                subfolderId: subfolder ? subfolder.id : null,
                tags: tags.map((tag) => tag.id),
                body,
            };
            if (edit) {
                data.id = id;
                savePage(data, setSaving, () => {
                    close();
                    navigate(
                        `/lanwiki/i/${folder.id}${subfolder ? '/s/' + subfolder.id : ''}/p/${
                            params.page
                        }/${params.pageID}`
                    );
                });
            } else {
                addPage(data, setSaving, close);
            }
        }
    };

    const resetData = () => {
        setTitle(page ? page.title : '');
        setFolder(setInitialFolder());
        setSubfolder(setInitialSubfolder());
        setTags(
            page
                ? allTags.filter((tag) => page.tags.some((tag2) => tag2.id === tag.id))
                : tagId === null
                ? []
                : allTags.filter((tag) => tag.id === tagId)
        );
        setBody(page ? page.body : '');
        setBodyImages([]);

        setShowErrors(false);
        setSaving(false);
    };

    return (
        <Empty>
            <div className="row">
                <div
                    className={classnames(
                        {
                            'fit-with-header scroll-visible': edit,
                        },
                        'task-edit-left'
                    )}
                    style={{ backgroundColor: '#eaeaea' }}
                >
                    {edit && !showEdit && (
                        <div
                            className="task-add-layout row"
                            style={{
                                backgroundColor: '#deeaf3',
                            }}
                        >
                            <button
                                type="button"
                                disabled={saving}
                                className="btn-link task-add-layout-button btn-distance"
                                onClick={() => {
                                    navigate(-1);
                                }}
                            >
                                <span className="material-symbols-outlined">arrow_back</span>
                                {t('back')}
                            </button>
                            {myRights.write && (
                                <button
                                    type="button"
                                    disabled={saving}
                                    className="btn-link task-add-layout-button btn-distance"
                                    onClick={() => {
                                        setShowEdit(true);
                                    }}
                                >
                                    <span className="material-symbols-outlined">edit</span>
                                    {t('edit')}
                                </button>
                            )}
                            {myRights.write && (
                                <button
                                    type="button"
                                    className="btn-link task-add-layout-button btn-distance"
                                    onClick={deletePage}
                                >
                                    <span className="material-symbols-outlined">delete</span>
                                    {t('delete')}
                                </button>
                            )}
                        </div>
                    )}

                    <div className="p-t-20 p-l-20 p-r-20">
                        {disabled && <h2>{title}</h2>}
                        {!disabled && (
                            <FormGroup>
                                <Empty>
                                    {!edit && <Label htmlFor="name">{t('title')}</Label>}
                                    <Input
                                        id="name"
                                        className="form-control"
                                        placeholder={t('titlePlaceholder')}
                                        value={title}
                                        onChange={(e) => setTitle(e.target.value)}
                                    />
                                </Empty>
                            </FormGroup>
                        )}
                        <FormGroup className="lan-wiki-note-description">
                            {!disabled && (
                                <CKEditor
                                    value={body}
                                    type="imageUpload"
                                    onChange={(body) => {
                                        setBody(body);
                                    }}
                                    uploadImage={(images) => {
                                        setBodyImages([...bodyImages, ...images]);
                                    }}
                                    images={bodyImages}
                                />
                            )}
                            {disabled && (
                                <div
                                    className="p-t-10"
                                    dangerouslySetInnerHTML={{
                                        __html: body,
                                    }}
                                />
                            )}
                        </FormGroup>
                        {!edit && (
                            <AddPageErrors
                                title={title}
                                body={body}
                                folder={folder}
                                subfolder={subfolder}
                                show={showErrors}
                            />
                        )}
                        {edit && (
                            <EditPageErrors
                                title={title}
                                body={body}
                                folder={!page || page.folder ? folder : { id: 1 }}
                                subfolder={subfolder}
                                show={showErrors}
                            />
                        )}

                        {edit && !disabled && (
                            <div
                                className="button-bar row stick-to-bottom p-l-20 p-b-20"
                                style={{
                                    backgroundColor: '#deeaf3',
                                }}
                            >
                                <div
                                    className="row m-t-5"
                                    style={{
                                        justifyContent: 'space-between',
                                        width: '100%',
                                    }}
                                >
                                    <button
                                        className="btn-full-red center-hor p-5"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            close();
                                            resetData();
                                        }}
                                    >
                                        <span className="material-symbols-outlined m-r-5">
                                            cancel
                                        </span>
                                        {t('cancel')}
                                    </button>
                                    <button
                                        className="btn center-hor"
                                        disabled={cannotSave() && showErrors}
                                        onClick={saveOrAddPage}
                                    >
                                        <span className="material-symbols-outlined">save</span>
                                        {saving ? `${t('saving')}...` : `${t('save')}`}
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                </div>

                <div
                    className={classnames(
                        { 'fit-with-header': edit },
                        'task-edit-right p-t-20 p-l-13 p-r-20 p-b-20'
                    )}
                    style={
                        edit
                            ? {
                                  backgroundColor: '#f2f1f1',
                              }
                            : {}
                    }
                >
                    <FormGroup>
                        <Label htmlFor="name">{t('folder')}</Label>
                        {disabled && <div>{folder.title}</div>}
                        {!disabled && (
                            <Select
                                placeholder={t('selectFolder')}
                                value={folder}
                                options={allFolders.filter((folder) => folder.myRights.write)}
                                onChange={(folder) => {
                                    setFolder(folder);
                                    setSubfolder(null);
                                }}
                                styles={pickSelectStyle(['noArrow', 'required'])}
                            />
                        )}
                    </FormGroup>

                    <FormGroup>
                        <Label htmlFor="name">{t('subfolder')}</Label>
                        {disabled && <div>{subfolder ? subfolder.title : t('noSubfolder')}</div>}
                        {!disabled && (
                            <Select
                                placeholder={t('selectSubfolder')}
                                value={subfolder ? toSelItem(subfolder) : null}
                                options={toSelArr(
                                    folder.subfolders
                                        .map((childSubfolder) =>
                                            childrenSubfoldersFlat(childSubfolder).flat()
                                        )
                                        .flat()
                                )}
                                onChange={(subfolder) => {
                                    setSubfolder(subfolder);
                                }}
                                styles={pickSelectStyle(['noArrow'])}
                            />
                        )}
                    </FormGroup>

                    <FormGroup>
                        <Label htmlFor="tags">{t('tags')}</Label>
                        {!disabled && (
                            <Select
                                id="tags"
                                placeholder={t('selectTags')}
                                isMulti
                                value={tags}
                                options={allTags}
                                onChange={(tags) => {
                                    setTags(tags);
                                }}
                                styles={pickSelectStyle(['noArrow', 'colored'])}
                            />
                        )}
                        {disabled && (
                            <div className="row">
                                {tags.map((tag) => (
                                    <span
                                        key={tag.id}
                                        style={{
                                            background: tag.color,
                                            color: 'white',
                                            borderRadius: 3,
                                        }}
                                        className="m-r-5 p-l-5 p-r-5"
                                    >
                                        {tag.title}
                                    </span>
                                ))}
                            </div>
                        )}
                    </FormGroup>
                    {edit && (
                        <Empty>
                            <FormGroup>
                                <Label>{t('createdBy')}</Label>
                                <div>{page.createdBy ? `${page.createdBy.fullName}` : ''}</div>
                            </FormGroup>

                            <FormGroup>
                                <Label>{t('createdDate')}</Label>
                                <div>
                                    {page.createdAt ? timestampToStringFNS(page.createdAt) : ''}
                                </div>
                            </FormGroup>

                            <FormGroup>
                                <Label>{t('changedBy')}</Label>
                                <div>{page.updatedBy ? `${page.updatedBy.fullName}` : ''}</div>
                            </FormGroup>

                            <FormGroup>
                                <Label>{t('changedAt')}</Label>
                                <div>
                                    {page.updatedAt ? timestampToStringFNS(page.updatedAt) : ''}
                                </div>
                            </FormGroup>
                        </Empty>
                    )}
                </div>
            </div>

            {!edit && (
                <div
                    className="row full-width p-t-20 p-r-20 p-l-20 p-b-20"
                    style={{ backgroundColor: '#eaeaea' }}
                >
                    <button className="btn-red m-r-10 p-l-5 p-r-5" onClick={close}>
                        <span className="material-symbols-outlined m-r-5">cancel</span>
                        {t('cancel')}
                    </button>
                    {!disabled && (
                        <div className="ml-auto">
                            <button
                                className="btn"
                                disabled={cannotSave() && showErrors}
                                onClick={saveOrAddPage}
                            >
                                {saving ? `${t('adding')}...` : `${t('add')}`}
                            </button>
                        </div>
                    )}
                </div>
            )}
        </Empty>
    );
}
