import React from 'react';

import { useParams } from 'react-router-dom';

import { useMutation, useQuery } from '@apollo/client';

import axios from 'axios';

import Scheme from './scheme';
import Loading from 'components/loading';

import { addLocalError } from 'apollo/localSchema/actions';

import { REST_URL } from 'configs/restAPI';

import {
    GET_SCHEME,
    ADD_OR_UPDATE_CMDB_SCHEME,
} from 'cmdb/queries';

import { GET_PROJECT } from 'apollo/queries';

export default function SchemeLoader(props) {
    const params = useParams();

    const { data: projectData } = useQuery(GET_PROJECT);
    const localProject = projectData.localProject;

    const {
        data: schemeData,
        loading: schemeLoading,
        refetch: schemeRefetch,
    } = useQuery(GET_SCHEME, {
        variables: {
            companyId: parseInt(params.companyID),
            projectId: localProject.project.id,
        },
        fetchPolicy: 'network-only',
        notifyOnNetworkStatusChange: true,
    });

    const [addOrUpdateCmdbScheme] = useMutation(
        ADD_OR_UPDATE_CMDB_SCHEME
    );

    const [schemeImage, setSchemeImage] =
        React.useState(undefined);

    React.useEffect(() => {
        if (!schemeLoading && schemeData) {
            getSchemeImage(schemeData.cmdbScheme);
        }
    }, [schemeLoading]);

    const getSchemeImage = (scheme) => {
        if (scheme && scheme.file) {
            axios
                .get(`${REST_URL}/api/get-cmdb-file`, {
                    params: {
                        path: scheme.file.path,
                    },
                    headers: {
                        'authorization': `${sessionStorage.getItem(
                            'acctok'
                        )}`,
                    },
                    responseType: 'arraybuffer',
                })
                .then((response) => {
                    setSchemeImage(
                        URL.createObjectURL(
                            new Blob([response.data], {
                                type: scheme.file.mimetype,
                            })
                        )
                    );
                    //download
                })
                .catch((err) => {
                    setSchemeImage(null);
                    addLocalError(err);
                });
        } else {
            setSchemeImage(null);
        }
    };

    if (schemeLoading || schemeImage === undefined) {
        return <Loading />;
    }

    return (
        <Scheme
            {...props}
            scheme={schemeData.cmdbScheme}
            schemeImage={schemeImage}
            addOrUpdateCmdbScheme={addOrUpdateCmdbScheme}
            schemeRefetch={() =>
                schemeRefetch({
                    variables: {
                        companyId: parseInt(
                            params.companyID
                        ),
                        projectId: localProject.project.id,
                    },
                })
            }
            companyId={parseInt(params.companyID)}
            project={localProject.project}
        />
    );
}
