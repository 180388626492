import React, { useState, useMemo } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import { useQuery, useSubscription } from '@apollo/client';
import { NavItem, Nav, Label } from 'reactstrap';
import Empty from 'components/Empty';
import Loading from 'components/loading';

import FoldersSidebar from './folderSidebar';
import SubfoldersSidebar from './subfolderSidebar';

import TagAdd from 'lanwiki/tags/add';
import TagEdit from 'lanwiki/tags/edit/modalButton';
import AddLanwikiPage from 'lanwiki/pages/add';

import classnames from 'classnames';

import { useTranslation } from 'react-i18next';

import {
    hasChildren,
    childrenSubfoldersFlat,
    getMyData,
} from 'helperFunctions';

import {
    setLSidebarTag,
    setLSidebarFolder,
} from 'apollo/localSchema/actions';
import {
    L_SIDEBAR_TAG,
    L_SIDEBAR_FOLDER,
    GET_PROJECT,
} from 'apollo/queries';
import {
    GET_TAGS,
    TAGS_SUBSCRIPTION,
    GET_FOLDERS,
    FOLDERS_SUBSCRIPTION,
} from 'lanwiki/queries';

import {
    FOLDERS,
    TAGS,
    COMBINED,
} from 'configs/constants/subfolders';

export default function Sidebar() {
    const navigate = useNavigate();
    const params = useParams();

    const { t } = useTranslation();
    const currentUser = getMyData();
    const userIsAdmin =
        currentUser && currentUser.role
            ? currentUser.role.level === 0
            : false;

    const { data: projectData } = useQuery(GET_PROJECT);
    const localProject = projectData.localProject;

    const [navHighlight, setNavHighlight] =
        useState(FOLDERS);

    const { data: sidebarTagData } =
        useQuery(L_SIDEBAR_TAG);
    const { data: sidebarFolderData } = useQuery(
        L_SIDEBAR_FOLDER
    );

    const {
        data: tagsData,
        loading: tagsLoading,
        refetch: tagsRefetch,
    } = useQuery(GET_TAGS, {
        variables: {
            projectId: localProject.project.id,
        },
        fetchPolicy: 'network-only',
    });

    useSubscription(TAGS_SUBSCRIPTION, {
        onData: () => {
            tagsRefetch({
                projectId: localProject.project.id,
            });
        },
    });

    const {
        data: foldersData,
        loading: foldersLoading,
        refetch: foldersRefetch,
    } = useQuery(GET_FOLDERS, {
        variables: {
            projectId: localProject.project.id,
        },
        fetchPolicy: 'network-only',
    });

    useSubscription(FOLDERS_SUBSCRIPTION, {
        onData: () => {
            foldersRefetch({
                projectId: localProject.project.id,
            });
        },
    });

    const [showArchived, setShowArchived] =
        React.useState(false);
    const sidebarTagId =
        sidebarTagData.lSidebarTag === null
            ? null
            : sidebarTagData.lSidebarTag.id;
    const sidebarFolderId =
        sidebarFolderData.lSidebarFolder === null
            ? null
            : sidebarFolderData.lSidebarFolder.id;

    const allSubfolders = useMemo(() => {
        return foldersData
            ? foldersData.lanwikiFolders
                  .map((folder) =>
                      folder.subfolders
                          .map((childSubfolder) =>
                              childrenSubfoldersFlat(
                                  childSubfolder,
                                  1
                              ).flat()
                          )
                          .flat()
                  )
                  .flat()
            : [];
    }, [foldersData]);

    React.useEffect(() => {
        if (
            !foldersLoading &&
            params.folderID !== undefined
        ) {
            if (!params.subfolderID) {
                setLSidebarFolder(
                    params.folderID === 'all'
                        ? null
                        : foldersData.lanwikiFolders.find(
                              (folder) =>
                                  folder.id ===
                                  parseInt(params.folderID)
                          )
                );
            } else {
                setLSidebarFolder(
                    allSubfolders.find(
                        (subfolder) =>
                            subfolder.id ===
                            parseInt(params.subfolderID)
                    )
                );
            }
        }
    }, [
        params.folderID,
        params.subfolderID,
        foldersLoading,
        allSubfolders,
    ]);

    if (tagsLoading || foldersLoading) {
        return (
            <div className="sidebar">
                <Loading />
            </div>
        );
    }

    const tags = tagsData.lanwikiTags;
    const folders = foldersData.lanwikiFolders.map(
        (folder) => ({ ...folder, layer: 0 })
    );

    const handleNavItemClick = (
        clickedFolder,
        clickedSubfolder,
        clickedTag,
        isAll
    ) => {
        if (clickedTag) {
            //bolo na All folders
            if (
                !sidebarFolderData.lSidebarFolder ||
                sidebarFolderData.lSidebarFolder.id === null
            ) {
                //vysviet tag, odsviet folder
                setLSidebarTag(isAll ? null : clickedTag);
                navigate(`/lanwiki/i/all`);
                setNavHighlight(TAGS);
                setLSidebarFolder(null);
            } else if (
                //bolo na root folderi bez subf
                sidebarFolderData.lSidebarFolder
                    .subfolders &&
                sidebarFolderData.lSidebarFolder.subfolders
                    .length === 0
            ) {
                //odsviet folder, vysviet tag
                setLSidebarTag(isAll ? null : clickedTag);
                navigate(`/lanwiki/i/all`);
                setNavHighlight(TAGS);
                setLSidebarFolder(null);
            } else if (
                //parent folder
                hasChildren(
                    sidebarFolderData.lSidebarFolder
                )
            ) {
                //svieti parent folder, vysvietit aj tag
                setLSidebarTag(isAll ? null : clickedTag);
                setNavHighlight(COMBINED);
            } else if (
                //final child subfolder
                sidebarFolderData.lSidebarFolder
                    .childrenSubfolders &&
                sidebarFolderData.lSidebarFolder
                    .childrenSubfolders.length === 0
            ) {
                //vysviet parent folder, vysvietit aj tag
                setLSidebarTag(isAll ? null : clickedTag);
                if (
                    sidebarFolderData.lSidebarFolder
                        .parentFolder
                ) {
                    navigate(
                        `/lanwiki/i/${sidebarFolderData.lSidebarFolder.parentFolder.id}`
                    );
                } else {
                    navigate(
                        `/lanwiki/i/${sidebarFolderData.lSidebarFolder.mainFolder.id}/s/${sidebarFolderData.lSidebarFolder.parentSubfolder.id}`
                    );
                }
                setNavHighlight(COMBINED);
                setLSidebarFolder(null);
            }
        } else {
            //odsviet tag, klik folder
            setLSidebarTag(null);
            let clickedItem = null;
            if (clickedFolder || clickedSubfolder) {
                clickedItem = clickedFolder
                    ? folders.find(
                          (folder) =>
                              clickedFolder.id === folder.id
                      )
                    : allSubfolders.find(
                          (subfolder) =>
                              clickedSubfolder.id ===
                              subfolder.id
                      );
            }
            setLSidebarFolder(clickedItem);
            setNavHighlight(FOLDERS);
            if (clickedSubfolder) {
                navigate(
                    `/lanwiki/i/${clickedItem.mainFolder.id}/s/${clickedSubfolder.id}`
                );
            } else {
                navigate(
                    `/lanwiki/i/${
                        clickedFolder
                            ? clickedFolder.id
                            : 'all'
                    }`
                );
            }
        }
    };

    const getParentFolder = () => {
        if (!sidebarFolderData.lSidebarFolder) {
            return null;
        }
        if (hasChildren(sidebarFolderData.lSidebarFolder)) {
            return sidebarFolderData.lSidebarFolder;
        } else if (
            !hasChildren(sidebarFolderData.lSidebarFolder)
        ) {
            if (
                sidebarFolderData.lSidebarFolder
                    .parentFolder
            ) {
                return folders.find(
                    (folder) =>
                        folder.id ===
                        sidebarFolderData.lSidebarFolder
                            .parentFolder.id
                );
            }
            if (
                sidebarFolderData.lSidebarFolder
                    .parentSubfolder
            ) {
                return allSubfolders.find(
                    (subfolder) =>
                        subfolder.id ===
                        sidebarFolderData.lSidebarFolder
                            .parentSubfolder.id
                );
            }
            return null;
        }
        return null;
    };

    const showSubfolderSidebar =
        getParentFolder() ||
        hasChildren(sidebarFolderData.lSidebarFolder);

    return (
        <div className="sidebar">
            <div className="scrollable fit-with-header">
                {!showArchived && (
                    <AddLanwikiPage
                        folderId={sidebarFolderId}
                        tagId={sidebarTagId}
                        sidebarFolder={
                            sidebarFolderData.lSidebarFolder
                        }
                        folders={folders}
                        allSubfolders={allSubfolders}
                    />
                )}
                <hr className="m-l-15 m-r-15 m-t-15" />

                {showSubfolderSidebar && (
                    <SubfoldersSidebar
                        folders={folders}
                        allSubfolders={allSubfolders}
                        parentFolder={getParentFolder()}
                        navHighlight={navHighlight}
                        setNavHighlight={setNavHighlight}
                        foldersLoading={foldersLoading}
                        foldersRefetch={foldersRefetch}
                        handleNavItemClick={
                            handleNavItemClick
                        }
                        showArchived={showArchived}
                        setShowArchived={setShowArchived}
                    />
                )}
                {!showSubfolderSidebar && (
                    <FoldersSidebar
                        folders={folders.filter((folder) =>
                            showArchived
                                ? folder.archived
                                : !folder.archived
                        )}
                        navHighlight={navHighlight}
                        setNavHighlight={setNavHighlight}
                        foldersLoading={foldersLoading}
                        foldersRefetch={foldersRefetch}
                        handleNavItemClick={
                            handleNavItemClick
                        }
                        showArchived={showArchived}
                        setShowArchived={setShowArchived}
                    />
                )}

                <hr className="m-l-15 m-r-15 m-t-15" />

                {!showArchived && (
                    <Nav vertical>
                        <Empty>
                            <NavItem
                                className={classnames(
                                    'row full-width sidebar-item m-t-5 noselect',
                                    {
                                        'active':
                                            [
                                                TAGS,
                                                COMBINED,
                                            ].includes(
                                                navHighlight
                                            ) &&
                                            sidebarTagId ===
                                                null,
                                    }
                                )}
                            >
                                <span
                                    className={classnames(
                                        'clickable sidebar-menu-item link',
                                        {
                                            'active':
                                                [
                                                    TAGS,
                                                    COMBINED,
                                                ].includes(
                                                    navHighlight
                                                ) &&
                                                sidebarTagId ===
                                                    null,
                                        }
                                    )}
                                    onClick={() => {
                                        handleNavItemClick(
                                            null,
                                            null,
                                            {},
                                            true
                                        );
                                    }}
                                >
                                    <span className="material-symbols-outlined p-r-5">
                                        sell
                                    </span>
                                    {t('allTags')}
                                </span>
                            </NavItem>
                            {tags.map((tag) => (
                                <NavItem
                                    key={tag.id}
                                    className={classnames(
                                        'row full-width sidebar-item noselect',
                                        {
                                            'active':
                                                [
                                                    TAGS,
                                                    COMBINED,
                                                ].includes(
                                                    navHighlight
                                                ) &&
                                                sidebarTagId ===
                                                    tag.id,
                                        }
                                    )}
                                >
                                    <span
                                        className={classnames(
                                            'clickable sidebar-menu-item link',
                                            {
                                                'active':
                                                    [
                                                        TAGS,
                                                        COMBINED,
                                                    ].includes(
                                                        navHighlight
                                                    ) &&
                                                    sidebarTagId ===
                                                        tag.id,
                                            }
                                        )}
                                        style={{
                                            textDecoration:
                                                'none !important',
                                        }}
                                        onClick={() => {
                                            handleNavItemClick(
                                                null,
                                                null,
                                                tag
                                            );
                                        }}
                                    >
                                        <span className="material-symbols-outlined p-r-5">
                                            sell
                                        </span>
                                        {tag.title}
                                    </span>
                                    {sidebarTagId ===
                                        tag.id &&
                                        (localProject.right
                                            .lanwikiTags ||
                                            userIsAdmin) && (
                                            <TagEdit
                                                id={tag.id}
                                            />
                                        )}
                                </NavItem>
                            ))}
                        </Empty>
                    </Nav>
                )}
                {!showArchived &&
                    (localProject.right.lanwikiTags ||
                        userIsAdmin) && <TagAdd />}
                {!showArchived && (
                    <hr className="m-l-15 m-r-15 m-t-15" />
                )}
                {!showArchived && (
                    <Nav vertical>
                        <li
                            className="sidebar-label row clickable noselect row align-items-center m-t-5"
                            onClick={() =>
                                setShowArchived(
                                    !showArchived
                                )
                            }
                        >
                            <span
                                className="material-symbols-outlined p-r-5"
                                style={{
                                    color: '#212121',
                                }}
                            >
                                inventory_2
                            </span>
                            <Label className="clickable m-l-0">
                                {t('archived')}
                            </Label>
                            <span className="material-symbols-outlined m-l-auto bigger">
                                chevron_right
                            </span>
                        </li>
                    </Nav>
                )}
            </div>
        </div>
    );
}
