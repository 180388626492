import React from 'react';

import { useQuery, useSubscription, useMutation } from '@apollo/client';

import Multiselect from 'components/multiselect';
import UserAdd from './userAdd';
import UserEdit from './userEdit';
import Loading from 'components/loading';
import SettingListContainer from '../components/settingListContainer';
import DeleteReplacement from 'components/deleteReplacement';

import { toSelArr } from 'helperFunctions';
import { useTranslation } from 'react-i18next';
import { addLocalError } from 'apollo/localSchema/actions';

import {
    GET_ROLES,
    ROLES_SUBSCRIPTION,
    GET_USERS,
    USERS_SUBSCRIPTION,
    COMPANIES_SUBSCRIPTION,
    DELETE_USER,
} from 'helpdesk/queries';

export default function UserListContainer() {
    const { t } = useTranslation();

    const [deleteUser] = useMutation(DELETE_USER);

    const {
        data: usersData,
        loading: usersLoading,
        refetch: usersRefetch,
    } = useQuery(GET_USERS, {
        fetchPolicy: 'network-only',
    });

    useSubscription(USERS_SUBSCRIPTION, {
        onData: () => {
            usersRefetch();
        },
    });

    useSubscription(COMPANIES_SUBSCRIPTION, {
        onData: () => {
            usersRefetch();
        },
    });

    const {
        data: rolesData,
        loading: rolesLoading,
        refetch: rolesRefetch,
    } = useQuery(GET_ROLES, {
        fetchPolicy: 'network-only',
    });

    useSubscription(ROLES_SUBSCRIPTION, {
        onData: () => {
            rolesRefetch();
        },
    });

    // state
    const [userFilter, setUserFilter] = React.useState('');
    const [editedItemId, setEditedItemId] = React.useState(null);
    const [itemToDeleteId, setItemToDeleteId] = React.useState(null);
    const [selectedRoles, setSelectedRoles] = React.useState([]);

    const dataLoading = usersLoading || rolesLoading;

    // sync
    React.useEffect(() => {
        if (!rolesLoading) {
            setSelectedRoles(toSelArr(rolesData.roles));
        }
    }, [rolesLoading]);

    const deleteUserFunc = (replacement) => {
        if (window.confirm(t('deleteUserMessage'))) {
            deleteUser({
                variables: {
                    id: itemToDeleteId,
                    newId: parseInt(replacement.id),
                },
            })
                .then(() => {
                    setItemToDeleteId(null);
                })
                .catch((err) => {
                    addLocalError(err);
                });
        }
    };

    if (dataLoading) {
        return (
            <div className="content">
                <div className="row m-0 p-0 taskList-container">
                    <Loading />
                </div>
            </div>
        );
    }

    const users = usersData.users.filter((user) =>
        selectedRoles.some((selectedRole) => selectedRole.id === user.role.id)
    );
    const roles = toSelArr(rolesData.roles);

    //data
    const allRolesSelected = selectedRoles.length === roles.length;

    const UserRoleFilter = (
        <Multiselect
            className="ml-auto"
            dropDownMenuClassname="p-b-10"
            options={[{ id: 'All', label: t('all') }, ...roles]}
            value={allRolesSelected ? [{ id: 'All', label: t('all') }, ...roles] : selectedRoles}
            label={t('filterByRoles')}
            onChange={(role) => {
                let selected = [...selectedRoles];
                if (role.id === 'All' && !allRolesSelected) {
                    selected = [...roles];
                } else {
                    if (selected.some((sr) => sr.id === role.id)) {
                        selected = selected.filter((sr) => sr.id !== role.id);
                    } else {
                        selected = [...selected, role];
                    }
                }
                setSelectedRoles(selected);
            }}
        />
    );

    return (
        <SettingListContainer
            header={t('users')}
            addLabel={t('user')}
            filter={userFilter}
            setFilter={setUserFilter}
            RightFilterComponent={UserRoleFilter}
            AddComponent={UserAdd}
            EditComponent={UserEdit}
            editedItemId={editedItemId}
            setEditedItemId={setEditedItemId}
        >
            <table className="table">
                <thead>
                    <tr>
                        <th>{t('name')}</th>
                        <th>{t('email')}</th>
                        <th>{t('company')}</th>
                        <th>{t('role')}</th>
                        <th width="100px">{t('actions')}</th>
                    </tr>
                </thead>
                <tbody>
                    {users
                        .filter(
                            (item) =>
                                item.email.toLowerCase().includes(userFilter.toLowerCase()) ||
                                item.fullName.toLowerCase().includes(userFilter.toLowerCase())
                        )
                        .sort((user1, user2) => (user1.email > user2.email ? 1 : -1))
                        .map((user) => (
                            <tr
                                key={user.id}
                                style={{
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                }}
                            >
                                <td>{user.fullName}</td>
                                <td>{user.email}</td>
                                <td>{user.company.title}</td>
                                <td>{user.role.title}</td>
                                <td className="row">
                                    <button
                                        className="btn-link-red m-r-0 m-l-0 p-0"
                                        onClick={() => {
                                            setItemToDeleteId(user.id);
                                        }}
                                    >
                                        <span className="material-symbols-outlined">delete</span>
                                    </button>
                                    <button
                                        className="btn-link m-r-0 m-l-10 p-0"
                                        onClick={() => {
                                            setEditedItemId(user.id);
                                        }}
                                    >
                                        <span className="material-symbols-outlined">edit</span>
                                    </button>
                                </td>
                            </tr>
                        ))}
                </tbody>
            </table>

            <DeleteReplacement
                isOpen={itemToDeleteId ? true : false}
                label={t('user')}
                options={toSelArr(
                    users.filter((user) => user.id !== itemToDeleteId),
                    'fullName'
                )}
                close={() => setItemToDeleteId(null)}
                finishDelete={deleteUserFunc}
            />
        </SettingListContainer>
    );
}
