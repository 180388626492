export default {
    aiSortType: {
        en: 'By Artificial inteligence',
        sk: 'Umelou inteligenciou',
        cz: 'Umělou inteligencí',
        de: 'Durch künstliche Intelligenz',
    },
    aiSort2: {
        en: 'Task sort by Artificial inteligence',
        sk: 'Zoradenie úloh umelou inteligenciou',
        cz: 'Seřazení úkolů umělou inteligencí',
        de: 'Aufgabensortierung durch künstliche Intelligenz',
    },
    ai: {
        en: 'Artificial inteligence',
        sk: 'Umelá inteligencia',
        cz: 'Umělá inteligence',
        de: 'Künstliche Intelligenz',
    },
    ai2: {
        en: 'Artificial inteligence',
        sk: 'Umelej inteligencie',
        cz: 'Umělé inteligence',
        de: 'Künstlichen Intelligenz',
    },
    combined: {
        en: 'Sort 1 - the slowest and the most accurate',
        sk: 'Zoradenie 1 - najpomalejsi a najpresnejsi',
        cz: 'Uspořádání 1- nejpomalejší a nejpřesnější',
        de: 'Sortieren 1 - die langsamste und genaueste',
    },
    elu: {
        en: 'Sort 2',
        sk: 'Zoradenie 2',
        cz: 'Uspořádání 2',
        de: 'Sortieren 2',
    },
    pure: {
        en: 'Sort 3',
        sk: 'Zoradenie 3',
        cz: 'Uspořádání 3',
        de: 'Sortieren 3',
    },
    selu: {
        en: 'Sort 4',
        sk: 'Zoradenie 4',
        cz: 'Uspořádání 4',
        de: 'Sortieren 4',
    },
    tanh: {
        en: 'Sort 5 - the fastest and the second most accurate',
        sk: 'Zoradenie 5 - najrychlejsi a druhy najpresnejsi',
        cz: 'Uspořádání 5 - nejrychlejší a druhý nejpřesnější',
        de: 'Sortieren 5 - die schnellste und die zweitgenaueste',
    },
    aiSettings: {
        en: 'AI Settings',
        sk: 'UI Nastavenia',
        cz: 'Nastavení AI',
        de: 'KI-Einstellungen',
    },
    aiSort: {
        en: 'AI Sort',
        sk: 'UI Zoradenie',
        cz: 'AI řazení',
        de: 'KI-Sortierung',
    },
    aiSolutions: {
        en: 'AI Solutions',
        sk: 'UI Riešenia',
        cz: 'AI řešení',
        de: 'KI-Lösungen',
    },
    aiNotifications: {
        en: 'AI Notifications',
        sk: 'UI Notifikácie',
        cz: 'Upozornění AI',
        de: 'KI-Benachrichtigungen',
    },
    aiStatistics: {
        en: 'AI Statistics',
        sk: 'UI Štatistiky',
        cz: 'Statistika AI',
        de: 'KI-Statistiken',
    },
    maxInterval: {
        en: 'Maximum interval',
        sk: 'Maximálny interval',
        cz: 'Maximální interval',
        de: 'Maximales Intervall',
    },
    averageInterval: {
        en: 'Average interval',
        sk: 'Priemerný interval',
        cz: 'Průměrný interval',
        de: 'Durchschnittliches Intervall',
    },
    maxSubtasksValues: {
        en: 'Maximum subtask values',
        sk: 'Maximálny pracovný čas úlohy',
        cz: 'Maximální hodnoty dílčích úkolů',
        de: 'Maximale Unteraufgabenwerte',
    },
    averageSubtasksValues: {
        en: 'Maximum subtask values',
        sk: 'Maximálny pracovný čas úlohy',
        cz: 'Maximální hodnoty dílčích úkolů',
        de: 'Maximale Unteraufgabenwerte',
    },
    autofill: {
        en: 'Autofill',
        sk: 'Riešenie',
        cz: 'Automatické vyplňování',
        de: 'Automatisches Ausfüllen',
    },
    workTime: {
        en: 'Work time',
        sk: 'Pracovný čas',
        cz: 'Pracovní doba',
        de: 'Arbeitszeit',
    },
    maxWorkTime: {
        en: 'Maximum working hours',
        sk: 'Maximálny pracovný čas',
        cz: 'Maximální pracovní doba',
        de: 'Maximale Arbeitszeit',
    },
    intervalFromCreateToClose: {
        en: 'The interval from creation to solution of the task',
        sk: 'Interval od vytvorenia do vyriešenia úlohy',
        cz: 'Interval od vytvoření do vyřešení úlohy',
        de: 'Das Intervall von der Erstellung bis zur Lösung der Aufgabe',
    },
    maxIntervalFromCreateToClose: {
        en: 'The maximum interval from creation to solution of the task',
        sk: 'Maximálny interval od vytvorenia do vyriešenia úlohy',
        cz: 'Maximální interval od vytvoření do vyřešení úlohy',
        de: 'Das maximale Intervall von der Erstellung bis zur Lösung der Aufgabe',
    },
    days: {
        en: 'Days',
        sk: 'Dni',
        cz: 'Dny',
        de: 'Tage',
    },
    notifyIfOverMaxInterval: {
        en: 'Notification in case of exceeding the maximum task resolution interval',
        sk: 'Notifikácia v prípade prekročenia maximálneho intervalu vyriešenia úlohy',
        cz: 'Notifikace v případě překročení maximálního intervalu vyřešení úlohy',
        de: 'Benachrichtigung bei Überschreitung des maximalen Aufgabenauflösungsintervalls',
    },
    notifyIfOverMaxSubtaskValues: {
        en: 'Notification in case of exceeding the maximum working time',
        sk: 'Notifikácia v prípade prekročenia maximálneho pracovného času',
        cz: 'Notifikace v případě překročení maximální pracovní doby',
        de: 'Benachrichtigung bei Überschreitung der maximalen Arbeitszeit',
    },
    foundSolution: {
        en: 'LanTask AI found a similarity with the task',
        sk: 'LanTask AI našla podobnosť s úlohou',
        cz: 'LanTask AI našla podobnost s úkolem',
        de: 'LanTask AI fand eine Ähnlichkeit mit der Aufgabe',
    },
    noSolution: {
        en: 'The task does not have a solution procedure.',
        sk: 'Úloha nemá postup pri riešení.',
        cz: 'Role nemá postup při řešení.',
        de: 'Die Aufgabe hat kein Lösungsverfahren.',
    },
    recommendsSolution: {
        en: 'AI suggests the following procedure for solving the task:',
        sk: 'Navrhuje nasledovný postup pri riešení úlohy:',
        cz: 'AI Navrhuje následující postup při řešení úlohy:',
        de: 'KI schlägt folgende Vorgehensweise zur Lösung der Aufgabe vor:',
    },
    addedSolution: {
        en: 'The solution has been added.',
        sk: 'Riešenie bolo pridané.',
        cz: 'Řešení bylo přidáno.',
        de: 'Die Lösung wurde hinzugefügt.',
    },
    cannotCreateUnassignedSubtask: {
        en: 'Cannot create unassigned subtask. Please assign someone to the task first!',
        sk: 'Nie je možné vytvoriť nepriradenú podúlohu. Prosím najprv niekomu priraďte úlohu.',
        cz: 'Nelze vytvořit nepřiřazený dílčí úkol. Nejprve prosím někoho přidělte k úkolu!',
        de: 'Nicht zugewiesene Unteraufgabe kann nicht erstellt werden. Bitte weisen Sie die Aufgabe zuerst jemandem zu!',
    },
    notAllSubtasksAdded: {
        en: 'Not all subtasks may have been added.',
        sk: 'Nie všetky podúlohy mohli byť pridané.',
        cz: 'Dny',
        de: 'Tage',
    },
    applySolution: {
        en: 'Not all subtasks may have been added.',
        sk: 'Aplikovať navhnuté AI riešenie',
        cz: 'Možná nebyly přidány všechny dílčí úkoly.',
        de: 'Möglicherweise wurden nicht alle Unteraufgaben hinzugefügt.',
    },
    lanTaskAI: {
        en: 'LanTask AI',
        sk: 'LanTask AI',
        cz: 'LanTask AI',
        de: 'LanTask KI',
    },
    overMaxSinceCreationNotifications: {
        en: 'The task exceeded the standard interval for solving similar tasks.',
        sk: 'Úloha prekročila štandardný interval riešenia podobných úloh.',
        cz: 'Úloha překročila standardní interval řešení podobných úloh.',
        de: 'Die Aufgabe hat das Standardintervall zum Lösen ähnlicher Aufgaben überschritten.',
    },
    overMaxSubtaskValuesNotifications: {
        en: 'The total time worked exceeded the working time for similar tasks.',
        sk: 'Celkový odpracovaný čas prekročil pracovný čas pri podobných úlohách.',
        cz: 'Celkový odpracovaný čas překročil pracovní dobu při podobných úkolech.',
        de: 'Die Gesamtarbeitszeit überstieg die Arbeitszeit für ähnliche Aufgaben.',
    },
    taskAlreadyHasAppliedAutofill: {
        en: 'This task has already applied an autofill solution.',
        sk: 'Táto úloha už má aplikované riešenie.',
        cz: 'Tento úkol již používá řešení automatického vyplňování.',
        de: 'Diese Aufgabe hat bereits eine Autofill-Lösung angewendet.',
    },
    autofillName: {
        en: 'Autofill name',
        sk: 'Názov automatického doplnenia',
        cz: 'Název automatického doplnení',
        de: 'Der Name der automatischen Vervollständigung',
    },
    titleTooShort: {
        en: 'The title is too short!',
        sk: 'Názov je príliš krátky!',
        cz: 'Název je příliš krátký!',
        de: 'Der Titel ist zu kurz!',
    },
    subtaskTimeInsufficinet: {
        en: 'The total time for subtasks is insufficient!',
        sk: 'Celkový čas na podúlohy je nedostatočný!',
        cz: 'Celkový čas pro dílčí úkoly je nedostatečný!',
        de: 'Die Gesamtzeit für Teilaufgaben reicht nicht aus!',
    },
};
