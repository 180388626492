import React, { useState, useEffect } from 'react';

import { Label, Spinner } from 'reactstrap';
import CKEditor from 'components/CKEditor';
import classnames from 'classnames';

import Attachments from './attachments';
import TagsPickerPopover from './tags';

import { useTranslation } from 'react-i18next';
import { toSelArr, removeStylesFromString } from 'helperFunctions';
import { REST_URL } from 'configs/restAPI';
import axios from 'axios';

import 'scss/direct/task-ckeditor.scss';
import { sortBy } from 'lodash';

export default function Description(props) {
    //data & queries
    const {
        taskId,
        description,
        setDescription,
        showDescription,
        setShowDescription,

        showEditBtn,
        showSave,
        onSaveBtnClick,
        onEditBtnClick,
        showAddAttachmentBtn,
        showEditTagsBtn,
        showTags,
        showBasicDescription,
        showAttachments,
        attachmentsDisabled,
        type,

        tags,
        onChangeTags,
        userRights,
        invoiced,
        autoUpdateTask,
        projectAttributes,
        project,
        attachments,
        descriptionAttachments,
        addAttachments,
        removeAttachment,
    } = props;

    const { t } = useTranslation();

    const [html, setHtml] = useState('');
    const [saving, setSaving] = useState(false);

    const blobToBase64 = (blob) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        return new Promise((resolve) => {
            reader.onloadend = () => {
                resolve(reader.result);
            };
        });
    };

    async function insertImage(image) {
        try {
            let blob = await axios.get(`${REST_URL}/api/get-attachments`, {
                params: type
                    ? {
                          type: type,
                          path: image.path,
                      }
                    : {
                          type: type ? type : 'task',
                          path: image.path,
                          fronInvoice: false,
                      },
                headers: {
                    'authorization': `${sessionStorage.getItem('acctok')}`,
                },
                responseType: 'blob',
            });

            let base64 = await blobToBase64(blob.data);
            return base64;
        } catch (error) {
            console.log(error);
        }
    }

    async function insertImages() {
        let newHtml = description + '';

        for (const image of descriptionAttachments) {
            const imageSource = await insertImage(image);
            newHtml = newHtml.replaceAll(`cid:${image.textId}`, imageSource);
        }

        setSaving(false);
        setHtml(newHtml);
    }

    useEffect(() => {
        if (descriptionAttachments.length > 0) {
            insertImages();
        } else {
            setSaving(false);
            setHtml(description);
        }
    }, [descriptionAttachments]);

    const getRenderDescription = () => {
        let RenderDescription = null;
        if (saving) {
            RenderDescription = (
                <div
                    className="task-edit-popis row align-items-center"
                    style={{
                        backgroundColor: '#ede9c7',
                        color: '#0078d7',
                    }}
                >
                    <Spinner className="spinner m-r-5" />
                    <span>{t('saving')}</span>
                </div>
            );
        } else if (!userRights.rights.taskDescriptionWrite || invoiced) {
            if (description.length !== 0) {
                RenderDescription = (
                    <div
                        className="task-edit-popis"
                        style={{
                            backgroundColor: '#ede9c7',
                        }}
                        dangerouslySetInnerHTML={{
                            __html: html,
                        }}
                    />
                );
            } else {
                RenderDescription = (
                    <div
                        className="task-edit-popis"
                        style={{
                            backgroundColor: '#ede9c7',
                        }}
                    >
                        {' '}
                        {t('noTaskDescription')}{' '}
                    </div>
                );
            }
        } else {
            if (showDescription && !invoiced) {
                RenderDescription = (
                    <div>
                        <CKEditor
                            value={html}
                            ForcePasteAsPlainText={true}
                            onReady={(editor) => {
                                if (taskId !== 'add') {
                                    editor.editing.view.document.on('keydown', (evt, data) => {
                                        if (data.keyCode === 27) {
                                            setSaving(true);
                                            if (!isNaN(taskId)) {
                                                autoUpdateTask({
                                                    description,
                                                });
                                            }
                                            setShowDescription(false);
                                            data.preventDefault();
                                            evt.stop();
                                        }
                                    });
                                }
                            }}
                            onChange={(description) => {
                                const cleanText = removeStylesFromString(description);
                                setHtml(cleanText);
                                setDescription(cleanText);
                            }}
                            type="basic"
                        />
                    </div>
                );
            } else {
                if (description.length !== 0) {
                    RenderDescription = (
                        <div
                            className="task-edit-popis"
                            style={{
                                backgroundColor: '#ede9c7',
                            }}
                            dangerouslySetInnerHTML={{
                                __html: html,
                            }}
                        />
                    );
                } else {
                    RenderDescription = (
                        <div
                            className="task-edit-popis"
                            style={{
                                backgroundColor: '#ede9c7',
                            }}
                        >
                            {t('noTaskDescription')}
                        </div>
                    );
                }
            }
        }

        return RenderDescription;
    };

    return (
        <div
            className={classnames('form-section', {
                'p-l-20 p-r-20': !isNaN(taskId),
            })}
        >
            <div className="row align-items-center">
                <Label className="m-r-10 m-b-0">{t('taskDescription')}</Label>
                {showEditBtn && (
                    <button
                        className="btn-link"
                        onClick={() => {
                            if (showDescription) {
                                if (showSave) {
                                    //  setSaving(true);
                                }
                                onSaveBtnClick();
                            } else {
                                onEditBtnClick();
                            }
                        }}
                    >
                        <span className="material-symbols-outlined">
                            {showDescription ? 'edit' : 'save'}
                        </span>
                        {!showDescription ? t('edit') : t('save')}
                    </button>
                )}
                {showAddAttachmentBtn && (
                    <label
                        htmlFor={`uploadAttachment-${isNaN(taskId) ? null : taskId}`}
                        className="btn-link clickable"
                        style={{
                            alignItems: 'center',
                            display: 'flex',
                            marginBottom: '0px',
                            marginRight: '10px',
                        }}
                    >
                        <span className="material-symbols-outlined bigger">add</span>{' '}
                        {t('attachment')}
                    </label>
                )}
                {showEditTagsBtn && (
                    <TagsPickerPopover
                        taskID={taskId}
                        required={userRights.attributeRights.tags.required}
                        disabled={projectAttributes.tags.fixed || invoiced}
                        items={toSelArr(project === null ? [] : project.tags)}
                        className="center-hor"
                        selected={tags}
                        onChange={onChangeTags}
                    />
                )}
                {showTags &&
                    sortBy(tags, [
                        { key: 'order', asc: true },
                        { key: 'title', asc: true },
                    ]).map((tag) => (
                        <span
                            key={tag.id}
                            style={{
                                background: tag.color,
                                color: 'white',
                                borderRadius: 3,
                            }}
                            className="m-r-5 p-l-5 p-r-5"
                        >
                            {tag.title}
                        </span>
                    ))}
            </div>
            <div className="form-section-rest">
                {!showBasicDescription && getRenderDescription()}
                {showBasicDescription && (
                    <CKEditor
                        value={description}
                        onChange={(description) => {
                            setDescription(removeStylesFromString(description));
                        }}
                        type="basic"
                    />
                )}
                {showAttachments && (
                    <Attachments
                        taskID={isNaN(taskId) ? null : taskId}
                        top={false}
                        type="task"
                        disabled={attachmentsDisabled}
                        attachments={attachments}
                        addAttachments={addAttachments}
                        removeAttachment={removeAttachment}
                        style={{
                            backgroundColor: '#ede9c7',
                        }}
                        attachmentStyle={{
                            backgroundColor: 'transparent',
                        }}
                    />
                )}
            </div>
        </div>
    );
}
