import React, { useMemo } from 'react';

import { useLocation } from 'react-router-dom';

import SettingsSidebar from './settingsSidebar';
import AISidebar from './aiSidebar';

export default function MainSettingsSidebar(props) {
    //data & queries
    const location = useLocation();

    const showAISettings = useMemo(() => {
        return location.pathname.includes('ai');
    }, [location]);

    return (
        <div className="sidebar">
            <div className="scrollable fit-with-header">
                {showAISettings && <AISidebar {...props} />}
                {!showAISettings && (
                    <SettingsSidebar {...props} />
                )}
            </div>
        </div>
    );
}
