export const hasChildren = (folder) => {
    if (!folder) {
        return false;
    }
    if (folder.subfolders && folder.subfolders.length > 0) {
        return true;
    }
    if (
        folder.childrenSubfolders &&
        folder.childrenSubfolders.length > 0
    ) {
        return true;
    }
    return false;
};

export const childrenSubfoldersFlat = (
    parentSubfolder,
    layer = 0
) => {
    if (parentSubfolder.childrenSubfolders.length >= 0) {
        return [
            { ...parentSubfolder, layer },
            ...parentSubfolder.childrenSubfolders
                .map((childSubfolder) =>
                    childrenSubfoldersFlat(
                        childSubfolder,
                        layer + 1
                    ).flat()
                )
                .flat(),
        ];
    }
};
