import { gql } from '@apollo/client';

export const ADD_USER = gql`
    mutation registerUser(
        $email: String!
        $name: String
        $surname: String!
        $language: LanguageEnum
        $password: String!
        $phone: String
        $receiveNotifications: Boolean
        $signature: String
        $roleId: Int!
        $companyId: Int!
    ) {
        registerUser(
            email: $email
            name: $name
            surname: $surname
            password: $password
            phone: $phone
            receiveNotifications: $receiveNotifications
            signature: $signature
            roleId: $roleId
            companyId: $companyId
            language: $language
        ) {
            id
            email
            fullName
            role {
                id
                title
            }
            company {
                id
                title
            }
        }
    }
`;

export const GET_USERS = gql`
    query {
        users {
            id
            email
            fullName
            role {
                id
                title
            }
            company {
                id
                title
            }
        }
    }
`;

export const GET_BASIC_USERS = gql`
    query {
        basicUsers {
            id
            email
            fullName
            role {
                level
                id
                title
            }
            company {
                id
                title
            }
        }
    }
`;

export const GET_USER = gql`
    query user($id: Int!) {
        user(id: $id) {
            id
            createdAt
            updatedAt
            active
            email
            name
            surname
            phone
            fullName
            receiveNotifications
            signature
            role {
                id
                title
                level
            }
            company {
                id
                title
            }
            language
        }
    }
`;

export const UPDATE_USER = gql`
    mutation updateUser(
        $id: Int!
        $email: String
        $name: String
        $surname: String
        $phone: String
        $receiveNotifications: Boolean
        $signature: String
        $roleId: Int
        $companyId: Int
        $language: LanguageEnum
        $password: String
    ) {
        updateUser(
            id: $id
            email: $email
            name: $name
            surname: $surname
            phone: $phone
            receiveNotifications: $receiveNotifications
            signature: $signature
            roleId: $roleId
            companyId: $companyId
            language: $language
            password: $password
        ) {
            id
            email
            role {
                id
                title
            }
            company {
                id
                title
            }
        }
    }
`;

export const UPDATE_PROFILE = gql`
    mutation updateProfile(
        $email: String
        $name: String
        $surname: String
        $phone: String
        $receiveNotifications: Boolean
        $signature: String
        $language: LanguageEnum
        $password: String
    ) {
        updateProfile(
            email: $email
            name: $name
            surname: $surname
            phone: $phone
            receiveNotifications: $receiveNotifications
            signature: $signature
            language: $language
            password: $password
        ) {
            accessToken
            user {
                id
            }
        }
    }
`;

export const DELETE_USER = gql`
    mutation deleteUser($id: Int!, $newId: Int!) {
        deleteUser(id: $id, newId: $newId) {
            id
        }
    }
`;

export const SET_USER_ACTIVE = gql`
    mutation setUserActive($id: Int!, $active: Boolean!) {
        setUserActive(id: $id, active: $active) {
            id
        }
    }
`;

export const SET_TASKLIST_LAYOUT = gql`
    mutation setTasklistLayout($tasklistLayout: Int!) {
        setTasklistLayout(tasklistLayout: $tasklistLayout) {
            tasklistLayout
        }
    }
`;

export const USERS_SUBSCRIPTION = gql`
    subscription usersSubscription {
        usersSubscription
    }
`;
