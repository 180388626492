import React from 'react';
import { UncontrolledPopover } from 'reactstrap';
import { useTranslation } from 'react-i18next';

export default function GeneralPopover(props) {
    const {
        placement,
        target,
        header,
        children,
        reset,
        submit,
        open,
        close,
        closeOnly,
        className,
        headerClassName,
        hideButtons,
    } = props;

    const { t } = useTranslation();

    const closeAction = () => {
        close && close();
    };

    const isOpened = open === true;

    return (
        <UncontrolledPopover
            trigger="legacy"
            placement={placement}
            className={`custom-popover ${className}`}
            isOpen={isOpened}
            target={target}
            toggle={closeAction}
        >
            {header && (
                <label
                    style={{ display: 'block' }}
                    className={headerClassName ? headerClassName : ''}
                >
                    {header}
                </label>
            )}
            {children}
            {!closeOnly && !hideButtons && (
                <div className="row m-t-15">
                    <button
                        type="button"
                        className="btn-link-red"
                        onClick={() => {
                            if (reset) {
                                reset();
                            }
                            closeAction();
                        }}
                    >
                        {t('cancel')}
                    </button>
                    {submit && (
                        <button
                            type="button"
                            className="btn ml-auto"
                            onClick={() => {
                                submit();
                                closeAction();
                            }}
                        >
                            {t('save')}
                        </button>
                    )}
                </div>
            )}
            {closeOnly && !hideButtons && (
                <div className="row m-t-15">
                    <button
                        type="button"
                        className="btn-link-red p-0"
                        onClick={() => {
                            closeAction();
                        }}
                    >
                        {t('close')}
                    </button>
                </div>
            )}
        </UncontrolledPopover>
    );
}
