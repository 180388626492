import React from 'react';

export default function ErrorMessage(props) {
    const { show, message, className } = props;

    if (show === undefined) {
        return (
            <div
                className={`error-message ${
                    className ? className : ''
                }`}
            >{`noErrorMessageDefined`}</div>
        );
    }
    if (!show) {
        return null;
    }
    return (
        <div
            className={`error-message ${
                className ? className : ''
            }`}
        >
            <span className="material-symbols-outlined m-r-5">
                warning
            </span>{' '}
            {message ? message : 'errorDefineShow'}
        </div>
    );
}
