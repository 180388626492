import React from 'react';
import {
    FormGroup,
    Label,
    Input,
    Button,
    InputGroup,
} from 'reactstrap';
import { randomPassword } from 'helperFunctions';
import { useTranslation } from 'react-i18next';

export default function SettingsHiddenInput(props) {
    //data
    const {
        id,
        label,
        type,
        placeholder,
        required,
        value,
        onChange,
        inputProps,
        inputClassName,
        regeneratePassword,
    } = props;

    const { t } = useTranslation();

    const [shown, setShown] = React.useState(false);

    return (
        <FormGroup>
            <div className="row">
                <Label htmlFor={id}>
                    {label}
                    {required && (
                        <span className="warning-big">
                            *
                        </span>
                    )}
                </Label>
                {regeneratePassword && (
                    <button
                        className="btn-link ml-auto"
                        onClick={() => {
                            onChange({
                                target: {
                                    value: randomPassword(),
                                },
                            });
                        }}
                    >
                        {t('regeneratePassword')}
                    </button>
                )}
            </div>
            <InputGroup>
                <Input
                    id={id}
                    type={shown ? type : 'password'}
                    placeholder={
                        placeholder
                            ? placeholder
                            : `${t(
                                  'enter'
                              )} ${label.toLowerCase()}`
                    }
                    value={value}
                    onChange={onChange}
                    className={` ${
                        inputClassName ? inputClassName : ''
                    }`}
                    {...(inputProps ? inputProps : {})}
                />
                <Button
                    className="clickable"
                    onClick={() => setShown(!shown)}
                >
                    <span className="material-symbols-outlined">
                        {!shown
                            ? 'visibility'
                            : 'visibility_off'}
                    </span>
                </Button>
            </InputGroup>
        </FormGroup>
    );
}
