import React from 'react';

import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useMutation, useQuery, useSubscription } from '@apollo/client';
import { ListGroupItem, Label } from 'reactstrap';
import Select from 'react-select';
import classnames from 'classnames';
import Loading from 'components/loading';
import { addLocalError } from 'apollo/localSchema/actions';
import { pickSelectStyle } from 'configs/components/select';
import NotificationInfo from './notificationInfo';

import {
    timestampToStringFNS,
    getLocation,
    translateAllSelectItems,
    translateSelectItem,
    getMyData,
} from 'helperFunctions';

import {
    GET_USER_NOTIFICATIONS,
    SET_USER_NOTIFICATION_READ,
    SET_ALL_USER_NOTIFICATIONS_READ,
    DELETE_ALL_USER_NOTIFICATIONS,
    DELETE_SELECTED_USER_NOTIFICATIONS,
    USER_NOTIFICATIONS_SUBSCRIPTION,
} from 'components/queries';

import { UPDATE_PROFILE } from 'helpdesk/queries';

import { useTranslation } from 'react-i18next';

const noTypeFilter = {
    value: null,
    label: 'All types',
    labelId: 'allTypes',
};

const readTypeFilter = {
    value: 'read',
    label: 'Read',
    labelId: 'read',
};

const unreadTypeFilter = {
    value: 'unread',
    label: 'Unread',
    labelId: 'unread',
};

export default function NotificationList() {
    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();

    const { t } = useTranslation();

    const currentUser = getMyData();

    const notificationID = params.notificationID ? parseInt(params.notificationID) : null;
    const URL = getLocation(location);

    const {
        data: notificationsData,
        loading: notificationsLoading,
        refetch: notificationsRefetch,
    } = useQuery(GET_USER_NOTIFICATIONS, {
        fetchPolicy: 'network-only',
    });

    useSubscription(USER_NOTIFICATIONS_SUBSCRIPTION, {
        onData: () => {
            notificationsRefetch();
        },
    });

    const [setUserNotificationRead] = useMutation(SET_USER_NOTIFICATION_READ);
    const [setAllUserNotificationsRead] = useMutation(SET_ALL_USER_NOTIFICATIONS_READ);
    const [deleteAllUserNotifications] = useMutation(DELETE_ALL_USER_NOTIFICATIONS);
    const [deleteSelectedUserNotifications] = useMutation(DELETE_SELECTED_USER_NOTIFICATIONS);

    const [updateProfile] = useMutation(UPDATE_PROFILE);

    const [searchFilter, setSearchFilter] = React.useState('');
    const [type, setType] = React.useState(translateSelectItem(noTypeFilter, t));

    const setEmailNotifications = () => {
        updateProfile({
            variables: {
                receiveNotifications: !currentUser.receiveNotifications,
            },
        });
    };

    const setNotificationReadFunc = (notification) => {
        if (!notification.read) {
            setUserNotificationRead({
                variables: {
                    id: notification.id,
                    read: true,
                },
            }).catch((err) => {
                addLocalError(err);
            });
        }
    };

    const markAllAsRead = () => {
        if (window.confirm(t('confirmMessagesMarkAllRead'))) {
            setAllUserNotificationsRead({
                variables: {
                    read: true,
                },
            }).catch((err) => {
                addLocalError(err);
            });
        }
    };

    const deleteAll = () => {
        if (window.confirm(t('confirmMessagesDeleteAll'))) {
            deleteAllUserNotifications().catch((err) => {
                addLocalError(err);
            });
        }
    };

    const deleteRead = () => {
        if (window.confirm(t('confirmMessagesDeleteAllRead'))) {
            const userNotifications = notificationsData.userNotifications
                .filter((notification) => notification.read)
                .map((notification) => notification.id);
            deleteSelectedUserNotifications({
                variables: {
                    ids: userNotifications,
                },
            }).catch((err) => {
                addLocalError(err);
            });
        }
    };

    if (notificationsLoading) {
        return <Loading />;
    }

    const filterNotifications = () => {
        let search = searchFilter.toLowerCase();
        return notificationsData.userNotifications
            .filter(
                (notification) =>
                    (type.value === null ||
                        (type.value === 'read' && notification.read) ||
                        (type.value === 'unread' && !notification.read)) &&
                    (timestampToStringFNS(notification.createdAt).includes(search) ||
                        (notification.message &&
                            notification.message.toLowerCase().includes(search)) ||
                        (notification.task &&
                            notification.task.title.toLowerCase().includes(search)) ||
                        (notification.task && notification.task.id.toString().includes(search)))
            )
            .sort((notification1, notification2) =>
                notification1.createdAt > notification2.createdAt ? -1 : 1
            );
    };

    const getTypes = () => {
        let typeFilter = [noTypeFilter, readTypeFilter, unreadTypeFilter];
        return translateAllSelectItems(typeFilter, t);
    };

    const notifications = filterNotifications();
    return (
        <div className="notifications row">
            <div className="col-lg-4 notification-list scroll-visible">
                <div className="fit-with-header-and-commandbar">
                    <h2 className="m-t-20 m-l-20 m-r-20">{t('notifications')}</h2>

                    <div className="row m-b-20 m-l-20 m-r-20">
                        <div className="search-row" style={{ width: '60%' }}>
                            <div className="search">
                                <input
                                    type="text"
                                    className="form-control search-text"
                                    value={searchFilter}
                                    onChange={(e) => setSearchFilter(e.target.value)}
                                    placeholder={t('search')}
                                />
                                <button className="search-btn" type="button">
                                    <span className="material-symbols-outlined">search</span>
                                </button>
                            </div>
                        </div>
                        <span
                            className="center-hor ml-auto"
                            style={{
                                width: '30%',
                                backgroundColor: 'white',
                            }}
                        >
                            <Select
                                value={type}
                                onChange={(type) => setType(type)}
                                options={getTypes()}
                                styles={pickSelectStyle(['invisible'])}
                            />
                        </span>
                    </div>

                    <div className="buttons-row m-l-20 m-r-20">
                        <button
                            type="button"
                            className="btn-link btn-distance"
                            onClick={markAllAsRead}
                            disabled={notifications.every((notification) => notification.read)}
                        >
                            {t('markAllRead')}
                        </button>
                        <button
                            type="button"
                            className="btn-link btn-distance"
                            onClick={deleteAll}
                            disabled={notifications.length === 0}
                        >
                            {t('deleteAll')}
                        </button>
                        <button
                            type="button"
                            className="btn-link"
                            onClick={deleteRead}
                            disabled={!notifications.some((notification) => notification.read)}
                        >
                            {t('deleteRead')}
                        </button>
                    </div>
                    <div>
                        {notifications.map((notification) => (
                            <li
                                key={notification.id}
                                className={classnames(
                                    {
                                        'notification-focused': notificationID === notification.id,
                                    },
                                    'clickable'
                                )}
                                onClick={() => {
                                    setNotificationReadFunc(notification);
                                    navigate(`${URL}/notifications/${notification.id}`);
                                }}
                            >
                                <div
                                    className={classnames({
                                        'notification-read': notification.read,
                                        'notification-not-read': !notification.read,
                                    })}
                                >
                                    <span className="notification-title">
                                        <span className="material-symbols-outlined m-r-5">
                                            {notification.read ? 'drafts' : 'mail'}
                                        </span>
                                        <span>{notification.subject}</span>
                                    </span>
                                    <div className="notification-rest">
                                        <div className="row">
                                            <div>
                                                <Label className="p-r-5">{t('user')}:</Label>
                                                {notification.fromUser
                                                    ? notification.fromUser.fullName
                                                    : t('noUser')}
                                            </div>
                                            <div className="ml-auto">
                                                {timestampToStringFNS(
                                                    parseInt(notification.createdAt)
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        ))}
                        {notifications.length === 0 && (
                            <ListGroupItem>{t('noNotifications')}</ListGroupItem>
                        )}
                    </div>
                </div>
            </div>
            <div className="col-lg-8">
                {notificationID !== null &&
                    notifications.some((notification) => notification.id === notificationID) && (
                        <NotificationInfo
                            notification={notifications.find(
                                (notification) => notification.id === notificationID
                            )}
                            currentUser={currentUser}
                            setEmailNotifications={setEmailNotifications}
                        />
                    )}
                {notificationID === null && (
                    <div className="fit-with-header" style={{ backgroundColor: 'white' }} />
                )}
            </div>
        </div>
    );
}
