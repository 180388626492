import React from 'react';
import { useMutation } from '@apollo/client';
import SubfolderAdd from './add';
import { getMyData } from 'helperFunctions';
import { ADD_SUBFOLDER } from 'lanwiki/queries';

export default function AddSubfolderLoader(props) {
    const currentUser = getMyData();
    const [addSubfolder] = useMutation(ADD_SUBFOLDER);

    return (
        <SubfolderAdd
            {...props}
            addSubfolder={addSubfolder}
            currentUser={currentUser}
        />
    );
}
