import React, { useMemo } from 'react';
import Select from 'react-select';
import { pickSelectStyle } from 'configs/components/select';
import {
    Modal,
    ModalBody,
    ModalHeader,
    FormGroup,
    Label,
} from 'reactstrap';

import GeneralFields from './generalFields';
import TextNumberSpecificFields from './textNumberSpecificFields';
import SelectsSpecificFields from './selectsSpecificFields';

import { useTranslation } from 'react-i18next';
import {
    ORDER_IS_NOT_NUMBER,
    TITLE_REQUIRED,
    TYPE_REQUIRED,
    OPTION_REQUIRED,
    READ_REQUIRED,
} from 'configs/constants/projects';

const types = [
    {
        label: 'Text',
        value: 'text',
    },
    {
        label: 'Textarea',
        value: 'textarea',
    },
    {
        label: 'Number',
        value: 'number',
    },
    {
        label: 'Select - single',
        value: 'select',
    },
    {
        label: 'Select - multi',
        value: 'multiselect',
    },
];

export default function CustomAttributes(props) {
    const { open, groups, closeModal, addCustomAttribute } =
        props;

    const { t } = useTranslation();

    const [order, setOrder] = React.useState(0);
    const [title, setTitle] = React.useState('');
    const [type, setType] = React.useState(null);
    const [selectValues, setSelectValues] =
        React.useState(null);
    const [add, setAdd] = React.useState([]);
    const [read, setRead] = React.useState([]);
    const [write, setWrite] = React.useState([]);
    const [defaultValue, setDefaultValue] =
        React.useState(null);
    const [required, setRequired] = React.useState(false);
    const [errors, setErrors] = React.useState([]);

    const getErrors = () => {
        let result = [];
        if (isNaN(parseInt(order))) {
            result.push(ORDER_IS_NOT_NUMBER);
        }
        if (title.length === 0) {
            result.push(TITLE_REQUIRED);
        }
        if (type === null) {
            result.push(TYPE_REQUIRED);
        }
        if (
            required &&
            ['select', 'multiselect'].includes(
                type.value
            ) &&
            selectValues.length === 0
        ) {
            result.push(OPTION_REQUIRED);
        }
        if (read.length === 0) {
            result.push(READ_REQUIRED);
        }
        return result;
    };

    const translatedTypes = useMemo(() => {
        return types.map((type) => ({
            ...type,
            label:
                type.value === 'select'
                    ? t(type.value + 'Noun')
                    : t(type.value),
        }));
    }, [types]);

    return (
        <Modal isOpen={open}>
            <ModalHeader>
                {t('addNewCustomAttribute')}
            </ModalHeader>
            <ModalBody>
                <FormGroup>
                    <Label>{t('type')}</Label>
                    <Select
                        styles={pickSelectStyle()}
                        options={translatedTypes}
                        value={type}
                        onChange={(type) => {
                            switch (type.value) {
                                case 'text': {
                                    setDefaultValue({
                                        text: '',
                                    });
                                    break;
                                }
                                case 'textarea': {
                                    setSelectValues(null);
                                    setDefaultValue({
                                        text: '',
                                    });
                                    break;
                                }
                                case 'number': {
                                    setSelectValues(null);
                                    setDefaultValue({
                                        number: 0,
                                    });
                                    break;
                                }
                                case 'select': {
                                    setSelectValues(
                                        selectValues
                                            ? [
                                                  ...selectValues.map(
                                                      (
                                                          option
                                                      ) => ({
                                                          ...option,
                                                          def: false,
                                                      })
                                                  ),
                                              ]
                                            : []
                                    );
                                    setDefaultValue(null);
                                    break;
                                }
                                case 'multiselect': {
                                    setSelectValues(
                                        selectValues
                                            ? [
                                                  ...selectValues,
                                              ]
                                            : []
                                    );
                                    setDefaultValue(null);
                                    break;
                                }
                                default: {
                                    break;
                                }
                            }
                            setType(type);
                        }}
                    />
                </FormGroup>

                {type !== null && (
                    <GeneralFields
                        type={type}
                        groups={groups}
                        order={order}
                        setOrder={setOrder}
                        title={title}
                        setTitle={setTitle}
                        required={required}
                        setRequired={setRequired}
                        selectValues={selectValues}
                        read={read}
                        setRead={setRead}
                        add={add}
                        setAdd={setAdd}
                        write={write}
                        setWrite={setWrite}
                    />
                )}

                {defaultValue && type !== null && (
                    <TextNumberSpecificFields
                        type={type}
                        defaultValue={
                            defaultValue.text
                                ? defaultValue.text
                                : defaultValue.number
                        }
                        setDefaultValue={setDefaultValue}
                    />
                )}
                {selectValues && (
                    <SelectsSpecificFields
                        type={type}
                        selectValues={selectValues}
                        setSelectValues={setSelectValues}
                        setDefaultValue={setDefaultValue}
                    />
                )}

                {errors.length > 0 && (
                    <div>
                        {errors.map((error) => (
                            <span className="error-message">
                                {t(error)}
                            </span>
                        ))}
                    </div>
                )}

                <div className="row">
                    <button
                        className="btn-link-cancel"
                        onClick={() => closeModal()}
                    >
                        {' '}
                        {t('cancel')}{' '}
                    </button>

                    <button
                        className="btn ml-auto"
                        onClick={() => {
                            let newErrors = getErrors();
                            if (newErrors.length > 0) {
                                setErrors(newErrors);
                            } else {
                                let newSelectValues = null;
                                if (selectValues) {
                                    newSelectValues = [
                                        ...selectValues,
                                    ];
                                    let valueSet = new Set(
                                        selectValues.map(
                                            (option) =>
                                                option.value
                                        )
                                    );
                                    if (
                                        valueSet.length !==
                                        selectValues.length
                                    ) {
                                        let occurences = {};
                                        for (
                                            var i = 0;
                                            i <
                                            selectValues.length;
                                            i++
                                        ) {
                                            if (
                                                occurences[
                                                    selectValues[
                                                        i
                                                    ].value
                                                ]
                                            ) {
                                                newSelectValues[
                                                    i
                                                ].value +=
                                                    ' ' +
                                                    (occurences[
                                                        selectValues[
                                                            i
                                                        ]
                                                            .value
                                                    ] +
                                                        1);
                                                occurences[
                                                    selectValues[
                                                        i
                                                    ].value
                                                ] =
                                                    occurences[
                                                        selectValues[
                                                            i
                                                        ]
                                                            .value
                                                    ] + 1;
                                            } else {
                                                occurences[
                                                    selectValues[
                                                        i
                                                    ].value
                                                ] = 1;
                                            }
                                        }
                                    }
                                    newSelectValues =
                                        newSelectValues.map(
                                            (option) => {
                                                return {
                                                    ...option,
                                                    value: option.value.toLowerCase(),
                                                };
                                            }
                                        );
                                }
                                addCustomAttribute({
                                    order,
                                    title,
                                    type,
                                    selectValues:
                                        newSelectValues,
                                    addGroups: add,
                                    viewGroups: read,
                                    editGroups: write,
                                    defaultValue,
                                    required,
                                });
                                setOrder(0);
                                setTitle('');
                                setType(null);
                                setSelectValues(null);
                                setAdd([]);
                                setRead([]);
                                setWrite([]);
                                setDefaultValue(null);
                                setRequired(false);
                                closeModal();
                            }
                        }}
                    >
                        {t('addCustomAttribute')}
                    </button>
                </div>
            </ModalBody>
        </Modal>
    );
}
