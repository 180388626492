import React from 'react';

import { useParams } from 'react-router-dom';

import {
    useMutation,
    useQuery,
    useApolloClient,
} from '@apollo/client';

import Form from './form';
import Loading from 'components/loading';

import { toSelArr } from 'helperFunctions';
import { addLocalError } from 'apollo/localSchema/actions';

import {
    GET_IMAPS,
    ADD_IMAP,
    GET_ROLES,
    GET_BASIC_COMPANIES,
    GET_PROJECTS,
} from 'helpdesk/queries';

import { useTranslation } from 'react-i18next';

export default function IMAPAdd(props) {
    const { closeModal } = props;

    const params = useParams();

    const { t } = useTranslation();
    const client = useApolloClient();

    const { data: roleData, loading: rolesLoading } =
        useQuery(GET_ROLES);

    const { data: companyData, loading: companiesLoading } =
        useQuery(GET_BASIC_COMPANIES);

    const { data: projectData, loading: projectsLoading } =
        useQuery(GET_PROJECTS);

    const [addImap] = useMutation(ADD_IMAP);

    //state
    const [saving, setSaving] = React.useState(false);
    const [newData, setNewData] = React.useState(false);

    const dataLoading =
        rolesLoading || companiesLoading || projectsLoading;

    //functions
    const addIMAPFunc = (data) => {
        setSaving(true);
        addImap({
            variables: {
                ...data,
            },
        })
            .then((response) => {
                const allIMAPs = client.readQuery({
                    query: GET_IMAPS,
                }).imaps;
                const newIMAP = {
                    ...response.data.addImap,
                    __typename: 'Imap',
                };
                client.writeQuery({
                    query: GET_IMAPS,
                    data: {
                        imaps: [
                            ...allIMAPs.filter(
                                (IMAP) =>
                                    IMAP.id !==
                                    parseInt(params.id)
                            ),
                            newIMAP,
                        ],
                    },
                });
                closeModal();
            })
            .catch((err) => {
                addLocalError(err);
            });
        setSaving(false);
        setNewData(false);
    };

    if (dataLoading) {
        return <Loading />;
    }

    return (
        <Form
            label={`${t('add')} ${t('imap')}`}
            onSave={addIMAPFunc}
            onClose={closeModal}
            saving={saving}
            newData={newData}
            setNewData={setNewData}
            roles={toSelArr(roleData.roles)}
            companies={toSelArr(companyData.basicCompanies)}
            projects={toSelArr(projectData.projects)}
        />
    );
}
