import React from 'react';
import { Button, FormGroup, Label, Input } from 'reactstrap';
import DeleteSubfolder from './deleteModal';

import { useTranslation } from 'react-i18next';

export default function SubfolderEdit(props) {
    const { id, close, subfolder, updateSubfolder, deleteSubfolder } = props;

    const { t } = useTranslation();

    const [title, setTitle] = React.useState(subfolder.title);
    const [saving, setSaving] = React.useState(false);

    const updateSubfolderFunc = () => {
        setSaving(true);
        updateSubfolder({
            variables: {
                id,
                title,
            },
        })
            .then(() => {
                setSaving(false);
                close();
            })
            .catch((e) => {
                console.log(e);
                setSaving(false);
            });
    };

    return (
        <div className="p-20">
            <FormGroup>
                <Label htmlFor="name">{t('title')}</Label>
                <Input
                    id="name"
                    className="form-control"
                    placeholder={t('titlePlaceholder')}
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                />
            </FormGroup>

            <div className="row buttons">
                <Button className="btn-link-cancel" disabled={saving} onClick={close}>
                    {t('close')}
                </Button>
                <DeleteSubfolder saving={saving} deleteSubfolder={deleteSubfolder} id={id} />
                <Button
                    className="btn"
                    disabled={title.length === 0 || saving}
                    onClick={updateSubfolderFunc}
                >
                    {!saving
                        ? `${t('save')} ${t('subfolder').toLowerCase()}`
                        : `${t('saving')} ${t('subfolder').toLowerCase()}`}
                </Button>
            </div>
        </div>
    );
}
