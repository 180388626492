export default {
  allCategories: {
    en: 'All categories',
    sk: 'Všetky kategórie',
    cz: 'Všechny kategorie',
    de: 'Alle Kategorien',
  },
  category: {
    en: 'Category',
    sk: 'Kategória',
    cz: 'Kategorie',
    de: 'Kategorie',
  },
  category2: {
    en: 'Category',
    sk: 'Kategóriu',
    cz: 'Kategorii',
    de: 'Kategorie',
  },
  categories: {
    en: 'Categories',
    sk: 'Kategórie',
    cz: 'Kategorie',
    de: 'Kategorien',
  },
  descriptionLabel: {
    en: 'Description label',
    sk: 'Poznámka k popisu',
    cz: 'Poznámka k popisu',
    de: 'Beschreibungsetikett',
  },
  descriptionLabelPlaceholder: {
    en: 'Enter description label',
    sk: 'Zadajte poznámku k popisu',
    cz: 'Zadejte poznámku k popisu',
    de: 'Beschreibungslabel eingeben',
  },
  backup: {
    en: 'Backup',
    sk: 'Zálohovanie',
    cz: 'Zálohování',
    de: 'Sicherung',
  },
  backupLabel: {
    en: 'Backup label',
    sk: 'Poznámka k zálohovaniu',
    cz: 'Poznámka k zálohování',
    de: 'Etikett für Sicherung',
  },
  backupLabelPlaceholder: {
    en: 'Enter backup label',
    sk: 'Zadajte poznámku k zálohovaniu',
    cz: 'Zadejte poznámku k zálohování',
    de: 'Sicherungsbezeichnung eingeben',
  },
  monitoring: {
    en: 'Monitoring',
    sk: 'Monitoring',
    cz: 'Sledování',
    de: 'Überwachung',
  },
  monitoringLabel: {
    en: 'Monitoring label',
    sk: 'Poznámka k monitoringu',
    cz: 'Poznámka k sledování',
    de: 'Überwachungsetikett',
  },
  monitoringLabelPlaceholder: {
    en: 'Enter monitoring label',
    sk: 'Zadajte poznámku k monitoringu',
    cz: 'Zadejte poznámku k sledování',
    de: 'Überwachungsbezeichnung eingeben',
  },

}
