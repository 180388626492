import React from 'react';
import { useMutation } from '@apollo/client';

import CategoryAdd from './add';

import { ADD_CATEGORY } from 'cmdb/queries';

export default function AddCategoryLoader(props) {
    const [addCategory] = useMutation(ADD_CATEGORY);

    return (
        <CategoryAdd {...props} addCategory={addCategory} />
    );
}
