import React, { useState } from 'react';
import { FormGroup, Label } from 'reactstrap';
import Empty from 'components/Empty';
import Checkbox from 'components/checkbox';

import { useTranslation } from 'react-i18next';

let fakeID = -1;

export default function SelectsSpecificFields(props) {
    const { type, selectValues, setSelectValues } = props;

    const { t } = useTranslation();

    const [openAdd, setOpenAdd] = useState(false);
    const [newOption, setNewOption] = useState({
        def: false,
        value: '',
        order: -1,
    });

    return (
        <Empty>
            <FormGroup>
                <Label>{t('options')}</Label>
                <table className="table">
                    <thead>
                        <tr>
                            <th width="50px">{t('default')}</th>
                            <th className="p-l-0">{t('title')}</th>
                            <th width="100px" className="text-end">
                                {t('actions')}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {selectValues.map((option, index) => (
                            <tr key={option.order}>
                                <td>
                                    <Checkbox
                                        className="m-t-5 segoe-blue-text"
                                        value={option.def}
                                        onChange={() => {
                                            let newSelectValues = [...selectValues];
                                            let newValue = !option.def;
                                            newSelectValues = selectValues.map((op) => {
                                                if (
                                                    (type === 'select' ||
                                                        type.value === 'select') &&
                                                    newValue
                                                ) {
                                                    return {
                                                        ...op,
                                                        def: op.id === option.id ? newValue : false,
                                                    };
                                                }
                                                return {
                                                    ...op,
                                                    def: op.id === option.id ? newValue : op.def,
                                                };
                                            });
                                            setSelectValues(newSelectValues);
                                        }}
                                    />
                                </td>
                                <td className="p-l-0">
                                    <input
                                        className="form-control hidden-input segoe-blue-text"
                                        value={option.value}
                                        onChange={(e) => {
                                            let newSelectValues = selectValues.map((op) => ({
                                                ...op,
                                                value:
                                                    op.id === option.id ? e.target.value : op.value,
                                            }));
                                            setSelectValues(newSelectValues);
                                        }}
                                    />
                                </td>
                                <td className="text-end">
                                    <button
                                        className="btn-link btn-distance"
                                        disabled={index === 0}
                                        onClick={() => {
                                            let newSelectValues = [...selectValues];
                                            newSelectValues[index - 1] = {
                                                ...selectValues[index],
                                                order: index - 1,
                                            };
                                            newSelectValues[index] = {
                                                ...selectValues[index - 1],
                                                order: index,
                                            };
                                            setSelectValues(newSelectValues);
                                        }}
                                    >
                                        <span className="material-symbols-outlined">
                                            arrow_upward
                                        </span>
                                    </button>
                                    <button
                                        className="btn-link btn-distance"
                                        disabled={index === selectValues.length - 1}
                                        onClick={() => {
                                            let newSelectValues = [...selectValues];
                                            newSelectValues[index + 1] = {
                                                ...selectValues[index],
                                                order: index + 1,
                                            };
                                            newSelectValues[index] = {
                                                ...selectValues[index + 1],
                                                order: index,
                                            };
                                            setSelectValues(newSelectValues);
                                        }}
                                    >
                                        <span className="material-symbols-outlined">
                                            arrow_downward
                                        </span>
                                    </button>
                                    <button
                                        className="btn-link"
                                        onClick={() => {
                                            let newSelectValues = [
                                                ...selectValues.filter((op) => op.id !== option.id),
                                            ].map((op, i) => ({
                                                ...op,
                                                order: i,
                                            }));
                                            setSelectValues(newSelectValues);
                                        }}
                                    >
                                        <span className="material-symbols-outlined p-r-0">
                                            close
                                        </span>
                                    </button>
                                </td>
                            </tr>
                        ))}
                        {openAdd && (
                            <tr key={'addOption'}>
                                <td>
                                    <Checkbox
                                        className="m-t-5 segoe-blue-text"
                                        value={newOption.def}
                                        onChange={() => {
                                            setNewOption({
                                                ...newOption,
                                                def: !newOption.def,
                                            });
                                        }}
                                    />
                                </td>
                                <td className="p-l-0">
                                    <input
                                        className="form-control segoe-blue-text"
                                        value={newOption.value}
                                        onChange={(e) => {
                                            setNewOption({
                                                ...newOption,
                                                value: e.target.value,
                                            });
                                        }}
                                    />
                                </td>
                                <td className="text-end">
                                    <button
                                        className="btn-link btn-distance"
                                        disabled={
                                            newOption.value.length === 0 ||
                                            selectValues.some((op) => op.value === newOption.value)
                                        }
                                        onClick={() => {
                                            const newSelectValue = {
                                                ...newOption,
                                                order: selectValues.length,
                                                id: fakeID--,
                                            };
                                            let newSelectValues = [...selectValues];
                                            if (
                                                (type === 'select' || type.value === 'select') &&
                                                newSelectValue.def
                                            ) {
                                                newSelectValues = newSelectValues.map((option) => ({
                                                    ...option,
                                                    def: false,
                                                }));
                                            }
                                            newSelectValues = [
                                                ...newSelectValues,
                                                {
                                                    ...newSelectValue,
                                                },
                                            ];
                                            setNewOption({
                                                def: false,
                                                value: '',
                                                order: -1,
                                            });
                                            setOpenAdd(false);
                                            setSelectValues(newSelectValues);
                                        }}
                                    >
                                        <span className="material-symbols-outlined bigger">
                                            add
                                        </span>
                                    </button>

                                    <button
                                        className="btn-link"
                                        onClick={() => {
                                            setOpenAdd(false);
                                        }}
                                    >
                                        <span className="material-symbols-outlined p-r-0">
                                            close
                                        </span>
                                    </button>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
                {!openAdd && (
                    <button className="btn-link" onClick={() => setOpenAdd(true)}>
                        <span className="material-symbols-outlined bigger">add</span>
                        {t('option')}
                    </button>
                )}
            </FormGroup>
        </Empty>
    );
}
