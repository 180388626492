export const getLocation = (location) => {
    let url = location.pathname;
    if (!url) {
        return 'no-url-check-get-location-call';
    }
    if (url.includes('cmdb')) {
        return '/cmdb';
    } else if (url.includes('helpdesk')) {
        return '/helpdesk';
    } else if (url.includes('passmanager')) {
        return '/passmanager';
    } else if (url.includes('lanpass')) {
        return '/lanpass';
    } else if (url.includes('expenditures')) {
        return '/expenditures';
    } else if (url.includes('projects')) {
        return '/projects';
    } else if (url.includes('reports')) {
        return '/reports';
    } else if (url.includes('monitoring')) {
        return '/monitoring';
    } else if (url.includes('invoices')) {
        return '/invoices';
    } else {
        return '/lanwiki';
    }
};
