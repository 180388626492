import React from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import {
    useQuery,
    useMutation,
    useSubscription,
} from '@apollo/client';
import LanpassPasswordForm from './form';
import Loading from 'components/loading';
import { useTranslation } from 'react-i18next';
import { toSelArr } from 'helperFunctions';

import {
    GET_FOLDERS,
    FOLDERS_SUBSCRIPTION,
    GET_PASSWORD,
    UPDATE_PASSWORD,
    DELETE_PASSWORD,
} from 'lanpass/queries';

import { GET_PROJECT } from 'apollo/queries';

export default function PasswordEditContainer() {
    const navigate = useNavigate();
    const params = useParams();

    const { t } = useTranslation();

    const { data: projectData } = useQuery(GET_PROJECT);
    const localProject = projectData.localProject;

    const {
        data: foldersData,
        loading: foldersLoading,
        refetch: foldersRefetch,
    } = useQuery(GET_FOLDERS, {
        variables: {
            projectId: localProject.project.id,
        },
        fetchPolicy: 'network-only',
    });

    useSubscription(FOLDERS_SUBSCRIPTION, {
        onData: () => {
            foldersRefetch({
                projectId: localProject.project.id,
            });
        },
    });

    const { data: passwordData, loading: passwordLoading } =
        useQuery(GET_PASSWORD, {
            variables: {
                id: params.passwordID
                    ? parseInt(params.passwordID)
                    : -1,
            },
            fetchPolicy: 'network-only',
        });

    //mutations
    const [updatePassword] = useMutation(UPDATE_PASSWORD);
    const [deletePassword] = useMutation(DELETE_PASSWORD);
    const [showEdit, setShowEdit] = React.useState(false);
    const [saving, setSaving] = React.useState(false);

    const loading = foldersLoading || passwordLoading;

    const deletePasswordFunc = () => {
        if (
            window.confirm(
                t('comfirmDeletingLanpassPassword')
            )
        ) {
            setSaving(true);
            deletePassword({
                variables: {
                    id: password.id,
                },
            })
                .then(() => {
                    setSaving(false);
                    navigate(-1);
                })
                .catch((e) => {
                    setSaving(false);
                    console.log(e);
                });
        }
    };

    if (loading) {
        return <Loading />;
    }

    if (!passwordData || !passwordData.passEntry) {
        return (
            <div
                className="fit-with-header scroll-visible p-t-20 p-l-20 p-r-20 p-b-0"
                style={{ backgroundColor: '#f2f1f1' }}
            ></div>
        );
    }

    const folders = foldersData.passFolders;
    const password = passwordData.passEntry;
    const myRights = password.myRights;

    return (
        <div className="scrollable">
            {!showEdit && (
                <div
                    className="task-add-layout row"
                    style={{ backgroundColor: '#deeaf3' }}
                >
                    {myRights.write && (
                        <button
                            type="button"
                            disabled={saving}
                            className="btn-link task-add-layout-button btn-distance"
                            onClick={() => {
                                setShowEdit(true);
                            }}
                        >
                            <span className="material-symbols-outlined">
                                edit
                            </span>
                            {t('edit')}
                        </button>
                    )}
                </div>
            )}
            <LanpassPasswordForm
                id={password.id}
                edit={true}
                disabled={!myRights.write || !showEdit}
                close={() => setShowEdit(false)}
                savePassword={(
                    data,
                    setSaving,
                    afterUpdate
                ) => {
                    setSaving(true);
                    updatePassword({ variables: data })
                        .then(() => {
                            setSaving(false);
                            afterUpdate();
                        })
                        .catch((e) => {
                            console.log(e);
                            setSaving(false);
                        });
                }}
                canDeletePassword={myRights.write}
                deletePassword={deletePasswordFunc}
                allFolders={toSelArr(folders)}
                passwordData={password}
            />
        </div>
    );
}
