export default {
  page2: {
    en: 'Pages',
    sk: 'Stránok',
    cz: 'Stránky',
    de: 'Seiten',
  },
  noLanwikiPages: {
    en: 'No LanWiki pages',
    sk: 'Bez LanWiki stránok',
    cz: 'Žádné stránky LanWiki',
    de: 'Keine LanWiki-Seiten',
  },
  lanwikiPage: {
    en: 'LanWiki page',
    sk: 'LanWiki stránku',
    cz: 'stránka LanWiki',
    de: 'LanWiki-Seite',
  },
  content: {
    en: 'Content',
    sk: 'Obsah',
    cz: 'Obsah',
    de: 'Inhalt',
  },
  selectTags: {
    en: 'Select tags',
    sk: 'Vyberte tagy',
    cz: 'Vyberte značky',
    de: 'Tags auswählen',
  },
  lanwikiPageMissingContent: {
    en: 'Page is without any content',
    sk: 'Stránka nemá žiadny obsah',
    cz: 'Stránka je bez obsahu',
    de: 'Seite ist ohne Inhalt',
  },
  comfirmDeletingLanwikiPage: {
    en: 'Are you sure you want to delete this LanWiki page?',
    sk: 'Ste si istý, že chcete zmazať túto LanWiki stránku?',
    cz: 'Opravdu chcete smazat tuto stránku LanWiki?',
    de: 'Möchten Sie diese LanWiki-Seite wirklich löschen?',
  },
  deleteAllLanwikiPages: {
    en: 'None (Delete all LanWiki pages in the folder)',
    sk: 'Žiadny (Vymaže všetky LanWiki stránky v priečinku)',
    cz: 'Žádné (smazat všechny stránky LanWiki ve složce)',
    de: 'Keine (alle LanWiki-Seiten im Ordner löschen)',
  },
  page: {
    en: 'Page',
    sk: 'Stránka',
    cz: 'Strana',
    de: 'Buchseite',
  },
}
