import React from 'react';

import { useParams } from 'react-router-dom';
import {
    useMutation,
    useQuery,
    useSubscription,
} from '@apollo/client';
import Conversation from './conversation';
import Loading from 'components/loading';
import {
    GET_CONVERSATION,
    ADD_WEBCHAT_MESSAGE,
    SET_CONVERSATION_STATUS,
    MESSAGES_SUBSCRIPTION,
    SET_CONVERSATION_READ,
} from 'helpdesk/queries';

export default function ConversationLoader() {
    const params = useParams();
    const id = parseInt(params.id);

    const [setRead] = useMutation(SET_CONVERSATION_READ);
    const [addWebchatMessage] = useMutation(
        ADD_WEBCHAT_MESSAGE
    );
    const [setStatus] = useMutation(
        SET_CONVERSATION_STATUS
    );
    const {
        data: conversationData,
        loading: conversationLoading,
        refetch: conversationRefetch,
    } = useQuery(GET_CONVERSATION, {
        variables: {
            id,
        },
        fetchPolicy: 'network-only',
    });

    useSubscription(MESSAGES_SUBSCRIPTION, {
        variables: {
            id,
        },
        onData: () => {
            conversationRefetch({
                id,
            });
            setRead({
                variables: {
                    id,
                },
            });
        },
    });

    React.useEffect(() => {
        setRead({
            variables: {
                id,
            },
        });
        conversationRefetch({
            id,
        });
    }, [id]);

    if (conversationLoading || !conversationData) {
        return <Loading />;
    }

    const { messages, ...conversation } =
        conversationData.conversation;

    return (
        <Conversation
            id={id}
            addMessage={addWebchatMessage}
            setStatus={setStatus}
            messages={messages}
            conversation={conversation}
        />
    );
}
