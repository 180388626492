import React from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import { useQuery, useMutation, useApolloClient, useSubscription } from '@apollo/client';

import Loading from 'components/loading';
import TaskEdit from './taskEdit';
import AccessDenied from 'components/accessDenied';
import ErrorMessage from 'components/errorMessage';
import axios from 'axios';

import { addLocalError } from 'apollo/localSchema/actions';
import { useTranslation } from 'react-i18next';

import { toSelArr, localFilterToValues, getMyData } from 'helperFunctions';

import {
    GET_TRIP_TYPES,
    TRIP_TYPES_SUBSCRIPTION,
    GET_TASK_TYPES,
    TASK_TYPES_SUBSCRIPTION,
    GET_MY_PROJECTS,
    PROJECTS_SUBSCRIPTION,
    GET_BASIC_COMPANIES,
    COMPANIES_SUBSCRIPTION,
    GET_BASIC_USERS,
    USERS_SUBSCRIPTION,
    GET_TASK,
    GET_TASKS,
    DELETE_TASK,
    TASK_DELETE_SUBSCRIPTION,
    UPDATE_TASK,
    DELETE_TASK_ATTACHMENT,
    ADD_TASK_TO_AUTOFILL,
    ADD_SUBTASK,
} from 'helpdesk/queries';

import { GET_FILTER, GET_PROJECT } from 'apollo/queries';

import { REST_URL } from 'configs/restAPI';

export default function TaskEditContainer(props) {
    //data & queries
    const { taskID, closeModal, fromInvoice, columns } = props;

    const navigate = useNavigate();
    const params = useParams();

    const { t } = useTranslation();

    const inModal = props.inModal === true;
    const id = inModal ? taskID : parseInt(params.taskID);
    const client = useApolloClient();

    const {
        data: basicCompaniesData,
        loading: basicCompaniesLoading,
        refetch: basicCompaniesRefetch,
    } = useQuery(GET_BASIC_COMPANIES, {
        fetchPolicy: 'network-only',
    });
    const {
        data: basicUsersData,
        loading: basicUsersLoading,
        refetch: basicUsersRefetch,
    } = useQuery(GET_BASIC_USERS, {
        fetchPolicy: 'network-only',
    });

    const { data: taskTypesData, refetch: taskTypesRefetch } = useQuery(GET_TASK_TYPES, {
        fetchPolicy: 'network-only',
    });

    const {
        data: tripTypesData,
        loading: tripTypesLoading,
        refetch: tripTypesRefetch,
    } = useQuery(GET_TRIP_TYPES, {
        fetchPolicy: 'network-only',
    });
    const {
        data: myProjectsData,
        loading: myProjectsLoading,
        refetch: myProjectsRefetch,
    } = useQuery(GET_MY_PROJECTS, {
        variables: {
            fromInvoice,
        },
        fetchPolicy: 'network-only',
    });
    const {
        data: taskData,
        loading: taskLoading,
        refetch: taskRefetch,
        error: taskError,
    } = useQuery(GET_TASK, {
        variables: {
            id,
            fromInvoice,
        },
    });

    //local
    const { data: filterData } = useQuery(GET_FILTER);

    const { data: projectData } = useQuery(GET_PROJECT);

    const [updateTask] = useMutation(UPDATE_TASK);

    const [deleteTask] = useMutation(DELETE_TASK);
    const [deleteTaskAttachment] = useMutation(DELETE_TASK_ATTACHMENT);
    const [addSubtask] = useMutation(ADD_SUBTASK);
    const [addTaskToAutofill] = useMutation(ADD_TASK_TO_AUTOFILL);

    useSubscription(TASK_TYPES_SUBSCRIPTION, {
        onData: () => {
            taskTypesRefetch();
        },
    });

    useSubscription(TRIP_TYPES_SUBSCRIPTION, {
        onData: () => {
            tripTypesRefetch();
        },
    });

    useSubscription(TASK_DELETE_SUBSCRIPTION, {
        variables: {
            taskId: id,
        },
        onData: () => {
            if (inModal) {
                //update calendar
                closeModal(true);
            } else {
                navigate(
                    window.location.pathname.substring(
                        0,
                        window.location.pathname.length - params.taskID.length
                    )
                );
            }
        },
    });

    useSubscription(PROJECTS_SUBSCRIPTION, {
        onData: () => {
            myProjectsRefetch();
        },
    });

    useSubscription(COMPANIES_SUBSCRIPTION, {
        onData: () => {
            basicCompaniesRefetch();
        },
    });

    useSubscription(USERS_SUBSCRIPTION, {
        onData: () => {
            basicUsersRefetch();
        },
    });

    React.useEffect(() => {
        taskRefetch({
            id,
            fromInvoice,
        });
    }, [id]);

    const [saving, setSaving] = React.useState(false);

    //functions

    const updateCasheStorage = (response, key, type) => {
        const task = client.readQuery({
            query: GET_TASK,
            variables: {
                id,
                fromInvoice,
            },
        }).task;
        let newTask = {
            ...task,
        };
        newTask[key] = [...newTask[key]];
        switch (type) {
            case 'ADD': {
                newTask[key].push(response);
                break;
            }
            case 'UPDATE': {
                newTask[key][newTask[key].findIndex((item) => item.id === response.id)] = response;
                break;
            }
            case 'DELETE': {
                newTask[key] = newTask[key].filter((item) => item.id !== response.id);
                break;
            }
            default: {
                return;
            }
        }
        client.writeQuery({
            query: GET_TASK,
            variables: {
                id,
                fromInvoice,
            },
            data: {
                task: newTask,
            },
        });
    };

    const deleteTaskFunc = () => {
        if (window.confirm(t('generalConfirmation'))) {
            deleteTask({
                variables: {
                    id,
                    fromInvoice,
                },
            })
                .then(() => {
                    try {
                        let tasks = client.readQuery({
                            query: GET_TASKS,
                            variables: {
                                filterId: filterData.localFilter.id,
                                filter: localFilterToValues(filterData.localFilter),
                                projectId: projectData.localProject.id,
                                sort: null,
                            },
                        }).tasks;
                        client.writeQuery({
                            query: GET_TASKS,
                            variables: {
                                filterId: filterData.localFilter.id,
                                filter: localFilterToValues(filterData.localFilter),
                                projectId: projectData.localProject.id,
                                sort: null,
                            },
                            data: {
                                ...tasks,
                                tasks: tasks.tasks.filter((task) => task.id !== id),
                            },
                        });
                    } catch (err) {
                        console.log(err);
                    }
                })
                .catch((err) => {
                    addLocalError(err);
                });
        }
    };

    const addCompanyToList = () => {
        basicCompaniesRefetch();
    };

    const addAttachments = (attachments) => {
        const formData = new FormData();
        attachments.forEach((file) => formData.append(`file`, file));
        formData.append('token', `${sessionStorage.getItem('acctok')}`);
        formData.append('taskId', id);
        formData.append('fromInvoice', fromInvoice);
        axios
            .post(`${REST_URL}/api/upload-attachments`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
            .then((response) => {
                const newAttachments = response.data.attachments.map((attachment) => ({
                    ...attachment,
                    __typename: 'TaskAttachment',
                }));
                const oldTask = client.readQuery({
                    query: GET_TASK,
                    variables: {
                        id,
                    },
                }).task;
                client.writeQuery({
                    query: GET_TASK,
                    variables: {
                        id,
                    },
                    data: {
                        task: {
                            ...oldTask,
                            taskAttachments: [...oldTask.taskAttachments, ...newAttachments],
                        },
                    },
                });
            });
    };

    const removeAttachment = (attachment) => {
        if (window.confirm(t('generalConfirmation'))) {
            deleteTaskAttachment({
                variables: {
                    id: attachment.id,
                    fromInvoice,
                },
            }).then(() => {
                const oldTask = client.readQuery({
                    query: GET_TASK,
                    variables: {
                        id,
                        fromInvoice,
                    },
                }).task;
                client.writeQuery({
                    query: GET_TASK,
                    variables: {
                        id,
                        fromInvoice,
                    },
                    data: {
                        task: {
                            ...oldTask,
                            taskAttachments: oldTask.taskAttachments.filter(
                                (taskAttachment) => taskAttachment.id !== attachment.id
                            ),
                        },
                    },
                });
            });
        }
    };

    const applyAISolution = (aiSolution) => {
        setSaving(true);

        const SOLUTION_ADDED = 0;
        const CANNOT_CREATE_UNASSIGNED_SUBTASK = 1;

        let assignedTo =
            taskData.task.assignedTo.length > 0 ? taskData.task.assignedTo[0].id : null;
        if (!assignedTo) {
            assignedTo =
                taskData.task.subtasks.length > 0 ? taskData.task.subtasks[0].assignedTo.id : null;
        }
        if (!assignedTo) {
            return CANNOT_CREATE_UNASSIGNED_SUBTASK;
        }

        for (var i = 0; i < aiSolution.length; i++) {
            addSubtask({
                variables: {
                    title: aiSolution[i].title,
                    order: taskData.task.subtasks.length + i,
                    done: false,
                    discount: 0,
                    quantity: aiSolution[i].quantity,
                    task: id,
                    assignedTo,
                },
            })
                .then((response) => {
                    updateCasheStorage(response.data.addSubtask, 'subtasks', 'ADD');
                })
                .catch((err) => {
                    addLocalError(err);
                });
        }

        addTaskToAutofill({
            variables: {
                taskId: id,
            },
        });

        setSaving(false);
        return SOLUTION_ADDED;
    };

    const currentUser = getMyData();
    const dataLoading =
        !currentUser ||
        basicCompaniesLoading ||
        basicUsersLoading ||
        /*  taskTypesLoading ||*/
        tripTypesLoading ||
        myProjectsLoading ||
        taskLoading;

    if (taskError) {
        return (
            <AccessDenied>
                <ErrorMessage show={true} message={taskError.message} />
            </AccessDenied>
        );
    }

    if (dataLoading) {
        return (
            <div
                style={
                    columns
                        ? {
                              width: '70%',
                          }
                        : {
                              width: '100%',
                          }
                }
            >
                <Loading />
            </div>
        );
    }

    return (
        <TaskEdit
            {...props}
            id={id}
            fromInvoice={fromInvoice}
            task={taskData.task}
            inModal={inModal}
            closeModal={closeModal}
            currentUser={currentUser}
            accessRights={currentUser.role.accessRights}
            companies={toSelArr(basicCompaniesData.basicCompanies)}
            users={toSelArr(basicUsersData.basicUsers, 'fullName')}
            tripTypes={toSelArr(tripTypesData.tripTypes)}
            taskTypes={toSelArr(taskTypesData.taskTypes)}
            projects={toSelArr(
                myProjectsData.myProjects.map((project) => ({
                    ...project,
                    id: project.project.id,
                    title: project.project.title,
                }))
            )}
            emails={
                /*emailsData && emailsData.emails ? emailsData.emails : */
                []
            }
            filterValues={localFilterToValues(filterData.localFilter)}
            originalProjectId={projectData.localProject.id}
            filterId={filterData.localFilter.id}
            addCompanyToList={addCompanyToList}
            addAttachments={addAttachments}
            removeAttachment={removeAttachment}
            deleteTaskFunc={deleteTaskFunc}
            updateCasheStorage={updateCasheStorage}
            updateTask={updateTask}
            client={client}
            saving={saving}
            setSaving={setSaving}
            applyAISolution={applyAISolution}
        />
    );
}
