import React from 'react';
import { useQuery, useSubscription } from '@apollo/client';
import { NavLink } from 'react-router-dom';
import {
    GET_DEF_COMPANY,
    COMPANIES_SUBSCRIPTION,
} from 'helpdesk/queries';
import { useTranslation } from 'react-i18next';

export default function DefaultCompany() {
    //data & queries
    const { t } = useTranslation();
    const {
        data: companyData,
        loading: companyLoading,
        refetch: companyRefetch,
    } = useQuery(GET_DEF_COMPANY, {
        fetchPolicy: 'network-only',
    });

    useSubscription(COMPANIES_SUBSCRIPTION, {
        onData: () => {
            companyRefetch();
        },
    });

    if (!companyLoading && !companyData) {
        return null;
    }

    const company = companyLoading
        ? null
        : companyData.defCompany;

    return (
        <NavLink
            key="defCompany"
            className={({ isActive }) =>
                `link-item ${isActive ? 'active' : ''}`
            }
            to={`/helpdesk/settings${
                companyLoading
                    ? ''
                    : `/company/${company.id}`
            }`}
        >
            Helpdesk {t('company')}
        </NavLink>
    );
}
