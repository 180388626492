import DatePicker from 'react-datepicker';
import createDateConfig from 'configs/components/datepicker';
import React from 'react';

export default function DataFNSDatePicker(props) {
    const { onChange, selected, hideTime, minDate, maxDate, ...rest } = props;

    const config = {
        ...createDateConfig(!hideTime),
        ...rest,
    };

    return (
        <DatePicker
            {...config}
            selected={selected}
            minDate={minDate}
            maxDate={maxDate}
            onChange={(date) => {
                if (onChange) {
                    onChange(date);
                }
            }}
        />
    );
}
