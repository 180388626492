import React from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import classnames from 'classnames';

import Loading from 'components/loading';

import { useTranslation } from 'react-i18next';

export default function Pagination(props) {
    const { count, page, shortForm, limit, loading } =
        props;

    const navigate = useNavigate();
    const params = useParams();

    const { t } = useTranslation();

    const pageFrom = limit * (page - 1) + 1;
    let pageTo = pageFrom + limit - 1;
    if (pageTo > count) {
        pageTo = count;
    }

    if (loading) {
        return (
            <div className="ml-auto" style={{ width: 150 }}>
                <Loading noPos size={2} />
            </div>
        );
    }

    const path = `/cmdb/i/${params.categoryID}/p/`;

    return (
        <div
            className={classnames(
                'row m-b-10 ml-auto m-r-30'
            )}
        >
            <div className="message ml-auto m-t-1">
                {`${pageFrom}-${pageTo} ${
                    !shortForm
                        ? t('fromTotalOf').toLowerCase()
                        : t('of')
                } ${count} ${
                    !shortForm
                        ? t('cmdbItems2').toLowerCase()
                        : ''
                }`}
            </div>
            <button
                disabled={page === 1}
                onClick={() => {
                    navigate(`${path}${page - 1}`);
                }}
                className="btn-link center-hor m-l-5 p-l-5 p-r-5"
            >
                <span className="material-symbols-outlined bigger">
                    chevron_left
                </span>
            </button>
            <button
                disabled={page * limit >= count}
                onClick={() => {
                    navigate(`${path}${page + 1}`);
                }}
                className="btn-link center-hor m-l-5 p-l-5 p-r-5"
            >
                <span className="material-symbols-outlined bigger">
                    chevron_right
                </span>
            </button>
        </div>
    );
}
