import UserList from 'helpdesk/settings/users';
import CompanyList from 'helpdesk/settings/companies';
import PublicFiltersList from 'helpdesk/settings/publicFilters';
//import SLAList from 'helpdesk/settings/pausals-new-design';
import ProjectList from 'helpdesk/settings/projects';
import StatusList from 'helpdesk/settings/templateStatuses';
//import PriceList from 'helpdesk/settings/pricelists-new-design';
import RoleList from 'helpdesk/settings/roles';
import TripTypeList from 'helpdesk/settings/tripTypes';
import TaskTypeList from 'helpdesk/settings/taskTypes';
import ImapList from 'helpdesk/settings/imaps';
import SMTPList from 'helpdesk/settings/smtps';
import Webchat from 'helpdesk/settings/webchat';

export default [
    {
        title: 'users',
        link: 'users',
        minimalRole: 1,
        element: <UserList />,
        value: 'users',
    },
    {
        title: 'companies',
        link: 'companies',
        minimalRole: 1,
        element: <CompanyList />,
        value: 'companies',
    },
    {
        title: 'publicFilters',
        link: 'publicFilters',
        minimalRole: 1,
        element: <PublicFiltersList />,
        value: 'publicFilters',
    },
    /*
    {
      title: 'sla',
      link: 'pausals',
      minimalRole: 3,
      element: <SLAList />,
      value: 'pausals'
    },*/
    {
        title: 'projects',
        link: 'projects',
        minimalRole: 2,
        element: <ProjectList />,
        value: 'projects',
    },
    {
        title: 'statuses',
        link: 'statuses',
        minimalRole: 3,
        element: <StatusList />,
        value: 'statuses',
    },
    /*
    {
      title: 'pricelists',
      link: 'pricelists',
      minimalRole: 2,
      element: <PriceList />,
      value: 'prices'
    },*/
    {
        title: 'roles',
        link: 'roles',
        minimalRole: 2,
        element: <RoleList />,
        value: 'roles',
    },
    {
        title: 'tripTypes',
        link: 'tripTypes',
        minimalRole: 2,
        element: <TripTypeList />,
        value: 'tripTypes',
    },
    {
        title: 'taskTypes',
        link: 'taskTypes',
        minimalRole: 2,
        element: <TaskTypeList />,
        value: 'taskTypes',
    },
    {
        title: 'imaps',
        link: 'imaps',
        minimalRole: 3,
        element: <ImapList />,
        value: 'imaps',
    },
    {
        title: 'smtps',
        link: 'smtps',
        minimalRole: 3,
        element: <SMTPList />,
        value: 'smtps',
    },
    {
        title: 'ai',
        link: 'ai',
        minimalRole: 3,
        value: 'aiSettings',
    },
    {
        title: 'webchat',
        link: 'webchat',
        minimalRole: 1,
        element: <Webchat />,
        value: 'webchat',
    },
];
