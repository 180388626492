import React from 'react';

import { useMutation, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import Loading from 'components/loading';
import SettingsInput from '../components/settingsInput';

import { addLocalError } from 'apollo/localSchema/actions';

import { GET_TRIP_TYPE, UPDATE_TRIP_TYPE } from 'helpdesk/queries';

export default function TripTypeEdit(props) {
    const { id, closeModal } = props;

    const { t } = useTranslation();

    const {
        data: tripTypeData,
        loading: tripTypeLoading,
        refetch: tripTypeRefetch,
    } = useQuery(GET_TRIP_TYPE, {
        variables: {
            id,
        },
        fetchPolicy: 'network-only',
    });

    const [updateTripType] = useMutation(UPDATE_TRIP_TYPE);

    //state
    const [title, setTitle] = React.useState('');
    const [order, setOrder] = React.useState(0);
    const [saving, setSaving] = React.useState(false);
    const [dataChanged, setDataChanged] = React.useState(false);

    // sync
    React.useEffect(() => {
        if (!tripTypeLoading) {
            setData();
        }
    }, [tripTypeLoading]);

    React.useEffect(() => {
        tripTypeRefetch({
            id,
        }).then(setData);
    }, [id]);

    // functions
    const setData = () => {
        if (tripTypeLoading) {
            return;
        }
        setTitle(tripTypeData.tripType.title);
        setOrder(tripTypeData.tripType.order);
        setDataChanged(false);
    };

    const updateTripTypeFunc = () => {
        setSaving(true);

        updateTripType({
            variables: {
                id,
                title,
                order: order !== '' ? parseInt(order) : 0,
            },
        })
            .then(() => {
                closeModal();
            })
            .catch((err) => {
                addLocalError(err);
            });

        setSaving(false);
        setDataChanged(false);
    };

    if (tripTypeLoading) {
        return <Loading />;
    }

    return (
        <div className="p-20">
            <h2 className="m-b-20">{`${t('edit')} ${t('tripType').toLowerCase()}`}</h2>

            <SettingsInput
                required
                label={t('tripTypeTitle')}
                id="title"
                value={title}
                onChange={(e) => {
                    setTitle(e.target.value);
                    setDataChanged(true);
                }}
            />

            <SettingsInput
                label={t('order')}
                placeholder={t('lowerMeansFirst')}
                id="order"
                value={order}
                onChange={(e) => {
                    setOrder(e.target.value);
                    setDataChanged(true);
                }}
            />

            <div className="form-buttons-row">
                <button className="btn-link btn-distance" onClick={closeModal}>
                    {t('cancel')}
                </button>

                <div className="ml-auto message m-r-10">
                    {dataChanged && (
                        <div className="message error-message">{t('saveBeforeLeaving')}</div>
                    )}
                    {!dataChanged && <div className="message success-message">{t('saved')}</div>}
                </div>

                <button className="btn m-t-5" disabled={saving} onClick={updateTripTypeFunc}>
                    {saving ? `${t('saving')}...` : `${t('save')} ${t('tripType').toLowerCase()}`}
                </button>
            </div>
        </div>
    );
}
