import React from 'react';
import {
    Button,
    FormGroup,
    Label,
    Input,
} from 'reactstrap';

import { useTranslation } from 'react-i18next';

export default function SubfolderAdd(props) {
    const { close, addSubfolder, parentVariables } = props;

    const { t } = useTranslation();

    const [title, setTitle] = React.useState('');

    const [saving, setSaving] = React.useState(false);

    const addSubfolderFunc = () => {
        setSaving(true);
        addSubfolder({
            variables: {
                title,
                ...parentVariables,
            },
        })
            .then(() => {
                setSaving(false);
                close();
            })
            .catch((e) => {
                console.log(e);
                setSaving(false);
            });
    };

    return (
        <div className="p-20">
            <FormGroup>
                <Label htmlFor="name">{t('title')}</Label>
                <Input
                    id="name"
                    className="form-control"
                    placeholder={t('titlePlaceholder')}
                    value={title}
                    onChange={(e) =>
                        setTitle(e.target.value)
                    }
                />
            </FormGroup>

            <div className="row buttons">
                <Button
                    className="btn-link-cancel"
                    disabled={saving}
                    onClick={close}
                >
                    {t('close')}
                </Button>
                <Button
                    className="btn ml-auto"
                    disabled={title.length === 0 || saving}
                    onClick={addSubfolderFunc}
                >
                    {!saving
                        ? `${t('add')} ${t(
                              'subfolder'
                          ).toLowerCase()}`
                        : `${t('adding')} ${t(
                              'subfolder'
                          ).toLowerCase()}`}
                </Button>
            </div>
        </div>
    );
}
