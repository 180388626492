import React from 'react';
import TableList from './tableList';

import { useQuery, useMutation, useApolloClient, useSubscription } from '@apollo/client';

import {
    createDisplayValues,
    defaultTasklistColumnPreference,
    attributeLimitingRights,
} from 'configs/constants/tasks';

import { splitArrayByFilter, processStringFilter, translateAllSelectItems } from 'helperFunctions';
import { useTranslation } from 'react-i18next';

import {
    addLocalError,
    setLocalTaskStringFilter,
    setSingleLocalTaskStringFilter,
    setGlobalTaskStringFilter,
} from 'apollo/localSchema/actions';

import { GET_LOCAL_TASK_STRING_FILTER, GET_GLOBAL_TASK_STRING_FILTER } from 'apollo/queries';

import {
    GET_TASKS,
    DELETE_TASK,
    GET_TASKLIST_COLUMNS_PREFERENCES,
    ADD_OR_UPDATE_TASKLIST_COLUMNS_PREFERENCES,
    ADD_TASK_SUBSCRIPTION,
} from 'helpdesk/queries';

export default function TableListLoader(props) {
    const {
        localProject,
        localMilestone,
        localWorkspace,
        localFilter,
        orderBy,
        ascending,
        limit,
        processTasks,
        currentUser,
        filterVariables,
        viewCustomAttributesInMyProjects,
    } = props;

    const { t } = useTranslation();

    //local queries
    const { data: localStringFilter } = useQuery(GET_LOCAL_TASK_STRING_FILTER);

    const { data: globalStringFilter } = useQuery(GET_GLOBAL_TASK_STRING_FILTER);

    //apollo queries
    const taskVariables = {
        projectId: localProject.id,
        milestoneId: localMilestone.id,
        workspaceId: localWorkspace.id,
        filter: filterVariables,
        sort: {
            asc: ascending,
            key: orderBy,
        },
        stringFilter: processStringFilter(globalStringFilter.globalTaskStringFilter),
        page: 1,
        limit,
    };

    const { data: preferencesData, loading: preferencesLoading } = useQuery(
        GET_TASKLIST_COLUMNS_PREFERENCES,
        {
            variables: {
                projectId: localProject.id,
            },
        }
    );

    const {
        data: tasksData,
        loading: tasksLoading,
        refetch: tasksRefetchFunc,
    } = useQuery(GET_TASKS, {
        variables: taskVariables,
        notifyOnNetworkStatusChange: true,
    });
    //mutations
    const [deleteTask] = useMutation(DELETE_TASK);
    const [addOrUpdatePreferences] = useMutation(ADD_OR_UPDATE_TASKLIST_COLUMNS_PREFERENCES);
    const client = useApolloClient();
    //state
    const [markedTasks, setMarkedTasks] = React.useState([]);
    const [forcedRefetch, setForcedRefetch] = React.useState(false);

    //sync
    const tasksRefetch = () => {
        tasksRefetchFunc(taskVariables);
    };

    //refetch tasks
    React.useEffect(() => {
        tasksRefetch();
    }, [
        localFilter,
        localProject.id,
        localMilestone.id,
        currentUser,
        globalStringFilter,
        forcedRefetch,
    ]);

    useSubscription(ADD_TASK_SUBSCRIPTION, {
        onData: () => {
            tasksRefetch();
        },
    });

    const dataLoading = preferencesLoading || tasksLoading;

    const tasks = dataLoading ? [] : tasksData.tasks.tasks;
    const createPreferences = () => {
        if (!currentUser || !currentUser.role.accessRights.tasklistPreferences) {
            return defaultTasklistColumnPreference;
        }
        let preference = defaultTasklistColumnPreference;
        if (preferencesData && preferencesData.tasklistColumnPreference) {
            preference = {
                ...preference,
                ...preferencesData.tasklistColumnPreference,
            };
        }
        if (localProject.project.id === null) {
            return preference;
        }
        preference.project = false;
        /*    if (preference.customAttributes){
      preference.customAttributes.forEach((customAttribute, i) => {
        preference
      });

    }*/
        attributeLimitingRights.forEach((limitingRight) => {
            if (!limitingRight.right(localProject)) {
                preference[limitingRight.preference] = false;
            }
        });
        return preference;
    };

    const setPreference = (visibility) => {
        addOrUpdatePreferences({
            variables: {
                ...createPreferences(),
                ...visibility,
                projectId: localProject.id,
            },
        })
            .then((response) => {
                const preference = client.readQuery({
                    query: GET_TASKLIST_COLUMNS_PREFERENCES,
                    variables: {
                        projectId: localProject.id,
                    },
                }).tasklistColumnPreference;
                let newPreference = preference ? preference : {};
                newPreference = {
                    ...newPreference,
                    ...response.data.addOrUpdateTasklistColumnPreference,
                };
                client.writeQuery({
                    query: GET_TASKLIST_COLUMNS_PREFERENCES,
                    variables: {
                        projectId: localProject.id,
                    },
                    data: {
                        tasklistColumnPreference: newPreference,
                    },
                });
            })
            .catch((error) => {
                addLocalError(error);
            });
    };

    const checkTask = (id) => {
        if (id === 'all') {
            if (markedTasks.length === tasks.length) {
                setMarkedTasks([]);
            } else {
                setMarkedTasks(tasks.map((task) => task.id));
            }
        } else {
            if (!markedTasks.includes(id)) {
                setMarkedTasks([...markedTasks, id]);
            } else {
                setMarkedTasks(markedTasks.filter((taskId) => taskId !== id));
            }
        }
    };

    const deleteTaskFunc = () => {
        if (window.confirm(t('deleteSelectedTasksConfirmation'))) {
            let tasksForDelete = tasks.filter((task) => markedTasks.includes(task.id));
            const [canDeleteTasks, cantDeleteTasks] = splitArrayByFilter(
                tasksForDelete,
                (task) => task.rights.deleteTask
            );
            Promise.all(
                canDeleteTasks.map((task) => {
                    deleteTask({
                        variables: {
                            id: task.id,
                        },
                    });
                })
            )
                .then(() => {
                    const existingTasks = client.readQuery({
                        query: GET_TASKS,
                        variables: taskVariables,
                    }).tasks;

                    client.writeQuery({
                        query: GET_TASKS,
                        data: {
                            tasks: {
                                ...existingTasks,
                                tasks: existingTasks.tasks.filter(
                                    (existingTask) =>
                                        !canDeleteTasks.some(
                                            (deletedTask) => deletedTask.id === existingTask.id
                                        )
                                ),
                            },
                        },
                        variables: taskVariables,
                    });
                })
                .catch((err) => {
                    addLocalError(err);
                });

            if (cantDeleteTasks.length > 0) {
                window.alert(
                    `${canDeleteTasks.length} ${t('notAllTasksDeletedMessage')}: \n` +
                        cantDeleteTasks.reduce(
                            (acc, task) => acc + `${task.id} ${task.title} \n`,
                            ''
                        )
                );
            }
        }
    };

    const addCustomAttributesToDisplayValues = (displayValues) => {
        if (localProject.project.id === null) {
            return displayValues;
        }
        const customAttributesPreferences = createPreferences().customAttributes;
        let newDisplayValues = [...displayValues];
        const customAttributesToDisplay = viewCustomAttributesInMyProjects.find(
            (viewCustomAttribute) => viewCustomAttribute.projectId === localProject.project.id
        ).viewCustomAttributes;
        customAttributesToDisplay.forEach((customAttribute) => {
            let attributeHasPreference = false;
            let customAttributeInPreferences = null;
            if (customAttributesPreferences && customAttributesPreferences.length > 0) {
                customAttributeInPreferences = customAttributesPreferences.find(
                    (customPreference) => customPreference.customAttribute.id === customAttribute.id
                );
                if (customAttributeInPreferences) {
                    attributeHasPreference = true;
                }
            }
            const newCustomAttribute = {
                ...customAttribute,
                label:
                    customAttribute.title.substring(0, 1).toUpperCase() +
                    customAttribute.title.substring(1),
                type: 'customAttribute',
                valueType: customAttribute.type,
                show: attributeHasPreference ? customAttributeInPreferences.show : false,
                value: customAttribute.id,
                isCustomAttribute: true,
            };
            newDisplayValues.push(newCustomAttribute);
        });

        return newDisplayValues;
    };
    const tableProps = {
        ...props,
        displayValues: translateAllSelectItems(
            addCustomAttributesToDisplayValues(
                createDisplayValues(createPreferences(), localProject.project.id !== null, t)
            ),
            t
        ),
        preference: createPreferences(),
        loading: dataLoading,
        setPreference,
        tasks: processTasks(tasks).map((task) => ({
            ...task,
            checked: markedTasks.includes(task.id),
        })),
        checkTask,
        markedTasks,
        deleteTask: deleteTaskFunc,
        count: tasksLoading ? null : tasksData.tasks.count,
        forceRefetch: () => setForcedRefetch(!forcedRefetch),
        localStringFilter: localStringFilter.localTaskStringFilter,
        setLocalTaskStringFilter,
        globalStringFilter: globalStringFilter.globalTaskStringFilter,
        setGlobalTaskStringFilter,
        setSingleLocalTaskStringFilter,
    };

    return <TableList {...tableProps} />;
}
