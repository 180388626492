export default {
  type: {
    en: 'Type',
    sk: 'Typ',
    cz: 'Typ',
    de: 'Typ',
  },
  search: {
    en: 'Search',
    sk: 'Hľadať',
    cz: 'Vyhledávání',
    de: 'Suche',
  },
  noUser: {
    en: 'No user',
    sk: 'Bez používateľa',
    cz: 'Žádný uživatel',
    de: 'Kein Benutzer',
  },
  noTask: {
    en: 'Task no longer exists.',
    sk: 'Úloha už neexistuje.',
    cz: 'Úkol již neexistuje.',
    de: 'Aufgabe existiert nicht mehr.',
  },
  task: {
    en: 'Task',
    sk: 'Úloha',
    cz: 'Úkol',
    de: 'Aufgabe',
  },
  tasks: {
    en: 'Tasks',
    sk: 'Úlohy',
    cz: 'Úkoly',
    de: 'Aufgaben',
  },
  filter: {
    en: 'Filter',
    sk: 'Filter',
    cz: 'Filter',
    de: 'Filter',
  },
  userProfile: {
    en: 'User profile',
    sk: 'Používateľský profil',
    cz: 'Uživatelský profil',
    de: 'Benutzerprofil',
  },
  generalConfirmation: {
    en: 'Are you sure?',
    sk: 'Ste si istý?',
    cz: 'Jste si jistý?',
    de: 'Bist du dir sicher?',
  },
  allTypes: {
    en: 'All types',
    sk: 'Všetky typy',
    cz: 'Všechny typy',
    de: 'Alle Arten',
  },
  read: {
    en: 'Read',
    sk: 'Prečítané',
    cz: 'Přečtený',
    de: 'Lesen',
  },
  unread: {
    en: 'Unread',
    sk: 'Neprečítané',
    cz: 'Nepřečtený',
    de: 'Ungelesen',
  },
  loading: {
    en: 'Loading',
    sk: 'Načítavám',
    cz: 'Načítání',
    de: 'Wird geladen',
  },
  loadingData: {
    en: 'Loading data',
    sk: 'Načítavajú sa údaje',
    cz: 'Načítání dat',
    de: 'Lade Daten',
  },
  logoutConfirmation: {
    en: 'Are you sure you want to log out?',
    sk: 'Naozaj sa chcete odhlásiť?',
    cz: 'Opravdu se chcete odhlásit?',
    de: 'Möchten Sie sich wirklich abmelden?',
  },
  sortBy: {
    en: 'Sort by',
    sk: 'Zoradit podľa',
    cz: 'Seřazeno podle',
    de: 'Sortieren nach',
  },
  yes: {
    en: 'Yes',
    sk: 'Áno',
    cz: 'Ano',
    de: 'Ja',
  },
  no: {
    en: 'No',
    sk: 'Nie',
    cz: 'Ne',
    de: 'Nein',
  },
  any: {
    en: 'Any',
    sk: 'Ktorýkoľvek',
    cz: 'Jakýkoli',
    de: 'Irgendein',
  },
  saveBeforeLeaving: {
    en: 'Save changes before leaving!',
    sk: 'Uložte zmeny pred odchodom!',
    cz: 'Před odjezdem uložte změny!',
    de: 'Änderungen vor dem Verlassen speichern!',
  },
  fillAllRequiredInformation: {
    en: 'Fill in all the required information!',
    sk: 'Vyplňte všetky požadované polia!',
    cz: 'Vyplňte všechny požadované údaje!',
    de: 'Füllen Sie alle erforderlichen Informationen aus!',
  },
  saved: {
    en: 'Saved',
    sk: 'Uložené',
    cz: 'Uložené',
    de: 'Gerettet',
  },
  view: {
    en: 'View',
    sk: 'Vidieť',
    cz: 'Pohled',
    de: 'Aussicht',
  },
  noDescription: {
    en: 'No description',
    sk: 'Bez popisu',
    cz: 'Bez popisu',
    de: 'Keine Beschreibung',
  },
  noTitle: {
    en: 'No title',
    sk: 'Bez nadpisu',
    cz: 'Bez názvu',
    de: 'Kein Titel',
  },
  noData: {
    en: 'No data',
    sk: 'Bez dát',
    cz: 'Žádná data',
    de: 'Keine Daten',
  },
}
