import React from 'react';

import { useQuery, useMutation, useApolloClient } from '@apollo/client';

import { FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import Empty from 'components/Empty';
import SettingLoading from '../components/settingLoading';
import SettingListContainer from '../components/settingListContainer';
import { itemAttributesFullfillsString } from '../components/helpers';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { pickSelectStyle } from 'configs/components/select';
import { toSelArr } from 'helperFunctions';

import SMTPAdd from './smtpAdd';
import SMTPEdit from './smtpEdit';
import { GET_SMTPS, TEST_SMTPS, DELETE_SMTP } from 'helpdesk/queries';
import { addLocalError } from 'apollo/localSchema/actions';

export default function SMTPsList() {
    const client = useApolloClient();

    const { t } = useTranslation();

    const {
        data: smtpsData,
        loading: smtpsLoading,
        refetch: smtpsRefetch,
    } = useQuery(GET_SMTPS, {
        fetchPolicy: 'network-only',
    });

    const [testSmtps] = useMutation(TEST_SMTPS);
    const [deleteSmtp] = useMutation(DELETE_SMTP);

    // state
    const [SMTPFilter, setSMTPFilter] = React.useState('');

    const [editedItemId, setEditedItemId] = React.useState(null);
    const [itemToDelete, setItemToDelete] = React.useState({});

    const [smtpTesting, setSmtpTesting] = React.useState(false);
    const [wasRefetched, setWasRefetched] = React.useState(false);

    const [newSMTP, setNewSMTP] = React.useState(null);
    const [newDefSMTP, setNewDefSMTP] = React.useState(null);

    if (smtpsLoading) {
        return <SettingLoading />;
    }

    const smtps = smtpsData.smtps;

    const deleteSMTPFunc = () => {
        if (window.confirm(t('generalConfirmation'))) {
            deleteSmtp({
                variables: {
                    id: itemToDelete.id,
                    newDefId: newDefSMTP ? parseInt(newDefSMTP.id) : null,
                    newId: newSMTP ? parseInt(newSMTP.id) : null,
                },
            })
                .then(() => {
                    if (itemToDelete.def) {
                        client.writeQuery({
                            query: GET_SMTPS,
                            data: {
                                smtps: smtps
                                    .filter((smtp) => smtp.id !== itemToDelete.id)
                                    .map((smtp) => {
                                        return {
                                            ...smtp,
                                            def: smtp.id === parseInt(newDefSMTP.id),
                                        };
                                    }),
                            },
                        });
                    } else {
                        client.writeQuery({
                            query: GET_SMTPS,
                            data: {
                                smtps: smtps.filter((smtp) => smtp.id !== itemToDelete.id),
                            },
                        });
                    }
                    setItemToDelete({});
                    setNewSMTP(null);
                    setNewDefSMTP(null);
                })
                .catch((err) => {
                    addLocalError(err);
                });
        }
    };

    const testSMTPs = () => {
        setSmtpTesting(true);
        testSmtps();
    };

    const getStatusIcon = (smtp) => {
        let color = 'red';
        let iconName = 'cancel';
        if ((smtpTesting && !wasRefetched) || smtp.currentlyTested) {
            color = 'orange';
            iconName = 'sync';
        } else if (smtp.working) {
            color = 'green';
            iconName = 'check_circle';
        }
        return (
            <span className="material-symbols-outlined" style={{ color }}>
                {iconName}
            </span>
        );
    };

    const TestSmtpComponent = (
        <Empty>
            {!smtpTesting && (
                <button
                    disabled={smtpTesting}
                    className="btn btn-primary center-hor ml-auto"
                    onClick={testSMTPs}
                >
                    {t('testSmtps')}
                </button>
            )}
            {smtpTesting && <div className="center-hor ml-auto">{t('testingSmtps')}</div>}
            {smtpTesting && (
                <button
                    className="btn btn-primary center-hor ml-auto"
                    onClick={() => {
                        smtpsRefetch().then(() => {
                            setWasRefetched(true);
                        });
                    }}
                >
                    {t('Refetch')}
                </button>
            )}
        </Empty>
    );

    return (
        <SettingListContainer
            header={t('smtps')}
            addLabel={t('smtp')}
            filter={SMTPFilter}
            setFilter={setSMTPFilter}
            RightFilterComponent={TestSmtpComponent}
            AddComponent={SMTPAdd}
            EditComponent={SMTPEdit}
            editedItemId={editedItemId}
            setEditedItemId={setEditedItemId}
        >
            <table className="table">
                <thead>
                    <tr>
                        <th>{t('title')}</th>
                        <th>{t('host')}</th>
                        <th>{t('port')}</th>
                        <th>{t('username')}</th>
                        <th>{t('default')}</th>
                        <th>{t('status')}</th>
                        <th width="100px">{t('actions')}</th>
                    </tr>
                </thead>
                <tbody>
                    {smtps
                        .filter((item) =>
                            itemAttributesFullfillsString(item, SMTPFilter, [
                                'title',
                                'host',
                                'port',
                                'username',
                            ])
                        )
                        .map((smtp) => (
                            <tr key={smtp.id}>
                                <td>{smtp.title}</td>
                                <td>{smtp.host}</td>
                                <td>{smtp.port}</td>
                                <td>{smtp.username}</td>
                                <td>{smtp.def ? t('yes') : t('no')}</td>
                                <td>
                                    <span className="material-symbols-outlined p-r-0">
                                        {getStatusIcon(smtp)}
                                    </span>
                                </td>
                                <td className="row">
                                    {smtps.length > 1 && (
                                        <button
                                            className="btn-link-red m-r-0 m-l-0 p-0"
                                            onClick={() => {
                                                setItemToDelete(smtp);
                                            }}
                                        >
                                            <span className="material-symbols-outlined">
                                                delete
                                            </span>
                                        </button>
                                    )}
                                    <button
                                        className="btn-link m-r-0 m-l-10 p-0"
                                        onClick={() => {
                                            setEditedItemId(smtp.id);
                                        }}
                                    >
                                        <span className="material-symbols-outlined">edit</span>
                                    </button>
                                </td>
                            </tr>
                        ))}
                </tbody>
            </table>
            <Modal isOpen={itemToDelete.id ? true : false}>
                <ModalHeader>{t('selectReplacementSmtp')}</ModalHeader>
                <ModalBody>
                    <FormGroup>
                        {itemToDelete.def && <Label>{t('smtpReplacement')}</Label>}
                        <Select
                            styles={pickSelectStyle()}
                            options={toSelArr(smtps.filter((smtp) => smtp.id !== itemToDelete.id))}
                            value={newSMTP}
                            onChange={(s) => {
                                setNewSMTP(s);
                            }}
                        />
                    </FormGroup>

                    {itemToDelete.def && (
                        <FormGroup>
                            <Label>{t('smtpNewDefault')}</Label>
                            <Select
                                styles={pickSelectStyle()}
                                options={toSelArr(
                                    smtps.filter((smtp) => smtp.id !== itemToDelete.id)
                                )}
                                value={newDefSMTP}
                                onChange={(s) => {
                                    setNewDefSMTP(s);
                                }}
                            />
                        </FormGroup>
                    )}
                </ModalBody>
                <ModalFooter>
                    <button className="btn-link mr-auto" onClick={() => setItemToDelete({})}>
                        {t('cancel')}
                    </button>
                    <button
                        className="btn ml-auto"
                        disabled={!newSMTP || (itemToDelete.def ? !newDefSMTP : false)}
                        onClick={deleteSMTPFunc}
                    >
                        {t('completeDeletion')}
                    </button>
                </ModalFooter>
            </Modal>
        </SettingListContainer>
    );
}
