export default {
  agents: {
    en: 'Agents',
    sk: 'Agenti',
    cz: 'Agenti',
    de: 'Agenten',
  },
  agent: {
    en: 'Agent',
    sk: 'Agent',
    cz: 'Agent',
    de: 'Agenten',
  },
  noAgents: {
    en: 'No agents',
    sk: 'Žiadni agenti',
    cz: 'Žádní agenti',
    de: 'Keine Agenten',
  },
  inDateRange: {
    en: 'In date range',
    sk: 'V rozsahu dátumov',
    cz: 'V časovém období',
    de: 'Im Datumsbereich',
  },
  monthlyAgentInvoice: {
    en: 'Monthly invoice for agent',
    sk: 'Mesačný výkaz faktúrovaných prác agenta',
    cz: 'Měsíční faktura pro agenta',
    de: 'Monatliche Rechnung für den Agenten',
  },
  period: {
    en: 'Period',
    sk: 'Obdobie',
    cz: 'Doba',
    de: 'Zeitraum',
  },
  hoursCount: {
    en: 'Hours count',
    sk: 'Počet hodín',
    cz: 'Počítají se hodiny',
    de: 'Stunden zählen',
  },
  notClosed: {
    en: 'Not closed yet',
    sk: 'Ešte neuzavreté',
    cz: 'Zatím nezavřeno',
    de: 'Noch nicht geschlossen',
  },
  totalHours: {
    en: 'Total hours',
    sk: 'Spolu počet hodín',
    cz: 'Celkový počet hodin',
    de: 'Gesamtstunden',
  },
/*  overtimeTotalHours: {
    en: 'Overtime total hours',
    sk: 'Spolu počet hodín mimo pracovný čas',
    cz: 'Přesčas celkem hodin',
    de: 'Überstunden Gesamtstunden',
  },*/
  taskIDs: {
    en: 'Task IDs',
    sk: 'Čísla úloh',
    cz: 'ID úkolů',
    de: 'Task-IDs',
  },
  quantityShort: {
    en: 'Quan.',
    sk: 'Mn.',
    cz: 'Mn.',
    de: 'Anzahl',
  },
  pc: {
    en: 'pc',
    sk: 'ks',
    cz: 'ks',
    de: 'Stck',
  },
  tripTotal: {
    en: 'Trip total',
    sk: 'Spolu počet výjazdov',
    cz: 'Celková cesta',
    de: 'Reise insgesamt',
  },/*
  overtimeTripTotal: {
    en: 'Overtime trip total',
    sk: 'Spolu počet výjazdov mimo pracovný čas',
    cz: '',
    de: '',
  },
  uniqueAgentTotal: {
    en: 'Totals by work and trip type - in pausal, over pausal, in project',
    sk: 'Sumár podľa typu práce a výjazdov - v rámci paušálu, nad rámec paušálu, projektových',
    cz: '',
    de: '',
  },
  countOfWorksInPausal: {
    en: 'Count of works in pausal',
    sk: 'Počet prác vrámci paušálu',
    cz: '',
    de: '',
  },
  countOfWorksOverPausal: {
    en: 'Count of works over pausal',
    sk: 'Počet prác nad rámec paušálu',
    cz: '',
    de: '',
  },
  countOfWorksInPausalAndOverPausal: {
    en: 'Count of works in pausal and over pausal',
    sk: 'Počet prác vrámci a nad rámec paušálu',
    cz: '',
    de: '',
  },*/
  countOfWorksInProject: {
    en: 'Count of works in project',
    sk: 'Projektové práce',
    cz: 'Počet prací v projektu',
    de: 'Anzahl der Arbeiten im Projekt',
  },/*
  countOfTripsInPausal: {
    en: 'Count of trips in pausal',
    sk: 'Výjazdy vrámci paušálu',
    cz: '',
    de: '',
  },
  countOfTripsOverPausal: {
    en: 'Count of trips over pausal',
    sk: 'Výjazdy nad rámec paušálu',
    cz: '',
    de: '',
  },
  countOfTripsInPausalAndOverPausal: {
    en: 'Count of trips in pausal and over pausal',
    sk: 'Výjazdy vrámci nad rámec paušálu',
    cz: '',
    de: '',
  },*/
  countOfTripsInProject: {
    en: 'Count of trips in project',
    sk: 'Výjazdy pri projektových prácach',
    cz: 'Počet cest v projektu',
    de: 'Anzahl der Fahrten im Projekt',
  },
}
