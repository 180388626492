export default {
    errorSource: {
        en: 'Source of error',
        sk: 'Zdroj chybového hlásenia',
        cz: 'Zdroj chyby',
        de: 'Fehlerquelle',
    },
    relatedID: {
        en: 'Related ID',
        sk: 'Súvisiaca ID',
        cz: 'Související ID',
        de: 'Zugehörige ID',
    },
    openRelated: {
        en: 'Open related',
        sk: 'Otvoriť súvisiace',
        cz: 'Otevřít související',
        de: 'Verwandte öffnen',
    },
    by: {
        en: 'by',
        sk: '',
        cz: '',
        de: 'von',
    },
    confirmMessagesMarkAllRead: {
        en: 'Are you sure, that you want to mark all messages as read?',
        sk: 'Naozaj chcete označiť všetky správy ako prečítané?',
        cz: 'Jste si jisti, že chcete označit všechny zprávy jako přečtené?',
        de: 'Sind Sie sicher, dass Sie alle Nachrichten als gelesen markieren möchten?',
    },
    confirmMessagesDeleteAll: {
        en: 'Are you sure, that you want to delete all messages?',
        sk: 'Ste si istý, že chcete vymazať všetky správy?',
        cz: 'Jste si jisti, že chcete smazat všechny zprávy?',
        de: 'Sind Sie sicher, dass Sie alle Nachrichten löschen möchten?',
    },
    confirmMessagesDeleteAllRead: {
        en: 'Are you sure, that you want to delete all read messages?',
        sk: 'Ste si istý, že chcete vymazať všetky prečítané správy?',
        cz: 'Jste si jisti, že chcete smazat všechny přečtené zprávy?',
        de: 'Sind Sie sicher, dass Sie alle gelesenen Nachrichten löschen möchten?',
    },
    confirmMessagesCancelNotifications: {
        en: 'Are you sure you want to stop receiving notifications?',
        sk: 'Ste si istý, že už nechcete dostávať upozornenia?',
        cz: 'Opravdu chcete přestat dostávat oznámení?',
        de: 'Möchten Sie wirklich keine Benachrichtigungen mehr erhalten?',
    },
    confirmMessagesCancelMailNotifications: {
        en: 'Are you sure you want to stop receiving notifications by mail?',
        sk: 'Ste si istý, že už nechcete dostávať upozornenia mailom?',
        cz: 'Opravdu chcete přestat dostávat oznámení e-mailem?',
        de: 'Möchten Sie wirklich keine Benachrichtigungen per E-Mail mehr erhalten?',
    },
    confirmMessagesAllowMailNotifications: {
        en: 'Are you sure you want to start receiving notifications by mail?',
        sk: 'Ste si istý, že chcete dostávať upozornenia mailom?',
        cz: 'Opravdu chcete začít dostávat oznámení poštou?',
        de: 'Möchten Sie wirklich Benachrichtigungen per E-Mail erhalten?',
    },
    errorMessages: {
        en: 'Error messages',
        sk: 'Chybové hlásenia',
        cz: 'Chybové zprávy',
        de: 'Fehlermeldungen',
    },
    markAllRead: {
        en: 'Mark all read',
        sk: 'Označit všetky ako prečítané',
        cz: 'Označte vše jako přečtené',
        de: 'Markiere alles Gelesene',
    },
    deleteAll: {
        en: 'Delete all',
        sk: 'Vymazať všetky',
        cz: 'Smazat všechny',
        de: 'Alles löschen',
    },
    deleteRead: {
        en: 'Delete read',
        sk: 'Vymazať prečítané',
        cz: 'Smazat přečtené',
        de: 'Gelesen löschen',
    },
    noErrors: {
        en: 'There are no errors!',
        sk: 'Bez chýbových hlásení!',
        cz: 'Nejsou žádné chyby!',
        de: 'Es sind keine Fehler vorhanden!',
    },
    localErrors: {
        en: 'Local Errors',
        sk: 'Lokálne chybové hlásenia',
        cz: 'Místní chyby',
        de: 'Lokale Fehler',
    },
    clearLocalErrors: {
        en: 'Clear local errors',
        sk: 'Vymazať lokálne chybové hlásenia',
        cz: 'Vymažte místní chyby',
        de: 'Lokale Fehler löschen',
    },
    errorMessage: {
        en: 'Error message',
        sk: 'Chybové hlásenie',
        cz: 'Chybové hlášení',
        de: 'Fehlermeldung',
    },
    errorName: {
        en: 'Error name',
        sk: 'Názov chyby',
        cz: 'Název chyby',
        de: 'Fehlername',
    },
    fileName: {
        en: 'File name',
        sk: 'Názov súboru',
        cz: 'Název souboru',
        de: 'Dateiname',
    },
    stackPath: {
        en: 'Stack path',
        sk: 'Cesta k chybe',
        cz: 'Cesta k chybě',
        de: 'Stack-Pfad',
    },
    stackTrace: {
        en: 'Stack trace',
        sk: 'Sledovanie chyby',
        cz: 'Stopa k chybě',
        de: 'Stack-Trace',
    },
    errorCode: {
        en: 'Error code',
        sk: 'Kód chyby',
        cz: 'Chybový kód',
        de: 'Fehlercode',
    },
    errorDefineShow: {
        en: 'DEFINE PARAMETER SHOW - add to the component parameter show={true/false condition}',
        sk: 'Nenastavili ste parameter SHOW - pridajte ho komponente show={true/false podmienka}',
        cz: 'Nenastavili jste parameter SHOW - přidejtet ho komponentu show={true/false podmínka}',
        de: 'PARAMETER SHOW DEFINIEREN - zum Komponentenparameter hinzufügen show={true/false Bedingung}',
    },
    noErrorMessageDefined: {
        en: 'No message defined!',
        sk: 'Nedefinovaná správa!',
        cz: 'Není definována žádná zpráva!',
        de: 'Keine Nachricht definiert!',
    },
    cannotAddCommentSmtpNotWorking: {
        en: 'Cannot add comments due to default SMTP not working. Please contact an administrator to solve this issue.',
        sk: 'Nie je možné pridávať komentáre, pretože predvolený SMTP nefunguje. Ak chcete tento problém vyriešiť, kontaktujte správcu.',
        cz: 'Nelze přidávat komentáře, protože výchozí SMTP nefunguje. Chcete-li tento problém vyřešit, kontaktujte správce.',
        de: 'Kommentare können nicht hinzugefügt werden, da Standard-SMTP nicht funktioniert. Bitte wenden Sie sich an einen Administrator, um dieses Problem zu lösen.',
    },
    maximumSizeExceeded: {
        en: 'Maximum attachment size (20MB) exceeded!',
        sk: 'Maximálna veľkosť prílohy (20 MB) bola prekročená!',
        cz: 'Překročena maximální velikost přílohy (20 MB)!',
        de: 'Maximale Anhangsgröße (20 MB) überschritten!',
    },
    errorOccured: {
        en: 'An error occured',
        sk: 'Vyskytla sa chyba',
        cz: 'Vyskytla se chyba',
        de: 'Ein Fehler ist aufgetreten',
    },
};
