export default {
  comfirmDeletingLanpassPassword: {
    en: 'Are you sure you want to delete this password?',
    sk: 'Ste si istý, že chcete zmazať toto heslo?',
    cz: 'Opravdu chcete toto heslo smazat?',
    de: 'Möchten Sie dieses Passwort wirklich löschen?',
  },
  deleteAllLanpassPages: {
    en: 'None (Delete all LanPass passwords in the folder)',
    sk: 'Žiadny (Vymaže všetky LanPass heslá v priečinku)',
    cz: 'Žádné (smazat všechna hesla LanPass ve složce)',
    de: 'Keine (Alle LanPass-Passwörter im Ordner löschen)',
  },
  passwordIsPrivate: {
    en: 'This password is private. Only you can see it.',
    sk: 'Toto heslo je súkromné. Môžete ho vidieť iba vy.',
    cz: 'Toto heslo je soukromé. Můžete to vidět jen vy.',
    de: 'Dieses Passwort ist privat. Nur du kannst es sehen.',
  },
  passwordIsPublic: {
    en: 'This password is public. Everyone with access to the folder can see it.',
    sk: 'Toto heslo je verejné. Vidí ho každý s prístupom do priečinka.',
    cz: 'Toto heslo je veřejné. Každý, kdo má přístup ke složce, ji může vidět.',
    de: 'Dieses Passwort ist öffentlich. Jeder mit Zugriff auf den Ordner kann ihn sehen.',
  },
}
