import React from 'react';

import {
  useNavigate,
  useLocation,
} from 'react-router-dom';
import {
  Label
} from 'reactstrap';
import {
  timestampToStringFNS,
  getLocation,
} from 'helperFunctions';
import {
  useTranslation
} from "react-i18next";

export default function ErrorInfo( props ) {
  const {
    errorMessage
  } = props;

  const navigate = useNavigate();
  const location = useLocation();

  const {
    t
  } = useTranslation();

  const getEditURL = () => {
    switch ( errorMessage.type ) {
      case 'smtp_email': {
        return `${getLocation(location)}/settings/smtps/${errorMessage.sourceId}`
      }
      default: {
        return getLocation( location );
      }
    }
  }

  const error = errorMessage;
  return (
    <div className="notification-info">
      <div className="p-20 scroll-visible fit-with-header">
          <h2>{t('errorMessage')}</h2>
          <h3>
          {` ${error.errorMessage}`}
        </h3>
        <hr />
        <div>
          <Label>{t('type')}</Label>
          {` ${error.type}`}
        </div>
        <div>
          <Label>{t('createdAt')}</Label>
          {` ${timestampToStringFNS(parseInt(error.createdAt))} ${error.user ? t('by') + error.user.email : ""}`}
        </div>
        <div>
          <Label>{t('errorSource')}</Label>
          {` ${error.source}`}
        </div>
        {
          error.sourceID !== null &&
          <div>
            <Label>{t('relatedID')}</Label>
            {` ${error.sourceId} `}
            <Label className="clickable" onClick={ ()=> navigate(getEditURL()) }>{t('openRelated')}</Label>
          </div>
        }
      </div>
    </div>
  );
}
