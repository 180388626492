import React from 'react';

import { useMutation } from '@apollo/client';
import Form from './form';
import { toSelItem } from 'helperFunctions';
import { addLocalError } from 'apollo/localSchema/actions';
import { useTranslation } from 'react-i18next';
import { ADD_COMPANY } from 'helpdesk/queries';

export default function CompanyAdd(props) {
    const { addCompanyToList, closeModal } = props;

    const { t } = useTranslation();

    const [addCompany] = useMutation(ADD_COMPANY);

    //state
    const [saving, setSaving] = React.useState(false);
    const [newData, setNewData] = React.useState(false);

    //functions
    const addCompanyFunc = (data) => {
        setSaving(true);

        addCompany({
            variables: {
                ...data,
            },
        })
            .then((response) => {
                if (addCompanyToList) {
                    addCompanyToList(
                        toSelItem({
                            ...response.data.addCompany,
                            __typename: 'BasicCompany',
                        })
                    );
                }

                closeModal();
            })
            .catch((err) => {
                addLocalError(err);
            });
        setNewData(false);
        setSaving(false);
    };

    const close = () => {
        setNewData(false);
        closeModal();
    };

    return (
        <Form
            label={`${t('add')} ${t(
                'company2'
            ).toLowerCase()}`}
            onSave={addCompanyFunc}
            onClose={close}
            saving={saving}
            newData={newData}
            setNewData={setNewData}
        />
    );
}
