import React, { useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import Loading from 'components/loading';

import CommandBar from './components/commandBar';
import Pagination from './components/pagination';
import ActiveSearch from './components/activeSearch';
import classnames from 'classnames';

import { timestampToStringFNS } from 'helperFunctions';

import { useTranslation } from 'react-i18next';

export default function PasswordsList(props) {
    const { loading, passwords, setLocalStringFilter, localStringFilter, setGlobalStringFilter } =
        props;

    const navigate = useNavigate();
    const params = useParams();

    const { t } = useTranslation();

    const [copiedPassword, setCopiedPassword] = useState(null);

    let path = `/lanpass/i/${params.folderID ? params.folderID : 'all'}${
        params.subfolderID ? '/s/' + params.subfolderID : ''
    }/p/${params.password ? params.password : 1}`;

    return (
        <div
            style={{
                width: params.password ? '80%' : '100%',
                height: 'calc(100vh - 40px)',
            }}
            className="scrollable scroll-grey"
        >
            <CommandBar {...props} />
            <div className="full-width fit-with-header-and-commandbar-list task-container">
                <div className="row m-l-30">
                    <input
                        type="text"
                        value={localStringFilter.title}
                        placeholder={t('title')}
                        className="form-control search p-l-10"
                        style={{ fontSize: '12px' }}
                        onKeyPress={(e) => {
                            if (e.charCode === 13 && !loading) {
                                setGlobalStringFilter();
                            }
                        }}
                        onChange={(e) => {
                            setLocalStringFilter('title', e.target.value);
                        }}
                    />
                    <button className="btn" onClick={setGlobalStringFilter}>
                        {t('search')}
                    </button>
                </div>

                <table className="table">
                    <thead>
                        <tr>
                            <th
                                style={{
                                    paddingBottom: '12px',
                                    paddingTop: '12px',
                                }}
                            >
                                {t('title')}
                            </th>
                            <th
                                width="250"
                                style={{
                                    paddingBottom: '12px',
                                    paddingTop: '12px',
                                }}
                            >
                                {t('login2')}
                            </th>
                            <th
                                width="250"
                                style={{
                                    paddingBottom: '12px',
                                    paddingTop: '12px',
                                }}
                            >
                                {t('password')}
                            </th>
                            <th
                                width="250"
                                style={{
                                    paddingBottom: '12px',
                                    paddingTop: '12px',
                                }}
                            >
                                {t('url')}
                            </th>
                            <th
                                width="250"
                                style={{
                                    paddingBottom: '12px',
                                    paddingTop: '12px',
                                }}
                            >
                                {t('expireDate')}
                            </th>
                        </tr>
                    </thead>

                    <tbody>
                        <ActiveSearch {...props} />

                        {passwords.map((password) => (
                            <tr
                                key={password.id}
                                className={classnames('clickable noselect')}
                                style={{
                                    backgroundColor:
                                        password.id === parseInt(params.passwordID)
                                            ? '#f8fcff'
                                            : 'white',
                                    borderLeft:
                                        password.id === parseInt(params.passwordID)
                                            ? '1px solid #0078d7'
                                            : 'none',
                                }}
                                onClick={() => navigate(`${path}/${password.id}`)}
                            >
                                <td
                                    className="font-14-f"
                                    style={{
                                        backgroundColor: 'inherit',
                                    }}
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                    >
                                        {password.title}
                                    </div>
                                </td>
                                <td
                                    className="p-l-0"
                                    style={{
                                        backgroundColor: 'inherit',
                                    }}
                                >
                                    {password.login.length > 0 ? password.login : t('noLogin')}
                                </td>
                                <td
                                    className="p-l-0 row normal-cursor"
                                    onClick={(e) => e.stopPropagation()}
                                    style={{
                                        backgroundColor: 'inherit',
                                    }}
                                >
                                    {password.password.length > 0
                                        ? '************'
                                        : t('noPassword')}

                                    <button
                                        className="btn-link m-r-35 m-l-auto"
                                        style={{
                                            height: 'fit-content',
                                            alignItems: 'center',
                                            display: 'flex',
                                        }}
                                        onClick={() => {
                                            navigator.clipboard.writeText(password.password);
                                            setCopiedPassword(password.id);
                                            setTimeout(() => {
                                                setCopiedPassword(null);
                                            }, 3000);
                                        }}
                                    >
                                        {copiedPassword === password.id && (
                                            <span
                                                className="inline-success-message p-t-0 p-b-0"
                                                style={{
                                                    height: '19px',
                                                }}
                                            >
                                                {t('copied')}
                                            </span>
                                        )}
                                        <span className="material-symbols-outlined">
                                            content_copy
                                        </span>
                                    </button>
                                </td>
                                <td
                                    className="p-l-0"
                                    style={{
                                        backgroundColor: 'inherit',
                                    }}
                                >
                                    <a
                                        href={`${password.url}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        onClick={(e) => e.stopPropagation()}
                                    >
                                        {password.url}
                                    </a>
                                </td>
                                <td
                                    className="p-l-0"
                                    style={{
                                        display: 'flex',
                                        backgroundColor: 'inherit',
                                    }}
                                >
                                    {password.expireDate
                                        ? timestampToStringFNS(password.expireDate)
                                        : t('noExpireDate')}
                                    {password.isPrivate && (
                                        <span className="material-symbols-outlined m-l-auto">
                                            lock_person
                                        </span>
                                    )}
                                </td>
                            </tr>
                        ))}
                        {passwords.length === 0 && (
                            <tr
                                className="clickable noselect"
                                style={{
                                    backgroundColor: 'inherit',
                                }}
                            >
                                <td colSpan="10" className="font-14-f">
                                    {t('noData')}
                                </td>
                            </tr>
                        )}
                        {loading && (
                            <tr>
                                <td colSpan="100">
                                    <Loading noPos flex />
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
                <Pagination {...props} />
            </div>
        </div>
    );
}
