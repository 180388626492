import React, { useMemo } from 'react';

import AutomationForm from './form';

import { Modal, ModalBody, ModalHeader } from 'reactstrap';

import { useQuery } from '@apollo/client';
import { toSelArr } from 'helperFunctions';

import { GET_MY_PROJECTS } from 'helpdesk/queries';

import { useTranslation } from 'react-i18next';

export default function EditEventAutomation(props) {
    const {
        open,
        updateAutomationEvent,
        automationEvent,
        closeModal,
        allUsers,
        userGroups,
        statuses,
        tags,
        lockedRequester,
        companies,
        customAttributes,
    } = props;

    const { t } = useTranslation();

    const { data: myProjectsData } = useQuery(GET_MY_PROJECTS, {
        fetchPolicy: 'network-only',
    });

    const projects = useMemo(() => {
        if (myProjectsData) {
            return toSelArr(myProjectsData.myProjects.map((project) => project.project));
        }
        return [];
    }, [myProjectsData]);

    const cantSave = false;

    return (
        <Modal isOpen={open}>
            <ModalHeader>{t('editProccessAutomation')}</ModalHeader>
            <ModalBody>
                <AutomationForm
                    onSubmit={updateAutomationEvent}
                    onCancel={closeModal}
                    closeModal={closeModal}
                    automationEvent={automationEvent}
                    cantSave={cantSave}
                    allUsers={allUsers}
                    userGroups={userGroups}
                    statuses={statuses}
                    tags={tags}
                    projects={projects}
                    lockedRequester={lockedRequester}
                    companies={companies}
                    customAttributes={customAttributes}
                />
            </ModalBody>
        </Modal>
    );
}
