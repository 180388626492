import React from 'react';
import Select from 'react-select';
import {
  pickSelectStyle
} from 'configs/components/select';
import DatePicker from 'react-datepicker';
import classnames from "classnames";

export const hightlightText = (message, text, color) => {
  let index = message.toLowerCase()
    .indexOf(text.toLowerCase());
  if (index === -1) {
    return (<span>{message}</span>);
  }
  return (
    <span>{message.substring(0, index)}
      <span style={{
        color
      }}>
        {message.substring(index, index + text.length)}
      </span>
      {message.substring(index + text.length, message.length)}
    </span>
  );
}

export const calculateTextAreaHeight = (e) => {
  //  const firstHeight = 29;
  //  const expansionHeight = 21;
  //  return firstHeight + Math.floor(Math.abs((e.target.scrollHeight-firstHeight))/expansionHeight)*expansionHeight;
  return e.target.scrollHeight;
}

export const getAttributeDefaultValue = (item) => {
  switch (item.type.id) {
    case 'input':
      return '';
    case 'textarea':
      return '';
    case 'select':
      return item.options.length > 0 ?
        item.options[0] :
        null;
    default:
      return '';
  }
}

export const htmlFixNewLines = (text) => {
  return text.replace(/(?:\r\n|\r|\n)/g, '<br>');
}

export const renderCustomAttribute = (id, title, type, value, onChangeValue, onBlur, options = [], selectStyles = [], disabled = false) => {
  let placeholder = title;
  const displayedOptions = options.map((option) => ({
    ...option,
    label: option.label ? option.label : option.value.substring(0, 1).toUpperCase() + option.value.substring(1)
  }));
  switch (type) {
    case "textarea":
      return (
        <textarea
          key={id}
          value={value.text}
          disabled={disabled}
          className="form-control"
          onBlur={onBlur}
          onChange={onChangeValue}
          placeholder={placeholder}
        />
      );
    case "number":
      return (
        <input
          key={id}
          type="number"
          value={value.number}
          disabled={disabled}
          className="form-control"
          onBlur={onBlur}
          onChange={onChangeValue}
          placeholder={placeholder}
        />
      );
    case "select":
      return (
        <Select
          key={id}
          options={displayedOptions}
          disabled={disabled}
          value={value.selectValues}
          onBlur={onBlur}
          onChange={onChangeValue}
          styles={pickSelectStyle([...selectStyles])}
        />
      )
    case "multiselect":
      return (
        <Select
          key={id}
          options={displayedOptions}
          isMulti
          value={value.selectValues}
          disabled={disabled}
          onBlur={onBlur}
          onChange={onChangeValue}
          styles={pickSelectStyle([...selectStyles])}
        />
      )
    default:
      return (
        <input
          key={id}
          value={value.text}
          disabled={disabled}
          className="form-control"
          onBlur={onBlur}
          onChange={onChangeValue}
          placeholder={placeholder}
        />
      )
  }
}


export const renderValueInput = (returnNullCondition, switchValue, inputValue, options, items, setItems, index, t) => {

  if (returnNullCondition) {
    return;
  }
  switch (switchValue) {
    case 'select':
      return ( <
        Select styles = {
          pickSelectStyle(['size16'])
        }
        options = {
          options
        }
        value = {
          inputValue
        }
        onChange = {
          value => {
            const newItems = items.map((item, itemIndex) => {
              if (itemIndex === index) {
                return {
                  ...item,
                  value: [value],
                }
              }
              return item;
            });
            setItems(newItems);
          }
        }
        />
      )
    case 'multiselect':
      return ( <
        Select styles = {
          pickSelectStyle(['size16'])
        }
        options = {
          options
        }
        value = {
          inputValue
        }
        isMulti onChange = {
          value => {
            const newItems = items.map((item, itemIndex) => {
              if (itemIndex === index) {
                return {
                  ...item,
                  value,
                }
              }
              return item;
            });
            setItems(newItems);
          }
        }
        />
      )
    case 'date':
      return ( <
        DatePicker className = {
          classnames("form-control")
        }
        selected = {
          inputValue ? inputValue[0].value : inputValue
        }
        hideTime isClearable onChange = {
          date => {
            const newItems = items.map((a, aIndex) => {
              if (aIndex === index) {
                return {
                  ...a,
                  value: isNaN(date.valueOf()) ? null : [{
                    value: date
                  }],
                }
              }
              return a;
            });
            setItems(newItems);
          }
        }
        placeholderText = {
          t('date')
        }
        />
      );
    case 'text':
      return ( <
        input className = "form-control"
        value = {
          inputValue ? inputValue[0].value : inputValue
        }
        type = {
          "text"
        }
        onChange = {
          (e) => {
            const newItems = items.map((a, aIndex) => {
              if (aIndex === index) {
                return {
                  ...a,
                  value: [{
                    value: e.target.value
                  }],
                }
              }
              return a;
            });
            setItems(newItems);
          }
        }
        placeholder = {
          t('value')
        }
        />
      );
    case 'number':
      return ( <
        input className = "form-control"
        value = {
          inputValue ? inputValue[0].value : inputValue
        }
        type = {
          "number"
        }
        onChange = {
          (e) => {
            const newItems = items.map((a, aIndex) => {
              if (aIndex === index) {
                return {
                  ...a,
                  value: [{
                    value: e.target.value
                  }],
                }
              }
              return a;
            });
            setItems(newItems);
          }
        }
        placeholder = {
          t('value')
        }
        />
      );
    case 'textarea':
      return ( <
        textarea className = "form-control"
        value = {
          inputValue ? inputValue[0].value : inputValue
        }
        onChange = {
          (e) => {
            const newItems = items.map((a, aIndex) => {
              if (aIndex === index) {
                return {
                  ...a,
                  value: [{
                    value: e.target.value
                  }],
                }
              }
              return a;
            });
            setItems(newItems);
          }
        }
        placeholder = {
          t('value')
        }
        />
      );
    default:
      return;
  }
}