export const intervals = [ {
    title: 'none',
    value: null,
    label: 'Žiadny'
  },
  {
    title: 'day',
    value: 'day',
    label: 'day'
  },
  {
    title: 'week',
    value: 'week',
    label: 'week'
  },
  {
    title: 'month',
    value: 'month',
    label: 'month'
  }
];