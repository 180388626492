import React from 'react';

import { useParams } from 'react-router-dom';

import TaskEdit from 'helpdesk/task/edit';

import ColumnList from './column';
import TableList from './table';
import DnDList from './dnd';
import Calendar from './calendar';
import GanttList from './gantt';
import Statistics from './statistics';

export default function TasklistSwitch(props) {
    const { tasklistLayout } = props;

    const params = useParams();

    const taskID = params.taskID;

    const link = `/helpdesk/taskList/i/${
        params.listID ? params.listID : 'all'
    }`;

    const generalProps = {
        ...props,
        link,
    };

    return (
        <div className="content-page">
            <div
                className="content"
                style={{ paddingTop: 0 }}
            >
                <div className="row m-0">
                    {tasklistLayout === 0 && (
                        <div className="flex">
                            <ColumnList {...generalProps} />
                        </div>
                    )}
                    {tasklistLayout === 1 && (
                        <div className="flex">
                            {taskID && (
                                <TaskEdit columns={false} />
                            )}
                            {!taskID && (
                                <TableList
                                    {...generalProps}
                                />
                            )}
                        </div>
                    )}

                    {tasklistLayout === 2 && (
                        <div className="col-xl-12">
                            {taskID && (
                                <TaskEdit columns={false} />
                            )}
                            {!taskID && (
                                <DnDList
                                    {...generalProps}
                                />
                            )}
                        </div>
                    )}
                    {tasklistLayout === 3 && (
                        <div className="col-xl-12">
                            {taskID && (
                                <TaskEdit columns={false} />
                            )}
                            {!taskID && (
                                <Calendar
                                    {...generalProps}
                                />
                            )}
                        </div>
                    )}
                    {tasklistLayout === 4 && (
                        <div className="col-xl-12">
                            {taskID && (
                                <TaskEdit columns={false} />
                            )}
                            {!taskID && (
                                <GanttList
                                    {...generalProps}
                                />
                            )}
                        </div>
                    )}
                    {tasklistLayout === 5 && (
                        <div className="col-xl-12">
                            <Statistics {...generalProps} />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}
