import React, { useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import { useQuery, useSubscription } from '@apollo/client';
import { NavItem, Nav } from 'reactstrap';
import Empty from 'components/Empty';
import Loading from 'components/loading';
import FolderAdd from 'lanpass/folders/add/modalButton';
import FolderEdit from 'lanpass/folders/edit/modalButton';
import AddPassword from 'lanpass/passwords/add';

import classnames from 'classnames';

import { useTranslation } from 'react-i18next';

import { setPSidebarFolder } from 'apollo/localSchema/actions';

import {
    P_SIDEBAR_FOLDER,
    GET_PROJECT,
} from 'apollo/queries';

import {
    GET_FOLDERS,
    FOLDERS_SUBSCRIPTION,
} from 'lanpass/queries';

import { getMyData } from 'helperFunctions';

export default function Sidebar() {
    const navigate = useNavigate();
    const params = useParams();

    const [parentFolder, setParentFolder] = useState(null);

    const { t } = useTranslation();

    const { data: projectData } = useQuery(GET_PROJECT);
    const localProject = projectData.localProject;

    const { data: sidebarFolderData } = useQuery(
        P_SIDEBAR_FOLDER
    );

    const currentUser = getMyData();
    const userIsAdmin =
        currentUser && currentUser.role
            ? currentUser.role.level === 0
            : false;

    const {
        data: foldersData,
        loading: foldersLoading,
        refetch: foldersRefetch,
    } = useQuery(GET_FOLDERS, {
        variables: {
            projectId: localProject.project.id,
        },
        fetchPolicy: 'network-only',
    });

    useSubscription(FOLDERS_SUBSCRIPTION, {
        onData: () => {
            foldersRefetch({
                projectId: localProject.project.id,
            });
        },
    });

    const sidebarFolderId =
        !sidebarFolderData ||
        sidebarFolderData.pSidebarFolder === null
            ? null
            : sidebarFolderData.pSidebarFolder.id;

    React.useEffect(() => {
        if (
            !foldersLoading &&
            params.folderID !== undefined
        ) {
            if (params.subfolderID) {
                foldersData.passFolders.forEach(
                    (folder) => {
                        folder.childrenFolders.forEach(
                            (subfolder) => {
                                if (
                                    subfolder.id ===
                                    parseInt(
                                        params.subfolderID
                                    )
                                ) {
                                    setPSidebarFolder(
                                        subfolder
                                    );
                                    setParentFolder(folder);
                                }
                            }
                        );
                    }
                );
            } else {
                const folder = foldersData.passFolders.find(
                    (folder) =>
                        folder.id ===
                        parseInt(params.folderID)
                );
                setPSidebarFolder(
                    params.folderID === 'all'
                        ? null
                        : folder
                );
                setParentFolder(
                    folder &&
                        folder.childrenFolders &&
                        folder.childrenFolders.length === 0
                        ? null
                        : folder
                );
            }
        }
    }, [
        params.folderID,
        foldersLoading,
        foldersData,
        params.subfolderID,
    ]);

    if (foldersLoading) {
        return (
            <div className="sidebar">
                <Loading />
            </div>
        );
    }

    const folders = foldersData.passFolders;

    const itemsToList = () => {
        if (parentFolder) {
            return parentFolder.childrenFolders;
        }
        return folders;
    };

    return (
        <div className="sidebar">
            <div className="scrollable fit-with-header">
                <AddPassword
                    folderId={params.folderID}
                    subfolderId={params.subfolderID}
                />
                <hr className="m-l-15 m-r-15 m-t-15" />
                <Nav vertical>
                    <Empty>
                        <NavItem
                            className={classnames(
                                'row full-width sidebar-item align-items-center noselect',
                                {
                                    'active':
                                        !params.subfolderID &&
                                        (parentFolder
                                            ? parseInt(
                                                  params.folderID
                                              ) ===
                                              parentFolder.id
                                            : params.folderID ===
                                              'all'),
                                }
                            )}
                        >
                            {parentFolder && (
                                <button
                                    className="btn-link m-l-12"
                                    style={{
                                        ...(!params.subfolderID &&
                                        (parentFolder
                                            ? parseInt(
                                                  params.folderID
                                              ) ===
                                              parentFolder.id
                                            : params.folderID ===
                                              'all')
                                            ? {}
                                            : {
                                                  color: '#3c3c3c',
                                              }),
                                    }}
                                    onClick={() => {
                                        navigate(
                                            '/lanpass/i/all'
                                        );
                                        setParentFolder(
                                            null
                                        );
                                    }}
                                >
                                    <span className="material-symbols-outlined bigger p-r-0">
                                        chevron_left
                                    </span>
                                </button>
                            )}
                            <span
                                className={classnames(
                                    'clickable link align-items-center',
                                    {
                                        'sidebar-menu-item-2':
                                            parentFolder,
                                        'sidebar-menu-item':
                                            !parentFolder,
                                        'active':
                                            !params.subfolderID &&
                                            (parentFolder
                                                ? parseInt(
                                                      params.folderID
                                                  ) ===
                                                  parentFolder.id
                                                : params.folderID ===
                                                  'all'),
                                    }
                                )}
                                onClick={() => {
                                    navigate(
                                        parentFolder
                                            ? `/lanpass/i/${parentFolder.id}`
                                            : '/lanpass/i/all'
                                    );
                                    setPSidebarFolder(
                                        parentFolder
                                    );
                                }}
                            >
                                {parentFolder
                                    ? parentFolder.title
                                    : t('allFolders')}
                            </span>
                            {parentFolder &&
                                parentFolder.myRights
                                    .manage &&
                                parseInt(
                                    params.folderID
                                ) === parentFolder.id &&
                                !params.subfolderID && (
                                    <FolderEdit
                                        id={parentFolder.id}
                                        folders={folders}
                                        foldersRefetch={
                                            foldersRefetch
                                        }
                                        className="m-r-15"
                                    />
                                )}
                        </NavItem>

                        {itemsToList().map((folder) => (
                            <NavItem
                                key={folder.id}
                                className={classnames(
                                    'row full-width sidebar-item noselect',
                                    {
                                        'active':
                                            sidebarFolderId ===
                                            folder.id,
                                    }
                                )}
                            >
                                <span
                                    className={classnames(
                                        'clickable sidebar-menu-item link align-items-center',
                                        {
                                            'active':
                                                sidebarFolderId ===
                                                folder.id,
                                        }
                                    )}
                                    onClick={() => {
                                        if (
                                            (folder.childrenFolders &&
                                                folder
                                                    .childrenFolders
                                                    .length >
                                                    0) ||
                                            !folder.parentFolder
                                        ) {
                                            navigate(
                                                `/lanpass/i/${folder.id}`
                                            );
                                        } else {
                                            navigate(
                                                `/lanpass/i/${folder.parentFolder.id}/s/${folder.id}`
                                            );
                                        }
                                        setPSidebarFolder(
                                            folder
                                        );
                                        if (
                                            folder.childrenFolders &&
                                            folder
                                                .childrenFolders
                                                .length > 0
                                        ) {
                                            setParentFolder(
                                                folder
                                            );
                                        } else if (
                                            !folder.parentFolder
                                        ) {
                                            setParentFolder(
                                                null
                                            );
                                        } else {
                                            setParentFolder(
                                                folders.find(
                                                    (
                                                        mainFolder
                                                    ) =>
                                                        mainFolder.id ===
                                                        folder
                                                            .parentFolder
                                                            .id
                                                )
                                            );
                                        }
                                    }}
                                >
                                    {folder.title}
                                    {folder.childrenFolders &&
                                        folder
                                            .childrenFolders
                                            .length > 0 && (
                                            <button
                                                className="btn-link ml-auto"
                                                style={{
                                                    color: '#3c3c3c',
                                                }}
                                                onClick={() => {}}
                                            >
                                                <span className="material-symbols-outlined bigger">
                                                    chevron_right
                                                </span>
                                            </button>
                                        )}

                                    {folder.myRights
                                        .manage &&
                                        parseInt(
                                            params.folderID
                                        ) === folder.id && (
                                            <FolderEdit
                                                id={
                                                    folder.id
                                                }
                                                folders={
                                                    folders
                                                }
                                                foldersRefetch={
                                                    foldersRefetch
                                                }
                                                className={
                                                    folder.childrenFolders &&
                                                    folder
                                                        .childrenFolders
                                                        .length >
                                                        0
                                                        ? 'm-r-15'
                                                        : 'm-r-5'
                                                }
                                            />
                                        )}
                                    {folder.myRights
                                        .manage &&
                                        parseInt(
                                            params.subfolderID
                                        ) === folder.id && (
                                            <FolderEdit
                                                id={
                                                    folder.id
                                                }
                                                parentFolder={
                                                    parentFolder
                                                }
                                                subfolders={
                                                    parentFolder.childrenFolders
                                                }
                                                foldersRefetch={
                                                    foldersRefetch
                                                }
                                            />
                                        )}
                                    {folder.myRights
                                        .manage &&
                                        parseInt(
                                            params.folderID
                                        ) === folder.id &&
                                        (!folder.childrenFolders ||
                                            folder
                                                .childrenFolders
                                                .length ===
                                                0) &&
                                        (localProject.right
                                            .passCreateFolder ||
                                            userIsAdmin) && (
                                            <FolderAdd
                                                parentFolder={
                                                    folder
                                                }
                                                folders={
                                                    folders
                                                }
                                                foldersRefetch={
                                                    foldersRefetch
                                                }
                                                headerLabel={t(
                                                    'subfolder'
                                                )}
                                            />
                                        )}
                                </span>
                            </NavItem>
                        ))}
                    </Empty>
                </Nav>
                {parentFolder &&
                    (localProject.right.passCreateFolder ||
                        userIsAdmin) && (
                        <FolderAdd
                            parentFolder={parentFolder}
                            foldersRefetch={foldersRefetch}
                            label={t('subfolder')}
                            className="m-l-12"
                            headerLabel={t('subfolder')}
                        />
                    )}
                {!parentFolder &&
                    (localProject.right.passCreateFolder ||
                        userIsAdmin) && (
                        <FolderAdd
                            foldersRefetch={foldersRefetch}
                            label={t('folder')}
                            className="m-l-12"
                            headerLabel={t('folder')}
                        />
                    )}
                <hr className="m-l-15 m-r-15 m-t-15" />
            </div>
        </div>
    );
}
