import React from 'react';

import ModalTaskEdit from 'helpdesk/task/edit/modalEdit';

import { timestampToDateFNS } from 'helperFunctions';

import { useTranslation } from 'react-i18next';

export default function AgentInvoice(props) {
    const { invoice, agent, fromDate, toDate, invoiceRefetch } = props;

    const { workTasks, tripTasks, tripTypeTotals, taskTypeTotals, totals } = invoice;

    const { t } = useTranslation();

    const [editedTask, setEditedTask] = React.useState(null);

    const onClickTask = (task) => {
        setEditedTask(task);
    };

    return (
        <div className="invoice-container">
            <h2>{t('monthlyAgentInvoice')}</h2>
            <div className="flex-row m-b-30">
                <div>
                    {`${t('agent')} ${agent.fullName} (${agent.email})`}
                    <br />
                    {`${t('period')} ${t('from').toLowerCase()} ${timestampToDateFNS(fromDate)} ${t(
                        'to'
                    ).toLowerCase()} ${timestampToDateFNS(toDate)}`}
                </div>
            </div>
            <h4>{t('works')}</h4>

            <table className="table row-highlight m-l-0 m-r-0">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>{t('taskTitle')}</th>
                        <th>{t('requester')}</th>
                        <th>{t('company')}</th>
                        <th>{t('closeDate')}</th>
                        <th>{t('workDescription')}</th>
                        <th width="100">{t('hoursCount')}</th>
                    </tr>
                </thead>
                <tbody>
                    {workTasks.map((task) => (
                        <tr key={task.id}>
                            <td>{task.id}</td>
                            <td className="clickable" onClick={() => onClickTask(task)}>
                                {task.title}
                            </td>
                            <td>{task.requester.fullName}</td>
                            <td>{task.company ? task.company.title : t('noCompany')}</td>
                            <td>
                                {isNaN(parseInt(task.closeDate))
                                    ? t('notClosed')
                                    : timestampToDateFNS(parseInt(task.closeDate))}
                            </td>
                            <td colSpan="2">
                                {task.subtasks.map((subtask, index) => (
                                    <div key={index} className="row">
                                        <span key={index + '-title'}>{subtask.title}</span>
                                        <span
                                            key={index + '-time'}
                                            style={{
                                                width: '50px',
                                                marginLeft: 'auto',
                                                textAlign: 'center',
                                            }}
                                        >
                                            {subtask.quantity}
                                        </span>
                                    </div>
                                ))}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            <table key="taskTotals" className="table row-highlight max-width-500  m-l-0 m-r-0">
                <thead>
                    <tr>
                        <th>{t('taskType')}</th>
                        <th className="width-100">{t('hoursCount')}</th>
                    </tr>
                </thead>
                <tbody>
                    {taskTypeTotals.map((taskType) => (
                        <tr key={taskType.id + taskType.title}>
                            <td>{taskType.title}</td>
                            <td>{taskType.quantity}</td>
                        </tr>
                    ))}
                </tbody>
            </table>

            <p className="m-0 m-b-20">{`${t('totalHours')}: ${totals.workHours}`}</p>

            <h4>{t('trips')}</h4>

            <table className="table row-highlight  m-l-0 m-r-0">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>{t('taskTitle')}</th>
                        <th>{t('requester')}</th>
                        <th>{t('company')}</th>
                        <th>{t('closeDate')}</th>
                        <th style={{ width: '150px' }}>{t('trip')}</th>
                        <th style={{ width: '50px' }}>{t('quantityShort')}</th>
                    </tr>
                </thead>
                <tbody>
                    {tripTasks.map((task) => (
                        <tr key={task.id}>
                            <td>{task.id}</td>
                            <td className="clickable" onClick={() => onClickTask(task)}>
                                {task.title}
                            </td>
                            <td>{task.requester.fullName}</td>
                            <td>{task.company ? task.company.title : t('company')}</td>
                            <td>
                                {isNaN(parseInt(task.closeDate))
                                    ? t('notClosed')
                                    : timestampToDateFNS(parseInt(task.closeDate))}
                            </td>
                            <td colSpan="2">
                                {task.workTrips.map((trip, index) => (
                                    <div key={index} className="row">
                                        <span key={index + '-title'}>{trip.type.title}</span>
                                        <span
                                            key={index + '-time'}
                                            style={{
                                                width: '50px',
                                                marginLeft: 'auto',
                                                textAlign: 'center',
                                            }}
                                        >
                                            {trip.quantity}
                                        </span>
                                    </div>
                                ))}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            <table key="tripTotals" className="table row-highlight max-width-500 m-l-0 m-r-0">
                <thead>
                    <tr>
                        <th>{t('trip')}</th>
                        <th className="width-100">{t('pc')}</th>
                    </tr>
                </thead>
                <tbody>
                    {tripTypeTotals.map((tripType) => (
                        <tr key={tripType.id + tripType.title}>
                            <td>{tripType.title}</td>
                            <td>{tripType.quantity}</td>
                        </tr>
                    ))}
                </tbody>
            </table>

            <p className="m-0 m-b-20">{`${t('tripTotal')}: ${totals.tripHours}`}</p>

            <ModalTaskEdit
                opened={editedTask !== null}
                taskID={editedTask ? editedTask.id : null}
                closeModal={() => {
                    setEditedTask(null);
                    //refresh if changed
                    invoiceRefetch();
                }}
                fromInvoice={true}
            />
        </div>
    );
}
