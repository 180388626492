import React from 'react';

import { useNavigate, useLocation } from 'react-router-dom';
import {
    useMutation,
    useQuery,
    useSubscription,
} from '@apollo/client';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { Link } from 'react-router-dom';
import Empty from 'components/Empty';
import ErrorIcon from 'components/errorMessages/errorIcon';
import NotificationIcon from 'components/notifications/notificationIcon';
import LocalErrors from 'components/localErrors';
import classnames from 'classnames';
import { getLocation, getMyData } from 'helperFunctions';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { pickSelectStyle } from 'configs/components/select';

import UserProfile from 'helpdesk/settings/users/userProfile';

import { LOGOUT_USER } from 'components/queries';
import { defaultFilter } from 'configs/constants/filter';

import {
    dashboard,
    allWorkspaces,
    allMilestones,
} from 'configs/constants/sidebar';

import {
    toSelArr,
    translateSelectItem,
} from 'helperFunctions';

import {
    GET_MY_PROJECTS,
    PROJECTS_SUBSCRIPTION,
    GET_WORKSPACES,
    WORKSPACES_SUBSCRIPTION,
} from 'helpdesk/queries';

import {
    GET_PROJECT,
    GET_LOCAL_WORKSPACE,
} from 'apollo/queries';

import {
    setProject,
    setWorkspace,
    setMilestone,
} from 'apollo/localSchema/actions';

export default function PageHeader() {
    //data & queries
    const navigate = useNavigate();
    const location = useLocation();

    const { t } = useTranslation();

    const [logoutUser] = useMutation(LOGOUT_USER);

    const {
        data: myProjectsData,
        loading: myProjectsLoading,
        refetch: myProjectsRefetch,
    } = useQuery(GET_MY_PROJECTS, {
        fetchPolicy: 'network-only',
    });

    const {
        data: workspacesData,
        loading: workspacesLoading,
        refetch: workspacesRefetch,
    } = useQuery(GET_WORKSPACES, {
        fetchPolicy: 'network-only',
    });

    const { data: projectData } = useQuery(GET_PROJECT);

    const { data: localWorkspaceData } = useQuery(
        GET_LOCAL_WORKSPACE
    );

    useSubscription(PROJECTS_SUBSCRIPTION, {
        onData: () => {
            myProjectsRefetch();
        },
    });

    useSubscription(WORKSPACES_SUBSCRIPTION, {
        onData: () => {
            workspacesRefetch();
        },
    });

    //state
    const [modalUserProfileOpen, setModalUserProfileOpen] =
        React.useState(false);

    React.useEffect(() => {
        if (!myProjectsLoading) {
            if (myProjectsData.myProjects) {
                const currentProject =
                    myProjectsData.myProjects.find(
                        (project) =>
                            project.project.id ===
                            projectData.localProject.id
                    );
                if (currentProject) {
                    setProject({
                        ...currentProject,
                        id: currentProject.project.id,
                        value: currentProject.project.id,
                        title: currentProject.project.title,
                        label: currentProject.project.title,
                    });
                }
            }
        }
    }, [myProjectsData, myProjectsLoading]);

    React.useEffect(() => {
        if (!workspacesLoading) {
            if (workspacesData.workspaces) {
                const currentWorkspace =
                    workspacesData.workspaces.find(
                        (workspace) => {
                            workspace.id ===
                                localWorkspaceData
                                    .localWorkspace.id;
                        }
                    );
                if (currentWorkspace) {
                    setWorkspace({
                        ...currentWorkspace,
                        id: currentWorkspace.id,
                        value: currentWorkspace.id,
                        title: currentWorkspace.title,
                        label: currentWorkspace.title,
                    });
                }
            }
        }
    }, [workspacesData, workspacesLoading]);

    const currentUser = getMyData();
    const accessRights =
        currentUser && currentUser.role
            ? currentUser.role.accessRights
            : {};
    const userIsAdmin =
        currentUser && currentUser.role
            ? currentUser.role.level === 0
            : false;
    const localProject = projectData.localProject;
    const localWorkspace =
        localWorkspaceData.localWorkspace;

    const myProjects = (
        myProjectsData ? [...myProjectsData.myProjects] : []
    )
        .filter(
            (project) =>
                localWorkspace.id === null ||
                localWorkspace.id ===
                    project.project.workspace.id
        )
        .sort((project1, project2) =>
            project1.project.title > project2.project.title
                ? 1
                : -1
        );

    const projects = toSelArr(
        [dashboard, ...myProjects].map((project) => ({
            ...project,
            id: project.project.id,
            title: t(project.project.title),
        }))
    );

    const workspaces = toSelArr(
        [
            allWorkspaces,
            ...(workspacesData
                ? workspacesData.workspaces
                : []),
        ].map((workspace) => ({
            ...workspace,
            id: workspace.id,
            title: workspace.labelId
                ? t(workspace.labelId)
                : t(workspace.title),
        }))
    );

    const URL = getLocation(location);
    const URLprefix = `/helpdesk/taskList/i/${defaultFilter}`;
    const tasklistPage = ![
        '/helpdesk/repeats',
        '/helpdesk/companies',
        '/helpdesk/users',
    ].some((url) => window.location.pathname.includes(url));

    return (
        <div
            className={classnames(
                'page-header flex m-l-30'
            )}
        >
            <div className="d-flex full-height">
                <div
                    className="center-hor m-r-10 d-flex"
                    style={{ flexDirection: 'row' }}
                >
                    <div>
                        <Select
                            options={workspaces}
                            value={translateSelectItem(
                                localWorkspace,
                                t
                            )}
                            styles={pickSelectStyle([
                                'invisible',
                                'whiteFont',
                            ])}
                            onChange={(workspace) => {
                                setProject(dashboard);
                                setWorkspace(workspace);
                            }}
                        />
                    </div>

                    <div>
                        <Select
                            options={projects}
                            value={translateSelectItem(
                                localProject,
                                t
                            )}
                            styles={pickSelectStyle([
                                'invisible',
                                'whiteFont',
                            ])}
                            onChange={(pro) => {
                                setMilestone(
                                    translateSelectItem(
                                        allMilestones,
                                        t
                                    )
                                );
                                setProject(pro);
                                if (tasklistPage) {
                                    navigate(URLprefix);
                                }
                            }}
                        />
                    </div>
                </div>
                {
                    <div className="center-hor row">
                        <Link
                            to={{
                                pathname: `/helpdesk/taskList/i/${defaultFilter}`,
                            }}
                            className={
                                'header-link' +
                                (URL.includes('helpdesk') ||
                                URL.includes('invoices')
                                    ? ' header-link-active'
                                    : '')
                            }
                        >
                            {t('tasks')}
                        </Link>
                        {((accessRights.lanwiki &&
                            localProject.id !== null &&
                            localProject.right
                                .lanwikiRead) ||
                            userIsAdmin) && (
                            <Link
                                to={{
                                    pathname: `/lanwiki/i/all`,
                                }}
                                className={
                                    'header-link' +
                                    (URL.includes('lanwiki')
                                        ? ' header-link-active'
                                        : '')
                                }
                            >
                                {t('wiki')}
                            </Link>
                        )}
                        {((accessRights.cmdb &&
                            localProject.id !== null &&
                            localProject.right.cmdbRead) ||
                            userIsAdmin) && (
                            <Link
                                to={{
                                    pathname: `/cmdb/i/all`,
                                }}
                                className={
                                    'header-link' +
                                    (URL.includes('cmdb')
                                        ? ' header-link-active'
                                        : '')
                                }
                            >
                                {t('assets')}
                            </Link>
                        )}
                        {((accessRights.pass &&
                            localProject.id !== null &&
                            localProject.right.passRead) ||
                            userIsAdmin) && (
                            <Link
                                to={{
                                    pathname: `/lanpass/i/all`,
                                }}
                                className={
                                    'header-link' +
                                    (URL.includes('lanpass')
                                        ? ' header-link-active'
                                        : '')
                                }
                            >
                                {t('passwords')}
                            </Link>
                        )}
                        {accessRights.vykazy && false && (
                            <Empty>
                                <Link
                                    to={{
                                        pathname: `/reports`,
                                    }}
                                    className={
                                        'header-link' +
                                        (URL.includes(
                                            'reports'
                                        )
                                            ? ' header-link-active'
                                            : '')
                                    }
                                >
                                    {t('invoices')}
                                </Link>
                            </Empty>
                        )}
                    </div>
                }

                <div className="ml-auto center-hor row m-r-30">
                    <div
                        className="header-icon"
                        onClick={() =>
                            setModalUserProfileOpen(true)
                        }
                    >
                        <span className="material-symbols-outlined bigger m-r-5">
                            person
                        </span>
                        <span>
                            {!currentUser
                                ? `${t('loading')}...`
                                : `${currentUser.name} ${currentUser.surname}`}
                        </span>
                    </div>

                    <LocalErrors />
                    {accessRights.viewErrors && (
                        <ErrorIcon location={URL} />
                    )}

                    <NotificationIcon location={URL} />
                    <div
                        className="header-icon"
                        onClick={() => {
                            if (
                                window.confirm(
                                    t('logoutConfirmation')
                                )
                            ) {
                                logoutUser().then(() => {
                                    navigate(
                                        `/helpdesk/taskList/i/${defaultFilter}`
                                    );
                                    window.location.reload(
                                        false
                                    );
                                });
                            }
                        }}
                    >
                        <span className="material-symbols-outlined">
                            logout
                        </span>
                    </div>
                </div>
            </div>

            <Modal
                style={{ width: '800px' }}
                isOpen={modalUserProfileOpen}
            >
                <ModalHeader>
                    {t('userProfile')}
                </ModalHeader>
                <ModalBody>
                    <UserProfile
                        closeModal={() =>
                            setModalUserProfileOpen(false)
                        }
                    />
                </ModalBody>
            </Modal>
        </div>
    );
}
