import React from 'react';
import { useLazyQuery } from '@apollo/client';
import Excel from 'exceljs';
import FileSaver from 'file-saver';
import * as fns from 'date-fns';

import { formatDate } from 'helperFunctions';
import { COMPANY_INVOICE } from 'invoices/queries';

export default function CompanyExcel(props) {
    const { variables, company } = props;
    const fromDate = new Date(parseInt(variables.fromDate));
    const toDate = new Date(parseInt(variables.toDate));

    const [
        fetchInvoice,
        { data: invoiceData, loading: invoiceLoading },
    ] = useLazyQuery(COMPANY_INVOICE, {
        onCompleted: () => {
            downloadExcel(invoiceData.companyInvoice);
        },
    });

    const downloadExcel = async (invoice) => {
        const filename = `Výkaz ${
            company.title
        } ${fns.getYear(fromDate)}/${fns.getMonth(
            fromDate
        )}`;

        const h1 = {
            bold: true,
            size: 16,
        };
        const h2 = {
            bold: true,
            size: 14,
        };
        const h3 = {
            bold: true,
        };

        const ws = new Excel.Workbook();
        const sheet = ws.addWorksheet('Vykaz');
        const widthMultiplier = 5;
        sheet.columns = [
            {
                width: 11 * widthMultiplier,
            },
            {
                width: 8 * widthMultiplier,
            },
            {
                width: 4 * widthMultiplier,
            },
            {
                width: 4 * widthMultiplier,
            },
            {
                width: 4 * widthMultiplier,
            },
            {
                width: 10 * widthMultiplier,
            },
            {
                width: 4 * widthMultiplier,
            },
            {
                width: 3 * widthMultiplier,
            },
            {
                width: 3 * widthMultiplier,
            },
            {
                width: 3 * widthMultiplier,
            },
        ];

        sheet.addRows([
            ['Fakturačný výkaz firmy'],
            [`Firma ${company.title}`],
            [
                `Obdobie od ${formatDate(
                    fromDate
                )} do: ${formatDate(toDate)}`,
            ],
            [],
            ['Práce'],
            [
                'ID',
                'Názov úlohy',
                'Zadal',
                'Rieši',
                'Close date',
                'Popis práce',
                'Hodiny',
            ],
        ]);
        setMultipleCellsStyle(['A1'], h1, sheet);
        setMultipleCellsStyle(['A5'], h2, sheet);
        setLastRowStyle(
            ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I'],
            sheet,
            h3
        );

        //Prace
        invoice.workTasks.forEach((task) => {
            sheet.addRows(
                task.subtasks.map((subtask, index) => {
                    if (index === 0) {
                        return [
                            task.id,
                            task.title,
                            task.requester.fullName,
                            task.assignedTo
                                .map(
                                    (user) => user.fullName
                                )
                                .join('\n'),
                            formatDate(task.closeDate),
                            subtask.title,
                            subtask.quantity,
                        ];
                    }
                    let row = [];
                    row[6] = subtask.title;
                    row[7] = subtask.quantity;
                    return row;
                })
            );
        });
        sheet.addRow([]);
        sheet.addRow([
            'Spolu počet hodín:',
            invoice.totals.workHours,
        ]);
        setLastRowStyle(['A', 'B'], sheet, h3);
        //Koniec Prace
        sheet.addRow([]);

        //Zaciatok vyjazdov
        sheet.addRow(['Výjazdy']);
        setLastRowStyle(['A'], sheet, h1);
        sheet.addRow([
            'ID',
            'Názov úlohy',
            'Zadal',
            'Rieši',
            'Close date',
            'Výjazd',
            'Mn.',
        ]);
        setLastRowStyle(
            ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H'],
            sheet,
            h3
        );
        invoice.tripTasks.forEach((task) => {
            sheet.addRows(
                task.workTrips.map((trip, index) => {
                    if (index === 0) {
                        return [
                            task.id,
                            task.title,
                            task.requester.fullName,
                            task.assignedTo
                                .map(
                                    (user) => user.fullName
                                )
                                .join('\n'),
                            formatDate(
                                parseInt(task.closeDate)
                            ),
                            trip.type.title,
                            trip.quantity,
                        ];
                    }
                    let row = [];
                    row[6] = trip.type.title;
                    row[7] = trip.quantity;
                    return row;
                })
            );
        });
        sheet.addRow([]);
        sheet.addRow([
            'Spolu počet výjazdov:',
            invoice.totals.tripHours,
        ]);
        setLastRowStyle(['A', 'B'], sheet, h3);
        //Koniec vyjazdov
        sheet.addRow([]);

        // MATERIALE
        sheet.addRow(['Materiále']);
        setLastRowStyle(['A'], sheet, h2);
        sheet.addRow([
            'ID',
            'Názov',
            'Zadal',
            'Rieši',
            'Close date',
            'Material',
            'Mn.',
            'Cena/Mn.',
            'Cena spolu',
        ]);
        setLastRowStyle(
            [
                'A',
                'B',
                'C',
                'D',
                'E',
                'F',
                'G',
                'H',
                'I',
                'J',
                'K',
            ],
            sheet,
            h3
        );
        invoice.materialTasks.forEach((task) => {
            sheet.addRows(
                task.materials.map((material, index) => {
                    if (index === 0) {
                        return [
                            task.id,
                            task.title,
                            task.requester.fullName,
                            task.assignedTo
                                .map(
                                    (user) => user.fullName
                                )
                                .join('\n'),
                            formatDate(
                                parseInt(task.closeDate)
                            ),
                            material.title,
                            material.quantity,
                            material.price,
                            material.total,
                        ];
                    }
                    let row = [];
                    row[6] = material.title;
                    row[7] = material.quantity;
                    row[8] = material.price;
                    row[9] = material.total;
                    return row;
                })
            );
        });
        sheet.addRow([]);

        sheet.addRow([
            'Spolu cena bez DPH:',
            `${invoice.totals.materialPrice.toFixed(
                2
            )} EUR`,
        ]);
        setLastRowStyle(['A', 'B'], sheet, h3);

        sheet.addRow([
            'Spolu cena s DPH:',
            `${invoice.totals.materialPriceWithDPH.toFixed(
                2
            )} EUR`,
        ]);
        setLastRowStyle(['A', 'B'], sheet, h3);
        sheet.addRow([]);

        //STIAHNUTE
        ws.xlsx
            .writeBuffer()
            .then((buffer) =>
                FileSaver.saveAs(
                    new Blob([buffer]),
                    `${filename}.xlsx`
                )
            );
    };

    const setLastRowStyle = (range, sheet, style) => {
        range.forEach((letter) => {
            sheet.getCell(
                `${letter}${sheet.rowCount}`
            ).font = style;
        });
    };

    /*
    const mergerLastRow = (range, sheet) => {
        sheet.mergeCells(`${range[0]}${sheet.rowCount}:${range[1]}${sheet.rowCount}`);
    };
    */

    const setMultipleCellsStyle = (cells, style, sheet) => {
        cells.forEach((cell) => {
            sheet.getCell(`${cell}`).font = style;
        });
    };

    return (
        <button
            className="btn-link"
            disabled={invoiceLoading}
            onClick={() => {
                if (invoiceData && !invoiceLoading) {
                    downloadExcel(
                        invoiceData.companyInvoice
                    );
                } else {
                    fetchInvoice({ variables });
                }
            }}
        >
            Excel
        </button>
    );
}
