export default {
  allTags: {
    en: 'All tags',
    sk: 'Všetky tagy',
    cz: 'Všechny značky',
    de: 'Alle Tags',
  },
  addTag: {
    en: 'Add tag',
    sk: 'Pridať tag',
    cz: 'Přidat značku',
    de: 'Tag hinzufügen',
  },
  editTag: {
    en: 'Edit tag',
    sk: 'Upraviť tag',
    cz: 'Upravit značku',
    de: 'Tag bearbeiten',
  },
  titlePlaceholder: {
    en: 'Enter title',
    sk: 'Zadajte názov',
    cz: 'Zadejte název',
    de: 'Titel eingeben',
  },
  descriptionPlaceholder: {
    en: 'Enter description',
    sk: 'Zadajte popis',
    cz: 'Zadejte popis',
    de: 'Beschreibung eingeben',
  },
  comfirmDeletingLanwikiTag: {
    en: 'Are you sure you want to delete this tag?',
    sk: 'Ste si istý, že chcete zmazať tento tag?',
    cz: 'Opravdu chcete tuto značku smazat?',
    de: 'Möchten Sie dieses Tag wirklich löschen?',
  },
}
