import React, { useState, useEffect } from 'react';
import { timestampToStringFNS } from 'helperFunctions';
import { Modal, ModalBody } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import PostalMime from 'postal-mime';
import { toSelItem, insertImages } from 'helperFunctions';

import { REST_URL } from 'configs/restAPI';

export default function EmailRender(props) {
    const { getAttachment, comment, sendEmail } = props;

    const { t } = useTranslation();

    const [body, setBody] = useState('');
    const [html, setHtml] = useState('');
    const [modalOpen, setModalOpen] = useState(false);
    const [errorModalOpen, setErrorModalOpen] = useState(false);

    async function showOriginal() {
        fetch(
            `${REST_URL}/api/get-attachments?` +
                new URLSearchParams({
                    type: 'commentEml',
                    path: comment.emlPath,
                }),
            {
                headers: {
                    'authorization': `${sessionStorage.getItem('acctok')}`,
                },
            }
        ).then((r) => {
            r.text().then((text) => {
                const parser = new PostalMime();
                parser.parse(text).then((email) => {
                    let newHtml = email.html + '';
                    for (const attachment of email.attachments) {
                        let url = URL.createObjectURL(new Blob([attachment.content]));
                        newHtml = newHtml.replace(
                            `cid:${attachment.contentId.substring(
                                1,
                                attachment.contentId.indexOf('>')
                            )}`,
                            url
                        );
                    }
                    setBody(newHtml);
                });
            });
        });
    }
    /*
    async function insertImage(image) {
        try {
            let blob = await axios.get(
                `${REST_URL}/api/get-attachments`,
                {
                    params: {
                        type: 'comment',
                        path: image.path,
                        fronInvoice: false,
                    },
                    headers: {
                        'authorization': `${sessionStorage.getItem(
                            'acctok'
                        )}`,
                    },
                    responseType: 'blob',
                }
            );
            return URL.createObjectURL(blob.data);
        } catch (error) {
            console.log(error);
        }
    }

    async function insertImages() {
        let newHtml = comment.html + '';

        for (const image of comment.commentTextAttachments) {
            const imageSource = await insertImage(image);
            newHtml = newHtml.replace(
                `cid:${image.textId}`,
                imageSource
            );
        }

        setHtml(newHtml);
    }
*/
    useEffect(() => {
        if (comment.commentTextAttachments.length > 0) {
            insertImages(comment.html, comment.commentTextAttachments, setHtml, 'comment');
        } else {
            setHtml(
                (comment.html ? comment.html : comment.message.replace(/(?:\r\n|\r|\n)/g, '<br>'))
                    .replace(/(?:<p>)/g, '<div>')
                    .replace(/(?:<\/p>)/g, '</div>')
            );
        }
    }, [comment]);

    return (
        <div>
            <div className="media m-t-20 m-l-10 p-t-20">
                <div
                    className="m-r-10 rounded-circle thumb-sm"
                    style={{
                        backgroundColor: '#0078d7',
                        color: 'white',
                        alignItems: 'center',
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                >
                    <span>
                        {comment.user !== null
                            ? (
                                  comment.user.name.substring(0, 1) +
                                  comment.user.surname.substring(0, 1)
                              ).toUpperCase()
                            : t('unknownUser')}
                    </span>
                </div>
                <div className="flex">
                    <div className="row justify-content-sb">
                        <div>
                            <div className="row align-items0center m-b-5">
                                <h2 className="font-13 m-0 font-bold">
                                    {t('from2')}:{' '}
                                    {comment.user !== null
                                        ? `${comment.user.fullName} <${comment.user.email}>`
                                        : t('unknownUser')}
                                </h2>
                                {comment.emailError && (
                                    <button
                                        className="btn-link-red m-l-5"
                                        style={{
                                            height: 'auto',
                                        }}
                                        onClick={() => {
                                            setErrorModalOpen(true);
                                        }}
                                    >
                                        <span className="material-symbols-outlined">error</span>
                                    </button>
                                )}
                            </div>
                            {comment.tos.length !== 0 && (
                                <h2 className="font-13 m-0 font-bold">
                                    {t('to2')}: {comment.tos.toString()}
                                </h2>
                            )}
                        </div>
                        <div style={{ textAlign: 'end' }}>
                            <div className="row align-items-center">
                                <button
                                    className="btn-link m-r-10"
                                    onClick={() => {
                                        sendEmail('reply', html, [
                                            toSelItem(comment.user, 'fullName'),
                                        ]);
                                    }}
                                >
                                    {t('reply')}
                                </button>
                                <span className="media-meta m-r-10 color-muted">|</span>
                                <button
                                    className="btn-link m-r-10"
                                    onClick={() => {
                                        sendEmail('forward', html, null);
                                    }}
                                >
                                    {t('forward')}
                                </button>
                                {comment.emlPath && (
                                    <span className="media-meta m-r-10 color-muted">|</span>
                                )}
                                {comment.emlPath && (
                                    <button
                                        className="btn-link m-r-10"
                                        onClick={() => {
                                            setModalOpen(true);
                                            showOriginal();
                                        }}
                                    >
                                        {t('showOriginal')}
                                    </button>
                                )}
                            </div>
                            <span className="media-meta m-r-10 color-muted">
                                {timestampToStringFNS(comment.createdAt)}
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <div className="m-l-10 m-b-30">
                <p className="m-b-10">
                    {t('subject')}: <span className="color-muted">{comment.subject}</span>
                </p>
                <div
                    className="ignore-css"
                    dangerouslySetInnerHTML={{
                        __html: html,
                    }}
                ></div>
                {comment.commentAttachments &&
                    comment.commentAttachments.map((attachment) => (
                        <span
                            key={attachment.id}
                            className="comment-attachment link m-r-5"
                            onClick={() => getAttachment(attachment)}
                        >
                            {`${attachment.filename} (${attachment.size / 1000}kb)`}
                        </span>
                    ))}
            </div>
            <Modal isOpen={errorModalOpen}>
                <div className="row align-items-center m-t-20 m-b-20">
                    <h2>{t('errorOccured')}</h2>
                    <button
                        className="btn-link m-r-10 m-l-auto"
                        onClick={() => {
                            setErrorModalOpen(false);
                        }}
                    >
                        <span className="material-symbols-outlined bigger">close</span>
                    </button>
                </div>
                <hr style={{ color: '#a6a9ad' }} />
                <ModalBody>
                    <div className="row align-items-center m-t-10 m-b-10">
                        <span className="material-symbols-outlined m-r-5 warning-big">warning</span>
                        <span className="warning-big">{comment.emailError}</span>
                    </div>
                </ModalBody>
            </Modal>

            <Modal isOpen={modalOpen}>
                <div className="row align-items-center m-t-20 m-b-20">
                    <h2>{t('originalEmail')}</h2>
                    <button
                        className="btn-link m-r-10 m-l-auto"
                        onClick={() => {
                            setModalOpen(false);
                        }}
                    >
                        <span className="material-symbols-outlined bigger">close</span>
                    </button>
                </div>
                <hr style={{ color: '#a6a9ad' }} />
                <ModalBody>
                    <div
                        dangerouslySetInnerHTML={{
                            __html: body,
                        }}
                    ></div>
                </ModalBody>
            </Modal>
        </div>
    );
}
