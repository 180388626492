import React from 'react';

import Select from 'react-select';
import { Label } from 'reactstrap';

import { pickSelectStyle } from 'configs/components/select';

export default function TaskFormSidebarItem(props) {
    //data & queries
    const {
        label,
        required,
        showEdit,
        value,
        editValue,
        onChangeEditValue,
        placeholder,
        selectOptions,
        selectStyles,
        valueContainerStyle,
        selectIsDisabled,
        selectIsMulti,
    } = props;

    //used sidebar
    return (
        <div className="form-selects-entry-column">
            <Label>
                {label}
                {required && (
                    <span className="warning-big m-l-5">
                        *
                    </span>
                )}
            </Label>
            <div className="form-selects-entry-column-rest">
                {!showEdit && (
                    <div
                        className="disabled-info"
                        style={valueContainerStyle}
                    >
                        {value}
                    </div>
                )}
                {showEdit && (
                    <Select
                        placeholder={placeholder}
                        value={editValue}
                        onChange={onChangeEditValue}
                        options={selectOptions}
                        styles={pickSelectStyle(
                            selectStyles
                        )}
                        isDisabled={selectIsDisabled}
                        isMulti={selectIsMulti}
                    />
                )}
            </div>
        </div>
    );
}
