export const applyOccasion = [{
    label: "when task is created",
    value: "create",
    translationKey: "whenCreated",
  },
  {
    label: "when task is updated",
    value: "edit",
    translationKey: "whenEdited",
  }
]

export const newSourceOptions = [{
    label: "Task",
    value: "task",
    inEvent: {
      isSourceOption: true,
      isActionOption: false,
      conditions: [
        'created',
        'editedBy',
      ],
      actions: [],
      valueInputTypes: {
        created: null,
        editedBy: 'multiselect',
      }
    },
    inTimer: {
      isSourceOption: false,
      isActionOption: false,
      conditions: [],
      actions: [],
      valueInputTypes: {}
    },
  },
  {
    label: "Important",
    value: "important",
    inEvent: {
      isSourceOption: true,
      isActionOption: true,
      conditions: [
        'setTo',
        'unsetFrom',
      ],
      actions: [
        'setTo'
      ],
      valueInputTypes: {
        setTo: 'select',
        unsetFrom: 'select',
      }
    },
    inTimer: {
      isSourceOption: true,
      isActionOption: true,
      conditions: [
        'is',
      ],
      actions: [
        'setTo'
      ],
      valueInputTypes: {
        is: 'select',
        setTo: 'select',
      }
    }
  },
  {
    label: "Status",
    value: "status",
    inEvent: {
      isSourceOption: true,
      isActionOption: true,
      conditions: [
        'setTo',
        'unsetFrom',
        'hasAction',
      ],
      actions: [
        'setTo'
      ],
      valueInputTypes: {
        setTo: 'multiselect',
        unsetFrom: 'multiselect',
        hasAction: 'multiselect',
      }
    },
    inTimer: {
      isSourceOption: true,
      isActionOption: true,
      conditions: [
        'in',
        'notIn',
      ],
      actions: [
        'setTo'
      ],
      valueInputTypes: {
        in: 'multiselect',
        notIn: 'multiselect',
        setTo: 'select',
      },
    },
  },
  {
    label: "Tags",
    value: "tags",
    inEvent: {
      isSourceOption: true,
      isActionOption: true,
      conditions: [
        'areSetTo',
        'areUnsetFrom',
      ],
      actions: [
        'setTo',
        'add',
      ],
      valueInputTypes: {
        areSetTo: 'multiselect',
        areUnsetFrom: 'multiselect',
        setTo: 'multiselect',
        add: 'multiselect',
      }
    },
    inTimer: {
      isSourceOption: true,
      isActionOption: true,
      conditions: [
        'in',
        'notIn',
      ],
      actions: [
        'setTo',
        'add',
      ],
      valueInputTypes: {
        in: 'multiselect',
        notIn: 'multiselect',
        setTo: 'multiselect',
        add: 'multiselect',
      },
    },
  },
  {
    label: "Assigned",
    value: "assignedTo",
    inEvent: {
      isSourceOption: true,
      isActionOption: true,
      conditions: [
        'areSetTo',
        'areUnsetFrom',
      ],
      actions: [
        'setTo',
        'add',
      ],
      valueInputTypes: {
        areSetTo: 'multiselect',
        areUnsetFrom: 'multiselect',
        setTo: 'multiselect',
        add: 'multiselect',
      }
    },
    inTimer: {
      isSourceOption: true,
      isActionOption: true,
      conditions: [
        'in',
        'notIn',
      ],
      actions: [
        'setTo',
        'add',
      ],
      valueInputTypes: {
        in: 'multiselect',
        notIn: 'multiselect',
        setTo: 'multiselect',
        add: 'multiselect',
      },
    },
  },
  {
    label: "Requester",
    value: "requester",
    inEvent: {
      isSourceOption: true,
      isActionOption: true,
      conditions: [
        'setTo',
        'unsetFrom',
      ],
      actions: [
        'setTo',
      ],
      valueInputTypes: {
        setTo: 'select',
        unsetFrom: 'select',
      }
    },
    inTimer: {
      isSourceOption: true,
      isActionOption: true,
      conditions: [
        'in',
        'notIn',
      ],
      actions: [
        'setTo',
      ],
      valueInputTypes: {
        in: 'multiselect',
        notIn: 'multiselect',
        setTo: 'select',
      },
    },
  },
  {
    label: "Company",
    value: "company",
    inEvent: {
      isSourceOption: true,
      isActionOption: true,
      conditions: [
        'setTo',
        'unsetFrom',
      ],
      actions: [
        'setTo',
      ],
      valueInputTypes: {
        setTo: 'select',
        unsetFrom: 'multiselect',
      }
    },
    inTimer: {
      isSourceOption: true,
      isActionOption: true,
      conditions: [
        'in',
        'notIn',
      ],
      actions: [
        'setTo',
      ],
      valueInputTypes: {
        in: 'select',
        notIn: 'multiselect',
        setTo: 'select',
      },
    },
  },
  {
    label: "Start",
    value: "startsAt",
    inEvent: {
      isSourceOption: true,
      isActionOption: true,
      conditions: [
        'changed',
        'before',
        'after',
      ],
      actions: [
        'setTo',
      ],
      valueInputTypes: {
        changed: null,
        setTo: 'date',
        unsetFrom: 'date',
      }
    },
    inTimer: {
      isSourceOption: true,
      isActionOption: true,
      conditions: [
        'after',
      ],
      actions: [
        'setTo',
      ],
      valueInputTypes: {
        after: 'text',
        setTo: 'date',
      },
    },
  },
  {
    label: "Deadline",
    value: "deadline",
    inEvent: {
      isSourceOption: true,
      isActionOption: true,
      conditions: [
        'changed',
        'before',
        'after',
      ],
      actions: [
        'setTo',
      ],
      valueInputTypes: {
        changed: null,
        setTo: 'date',
        unsetFrom: 'date',
      }
    },
    inTimer: {
      isSourceOption: true,
      isActionOption: true,
      conditions: [
        'after',
      ],
      actions: [
        'setTo',
      ],
      valueInputTypes: {
        after: 'text',
        setTo: 'date',
      },
    },
  },
  {
    label: "Pending date",
    value: "pendingDate",
    inEvent: {
      isSourceOption: true,
      isActionOption: true,
      conditions: [
        'changed',
        'before',
        'after',
      ],
      actions: [
        'setTo',
      ],
      valueInputTypes: {
        changed: null,
        setTo: 'date',
        unsetFrom: 'date',
      }
    },
    inTimer: {
      isSourceOption: true,
      isActionOption: true,
      conditions: [
        'after',
      ],
      actions: [
        'setTo',
      ],
      valueInputTypes: {
        after: 'text',
        setTo: 'date',
      },
    },
  },
  {
    label: "Pending changeable",
    value: "pendingChangeable",
    inEvent: {
      isSourceOption: false,
      isActionOption: true,
      conditions: [],
      actions: [
        'setTo',
      ],
      valueInputTypes: {
        setTo: 'select',
      }
    },
    inTimer: {
      isSourceOption: false,
      isActionOption: true,
      conditions: [],
      actions: [
        'setTo',
      ],
      valueInputTypes: {
        setTo: 'select',
      },
    },
  },
  {
    label: "Close date",
    value: "closeDate",
    inEvent: {
      isSourceOption: true,
      isActionOption: true,
      conditions: [
        'changed',
        'before',
        'after',
      ],
      actions: [
        'setTo',
      ],
      valueInputTypes: {
        changed: null,
        setTo: 'date',
        unsetFrom: 'date',
      }
    },
    inTimer: {
      isSourceOption: true,
      isActionOption: true,
      conditions: [
        'after',
      ],
      actions: [
        'setTo',
      ],
      valueInputTypes: {
        after: 'text',
        setTo: 'date',
      },
    },
  },
  {
    label: "Updated date",
    value: "updatedAt",
    inEvent: {
      isSourceOption: false,
      isActionOption: false,
      conditions: [],
      actions: [],
      valueInputTypes: {}
    },
    inTimer: {
      isSourceOption: false,
      isActionOption: false,
      conditions: [],
      actions: [],
      valueInputTypes: {},
    },
  },
  {
    label: "Created date",
    value: "createdAt",
    inEvent: {
      isSourceOption: false,
      isActionOption: true,
      conditions: [],
      actions: [
        'setTo',
      ],
      valueInputTypes: {
        setTo: 'date',
      }
    },
    inTimer: {
      isSourceOption: false,
      isActionOption: true,
      conditions: [],
      actions: [
        'setTo',
      ],
      valueInputTypes: {
        setTo: 'date',
      },
    },
  },
  {
    label: "Status change",
    value: "statusChange",
    inEvent: {
      isSourceOption: false,
      isActionOption: false,
      conditions: [],
      actions: [],
      valueInputTypes: {}
    },
    inTimer: {
      isSourceOption: true,
      isActionOption: false,
      conditions: [
        'after',
      ],
      actions: [],
      valueInputTypes: {
        after: 'text',
      },
    },
  },
  {
    label: "Change date",
    value: "changeDate",
    inEvent: {
      isSourceOption: false,
      isActionOption: false,
      conditions: [],
      actions: [],
      valueInputTypes: {}
    },
    inTimer: {
      isSourceOption: false,
      isActionOption: false,
      conditions: [],
      actions: [],
      valueInputTypes: {},
    },
  },
  {
    label: "Title",
    value: "title",
    inEvent: {
      isSourceOption: true,
      isActionOption: false,
      conditions: [
        'changed',
      ],
      actions: [],
      valueInputTypes: {
        changed: null,
      }
    },
    inTimer: {
      isSourceOption: false,
      isActionOption: false,
      conditions: [],
      actions: [],
      valueInputTypes: {},
    },
  },
  {
    label: "Description",
    value: "description",
    inEvent: {
      isSourceOption: true,
      isActionOption: false,
      conditions: [
        'changed',
      ],
      actions: [],
      valueInputTypes: {
        changed: null,
      }
    },
    inTimer: {
      isSourceOption: false,
      isActionOption: false,
      conditions: [],
      actions: [],
      valueInputTypes: {},
    },
  },
  {
    label: "Attachment",
    value: "attachment",
    inEvent: {
      isSourceOption: true,
      isActionOption: false,
      conditions: [
        'addedBy',
        'deletedBy',
      ],
      actions: [],
      valueInputTypes: {
        addedBy: 'multiselect',
        deletedBy: 'multiselect',
      }
    },
    inTimer: {
      isSourceOption: false,
      isActionOption: false,
      conditions: [],
      actions: [],
      valueInputTypes: {},
    },
  },
  {
    label: "Subtask",
    value: "subtask",
    inEvent: {
      isSourceOption: true,
      isActionOption: false,
      conditions: [
        'setTo',
        'addedBy',
        'editedBy',
        'deletedBy',
      ],
      actions: [],
      valueInputTypes: {
        setTo: 'select',
        addedBy: 'multiselect',
        editedBy: 'multiselect',
        deletedBy: 'multiselect',
      }
    },
    inTimer: {
      isSourceOption: false,
      isActionOption: false,
      conditions: [],
      actions: [],
      valueInputTypes: {},
    },
  },
  {
    label: "Short subtask",
    value: "shortSubtask",
    inEvent: {
      isSourceOption: true,
      isActionOption: false,
      conditions: [
        'setTo',
        'addedBy',
        'editedBy',
        'deletedBy',
      ],
      actions: [],
      valueInputTypes: {
        setTo: 'select',
        addedBy: 'multiselect',
        editedBy: 'multiselect',
        deletedBy: 'multiselect',
      }
    },
    inTimer: {
      isSourceOption: false,
      isActionOption: false,
      conditions: [],
      actions: [],
      valueInputTypes: {},
    },
  },
  {
    label: "Material",
    value: "material",
    inEvent: {
      isSourceOption: true,
      isActionOption: false,
      conditions: [
        'setTo',
        'addedBy',
        'editedBy',
        'deletedBy',
      ],
      actions: [],
      valueInputTypes: {
        setTo: 'select',
        addedBy: 'multiselect',
        editedBy: 'multiselect',
        deletedBy: 'multiselect',
      }
    },
    inTimer: {
      isSourceOption: false,
      isActionOption: false,
      conditions: [],
      actions: [],
      valueInputTypes: {},
    },
  },
  {
    label: "Repeat",
    value: "repeat",
    inEvent: {
      isSourceOption: true,
      isActionOption: false,
      conditions: [
        'addedBy',
        'editedBy',
        'deletedBy',
      ],
      actions: [],
      valueInputTypes: {
        addedBy: 'multiselect',
        editedBy: 'multiselect',
        deletedBy: 'multiselect',
      }
    },
    inTimer: {
      isSourceOption: false,
      isActionOption: false,
      conditions: [],
      actions: [],
      valueInputTypes: {},
    },
  },
  {
    label: "Project",
    value: "project",
    inEvent: {
      isSourceOption: true,
      isActionOption: false,
      conditions: [
        'setTo',
      ],
      actions: [],
      valueInputTypes: {
        setTo: 'select',
      }
    },
    inTimer: {
      isSourceOption: true,
      isActionOption: false,
      conditions: [
        'in',
        'notIn',
      ],
      actions: [],
      valueInputTypes: {
        in: 'multiselect',
        notIn: 'multiselect',
      },
    },
  },
  {
    label: "Comment",
    value: "comment",
    inEvent: {
      isSourceOption: true,
      isActionOption: false,
      conditions: [
        'addedBy',
        'contains',
      ],
      actions: [],
      valueInputTypes: {
        addedBy: 'multiselect',
        contains: 'text',
      },
    },
    inTimer: {
      isSourceOption: false,
      isActionOption: false,
    },
  },
  {
    label: "Mail",
    value: "email",
    inEvent: {
      isSourceOption: false,
      isActionOption: true,
      conditions: [],
      actions: [
        'send'
      ],
      valueInputTypes: {
        send: 'multiselect',
      },
    },
    inTimer: {
      isSourceOption: false,
      isActionOption: true,
      conditions: [],
      actions: [
        'send'
      ],
      valueInputTypes: {
        send: 'multiselect',
      },
    },
  },
  {
    label: "Notification",
    value: "notification",
    inEvent: {
      isSourceOption: false,
      isActionOption: true,
      conditions: [],
      actions: [
        'send'
      ],
      valueInputTypes: {
        send: 'multiselect',
      },
    },
    inTimer: {
      isSourceOption: false,
      isActionOption: true,
      conditions: [],
      actions: [
        'send'
      ],
      valueInputTypes: {
        send: 'multiselect',
      },
    },
  }
]

export const settingsForCustomAttributes = [{
  type: "customAttributeText",
  inEvent: {
    isSourceOption: true,
    isActionOption: true,
    conditions: [
      'contains'
    ],
    actions: [
      'setTo'
    ],
    valueInputTypes: {
      contains: 'text',
      setTo: 'text',
    },
  },
  inTimer: {
    isSourceOption: true,
    isActionOption: true,
    conditions: [
      'contains'
    ],
    actions: [
      'setTo'
    ],
    valueInputTypes: {
      contains: 'text',
      setTo: 'text',
    },
  },
}, {
  type: "customAttributeTextarea",
  inEvent: {
    isSourceOption: true,
    isActionOption: true,
    conditions: [
      'contains'
    ],
    actions: [
      'setTo'
    ],
    valueInputTypes: {
      contains: 'text',
      setTo: 'text',
    },
  },
  inTimer: {
    isSourceOption: true,
    isActionOption: true,
    conditions: [
      'contains'
    ],
    actions: [
      'setTo'
    ],
    valueInputTypes: {
      contains: 'textarea',
      setTo: 'textarea',
    },
  },
}, {
  type: "customAttributeNumber",
  inEvent: {
    isSourceOption: true,
    isActionOption: true,
    conditions: [
      'numLt',
      'numGt',
      'numLtEq',
      'numGtEq',
      'numEq',
    ],
    actions: [
      'setTo'
    ],
    valueInputTypes: {
      numLt: 'number',
      numGt: 'number',
      numLtEq: 'number',
      numGtEq: 'number',
      numEq: 'number',
      setTo: 'number',
    },
  },
  inTimer: {
    isSourceOption: true,
    isActionOption: true,
    conditions: [
      'numEq',
    ],
    actions: [
      'setTo'
    ],
    valueInputTypes: {
      numEq: 'number',
      setTo: 'number',
    },
  },
}, {
  type: "customAttributeSelect",
  inEvent: {
    isSourceOption: true,
    isActionOption: true,
    conditions: [
      'setTo',
      'unsetFrom',
    ],
    actions: [
      'setTo'
    ],
    valueInputTypes: {
      setTo: 'select',
      unsetFrom: 'multiselect',
    },
  },
  inTimer: {
    isSourceOption: true,
    isActionOption: true,
    conditions: [
      'in',
      'notIn',
    ],
    actions: [
      'setTo'
    ],
    valueInputTypes: {
      in: 'multiselect',
      notIn: 'multiselect',
      setTo: 'select',
    },
  },
}, {
  type: "customAttributeMultiselect",
  inEvent: {
    isSourceOption: true,
    isActionOption: true,
    conditions: [
      'setTo',
      'unsetFrom',
    ],
    actions: [
      'setTo',
      'add'
    ],
    valueInputTypes: {
      setTo: 'multiselect',
      unsetFrom: 'multiselect',
      add: 'multiselect',
    },
  },
  inTimer: {
    isSourceOption: true,
    isActionOption: true,
    conditions: [
      'in',
      'notIn',
    ],
    actions: [
      'setTo',
      'add'
    ],
    valueInputTypes: {
      in: 'multiselect',
      notIn: 'multiselect',
      setTo: 'multiselect',
      add: 'multiselect',
    },
  },
}]

export const conditionOptions = [{
    label: "is set to",
    value: "setTo",
    translationKey: "isSetTo",
  },
  {
    label: "is unset from",
    value: "unsetFrom",
    translationKey: "isUnsetFrom",
  },
  {
    label: "are set to",
    value: "areSetTo",
    translationKey: "areSetTo",
  },
  {
    label: "are unset from",
    value: "areUnsetFrom",
    translationKey: "areUnsetFrom",
  },
  {
    label: "is",
    value: "is",
    translationKey: "is",
  },
  {
    label: "is not",
    value: "isNot",
    translationKey: "isNot",
  },
  {
    label: "are",
    value: "are",
    translationKey: "are",
  },
  {
    label: "are not",
    value: "areNot",
    translationKey: "areNot",
  },
  {
    label: "is created",
    value: "created",
    translationKey: "isCreated",
  },
  {
    label: "is changed",
    value: "changed",
    translationKey: "isChanged",
  },
  {
    label: "is added by",
    value: "addedBy",
    translationKey: "isAddedBy",
  },
  {
    label: "is updated by",
    value: "editedBy",
    translationKey: "isEditedBy",
  },
  {
    label: "was updated by",
    value: "wasUpdatedBy",
    translationKey: "wasUpdatedBy",
  },
  {
    label: "is deleted by",
    value: "deletedBy",
    translationKey: "isDeletedBy",
  },
  {
    label: "has action",
    value: "hasAction",
    translationKey: "hasAction",
  },
  {
    label: "is before",
    value: "before",
    translationKey: "before",
  },
  {
    label: "is after",
    value: "after",
    translationKey: "after",
  },
  {
    label: "in",
    value: "in",
    translationKey: "in",
  },
  {
    label: "not in",
    value: "notIn",
    translationKey: "notIn",
  },
  {
    label: "contains",
    value: "contains",
    translationKey: "contains",
  },
  {
    label: "contain",
    value: "contain",
    translationKey: "contain",
  },
  {
    label: "does not contain",
    value: "notContains",
    translationKey: "notContains",
  },
  {
    label: "do not contain",
    value: "notContain",
    translationKey: "notContain",
  },
  {
    label: "is less than",
    value: "numLt",
    translationKey: "numLt",
  },
  {
    label: "is greater than",
    value: "numGt",
    translationKey: "numGt",
  },
  {
    label: "is equal to",
    value: "numEq",
    translationKey: "numEq",
  },
  {
    label: "is greater or equal to",
    value: "numGtEq",
    translationKey: "numGtEq",
  },
  {
    label: "is less or equal to",
    value: "numLtEq",
    translationKey: "numLtEq",
  }
]

export const actionOptions = [{
  label: "set to",
  value: "setTo",
  translationKey: "setTo",
}, {
  label: "add",
  value: "add",
  translationKey: "addOption",
}, {
  label: "send to",
  value: "send",
  translationKey: "sendOption",
}, ]

export const importantOptions = [{
    label: "Important",
    value: "important",
    translationKey: "important",
  },
  {
    label: "Normal",
    value: "normal",
    translationKey: "normal",
  }
];

export const completionOptions = [{
    label: "Done",
    value: "done",
    translationKey: "done",
  },
  {
    label: "Open",
    value: "open",
    translationKey: "open",
  }
];

export const requesterOption = {
  label: "Requester",
  value: "requester",
  translationKey: "requester",
};

export const requesterCompanyOption = {
  label: "Requester's company",
  value: "requesterCompany",
  translationKey: "requesterCompany",
};

export const creatorCompanyOption = {
  label: "Task creator's company",
  value: "creatorCompany",
  translationKey: "creatorCompany",
};

export const anyOption = {
  label: "Any",
  value: "any",
  translationKey: "any",
};

export const assignedOption = {
  label: "Assigned user",
  value: "assignedTo",
  translationKey: "assignedTo",
};

export const emptyOption = {
  label: "Empty",
  value: "empty",
  translationKey: "empty",
};

export const currentUserOption = {
  label: "Current user",
  value: "currentUser",
  translationKey: "currentUser",
};

export const creatorOption = {
  label: "Task creator",
  value: "creator",
  translationKey: "creator",
};

export const boolOptions = [{
  label: "True",
  value: true,
  translationKey: "true",
}, {
  label: "False",
  value: false,
  translationKey: "false",
}];

export const overdueOption = {
  label: "Overdue",
  value: "overdue",
  translationKey: "overdue",
};

export const currentProjectOption = {
  label: "Current project",
  value: "currentProject",
  translationKey: "currentProject",
}

export const applyTimeOccasions = [{
  label: "00:00",
  value: "00",
}, {
  label: "01:00",
  value: "01",
}, {
  label: "02:00",
  value: "02",
}, {
  label: "03:00",
  value: "03",
}, {
  label: "04:00",
  value: "04",
}, {
  label: "05:00",
  value: "05",
}, {
  label: "06:00",
  value: "06",
}, {
  label: "07:00",
  value: "07",
}, {
  label: "08:00",
  value: "08",
}, {
  label: "09:00",
  value: "09",
}, {
  label: "10:00",
  value: "10",
}, {
  label: "11:00",
  value: "11",
}, {
  label: "12:00",
  value: "12",
}, {
  label: "13:00",
  value: "13",
}, {
  label: "14:00",
  value: "14",
}, {
  label: "15:00",
  value: "15",
}, {
  label: "16:00",
  value: "16",
}, {
  label: "17:00",
  value: "17",
}, {
  label: "18:00",
  value: "18",
}, {
  label: "19:00",
  value: "19",
}, {
  label: "20:00",
  value: "20",
}, {
  label: "21:00",
  value: "21",
}, {
  label: "22:00",
  value: "22",
}, {
  label: "23:00",
  value: "23",
}, ];