export default {
    globalLabel: {
        en: 'Global (shown in every project)',
        sk: 'Globálny (zobrazený v každom projekte)',
        cz: 'Globální (zobrazeno v každém projektu)',
        de: 'Global (wird in jedem Projekt angezeigt)',
    },
    noProjectOnlyDashboard: {
        en: 'All projects',
        sk: 'Všetky projekty',
        cz: 'Všechny projekty',
        de: 'Alle Projekte',
    },
    filterTitle: {
        en: 'Filter name',
        sk: 'Názov filtra',
        cz: 'Název filtru',
        de: 'Filtername',
    },
    filterTitlePlaceholder: {
        en: 'Enter filter name',
        sk: 'Zadajte názov filtra',
        cz: 'Zadejte název filtru',
        de: 'Geben Sie den Filternamen ein',
    },
    selectTagsForFilter: {
        en: 'Select tags for this filter',
        sk: 'Vyberte tagy pre filter',
        cz: 'Vyberte značky pro tento filtr',
        de: 'Tags für diesen Filter auswählen',
    },
    selectStatusesForFilter: {
        en: 'Select statuses for this filter',
        sk: 'Vyberte statusy pre filter',
        cz: 'Vyberte stavy pro tento filtr',
        de: 'Wählen Sie Status für diesen Filter aus',
    },
    addFilterToProject: {
        en: 'Add filter to project',
        sk: 'Pridať filter do projektu',
        cz: 'Filter zum Projekt hinzufügen',
        de: 'Přidat filtr do projektu',
    },
    addGeneralFilter: {
        en: 'Add general filter',
        sk: 'Pridať filter',
        cz: 'Přidejte obecný filtr',
        de: 'Allgemeiner Filter hinzufügen',
    },
    publicFilter: {
        en: 'Public (everyone see this filter)',
        sk: 'Verejný (každý vidí tento filter)',
        cz: 'Veřejné (tento filtr vidí všichni)',
        de: 'Öffentlich (jeder sieht diesen Filter)',
    },
    dashboard: {
        en: 'Shown in all tasks',
        sk: 'Vo všetkých úlohách',
        cz: 'Zobrazeno ve všech úkolech',
        de: 'Wird in allen Aufgaben angezeigt',
    },
    saveFilter: {
        en: 'Save filter',
        sk: 'Uložiť filter',
        cz: 'Uložit filtr',
        de: 'Filter speichern',
    },
    addFilter: {
        en: 'Add filter',
        sk: 'Pridať filter',
        cz: 'Přidat filtr',
        de: 'Filter hinzufügen',
    },
    calendarUsers: {
        en: 'Calendar users',
        sk: 'Calendároví používatelia',
        cz: 'Uživatelé kalendáře',
        de: 'Kalenderbenutzer',
    },
    repetitiveTasks: {
        en: 'Repetitive tasks',
        sk: 'Opakované úlohy',
        cz: 'Opakující se úkoly',
        de: 'Wiederkehrende Aufgaben',
    },
    filters: {
        en: 'Filters',
        sk: 'Filtre',
        cz: 'Filtry',
        de: 'Filter',
    },
    allProjects: {
        en: 'All projects',
        sk: 'Všetky projekty',
        cz: 'Všechny projekty',
        de: 'Alle Projekte',
    },

    appLogInData: {
        en: 'Using mobile phone application',
        sk: 'Aplikácia pre mobilný telefón',
        cz: 'Aplikace pro mobilní telefony',
        de: 'Die Handy-App',
    },
    appLogInInfo: {
        en: 'Before you can log in to our mobile phone application you will be asked to provide your Lantask prefix and a port onto which the application will connect. The information you need to fill in are as follows:',
        sk: 'Predtým, ako sa budete môcť prihlásiť do našej aplikácie pre mobilné telefóny, budete požiadaní o zadanie Lantask prefixu a portu, ku ktorému sa aplikácia pripojí. Údaje, ktoré musíte vyplniť, sú nasledovné:',
        cz: 'Než se budete moci přihlásit do naší aplikace pro mobilní telefony, budete požádáni o zadání Lantask prefix a portu, ke kterému se aplikace připojí. Údaje, které musíte vyplnit, jsou následující:',
        de: 'Bevor Sie sich bei unserer Mobiltelefonanwendung anmelden können, werden Sie aufgefordert, Ihr Lantask-Präfix und einen Port anzugeben, über den die Anwendung eine Verbindung herstellt. Die Informationen, die Sie ausfüllen müssen, lauten wie folgt:',
    },
    appPrefix: {
        en: 'Prefix',
        sk: 'Prefix',
        cz: 'Prefix',
        de: 'Prefix',
    },
    appPort: {
        en: 'Port',
        sk: 'Port',
        cz: 'Port',
        de: 'Port',
    },
    workspace: {
        en: 'Workspace',
        sk: 'Pracovný priestor',
        cz: 'Pracovní prostor',
        de: 'Arbeitsplatz',
    },
    editWorkspace: {
        en: 'Edit workspace',
        sk: 'Upraviť pracovný priestor',
        cz: 'Upravit pracovní prostor',
        de: 'Arbeitsbereich bearbeiten',
    },
    workspaces: {
        en: 'Workspaces',
        sk: 'Pracovné priestory',
        cz: 'Pracovní prostory',
        de: 'Arbeitsbereiche',
    },
    allWorkspaces: {
        en: 'All workspaces',
        sk: 'Všetky pracovné priestory',
        cz: 'Všechny pracovní prostory',
        de: 'Alle Arbeitsbereiche',
    },
    workspaceTitle: {
        en: 'Workspace title',
        sk: 'Názov pracovného priestoru',
        cz: 'Název pracovního prostoru',
        de: 'Titel des Arbeitsbereichs',
    },
    workspaceDescription: {
        en: 'Workspace description',
        sk: 'Popis pracovného priestoru',
        cz: 'Popis pracovního prostoru',
        de: 'Beschreibung des Arbeitsbereichs',
    },
};
