import React from 'react';
import { useQuery, useSubscription } from '@apollo/client';
import Loading from 'components/loading';
import { GET_REPEATS, REPEATS_SUBSCRIPTION } from 'helpdesk/queries';
import { CMDB_SIDEBAR_COMPANY } from 'apollo/queries';

import RepeatList from 'helpdesk/components/repeat/repeatsList';

export default function RepeatListLoader() {
    // state
    const { data: companyData } = useQuery(CMDB_SIDEBAR_COMPANY);
    const companyId =
        companyData.cmdbSidebarCompany.id === null ? undefined : companyData.cmdbSidebarCompany.id;

    const {
        data: repeatsData,
        loading: repeatsLoading,
        refetch: repeatsRefetch,
    } = useQuery(GET_REPEATS, {
        variables: {
            companyId,
        },
        fetchPolicy: 'network-only',
    });

    useSubscription(REPEATS_SUBSCRIPTION, {
        onData: () => {
            repeatsRefetch();
        },
    });

    if (repeatsLoading) {
        return <Loading />;
    }

    return <RepeatList repeatsData={repeatsData} projectSelected={false} />;
}
