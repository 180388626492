export default {
  passwords: {
    en: 'Passwords',
    sk: 'Heslá',
    cz: 'Heslá',
    de: 'Passwords',
  },
  noLogin: {
    en: 'No login',
    sk: 'Bez prihlásenia',
    cz: 'Bez přhlásení',
    de: 'Keine Anmeldung',
  },
  cmdbPasswordMissingTitle: {
    en: 'Password entry is missing title',
    sk: 'Záznam hesla nemá žiadny názov',
    cz: 'V záznamu hesla chybí název',
    de: 'Bei der Kennworteingabe fehlt der Titel',
  },
  cmdbPasswordMissingLogin: {
    en: 'Password entry is without any login',
    sk: 'Záznam hesla nemá žiadne prihlasovacie meno',
    cz: 'V záznamu hesla chybí název',
    de: 'Die Passworteingabe erfolgt ohne Anmeldung',
  },
  cmdbPasswordMissingPassword: {
    en: 'Password entry is missing password',
    sk: 'Záznam hesla nemá žiadne heslo',
    cz: 'V záznamu hesla chybí uživatelské jméno',
    de: 'Passworteingabe fehlt Passwort',
  },
  cmdbPasswordPasswordCheckFailed: {
    en: 'Entered password and password check are not same',
    sk: 'Zadané heslo a kontrola hesla nie sú rovnaké',
    cz: 'Zadané heslo a kontrola hesla nejsou stejné',
    de: 'Eingegebenes Passwort und Passwortprüfung sind nicht identisch',
  },
  comfirmDeletingCMDBPassword: {
    en: 'Are you sure you want to delete this CMDB password?',
    sk: 'Ste si istý, že chcete zmazať toto heslo?',
    cz: 'Opravdu chcete smazat toto CMDB heslo?',
    de: 'Möchten Sie dieses CMDB-Passwort wirklich löschen?',
  },
  noPasswords: {
    en: 'No passwords',
    sk: 'Bez hesiel',
    cz: 'Žádná hesla',
    de: 'Keine Passwörter',
  },
  url: {
    en: 'URL',
    sk: 'URL',
    cz: 'URL',
    de: 'URL',
  },
  noUrl: {
    en: 'No URL',
    sk: 'Bez URL',
    cz: 'Žádné URL',
    de: 'Keine URL',
  },
  urlPlaceholder: {
    en: 'Enter URL',
    sk: 'Zadajte URL',
    cz: 'Zadejte URL',
    de: 'URL eingeben',
  },
  hide: {
    en: 'Hide',
    sk: 'Schovať',
    cz: 'Skrýt',
    de: 'Ausblenden',
  },
  enterNote: {
    en: 'Enter note',
    sk: 'Zadajte poznámku',
    cz: 'Zadejte poznámku',
    de: 'Notiz eingeben',
  },
  passwordCheck: {
    en: 'Password check',
    sk: 'Kontrola hesla',
    cz: 'Kontrola hesla',
    de: 'Passwortüberprüfung',
  },

  passwordCheckPlaceholder: {
    en: 'Enter password again',
    sk: 'Zadajte znova heslo',
    cz: 'Zadejte znova heslo',
    de: 'Passwort erneut eingeben',
  },
  login2: {
    en: 'Login',
    sk: 'Prihlásenie',
    cz: 'Přihlásení',
    de: 'Anmeldung',
  },
  login2Placeholder: {
    en: 'Enter login',
    sk: 'Zadajte prihlásovacie údaje',
    cz: 'Zadejte přihlašovací jméno',
    de: 'Login eingeben',
  },
  noPassword: {
    en: 'No password',
    sk: 'Bez hesla',
    cz: 'Bez hesla',
    de: 'Kein Passwort',
  },
}
