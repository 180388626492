import React from 'react';
import Checkbox from 'components/checkbox';

export default function ShortSubtasksPrint( props ) {

  const {
    task,
    t
  } = props;

  const {
    shortSubtasks
  } = task;

  const columns = [
    {
      label: null,
      key: 'done',
    },{
      label: t('title'),
      key: 'title',
    } ];

    if (shortSubtasks.length === 0){
      return null;
    }

  return (
    <div>
      <label>{t('shortSubtasks')}</label>
    <table>
      <thead>
        <tr>
        {
          columns.map((column) => (
            <th key={column.key}>{column.label}</th>
          ))
        }
      </tr>
      </thead>
      <tbody>
        {
          shortSubtasks.map((shortSubtask) => {
            return (
              <tr key={shortSubtask.id}>
                {
                  columns.map((column) => {
                    if (column.key === "done"){
                      return (
                        <td key={column.key}>
                          <Checkbox
                            readOnly={true}
                            className="m-t-5"
                            value={ shortSubtask.done }
                            />
                        </td>
                      )
                    }
                    return (<td key={column.key}>{shortSubtask[column.key]}</td>)
                  })
                }
                </tr>
            )
          })
        }
      </tbody>
    </table>
  </div>
  )

}
