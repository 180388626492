export default {
    allFolders: {
        en: 'All folders',
        sk: 'Všetky priečinky',
        cz: 'Všechny složky',
        de: 'Alle Ordner',
    },
    folder: {
        en: 'Folder',
        sk: 'Priečinok',
        cz: 'Složka',
        de: 'Mappe',
    },
    subfolder: {
        en: 'Subfolder',
        sk: 'Podpriečinok',
        cz: 'Podsložka',
        de: 'Unterordner',
    },
    folders: {
        en: 'Folders',
        sk: 'Priečinky',
        cz: 'Složky',
        de: 'Ordner',
    },
    subfolders: {
        en: 'Subfolders',
        sk: 'Podpriečinky',
        cz: 'Podsložky',
        de: 'Unterordner',
    },
    selectSubfolder: {
        en: 'Select subfolder',
        sk: 'Zvoľte oodriečinok',
        cz: 'Vyberte podsložku',
        de: 'Unterordner auswählen',
    },
    userRights: {
        en: 'User rights',
        sk: 'Práva používateľov',
        cz: 'Uživatelská práva',
        de: 'Nutzerrechte',
    },
    write: {
        en: 'Write',
        sk: 'Upravovať',
        cz: 'Napsat',
        de: 'Schreiben',
    },
    manage: {
        en: 'Manage',
        sk: 'Spravovať',
        cz: 'Spravovat',
        de: 'Verwalten',
    },
    replacement: {
        en: 'Replacement',
        sk: 'Náhrada',
        cz: 'Výměna, nahrazení',
        de: 'Ersatz',
    },
};
