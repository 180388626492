import React from 'react';
import ReactToPrint from 'react-to-print';
import AgentInvoice from '../agentInvoice';

export default function AgentPrint(props) {
    const { filterData, agent } = props;

    const toPrintRef = React.useRef();
    const buttonRef = React.useRef();
    return (
        <div className="display-inline">
            <button
                className="btn-link btn-distance"
                onClick={() => {
                    buttonRef.current.click();
                }}
            >
                PDF
            </button>
            <ReactToPrint
                trigger={() => (
                    <button
                        className="btn-link waves-effect"
                        ref={buttonRef}
                        style={{ display: 'none' }}
                    />
                )}
                content={() => toPrintRef.current}
            />
            <div style={{ display: 'none' }}>
                <div ref={toPrintRef} className="p-20">
                    <style type="text/css" media="print">
                        {'@page { size: landscape; }'}
                    </style>
                    <AgentInvoice agent={agent} filterData={filterData} />
                </div>
            </div>
        </div>
    );
}
