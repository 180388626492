import React from 'react';

import { timestampToStringFNS } from 'helperFunctions';

import Checkbox from 'components/checkbox';
import { useTranslation } from 'react-i18next';

export default function ReportsTable(props) {
    const { tasks, columnsToShow, onClickTask } = props;

    const { t } = useTranslation();

    const displayTrips = columnsToShow.includes('tripType') && columnsToShow.includes('quantity');

    const displayWorks = columnsToShow.includes('taskType') && columnsToShow.includes('quantity');

    const displayMaterials =
        columnsToShow.includes('material') &&
        columnsToShow.includes('quantity') &&
        columnsToShow.includes('pricePerQuantity') &&
        columnsToShow.includes('total');

    return (
        <table className="table row-highlight m-l-0 m-r-0">
            <thead>
                <tr>
                    {columnsToShow.includes('checkbox') && <th width="30" />}
                    {columnsToShow.includes('id') && <th>ID</th>}
                    {columnsToShow.includes('title') && <th>{t('taskTitle')}</th>}
                    {columnsToShow.includes('requester') && <th>{t('requester')}</th>}
                    {columnsToShow.includes('assignedTo') && <th>{t('assignedTo')}</th>}
                    {columnsToShow.includes('status') && <th>{t('status')}</th>}
                    {columnsToShow.includes('statusChange') && <th>{t('statusDate')}</th>}
                    {columnsToShow.includes('closeDate') && <th>{t('closeDate')}</th>}
                    {columnsToShow.includes('description') && <th>{t('description')}</th>}
                    {columnsToShow.includes('taskType') && (
                        <th style={{ width: '200px' }}>{t('works')}</th>
                    )}
                    {columnsToShow.includes('tripType') && (
                        <th style={{ width: '200px' }}>{t('trips')}</th>
                    )}
                    {columnsToShow.includes('hours') && (
                        <th style={{ width: '50px' }}>{t('hours')}</th>
                    )}
                    {columnsToShow.includes('material') && (
                        <th
                            style={{
                                width: '150px',
                                paddingLeft: 0,
                            }}
                        >
                            {t('materials')}
                        </th>
                    )}
                    {columnsToShow.includes('quantity') && (
                        <th style={{ width: '50px' }}>{t('quantityShort')}</th>
                    )}
                    {columnsToShow.includes('unit') && (
                        <th style={{ width: '100px' }}>{t('unit')}</th>
                    )}
                    {columnsToShow.includes('pricePerHour') && (
                        <th style={{ width: '70px' }}>{t('pricePerHour')}</th>
                    )}
                    {columnsToShow.includes('pricePerQuantity') && (
                        <th style={{ width: '100px' }}>{t('pricePerQuantity')}</th>
                    )}
                    {columnsToShow.includes('pricePerUnit') && (
                        <th style={{ width: '50px' }}>{t('pricePerUnit')}</th>
                    )}
                    {columnsToShow.includes('total') && (
                        <th style={{ width: '100px' }}>{t('totalPrice')}</th>
                    )}
                </tr>
            </thead>
            <tbody>
                {tasks.map((task) => (
                    <tr key={task.id}>
                        {columnsToShow.includes('checkbox') && (
                            <Checkbox
                                className="m-l-5 m-r-5 m-t-7"
                                value={[].includes(task.id)}
                                onChange={() => {}}
                            />
                        )}
                        {columnsToShow.includes('id') && <td>{task.id}</td>}

                        {columnsToShow.includes('title') && (
                            <td
                                className="clickable"
                                style={{ color: '#1976d2' }}
                                onClick={() => onClickTask(task)}
                            >
                                {task.title}
                            </td>
                        )}

                        {columnsToShow.includes('requester') && (
                            <td>{task.requester ? task.requester.fullName : 'Nikto'}</td>
                        )}

                        {columnsToShow.includes('assignedTo') && (
                            <td>
                                {task.assignedTo.map((user) => (
                                    <p key={user.id}>{user.fullName}</p>
                                ))}
                            </td>
                        )}
                        {columnsToShow.includes('status') && (
                            <td>
                                <span
                                    className="label label-info"
                                    style={{
                                        backgroundColor:
                                            task.status && task.status.color
                                                ? task.status.color
                                                : 'white',
                                    }}
                                >
                                    {task.status ? task.status.title : 'Neznámy status'}
                                </span>
                            </td>
                        )}
                        {columnsToShow.includes('statusChange') && (
                            <td>{timestampToStringFNS(task.statusChange)}</td>
                        )}
                        {columnsToShow.includes('closeDate') && (
                            <td>{timestampToStringFNS(task.closeDate)}</td>
                        )}

                        {displayWorks && (
                            <td colSpan="2">
                                {task.subtasks.map((subtask, index) => (
                                    <div key={index} className="row">
                                        <span
                                            key={index + '-title'}
                                            style={{
                                                paddingLeft: 0,
                                            }}
                                        >
                                            {subtask.title}
                                        </span>
                                        <span
                                            key={index + '-time'}
                                            style={{
                                                width: '50px',
                                                marginLeft: 'auto',
                                                textAlign: 'center',
                                            }}
                                        >
                                            {subtask.quantity}
                                        </span>
                                    </div>
                                ))}
                            </td>
                        )}

                        {displayTrips && (
                            <td colSpan="2">
                                {task.workTrips.map((trip, index) => (
                                    <div key={index} className="row">
                                        <span
                                            key={index + '-title'}
                                            style={{
                                                paddingLeft: 0,
                                            }}
                                        >
                                            {trip.type.title}
                                        </span>
                                        <span
                                            key={index + '-time'}
                                            style={{
                                                width: '50px',
                                                marginLeft: 'auto',
                                                textAlign: 'center',
                                            }}
                                        >
                                            {trip.quantity}
                                        </span>
                                    </div>
                                ))}
                            </td>
                        )}

                        {displayMaterials && (
                            <td colSpan="4">
                                {task.materials.map((material, index) => (
                                    <div key={index} className="row">
                                        <span
                                            key={index + '-title'}
                                            style={{
                                                width: '150px',
                                                paddingLeft: 0,
                                            }}
                                        >
                                            {material.title}
                                        </span>
                                        <span
                                            key={index + '-quantity'}
                                            style={{
                                                width: '50px',
                                            }}
                                        >
                                            {`${material.quantity} ${t('pc')}`}
                                        </span>
                                        <span
                                            key={index + '-unitPrice'}
                                            style={{
                                                width: '100px',
                                            }}
                                        >
                                            {material.price + ' €'}
                                        </span>
                                        <span key={index + '-totalPrice'}>
                                            {parseFloat(material.total).toFixed(2) + ' €'}
                                        </span>
                                    </div>
                                ))}
                            </td>
                        )}
                    </tr>
                ))}
            </tbody>
        </table>
    );
}
