import { gql } from '@apollo/client';

export const GET_WEBCHAT_TOKEN = gql`
    query webchatToken($forceNew: Boolean!) {
        webchatToken(forceNew: $forceNew)
    }
`;

export const GET_CONVERSATION = gql`
    query conversation($id: Int!) {
        conversation(id: $id) {
            active
            read
            email
            fullName
            messages {
                id
                createdAt
                fromCustomer
                read
                message
                user {
                    id
                    fullName
                }
            }
        }
    }
`;

export const GET_CONVERSATIONS = gql`
    query {
        conversations {
            id
            createdAt
            active
            read
            email
            fullName
            unreadMessages
            initialComment
        }
    }
`;

export const SET_CONVERSATION_READ = gql`
    mutation setWebchatConversationRead($id: Int!) {
        setWebchatConversationRead(id: $id) {
            active
            read
            email
            fullName
            webchatId
            messages {
                id
                createdAt
                fromCustomer
                read
                message
                user {
                    id
                    fullName
                }
            }
        }
    }
`;

export const SET_CONVERSATION_STATUS = gql`
    mutation setWebchatConversationStatus(
        $id: Int!
        $active: Boolean!
    ) {
        setWebchatConversationStatus(
            id: $id
            active: $active
        ) {
            active
            read
            email
            fullName
            webchatId
            messages {
                id
                createdAt
                fromCustomer
                read
                message
                user {
                    id
                    fullName
                }
            }
        }
    }
`;

export const ADD_WEBCHAT_MESSAGE = gql`
    mutation addWebchatMessage(
        $message: String!
        $conversationId: Int!
    ) {
        addWebchatMessage(
            message: $message
            conversationId: $conversationId
        ) {
            id
            createdAt
            fromCustomer
            read
            message
            user {
                id
                fullName
            }
        }
    }
`;

export const MESSAGES_SUBSCRIPTION = gql`
    subscription webchatMessagesSubscription($id: Int) {
        webchatMessagesSubscription(id: $id) {
            id
            createdAt
            fromCustomer
            read
            message
            user {
                id
                fullName
            }
        }
    }
`;

export const CONVERSATIONS_SUBSCRIPTION = gql`
    subscription webchatConversationsSubscription {
        webchatConversationsSubscription
    }
`;
