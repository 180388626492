import React from 'react';
import { useMutation, useQuery } from '@apollo/client';
import Loading from 'components/loading';
import FolderAdd from './add';
import { getMyData } from 'helperFunctions';
import {
    GET_PASS_USERS,
    ADD_FOLDER,
} from 'lanpass/queries';
import { GET_PROJECT } from 'apollo/queries';

export default function AddFolderLoader(props) {
    const { parentFolder } = props;

    const currentUser = getMyData();

    const { data: projectData } = useQuery(GET_PROJECT);
    const localProject = projectData.localProject;

    const { data: usersData, loading: usersLoading } =
        useQuery(GET_PASS_USERS, {
            variables: {
                projectId: localProject.project.id,
            },
            fetchPolicy: 'network-only',
        });
    const [addFolder] = useMutation(ADD_FOLDER);

    if (usersLoading) {
        return <Loading />;
    }

    return (
        <FolderAdd
            {...props}
            addFolder={addFolder}
            users={usersData.passUsers.filter(
                (user) =>
                    user.role.accessRights.pass &&
                    (parentFolder
                        ? parentFolder.folderRights.find(
                              (userRight) =>
                                  userRight.user.id ===
                                  user.id
                          )
                        : true)
            )}
            currentUser={currentUser}
            projectId={localProject.project.id}
        />
    );
}
