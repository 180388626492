import React from 'react';
import { useMutation, useQuery } from '@apollo/client';
import Loading from 'components/loading';
import FolderEdit from './edit';
import { getMyData } from 'helperFunctions';
import {
    GET_PASS_USERS,
    GET_FOLDER,
    UPDATE_FOLDER,
    DELETE_FOLDER,
} from 'lanpass/queries';

import { GET_PROJECT } from 'apollo/queries';

export default function EditFolderLoader(props) {
    const { id, folders, parentFolder, subfolders } = props;
    const currentUser = getMyData();

    const { data: projectData } = useQuery(GET_PROJECT);
    const localProject = projectData.localProject;

    const { data: usersData, loading: usersLoading } =
        useQuery(GET_PASS_USERS, {
            variables: {
                projectId: localProject.project.id,
            },
            fetchPolicy: 'network-only',
        });

    const { data: folderData, loading: folderLoading } =
        useQuery(GET_FOLDER, {
            variables: {
                id,
            },
            fetchPolicy: 'network-only',
        });

    const [updateFolder] = useMutation(UPDATE_FOLDER);
    const [deleteFolder] = useMutation(DELETE_FOLDER);

    if (usersLoading || folderLoading) {
        return <Loading />;
    }

    return (
        <FolderEdit
            {...props}
            updateFolder={updateFolder}
            deleteFolder={deleteFolder}
            users={usersData.passUsers.filter(
                (user) =>
                    user.role.accessRights.pass &&
                    (parentFolder
                        ? parentFolder.folderRights.find(
                              (userRight) =>
                                  userRight.user.id ===
                                  user.id
                          )
                        : true)
            )}
            folder={folderData.passFolder}
            folders={folders}
            subfolders={subfolders}
            currentUser={currentUser}
        />
    );
}
