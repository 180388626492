export default {
  manual: {
    en: 'Manual',
    sk: 'Návod',
    cz: 'Příručka',
    de: 'Handbuch',
  },
  manuals: {
    en: 'Manuals',
    sk: 'Návody',
    cz: 'Příručky',
    de: 'Anleitungen',
  },
  cmdbManualMissingContent: {
    en: 'Manual is without any content',
    sk: 'Návod nemá žiadny obsah',
    cz: 'Návod je bez obsahu',
    de: 'Handbuch ist ohne Inhalt',
  },
  comfirmDeletingCMDBManual: {
    en: 'Are you sure you want to delete this CMDB manual?',
    sk: 'Ste si istý, že chcete zmazať tento návod?',
    cz: 'Opravdu chcete smazat tuto CMDB příručku?',
    de: 'Möchten Sie dieses CMDB-Handbuch wirklich löschen?',
  },
  changedBy: {
    en: 'Changed by',
    sk: 'Zmenil',
    cz: 'Změnil',
    de: 'Verändert von',
  },
  changedAt: {
    en: 'Changed at',
    sk: 'Upravené',
    cz: 'Změněno v',
    de: 'Geändert um',
  },
  noManuals: {
    en: 'No manuals',
    sk: 'Bez návodov',
    cz: 'Bez příručky',
    de: 'Keine Handbücher',
  },
}
