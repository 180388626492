export default {
    currentDate: {
        en: 'Current date',
        sk: 'Aktuálny dátum',
        cz: 'Dnešní datum',
        de: 'Aktuelles Datum',
    },
    selectDateFrom: {
        en: 'Select date from',
        sk: 'Vyberte dátum od',
        cz: 'Vyberte datum od',
        de: 'Datum auswählen von',
    },
    selectDateTo: {
        en: 'Select date to',
        sk: 'Vyberte dátum do',
        cz: 'Vyberte datum do',
        de: 'Datum bis auswählen',
    },
    from: {
        en: 'From',
        sk: 'Od',
        cz: 'Od',
        de: 'Aus',
    },
    to: {
        en: 'To',
        sk: 'Do',
        cz: 'Do',
        de: 'Zu',
    },
    now: {
        en: 'Now',
        sk: 'Teraz',
        cz: 'Nyní',
        de: 'Jetzt',
    },
    all: {
        en: 'All',
        sk: 'Všetko',
        cz: 'Všechno',
        de: 'Alle',
    },
    setNow: {
        en: 'Set now',
        sk: 'Nastaviť teraz',
        cz: 'Nastavte nyní',
        de: 'Jetzt einstellen',
    },
    unset: {
        en: 'Unset',
        sk: 'Odnastaviť',
        cz: 'Odnastavte',
        de: 'Ungesetzt',
    },
    noDate: {
        en: 'No date',
        sk: 'Bez dátumu',
        cz: 'Žádné rande',
        de: 'Kein Datum',
    },
    thisWeek: {
        en: 'This week',
        sk: 'Tento týždeň',
        cz: 'This week',
        de: 'In dieser Woche',
    },
    lastWeek: {
        en: 'Last week',
        sk: 'Minulý týždeň',
        cz: 'Last week',
        de: 'Last week',
    },
    thisMonth: {
        en: 'This month',
        sk: 'Tento mesiac',
        cz: 'Tento měsíc',
        de: 'Diesen Monat',
    },
    lastMonth: {
        en: 'Last month',
        sk: 'Posledný mesiac',
        cz: 'Minulý měsíc',
        de: 'Im vergangenen Monat',
    },
    setDate: {
        en: 'Set Date',
        sk: 'Nastaviť dátum',
        cz: 'Nastavit datum',
        de: 'Datum einstellen',
    },
};
