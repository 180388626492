import { gql } from '@apollo/client';

export const GET_COMMENTS = gql`
    query comments(
        $task: Int!
        $page: Int
        $limit: Int
        $fromInvoice: Boolean
    ) {
        comments(
            task: $task
            page: $page
            limit: $limit
            fromInvoice: $fromInvoice
        ) {
            id
            messageCount
            createdAt
            internal
            message
            html
            subject
            tos
            emlPath
            emailSend
            emailError
            user {
                id
                fullName
                email
                name
                surname
            }
            commentAttachments {
                id
                path
                filename
                size
                mimetype
            }
            commentTextAttachments {
                id
                path
                textId
            }
        }
    }
`;

export const COMMENTS_SUBSCRIPTION = gql`
    subscription commentsSubscription($taskId: Int!) {
        commentsSubscription(taskId: $taskId)
    }
`;
