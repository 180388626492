export default {
    lanwiki: {
        en: 'LanWiki',
        sk: 'LanWiki',
        cz: 'LanWiki',
        de: 'LanWiki',
    },
    wiki: {
        en: 'Wiki',
        sk: 'Wiki',
        cz: 'Wiki',
        de: 'Wiki',
    },
};
