import React from 'react';
import Loading from 'components/loading';

import {
  useParams,
} from 'react-router-dom';

export default function SettingLoading() {
  //data
  const params = useParams();

  return (
    <div className="content">
      <div className="row m-0 p-0 taskList-container">
        <div className="col-lg-4">
          <Loading />
        </div>
        <div className="col-lg-8">
          {params.id &&
            <Loading />
          }
        </div>
      </div>
    </div>
  )
}