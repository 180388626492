import React from 'react';

import Form from './form';

import { useTranslation } from 'react-i18next';

export default function AddressesTable(props) {
    const { addresses, onAdd, onEdit, onDelete, disabled } = props;

    const { t } = useTranslation();

    const [addOpen, setAddOpen] = React.useState(false);
    const [edited, setEdited] = React.useState(null);
    const [fullAddressRows, setFullAddressRows] = React.useState([]);

    return (
        <div>
            <table className="table">
                <thead>
                    <tr className="bolder">
                        <th>{t('nic')}</th>
                        <th className="text-center">{t('ip')}</th>
                        <th className="text-center">{t('mask')}</th>
                        <th className="text-center">{t('gateway')}</th>
                        <th className="text-center">{t('dns')}</th>
                        <th className="text-center">{t('vlan')}</th>
                        <th className="text-center">{t('note')}</th>
                        {!disabled && (
                            <th className="text-center" width="55">
                                {t('actions')}
                            </th>
                        )}
                    </tr>
                </thead>

                <tbody>
                    {addresses.map((address) => (
                        <tr
                            key={address.id}
                            style={address.note.length > 100 ? { cursor: 'pointer' } : {}}
                            onClick={() => {
                                if (fullAddressRows.includes(address.id)) {
                                    setFullAddressRows(
                                        [...fullAddressRows].filter((a) => a !== address.id)
                                    );
                                } else {
                                    setFullAddressRows([...fullAddressRows, address.id]);
                                }
                            }}
                        >
                            <td>{address.nic}</td>
                            <td className="text-center">{address.ip}</td>
                            <td className="text-center">{address.mask}</td>
                            <td className="text-center">{address.gateway}</td>
                            <td className="text-center">{address.dns}</td>
                            <td className="text-center">{address.vlan}</td>
                            <td className="max-width-200 text-center">
                                {fullAddressRows.includes(address.id)
                                    ? address.note
                                    : address.note.substring(0, 100)}
                            </td>
                            {!disabled && (
                                <td className="text-center flex-row align-items-center">
                                    <button className="btn-link" onClick={() => setEdited(address)}>
                                        <span className="material-symbols-outlined">edit</span>
                                    </button>
                                    <button
                                        className="btn-link"
                                        onClick={() => onDelete(address.id)}
                                    >
                                        <span className="material-symbols-outlined p-r-0">
                                            close
                                        </span>
                                    </button>
                                </td>
                            )}
                        </tr>
                    ))}
                    {!disabled && (
                        <tr key="add">
                            <td colSpan="10">
                                <button className="btn-link" onClick={() => setAddOpen(true)}>
                                    <span className="material-symbols-outlined bigger">add</span>
                                    {t('address2')}
                                </button>
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
            <Form
                address={edited}
                open={addOpen || edited !== null}
                close={() => {
                    setAddOpen(false);
                    setEdited(null);
                }}
                edit={!addOpen && edited}
                onChange={(newData) => {
                    if (addOpen) {
                        onAdd(newData);
                        setAddOpen(false);
                    } else {
                        onEdit(newData);
                        setEdited(null);
                    }
                }}
            />
        </div>
    );
}
