import { gql } from '@apollo/client';

import { subfolder } from './subfolders';

export const folder = `
    id
    title
    order
    archived
    myRights {
        write
        manage
    }
    subfolders{
        ${subfolder}
        childrenSubfolders{
          ${subfolder}
          childrenSubfolders{
            ${subfolder}
            childrenSubfolders{
              ${subfolder}
            }
          }
        }
    }
`;

export const GET_FOLDERS = gql`
    query lanwikiFolders($archived: Boolean, $projectId: Int) {
        lanwikiFolders(archived: $archived, projectId: $projectId) {
            ${folder}
        }
    }
`;

export const GET_FOLDER = gql`
    query lanwikiFolder($id: Int!) {
        lanwikiFolder(id: $id) {
            title
            archived
            order
            description
            folderRights {
                active
                read
                write
                manage
                user {
                    id
                    email
                    fullName
                }
            }
        }
    }
`;

export const GET_LANWIKI_USERS = gql`
    query lanwikiUsers($projectId: Int!) {
        lanwikiUsers(projectId: $projectId) {
            id
            email
            fullName
        }
    }
`;

export const ADD_FOLDER = gql`
    mutation addLanwikiFolder(
        $title: String!
        $archived: Boolean!
        $order: Int!
        $description: String!
        $folderRights: [LanwikiFolderRightInput]!
        $projectId: Int!
    ) {
        addLanwikiFolder(
            title: $title
            archived: $archived
            order: $order
            description: $description
            folderRights: $folderRights
            projectId: $projectId
        ) {
            id
        }
    }
`;

export const UPDATE_FOLDER = gql`
    mutation updateLanwikiFolder(
        $id: Int!
        $title: String!
        $archived: Boolean!
        $order: Int!
        $description: String!
        $folderRights: [LanwikiFolderRightInput]!
    ) {
        updateLanwikiFolder(
            id: $id
            title: $title
            archived: $archived
            order: $order
            description: $description
            folderRights: $folderRights
        ) {
            ${folder}
        }
    }
`;

export const DELETE_FOLDER = gql`
    mutation deleteLanwikiFolder($id: Int!, $newId: Int) {
        deleteLanwikiFolder(id: $id, newId: $newId) {
            id
        }
    }
`;

export const FOLDERS_SUBSCRIPTION = gql`
    subscription lanwikiFolderSubscription {
        lanwikiFolderSubscription
    }
`;
