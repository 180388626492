import React from 'react';

import {
    useMutation,
    useQuery,
    useApolloClient,
} from '@apollo/client';

import Form from './form';
import Loading from 'components/loading';

import { useParams } from 'react-router-dom';

import { toSelArr } from 'helperFunctions';
import { addLocalError } from 'apollo/localSchema/actions';
import { useTranslation } from 'react-i18next';

import {
    GET_IMAPS,
    GET_IMAP,
    UPDATE_IMAP,
    TEST_IMAP,
    GET_ROLES,
    GET_BASIC_COMPANIES,
    GET_PROJECTS,
} from 'helpdesk/queries';

export default function IMAPEdit(props) {
    const { id, closeModal } = props;

    const params = useParams();

    const { t } = useTranslation();

    const client = useApolloClient();
    const allIMAPs = toSelArr(
        client.readQuery({
            query: GET_IMAPS,
        }).imaps
    );

    const {
        data: imapData,
        loading: imapLoading,
        refetch: imapRefetch,
    } = useQuery(GET_IMAP, {
        variables: {
            id,
        },
        fetchPolicy: 'network-only',
        notifyOnNetworkStatusChange: true,
    });

    const { data: roleData, loading: rolesLoading } =
        useQuery(GET_ROLES);

    const { data: companyData, loading: companiesLoading } =
        useQuery(GET_BASIC_COMPANIES);

    const { data: projectData, loading: projectsLoading } =
        useQuery(GET_PROJECTS);

    const [testImap] = useMutation(TEST_IMAP);
    const [updateImap] = useMutation(UPDATE_IMAP);

    //state
    const [tested, setTested] = React.useState(false);

    const [saving, setSaving] = React.useState(false);
    const [newData, setNewData] = React.useState(false);

    const dataLoading =
        imapLoading ||
        rolesLoading ||
        companiesLoading ||
        projectsLoading;

    // sync
    React.useEffect(() => {
        if (!dataLoading) {
            setTested(false);
            setNewData(false);
        }
    }, [dataLoading]);

    React.useEffect(() => {
        imapRefetch({
            id,
        });
    }, [id]);

    // functions
    const updateIMAPFunc = (data) => {
        setSaving(true);
        updateImap({
            variables: {
                id,
                ...data,
            },
        })
            .then((response) => {
                const updatedIMAP = {
                    ...response.data.updateImap,
                };
                client.writeQuery({
                    query: GET_IMAPS,
                    data: {
                        imaps: [
                            ...allIMAPs.map((IMAP) => {
                                if (IMAP.id === id) {
                                    return {
                                        ...updatedIMAP,
                                    };
                                } else {
                                    return {
                                        ...IMAP,
                                    };
                                }
                            }),
                        ],
                    },
                });

                closeModal();
            })
            .catch((err) => {
                addLocalError(err);
            });

        setSaving(false);
        setNewData(false);
    };

    const startTest = () => {
        let newImaps = [...allIMAPs];
        let imapIndex = newImaps.findIndex(
            (imap) => imap.id === parseInt(params.id)
        );
        newImaps[imapIndex].currentlyTested = true;
        client.writeQuery({
            query: GET_IMAPS,
            data: {
                imaps: newImaps,
            },
        });
        setTested(true);
        testImap({
            variables: {
                id: parseInt(params.id),
            },
        });
    };

    if (dataLoading) {
        return <Loading />;
    }

    return (
        <Form
            label={`${t('edit')} ${t('imap')}`}
            onSave={updateIMAPFunc}
            onTestImap={startTest}
            tested={tested}
            onClose={closeModal}
            saving={saving}
            newData={newData}
            setNewData={setNewData}
            roles={toSelArr(roleData.roles)}
            companies={toSelArr(companyData.basicCompanies)}
            projects={toSelArr(projectData.projects)}
            imap={imapData.imap}
        />
    );
}
