export default {
  lanpass: {
    en: 'LanPass',
    sk: 'LanPass',
    cz: 'LanPass',
    de: 'LanPass',
  },
  pass: {
    en: 'LanPass',
    sk: 'LanPass',
    cz: 'LanPass',
    de: 'LanPass',
  },
}
