import React from 'react';

import AddEventAutomation from './add';
import EditEventAutomation from './edit';

import { useTranslation } from 'react-i18next';

export default function EventAutomation(props) {
    const { automationEvents, addAutomationEvent, updateAutomationEvent, deleteAutomationEvent } =
        props;

    const { t } = useTranslation();

    const [addOpen, setAddOpen] = React.useState(false);
    const [editOpen, setEditOpen] = React.useState(null);

    const disabled = false;

    return (
        <div className="project-settings">
            <h3>{t('eventAutomation')}</h3>
            <table className="table m-t-10 bkg-white">
                <thead>
                    <tr>
                        <th> {t('title')} </th>
                        <th width="200px"> {t('description')} </th>
                        <th width="100px"> {t('active')} </th>
                        <th className="text-center" width="100px">
                            {' '}
                            {t('order')}{' '}
                        </th>
                        <th className="text-center " width="100px">
                            {t('actions')}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {automationEvents
                        .sort((o1, o2) => (parseInt(o1.order) < parseInt(o2.order) ? -1 : 1))
                        .map((automation) => (
                            <tr key={automation.id}>
                                <td>
                                    {' '}
                                    {automation.title.length ? automation.title : t('noTitle')}{' '}
                                </td>
                                <td>
                                    {' '}
                                    {automation.description.length
                                        ? automation.description
                                        : t('noDescription')}{' '}
                                </td>
                                <td> {automation.active ? t('yes') : t('no')} </td>
                                <td className="text-center"> {automation.order} </td>
                                <td>
                                    <div className="row justify-content-center">
                                        <button
                                            className="btn-link btn-distance"
                                            disabled={disabled}
                                            onClick={() => {
                                                if (window.confirm(t('areYouSure'))) {
                                                    deleteAutomationEvent(automation.id);
                                                }
                                            }}
                                        >
                                            <span className="material-symbols-outlined p-r-0">
                                                close
                                            </span>
                                        </button>
                                        <button
                                            className="btn-link"
                                            disabled={disabled}
                                            onClick={() => {
                                                setEditOpen(automation);
                                            }}
                                        >
                                            <span className="material-symbols-outlined">edit</span>
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        ))}
                </tbody>
            </table>
            <button
                className="btn-link"
                disabled={disabled}
                onClick={() => {
                    setAddOpen(true);
                }}
            >
                <span className="material-symbols-outlined bigger">add</span>
                {t('item')}
            </button>
            <AddEventAutomation
                {...props}
                open={addOpen}
                closeModal={() => setAddOpen(false)}
                addAutomationEvent={addAutomationEvent}
            />
            <EditEventAutomation
                {...props}
                open={editOpen !== null}
                automationEvent={editOpen}
                closeModal={() => setEditOpen(null)}
                updateAutomationEvent={updateAutomationEvent}
            />
        </div>
    );
}
