import React from 'react';
import { useQuery, useSubscription } from '@apollo/client';

import Loading from 'components/loading';

import { GET_PROJECT } from 'apollo/queries';

import { GET_REPEATS, REPEATS_SUBSCRIPTION } from 'helpdesk/queries';
import RepeatList from './repeatsList';

export default function RepeatListLoader() {
    // state
    const { data: projectData } = useQuery(GET_PROJECT);

    const {
        data: repeatsData,
        loading: repeatsLoading,
        refetch: repeatsRefetch,
    } = useQuery(GET_REPEATS, {
        variables: {
            projectId: projectData.localProject.id,
        },
        fetchPolicy: 'network-only',
    });

    useSubscription(REPEATS_SUBSCRIPTION, {
        onData: () => {
            repeatsRefetch();
        },
    });

    if (repeatsLoading) {
        return <Loading />;
    }

    const projectSelected = projectData.localProject.id !== null;
    return <RepeatList repeatsData={repeatsData} projectSelected={projectSelected} />;
}
