import React from 'react';

import { useNavigate, useLocation } from 'react-router-dom';
import { useQuery, useSubscription } from '@apollo/client';
import classnames from 'classnames';
import { getMyData, getLocation } from 'helperFunctions';
import {
    GET_ERROR_MESSAGES_COUNT,
    ERROR_MESSAGE_COUNT_SUBSCRIPTION,
} from 'components/queries';

export default function ErrorIcon() {
    const navigate = useNavigate();
    const location = useLocation();

    const {
        data: errorMessageCountData,
        loading: errorMessageCountLoading,
        refetch: errorMessageCountRefetch,
    } = useQuery(GET_ERROR_MESSAGES_COUNT);

    useSubscription(ERROR_MESSAGE_COUNT_SUBSCRIPTION, {
        onData: () => {
            errorMessageCountRefetch();
        },
    });
    const currentUser = getMyData();
    if (!currentUser || errorMessageCountLoading) {
        return null;
    }
    const accessRights = currentUser.role.accessRights;
    if (!accessRights.viewErrors) {
        return null;
    }

    return (
        <div
            className="header-icon"
            id="page-header-notifications"
            onClick={() =>
                navigate(
                    `${getLocation(location)}/errorMessages`
                )
            }
        >
            <span
                className={classnames(
                    {
                        'color-danger':
                            errorMessageCountData.errorMessageCount >
                            0,
                    },
                    'material-symbols-outlined'
                )}
            >
                warning
            </span>
            <span className="header-icon-text">
                {errorMessageCountData.errorMessageCount}
            </span>
        </div>
    );
}
