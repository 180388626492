import React from 'react';

import { Nav, Label } from 'reactstrap';
import { NavLink } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

export default function AISidebar() {
    //data & queries

    const { t } = useTranslation();

    return (
        <Nav vertical>
            <div
                className="sidebar-align p-t-7 p-b-7"
                style={{ display: 'flex' }}
            >
                <span className="material-symbols-outlined p-r-5">
                    settings
                </span>
                <Label className="noselect m-b-0">
                    {t('aiSettings')}
                </Label>
            </div>
            <NavLink
                key="ai-sort"
                className={({ isActive }) =>
                    `link-item ${isActive ? 'active' : ''}`
                }
                to={`/helpdesk/settings/ai/sort`}
            >
                {t('aiSort')}
            </NavLink>
            <NavLink
                key="ai-solutions"
                className={({ isActive }) =>
                    `link-item ${isActive ? 'active' : ''}`
                }
                to={`/helpdesk/settings/ai/solutions`}
            >
                {t('aiSolutions')}
            </NavLink>
            <NavLink
                key="ai-notifications"
                className={({ isActive }) =>
                    `link-item ${isActive ? 'active' : ''}`
                }
                to={`/helpdesk/settings/ai/notifications`}
            >
                {t('aiNotifications')}
            </NavLink>
            <NavLink
                key="ai-statistics"
                className={({ isActive }) =>
                    `link-item ${isActive ? 'active' : ''}`
                }
                to={`/helpdesk/settings/ai/statistics`}
            >
                {t('aiStatistics')}
            </NavLink>

            <NavLink
                key="back"
                className={() =>
                    `link-item text-highlight p-l-8`
                }
                to={`/helpdesk/settings`}
            >
                <span className="material-symbols-outlined bigger">
                    chevron_left
                </span>
                {t('back')}
            </NavLink>
        </Nav>
    );
}
