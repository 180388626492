import React from 'react';
import { timestampToStringFNS } from 'helperFunctions';
import { useTranslation } from 'react-i18next';

export default function ColumnItemRender(props) {
    const { task } = props;

    const { t } = useTranslation();

    const RenderColumnRow = (props) => {
        const { label, text, children } = props;
        return (
            <div className="flex-row">
                <div>
                    <span
                        style={{ textOverflow: 'ellipsis' }}
                    >
                        <span className="attribute-label">
                            {label}:{' '}
                        </span>
                        {text}
                    </span>
                </div>
                <div className="m-l-auto">{children}</div>
            </div>
        );
    };

    return (
        <li>
            <div className="taskCol-title">
                <span className="attribute-label">
                    #{task.id} |{' '}
                </span>{' '}
                {task.title}
            </div>
            <div className="taskCol-body">
                <RenderColumnRow
                    label={t('requester')}
                    text={
                        task.requester
                            ? ' ' + task.requester.fullName
                            : t('unknownUser')
                    }
                >
                    <span
                        className="label-info"
                        style={{
                            backgroundColor:
                                task.status &&
                                task.status.color
                                    ? task.status.color
                                    : 'white',
                        }}
                    >
                        {task.status
                            ? task.status.title
                            : t('unknownStatus')}
                    </span>
                </RenderColumnRow>
                <RenderColumnRow
                    label={t('company')}
                    text={
                        task.company
                            ? task.company.title
                            : t('unknown')
                    }
                >
                    <span className="attribute-label row align-items-center">
                        <span className="material-symbols-outlined">
                            magic_button
                        </span>
                        {task.createdAt
                            ? timestampToStringFNS(
                                  task.createdAt
                              )
                            : t('none')}
                    </span>
                </RenderColumnRow>
                <RenderColumnRow
                    label={t('assignedTo')}
                    text={
                        task.assignedTo
                            ? task.assignedTo
                                  .reduce(
                                      (total, user) =>
                                          (total +=
                                              user.fullName +
                                              ', '),
                                      ''
                                  )
                                  .slice(0, -2)
                            : t('unknownUser')
                    }
                >
                    <span className="row align-items-center">
                        <span className="material-symbols-outlined attribute-label">
                            warning
                        </span>
                        {task.deadline
                            ? timestampToStringFNS(
                                  task.deadline
                              )
                            : t('deadlinePlaceholder')}
                    </span>
                </RenderColumnRow>
            </div>

            {task.tags.length > 0 && (
                <div>
                    {task.tags.map((tag) => (
                        <span
                            key={tag.id}
                            className="label-info m-r-5"
                            style={{
                                backgroundColor: tag.color,
                                color: 'white',
                            }}
                        >
                            {tag.title}
                        </span>
                    ))}
                </div>
            )}
        </li>
    );
}
