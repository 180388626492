import React, { useMemo } from 'react';
import AddCustomAttributeModal from './addCustomAttribute';
import EditCustomAttributeModal from './editCustomAttribute';
import { useTranslation } from 'react-i18next';

export default function CustomAttributes(props) {
    const {
        disabled,
        groups,
        customAttributes,
        addCustomAttribute,
        updateCustomAttribute,
        deleteCustomAttribute,
    } = props;

    const { t } = useTranslation();

    const [addCustomAttributeOpen, setAddCustomAttributeOpen] = React.useState(false);
    const [editCustomAttributeOpen, setEditCustomAttributeOpen] = React.useState(null);

    const getDefaultValues = (customAttribute) => {
        const type =
            typeof customAttribute.type === 'string'
                ? customAttribute.type
                : customAttribute.type.value;
        if (['text', 'textarea'].includes(type)) {
            return customAttribute.defaultValue.text;
        }
        if (['number'].includes(type)) {
            return customAttribute.defaultValue.number;
        }
        return customAttribute.selectValues
            .filter((option) => option.def)
            .sort((o1, o2) => (o1.order < o2.order ? -1 : 1))
            .map((option) => (
                <p id={option.id} key={option.id ? option.id : option.value}>
                    {option.value.substring(0, 1).toUpperCase() + option.value.substring(1)}
                </p>
            ));
    };

    const translatedGroups = useMemo(() => {
        return groups.map((group) => ({
            ...group,
            title: t(group.title),
        }));
    }, [groups]);

    return (
        <div className="project-settings">
            <table className="table m-t-10 bkg-white">
                <thead>
                    <tr>
                        <th> {t('order')} </th>
                        <th> {t('title')} </th>
                        <th> {t('type')} </th>
                        <th> {t('options')} </th>
                        <th> {t('add')} </th>
                        <th> {t('read')} </th>
                        <th> {t('write')} </th>
                        <th> {t('defaultValue')} </th>
                        <th> {t('required')} </th>
                        <th className="text-center"></th>
                    </tr>
                </thead>
                <tbody>
                    {customAttributes
                        .sort((o1, o2) => (parseInt(o1.order) < parseInt(o2.order) ? -1 : 1))
                        .map((customAttribute) => (
                            <tr key={customAttribute.id}>
                                <td className="text-center">{customAttribute.order}</td>
                                <td>{customAttribute.title}</td>
                                <td>
                                    {t(
                                        typeof customAttribute.type === 'string'
                                            ? customAttribute.type === 'select'
                                                ? t(customAttribute.type + 'Noun')
                                                : t(customAttribute.type)
                                            : customAttribute.type.value
                                    )}
                                </td>
                                <td>
                                    {customAttribute.selectValues
                                        ? customAttribute.selectValues
                                              .sort((o1, o2) => (o1.order < o2.order ? -1 : 1))
                                              .map((option) => (
                                                  <p
                                                      id={option.id}
                                                      key={option.id ? option.id : option.value}
                                                  >
                                                      {option.value.substring(0, 1).toUpperCase() +
                                                          option.value.substring(1)}
                                                  </p>
                                              ))
                                        : ''}
                                </td>
                                <td>
                                    {customAttribute.addGroups.map((add) => (
                                        <p id={add.id} key={add.id}>
                                            {t(add.title)}
                                        </p>
                                    ))}
                                </td>
                                <td>
                                    {customAttribute.viewGroups.map((read) => (
                                        <p id={read.id} key={read.id}>
                                            {t(read.title)}
                                        </p>
                                    ))}
                                </td>
                                <td>
                                    {customAttribute.editGroups.map((write) => (
                                        <p id={write.id} key={write.id}>
                                            {t(write.title)}
                                        </p>
                                    ))}
                                </td>
                                <td>{getDefaultValues(customAttribute)}</td>
                                <td>{customAttribute.required ? t('yes') : t('no')}</td>
                                <td>
                                    <div className="row ">
                                        <button
                                            className="btn-link btn-distance"
                                            disabled={disabled}
                                            onClick={() => {
                                                if (window.confirm(t('areYouSure'))) {
                                                    deleteCustomAttribute(customAttribute.id);
                                                }
                                            }}
                                        >
                                            <span className="material-symbols-outlined p-r-0">
                                                close
                                            </span>
                                        </button>
                                        <button
                                            className="btn-link"
                                            disabled={disabled}
                                            onClick={() => {
                                                setEditCustomAttributeOpen(customAttribute);
                                            }}
                                        >
                                            <span className="material-symbols-outlined">edit</span>
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        ))}
                </tbody>
            </table>
            <button
                className="btn-link"
                disabled={disabled}
                onClick={() => {
                    setAddCustomAttributeOpen(true);
                }}
            >
                <span className="material-symbols-outlined bigger">add</span>
                {t('customAttribute')}
            </button>
            <AddCustomAttributeModal
                groups={translatedGroups}
                open={addCustomAttributeOpen}
                addCustomAttribute={addCustomAttribute}
                closeModal={() => setAddCustomAttributeOpen(false)}
            />
            <EditCustomAttributeModal
                groups={translatedGroups}
                open={editCustomAttributeOpen !== null}
                updateCustomAttribute={updateCustomAttribute}
                attribute={editCustomAttributeOpen}
                closeModal={() => setEditCustomAttributeOpen(null)}
            />
        </div>
    );
}
