import React from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import Empty from 'components/Empty';
import ProjectFilterForm from './projectFilterForm';
import { useTranslation } from 'react-i18next';

export default function ProjectFilterAdd(props) {
    //props
    const {
        allGroups,
        allStatuses,
        allTags,
        customAttributes,
        allUsers,
        allCompanies,
        addProjectFilter,
        /*allTaskTypes,*/
    } = props;

    const { t } = useTranslation();

    const [open, setOpen] = React.useState(false);

    return (
        <Empty>
            <button
                className="btn-link btn-distance"
                onClick={() => setOpen(true)}
            >
                <span className="material-symbols-outlined bigger">
                    add
                </span>
                {t('projectFilter')}
            </button>
            <Modal isOpen={open}>
                <ModalHeader>
                    {`${t('add')} ${t(
                        'projectFilter'
                    ).toLowerCase()}`}
                </ModalHeader>
                <ModalBody>
                    <ProjectFilterForm
                        submit={(projectFilter) =>
                            addProjectFilter(projectFilter)
                        }
                        closeModal={() => setOpen(false)}
                        allGroups={allGroups}
                        allStatuses={allStatuses}
                        allTags={allTags}
                        allCustomAttributes={
                            customAttributes
                        }
                        allUsers={allUsers}
                        allCompanies={allCompanies}
                    />
                </ModalBody>
            </Modal>
        </Empty>
    );
}
